import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import ReportCard from "./ReportCard";
import ServiceAuth from "./ServiceAuth";
import { Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from "moment";
import dayjs from 'dayjs';

const Billings = (props) => {
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [custName, setCustName] = useState([]);

  // { "primaryBusinessType": "Support Coordination", "featureValue": 101 },
  // { "primaryBusinessType": "Service Provider", "featureValue": 102 },
  let pBusinessType = props.user?.comp_settings.find(setting => setting.feature_value === 'primaryBusinessType') ? props.user.comp_settings.filter((setting) => setting.feature_value === "primaryBusinessType")[0].feature_id : 101

  let displayReports;
  if (pBusinessType === 101) {
    displayReports = props.data.filter((report) => report.report_name !== 'Invoice Report');
  } else {
    displayReports = props.data;
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCustName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleMenuItemClick = (event) => {
    if (props.state.cus_DDDIDs.indexOf(event.target.id) === -1) {
      // event.target.id not found in DDDIDs, add it
      props?.setState({
        ...props?.state,
        cus_DDDIDs: [...props.state.cus_DDDIDs, event.target.id],
      });
    } else {
      // event.target.id found in DDDIDs, remove it
      props?.setState({
        ...props?.state,
        cus_DDDIDs: props.state.cus_DDDIDs.filter((id) => id !== event.target.id),
      });
    }
  }

  return (
    <Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              fontFamily: "Lato",
              marginBottom: 30,
            }}
          >
            Billing Reports
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: "From Date", end: "To Date" }}
            >
              <DateRangePicker
                
                value={dayjs(props?.billingFilterData?.dateRange)}
                onChange={(newValue) => {
                  newValue = newValue.map((d) => dayjs(d).format('YYYY-MM-DD'))
                  props?.setState({
                    ...props?.state,
                    billingFilterData: {
                      ...props?.state.billingFilterData,
                      dateRange: newValue,
                    },
                  });
                  console.log('billingFilterData props', props.billingFilterData)
                  console.log('billingFilterData >>>', moment(
                    new Date(newValue[0])
                  ).isValid()
                    ? moment(
                      new Date(newValue[0])
                    ).format("YYYY-MM-DD")
                    : null)
                }}
                slotProps={{ fieldSeparator: { children: 'to' } }}
              />
            </LocalizationProvider>
          </Grid>
          <div>
            <FormControl sx={{ m: 2, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Select Client</InputLabel>
              <Select
                labelId="select-client-label"
                id="select-client"
                multiple
                value={custName}
                onChange={handleChange}
                input={<OutlinedInput label="Select Client" />}
              >
                {props.state.customerList.map((cus) => (
                  <MenuItem
                    id={cus.DDDID}
                    key={cus.cus_id}
                    value={cus.cus_name}
                    onClick={handleMenuItemClick}
                  >
                    {cus.cus_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ bottom: "10px", top: "10px", position: "relative" }}
      >
        <Grid item xs={9}>
          {displayReports.map((e) => (
            e.report_type === 1 ?
              <ReportCard
                data={e}
                openReport={props?.openReport}
                markFavourite={props?.markFavourite}
              />
              :
              <ReportCard
                data={e}
                openReport={(e) => { setSelectedScreen(e.report_name) }}
                markFavourite={props?.markFavourite}
              />
          ))}
        </Grid>
      </Grid>
      <Grid container style={{ bottom: "10px" }}>
        <Grid item xs={12}>
          {selectedScreen === "Service Authorization Report" ? <ServiceAuth {...props} screenName={'Service Authorization Report'} /> : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Billings;
