import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, Alert, Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function LateMTReport(props) {
    // const [gridId, setGridId] = useState(56);
    // const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
    const [reportDetails, setReportDetails] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, [currentMonth]);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports", {
                params: { empID: props.user.emp_id },
            })
            .then((response) => {
                const report = response?.data?.reports.find((ele) => ele.report_name === "Late MT Report");
                setReportDetails(report);
                setIsFavorite(report ? (report.status === 1 ? true : false) : false);
            });

        axios
            .get(APIURL + "/reports/getLateMT", {
                params: { com_id: props.user.comp_id, period: moment(currentMonth).format('MMM YYYY') },
            })
            .then((response) => {
                setData(response?.data.length !== 0 ? response?.data : []);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const columns = [
        { field: "tas_id", headerName: "Task ID", hide: true },
        { field: "cus_name", headerName: "Customer Name", width: 200 },
        {
            field: "pro_ends_on",
            headerName: "Plan End Date",
            width: 130,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "SC_name", headerName: "SC Name", width: 200 },
        { field: "SC_supervisor_name", headerName: "SC Supervisor Name", width: 200 },
        { field: "tas_mm_id", headerName: "MM ID", hide: true },
        { field: "tas_mm_status", headerName: "MT Status", width: 200 },
    ]

    let rows = data.map((data, idx) => {
        let SC_name = (data.emp_first_name && data.emp_last_name) ? (data.emp_first_name + ' ' + data.emp_last_name) : '';
        let SC_supervisor_name = data.SC_MANAGER ? data.SC_MANAGER[0].emp_first_name + ' ' + data.SC_MANAGER[0].emp_last_name : '';

        let taskData = {
            id: idx,
            tas_id: data.tas_id,
            cus_name: data.cus_name,
            pro_ends_on: data.pro_ends_on,
            SC_name: SC_name,
            SC_supervisor_name: SC_supervisor_name,
            tas_mm_id: data.tas_mm_id,
            tas_mm_status: data.tas_mm_status,
        };
        return taskData;
    });

    function onDateChange(newValue) {
        setCurrMonth(moment(newValue).startOf("month"));
    }

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
            empID: props.user.emp_id
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            (<div style={{ margin: 10, width: '75vw' }}>
                {rows.length === 0 ?
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: "500px", marginTop: 10 }}
                    >
                        <img
                            src="./nosearchresult.png"
                            alt="nosearchresult"
                            style={{ width: "25rem", height: "15rem", margin: 10 }}
                        />
                        <h5
                            style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
                            className="text-center"
                        >
                            There is currently no data available{" "}
                        </h5>
                    </div>
                    :
                    <div style={{ marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div id="report-main" style={{ marginBottom: 20 }}>
                                <Button
                                    size="large"
                                    className="DayButton"
                                    onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                </Button>
                                <LocalizationProvider
                                    class="DatePickerParent"
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        label="Select Date"
                                        views={["year", "month"]}
                                        value={new Date(currentMonth)}
                                        onChange={(newValue) => {
                                            onDateChange(newValue);
                                        }}
                                        slotProps={{textField: {variant: 'standard'}}}
                                    />
                                </LocalizationProvider>
                                <Button
                                    size="large"
                                    className="DayButton"
                                    onClick={() => onDateChange(currentMonth.add(1, "month"))}
                                >
                                    <i className="fas fa-arrow-right"></i>
                                </Button>
                            </div>
                            <div>
                                <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                                    <img
                                        src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                                        alt="barchart"
                                        style={{
                                            fontFamily: "Lato",
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            cursor: "pointer",
                                            margin: 5
                                        }}
                                        onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                                    />
                                </Tip>
                            </div>
                        </div>
                        <div style={{ height: '75vh' }}>
                            <DataGridPro
                                // gridId={gridId}
                                // user={props.user}
                                initialState={{ sorting: { sortModel: [{ field: "pro_ends_on", sort: "asc" }] } }}
                                // apiRef={apiRef}
                                getRowId={(row) => row.id}
                                rows={rows}
                                columns={columns}
                                slots={{
                                    toolbar: GridToolbar,
                                }}
                                checkboxSelection={false}
                                disableRowSelectionOnClick
                            />
                        </div>
                    </div>
                }
                <Snackbar
                    open={successText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setSuccessText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"success"}
                        elevation={6}
                        variant="filled"
                    >
                        {successText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setSuccessText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={errorText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setErrorText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"error"}
                        elevation={6}
                        variant="filled"
                    >
                        {errorText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setErrorText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
            </div>)
        );
    }
}