import React from "react";
import { createRef, useImperativeHandle } from "react";
import axios from "axios";
import { APIURL } from "../../config";
import moment from "moment";
import { jsonToCSV } from "react-papaparse";
import { CSVDownloader } from "react-papaparse";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Form, FormControl, Button } from "react-bootstrap";
import { Typography, Box } from "@mui/material";
import EVVFilter from "./EVVFilter";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';

// For each employee, and each customer (project) under that employee, generate a summary line for the period selected
// First Name	Employee*
// Last Name	Employee*
// Activity Date	Task Date *
// Record Entry Date-Time	Start Date and Time*
// Account Code	Task Type*
// Regular Hrs	Task Duration*
// MT End Date	Task End Date
// Payroll End Date

const columns = [
  {
    field: "Provider",
    headerName: "Service Provider",
    width: 160,
    renderCell: (params) => {
      return (
        <div>
          <p
            className="text-wrap"
            style={{ lineHeight: "1", marginTop: "12px" }}
          >
            {params.value}{" "}
          </p>
        </div>
      );
    },
  },
  {
    field: "Receiver",
    headerName: "Service Receiver",
    width: 160,
    renderCell: (params) => {
      return (
        <div>
          <p
            className="text-wrap"
            style={{ lineHeight: "1", marginTop: "12px" }}
          >
            {params.value}{" "}
          </p>
        </div>
      );
    },
  },
  {
    field: "TaskType",
    headerName: "Type",
    width: 105,
  },

  {
    field: "DateofService",
    headerName: "Date",
    width: 108,
  },

  {
    field: "StarttoEnd",
    headerName: "Time",
    flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <p
            className="text-wrap"
            style={{ lineHeight: "1", marginTop: "12px" }}
          >
            {params.value}{" "}
          </p>
        </div>
      );
    },
  },

  {
    field: "Location",
    headerName: "Location",
    flex: 2,
    renderCell: (params) => {
      let loc_1 = params.value[0] ? params.value[0].loc_addr_line1 + ", " + params.value[0].loc_addr_line2 + ", " + params.value[0].loc_city + ", " + params.value[0].loc_state + ", " + params.value[0].loc_zip : '-';
      let loc_2 = params.value[1] ? params.value[1].loc_addr_line1 + ", " + params.value[1].loc_addr_line2 + ", " + params.value[1].loc_city + ", " + params.value[1].loc_state + ", " + params.value[1].loc_zip : '';
      return (
        <div>
          <div>
            {loc_1}
          </div>
          {loc_2 &&
            <div>
              {loc_2}
            </div>
          }
        </div>
      );
    },
  },
];

class Evv_Report extends React.Component {
  constructor(props) {
    super(props);
    this.apiRef = createRef();
  }
  state = {
    gridId: 43,
    loading: true,
    EvvReports: [],
    searchText: "",
    isCustomReportOpen: false,
    fromDate: null,
    toDate: null,
    serviceProvider: null,
    servieReciever: null,
    taskType: null,
    locations: null,
    filterResultsOutput: "",
    pageSize: 10,
    pageNo: 0,
  };
  async componentDidMount() {
    let evv_reports = [];
    let customer_det = [];

    if (this.props.user.type === "Admin") {
      evv_reports = await axios.get(APIURL + "/timesheets", {
        params: {
          comp_id: this.props.comp_id,
          employeeId: this.props.user.emp_id,
          companyId: this.props.comp_id,
          start: moment().startOf("month").format("YYYY-MM-DD"),
          end: moment().endOf("month").format("YYYY-MM-DD"),
        },
      });
      customer_det = await axios.get(APIURL + "/customers/v2/associated", {
        params: { company_id: this.props.comp_id, employee_id: this.props.user.emp_id },
      });
      // evv_reports = evv_reports.data.filter((loop1)=> ((!!loop1.taskDets && (loop1.taskDets.tas_type === 'Home Visit' || loop1.taskDets.tas_type === 'Visit' || loop1.taskDets.tas_type === 'In-Person'))))
    } else if (this.props.user.type === "Manager") {
      evv_reports = await axios.get(APIURL + "/timesheets", {
        params: {
          comp_id: this.props.comp_id,
          emp_id: this.props.user.emp_id,
          employeeId: this.props.user.emp_id,
          companyId: this.props.comp_id,
          start: moment().startOf("month").format("YYYY-MM-DD"),
          end: moment().endOf("month").format("YYYY-MM-DD"),
        },
      });
      customer_det = await axios.get(APIURL + "/customers/v2/associated", {
        params: { company_id: this.props.comp_id, employee_id: this.props.user.emp_id },
      });
      // evv_reports = evv_reports.data.filter((loop1)=>(((!!loop1.taskDets && (loop1.taskDets.tas_type === 'Home Visit' || loop1.taskDets.tas_type === 'Visit' || loop1.taskDets.tas_type === 'In-Person')))))
    } else {
      evv_reports = await axios.get(APIURL + "/timesheets", {
        params: {
          emp_id: this.props.user.emp_id,
          employeeId: this.props.user.emp_id,
          companyId: this.props.comp_id,
          start: moment().startOf("month").format("YYYY-MM-DD"),
          end: moment().endOf("month").format("YYYY-MM-DD"),
        },
      });
      customer_det = await axios.get(APIURL + "/customers/v2/associated", {
        params: { company_id: this.props.comp_id, employee_id: this.props.user.emp_id },
      });
      // evv_reports = evv_reports.data.filter((loop1)=> ((!!loop1.taskDets && (loop1.taskDets.tas_type === 'Home Visit' || loop1.taskDets.tas_type === 'Visit' || loop1.taskDets.tas_type === 'In-Person'))))
    }

    const regex = /visit/i; // Case-insensitive regular expression to check for "Visit"
    evv_reports = evv_reports.data.filter(
      (loop1) =>
        !!loop1.taskDets &&
        (regex.test(loop1.taskDets.tas_type))
    );

    // console.log("Evv data - ",evv_reports,customer_det)

    let evv = evv_reports.map((evvloop) => {
      let addCusName = evvloop;
      let found_customer = !!evvloop.projDets
        ? customer_det.data.find(
          (loop) => loop.cus_id === evvloop.projDets.pro_customer_id
        )
        : null;
      // console.log("Evv iterator - ",found_customer)
      addCusName = {
        ...addCusName,
        customer_name: !!found_customer ? found_customer.cus_name : "",
      };
      return addCusName;
    });
    // console.log("Evv data - ",this.state.EvvReports)
    this.setState({ EvvReports: evv, loading: false });
    localStorage.removeItem("evvFilterData");
  }

  handlePaginationModelChange = (paginationModel) => {
    console.log("paginationModel", paginationModel);
    if (paginationModel.pageSize !== this.state.pageSize) {
      this.setState({
        ...this.state,
        pageSize: paginationModel.pageSize,
        pageNo: 0,
      });
    } else {
      this.setState({
        ...this.state,
        pageNo: paginationModel.page,
      });
    }
  };

  onChangeSearch = (e) => {
    this.setState({
      ...this.state,
      searchText: e.target.value,
    });
  };

  openCustomReport = (e) => {
    e.preventDefault();
    console.log("Custom Report");
    if (this.state.isCustomReportOpen) {
      this.setState({
        ...this.state,
        isCustomReportOpen: !this.state.isCustomReportOpen,
        filterResultsOutput: "",
      });
      localStorage.removeItem("evvFilterData");
    } else {
      this.setState({
        ...this.state,
        isCustomReportOpen: !this.state.isCustomReportOpen,
      });
    }
  };
  closeCustomReport = (e) => {
    e.preventDefault();
    console.log("Custom Report", e);
    let val;
    val = !this.state.isCustomReportOpen;
    this.setState({
      ...this.state,
      isCustomReportOpen: val,
      // filterResultsOutput: this.state.filterResultsOutput
    });
  };

  onSubmitCustomReport = () => {
    console.log("Submit Filter");
  };
  handleFilterForm = (finalObj) => {
    console.log("Final object", finalObj);

    const objectValues = Object.values(finalObj);
    const objectKeys = Object.keys(finalObj);
    let output = [];
    objectValues.forEach((element, index) => {
      if (element) {
        output.push(element);
      }
    });
    output = output.join(", ");
    console.log("output", output);
    this.setState({
      ...this.state,
      filterResults: finalObj,
      filterResultsOutput: output,
    });
  };

  render() {
    let evv_rows = this.state.EvvReports.map((EvvReportsarray) => {
      console.log(EvvReportsarray);
      let row = {
        id: EvvReportsarray.id,
        Provider: !!EvvReportsarray.empDets
          ? EvvReportsarray.empDets.emp_first_name +
          " " +
          EvvReportsarray.empDets.emp_last_name
          : "-",
        Receiver: EvvReportsarray.customer_name,
        TaskType: !!EvvReportsarray.taskDets
          ? EvvReportsarray.taskDets.tas_type
          : "-",
        Location: !!EvvReportsarray.locDets
          ? EvvReportsarray.locDets
          : "-",
        StarttoEnd: !!EvvReportsarray
          ? moment(EvvReportsarray.start_time.replace("Z", "")).format("h:mm a") +
          " - " +
          moment(EvvReportsarray.end_time.replace("Z", "")).format("h:mm a")
          : "-",
        DateofService: !!EvvReportsarray.taskDets
          ? !!moment(EvvReportsarray.taskDets.tas_end_time).isValid()
            ? moment(EvvReportsarray.taskDets.tas_end_time)
              .utc()
              .format("MM/DD/YYYY")
            : moment(EvvReportsarray.taskDets.tas_end_date)
              .utc()
              .format("MM/DD/YYYY h:mm a")
          : "-",
      };

      return row;
    });

    let row_csv_evv = evv_rows.map((repevv) => {
      let new_repevv = {
        ...repevv,
        // eslint-disable-next-line
        ["Service Provider"]: repevv.Provider,
        // eslint-disable-next-line
        ["Service Receiver"]: repevv.Receiver,
        // eslint-disable-next-line
        ["Type of Service"]: repevv.TaskType,
        // eslint-disable-next-line
        ["Date of the Service"]: repevv.DateofService,
        // eslint-disable-next-line
        ["Time the service starts and ends"]: repevv.StarttoEnd,
        // eslint-disable-next-line
        ["Location of the Service"]: repevv.Location,
        // eslint-disable-next-line
      };

      delete new_repevv.id;
      delete new_repevv.Provider;
      delete new_repevv.Receiver;
      delete new_repevv.TaskType;
      delete new_repevv.DateofService;
      delete new_repevv.StarttoEnd;
      delete new_repevv.Location;

      return new_repevv;
    });

    const csv_evv = jsonToCSV(row_csv_evv);

    let tempObj = localStorage.getItem("evvFilterData");
    if (!tempObj && this.state.filterResultsOutput != "") {
      this.setState({ filterResultsOutput: "" });
    }
    return (
      <div>
        {this.state.loading || !this.state.EvvReports ? (
          <div className="d-flex flex-row w-100 justify-content-center mt-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <Grid>
            {this.state.isCustomReportOpen ? (
              <EVVFilter
                openCustomReport={this.openCustomReport}
                closeCustomReport={this.closeCustomReport}
                onFormSubmission={this.handleFilterForm}
                filterResultsOutput={this.state.filterResultsOutput}
              />
            ) : null}
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "Lato",
                    marginBottom: 20,
                  }}
                >
                  {this.props?.screenName ?? "EVV Reports"}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              {this.state.EvvReports.length === 0 ? (
                <Grid container direction="row">
                  <Grid item xs={2}>
                    <Form inline>
                      {/* <FormControl
                      onChange={this.onChangeSearch}
                      type="text"
                      value={this.state.searchText}
                      placeholder="Search"
                      className="form-control w-50 mr-1"
                    />
                    <Button
                      type="submit"
                      variant="outline-primary"
                      style={{
                        border: "solid 1px #95D0EB",
                        marginLeft: "-5px",
                        borderRadius: "0 5px 5px 0",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                      }}
                    >
                      <i className="fas fa-search"></i>
                    </Button> */}
                      <Button
                        type="submit"
                        onClick={(e) => this.openCustomReport(e)}
                        variant="primary"
                        className="mb-4"
                      >
                        {this.state.isCustomReportOpen
                          ? "Reset"
                          : "Custom Report"}
                      </Button>
                    </Form>
                  </Grid>
                  <Grid item xs={8}>
                    {this.state.filterResultsOutput !== "" && (
                      <Typography
                        style={{
                          fontStyle: "italic",
                          fontFamily: "Lato",
                          color: "#626262",
                          top: " 7px",
                          position: "relative",
                          fontSize: "14px",
                        }}
                      >
                        Reports results: {this.state.filterResultsOutput}
                      </Typography>
                    )}
                  </Grid>
                  {this.state.EvvReports.length !== 0 ? (
                    <Grid item xs={2} container justifyContent="end">
                      <CSVDownloader
                        data={csv_evv}
                        filename={"EVV report"}
                        bom={true}
                      >
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-info m-1"
                        >
                          CSV
                        </button>
                      </CSVDownloader>
                      <CSVDownloader
                        data={csv_evv}
                        filename={"EVV report"}
                        bom={true}
                      >
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-info m-1"
                        >
                          PDF
                        </button>
                      </CSVDownloader>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}

              {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {this.state.EvvReports.length !== 0 && (
                  <CSVDownloader
                    data={csv_evv}
                    filename={"EVV report"}
                    bom={true}
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-info m-1"
                    >
                      Download report{" "}
                    </button>
                  </CSVDownloader>
                )}
              </div> */}
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
              // style={{ marginTop: 20 }}
              >
                {this.state.EvvReports.length !== 0 && (
                  <Box sx={{ width: '100%', height: "calc(100vh - 150px)" }}>
                    <ControlledDataGrid
                      gridId={this.state.gridId}
                      user={this.props.user}
                      initialState={{ sorting: { sortModel: [{ field: "DateofService", sort: "desc" }] } }}
                      apiRef={this.apiRef}
                      rows={evv_rows}
                      columns={columns}
                      autoHeight={true}
                      pagination
                      rowsPerPageOptions={[5, 10, 20]}
                      paginationModel={{ page: this.state.pageNo, pageSize: this.state.pageSize }}
                      onPaginationModelChange={(paginationModel) => this.handlePaginationModelChange(paginationModel)}
                    />
                  </Box>
                )}
              </Grid>
              {this.state.EvvReports.length === 0 && (
                <div
                  style={{ marginTop: 100 }}
                  className="d-flex flex-row w-100 justify-content-center align-items-center"
                >
                  <div>
                    <img
                      src="./EVV_reports.png"
                      alt="EVV_reports"
                      style={{ width: "10rem", height: "10rem", margin: 10 }}
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <h5
                      style={{
                        fontFamily: "Lato",
                        marginTop: 48,
                        marginBottom: 30,
                      }}
                      className="text-center"
                    >
                      Your team haven’t made any client visits yet{" "}
                    </h5>
                    <p
                      style={{ fontSize: 14, width: "70%", fontFamily: "Lato" }}
                      className="text-muted text-center text-wrap"
                    >
                      Once they have, you’ll see an overview all all visits
                      here. Still need to invite your team? Let’s add them to
                      FieldWorker now!
                    </p>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}
const EvvReport = React.forwardRef((props, ref) => {
  const apiRef = useGridApiRef();
  useImperativeHandle(ref, () => apiRef.current);

  return <Evv_Report {...props} apiRef={apiRef} />;
});

export default EvvReport;
