import React, { useState, useEffect } from "react"
import { CSVReader } from 'react-papaparse';
import { Button } from 'react-bootstrap';
import { APIURL } from '../../../config';
import { useUtils } from '../../../utils/utilities';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import Icon from '@mui/material/Icon';
import moment from 'moment'
import './BulkUpload.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    myClass: {
      color: "#007bff !important",
      "&:hover": {
        color:'#007bee !important',
        cursor: 'pointer'
      },
    },
  }));


const customerColumns = [
    { field: 'id', headerName: 'ID', flex: 1, hide: true },
    {
        field: 'name',
        headerName: 'Customer Name',
        flex: 1,
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
    },
    {
        field: 'errors',
        headerName: 'Errors',
        flex: 1.75,
        valueGetter: (value, row, column, apiRef) => row.errors.join(),    
    },
]

const employeeColumns = [
    { field: 'id', headerName: 'ID', flex: 1, hide: true },
    {
        field: 'name',
        headerName: 'Employee Name',
        flex: 1,
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
    },
    {
        field: 'errors',
        headerName: 'Errors',
        flex: 1.75,
        valueGetter: (value, row, column, apiRef) => row.errors.join(),    
    },
]

const iRecordColumns = [
    { field: 'id', headerName: 'ID', flex: 0.5, hide: true },
    { field: 'import_id', headerName: 'Import ID', flex: 0.5, },
    {
        field: 'type',
        headerName: 'Type',
        flex: 1,
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 1,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
    },
    {
        field: 'errors',
        headerName: 'Errors',
        flex: 1.75,
        valueGetter: (value, row, column, apiRef) => row.errors.join(),
    },
]

export default function BulkUploadv2(props){

    const [CSVData, setCSVData] = useState();
    const [file, setFile] = useState();
    const [option, setOption] = useState('1');
    const [loading, setLoading] = useState(false);
    const [showImportButton, setShowImportButton] = useState(false);

    const [showCustomerTable, setShowCustomerTable] = useState(false)
    const [showEmployeeTable, setShowEmployeeTable] = useState(false)
    const [showIRecordTable, setShowIRecordTable] = useState(false)
    const [employeeRows, setEmployeeRows] = useState([]);
    const [customerRows, setCustomerRows] = useState([]);
    const [iRecordRows, setiRecordRows] = useState([]);
    const [responseMessage, setResponseMessage] = useState(null)
    const [errorAlert, setErrorAlert] = useState('')

    const { openLinkInTab } = useUtils();
    const classes = useStyles();

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            
        }
        fetchData();
        return () => { mounted = false };
        // eslint-disable-next-line
    }, []);

    async function processCSV(start, end) {
        let attr = CSVData[0].data
        console.log(attr)
        let csvvals = CSVData.slice(1, CSVData.length)
        let csvdataitems = csvvals.map((obj) => { return obj.data })
        console.log(csvdataitems)
        var i = 0
        var j = 0
        let processed = []
        for (i = 0; i < csvdataitems.length; i++) {
            var temp = {}
            for (j = 0; j < attr.length; j++) {
                temp[attr[j]] = csvdataitems[i][j]
            }
            processed.push(temp)
        }
        console.log(processed.length)
        console.log(processed)
        //this.getResults(processed)
    }

    async function importCustomers(){
        let resArray = []
        var formDataPayload = new FormData()
        formDataPayload.append("filecontent", file)
        setLoading(true)
        console.log(option === '1' ? 'bulkupload' : option === '2' ? 'bulkuploadEmployee' : 'bulkuploadCustomer')
        let resultApi = await axios.post(APIURL + `/${option === '1' ? 'bulkupload' : option === '2' ? 'bulkuploadEmployee' : 'bulkuploadCustomer'}?company_id=` + props.comp_id + '&user_name=' + props.user.emp_name, formDataPayload)
        console.log(resultApi.data)

        if(resultApi.data.code === 400){
            setErrorAlert(resultApi.data.message)
            setLoading(false)
            setResponseMessage(resultApi.data.message)
        }

        //activty log
        if (resultApi.data.code === 200) {
            let logging_subtype;
            if(option=='1')
            { logging_subtype='IRecord bulk import'}
            else if(option=='2')
            { logging_subtype='Employee bulk import'}
            else if(option=='3')
            { logging_subtype='Customer bulk import'}
        
            var sha1 = require('sha1');
            let sessionID=window.localStorage.getItem('Fw4_access_Token')
            var hash = sha1(sessionID);
            var log_data={
                email:props.user.email,
                actionSubType:logging_subtype,
                actionType:'Data Import',
                sessionID:hash
            }                                                                                                                                                                                                                                                               
            let msg = await axios.post(APIURL+"/activity_log",log_data ); 
        }

        if (resultApi.data.code === 200 && option === '1') {
            let rows = []
            let nextID = 1

            console.log('iRECORD DEBUG')
            console.log(resultApi.data)
            let recordData = resultApi.data.results
            
            for(let i = 0; i < recordData.length; i++){
                rows.push({
                    id: nextID,
                    import_id: recordData[i].id,
                    type: 'Employee',
                    description: recordData[i].EmpName,
                    status: recordData[i].caseType.charAt(0) === '0' ? 'New' : recordData[i].caseType.charAt(0) === '1' ? 'Update' : 'Error',
                    errors: recordData[i].errors.emp
                })
                nextID = nextID + 1;
                rows.push({
                    id: nextID,
                    import_id: recordData[i].id,
                    type: 'Customer',
                    description: recordData[i].custName,
                    status: recordData[i].caseType.charAt(1) === '0' ? 'New' : recordData[i].caseType.charAt(1) === '1' ? 'Update' : 'Error',
                    errors: recordData[i].errors.cus
                })
                nextID = nextID + 1;
                rows.push({
                    id: nextID,
                    import_id: recordData[i].id,
                    type: 'Project',
                    description: recordData[i].projName,
                    status: recordData[i].caseType.charAt(2) === '0' ? 'New' : recordData[i].caseType.charAt(2) === '1' ? 'Update' : 'Error',
                    errors: recordData[i].errors.pro
                })
                nextID = nextID + 1;
            }

            setiRecordRows(rows)
            setShowIRecordTable(true)
            setShowEmployeeTable(false)
            setShowCustomerTable(false)

        }   else if (resultApi.data.code === 200 && option === '2') {
            let rows = []
            let nextID = 1

            for(let i = 0; i < resultApi.data.results.success.length; i++){
                rows.push({
                    id: nextID,
                    email: resultApi.data.results.success[i].email,
                    name: resultApi.data.results.success[i]['emp_first_name'] + ' ' + resultApi.data.results.success[i]['emp_last_name'],
                    status: 'Success',
                    errors: []
                })
                nextID = nextID + 1;
            }
            for(let i = 0; i < resultApi.data.results.failure.length; i++){
                rows.push({
                    id: nextID,
                    email: resultApi.data.results.failure[i].email,
                    name: resultApi.data.results.failure[i]['Employee First Name'] + ' ' +resultApi.data.results.failure[i]['Employee Last Name'],
                    status: 'Failure',
                    errors: resultApi.data.results.failure[i].errors
                })
                nextID = nextID + 1;
            }

            setEmployeeRows(rows)
            setShowEmployeeTable(true)
            setShowCustomerTable(false)
            setShowIRecordTable(false)

        } else if (resultApi.data.code === 200 && option === '3') {
            let rows = []
            let nextID = 1

            for(let i = 0; i < resultApi.data.results.success.length; i++){
                rows.push({
                    id: nextID,
                    email: resultApi.data.results.success[i].Email,
                    name: resultApi.data.results.success[i].Name,
                    status: 'New',
                    errors: []
                })
                nextID = nextID + 1;
            }
            for(let i = 0; i < resultApi.data.results.update.length; i++){
                rows.push({
                    id: nextID,
                    email: resultApi.data.results.update[i].Email,
                    name: resultApi.data.results.update[i].Name,
                    status: 'Updated',
                    errors: []
                })
                nextID = nextID + 1;
            }
            for(let i = 0; i < resultApi.data.results.failure.length; i++){
                rows.push({
                    id: nextID,
                    email: resultApi.data.results.failure[i].Email,
                    name: resultApi.data.results.failure[i].Name,
                    status: 'Failure',
                    errors: resultApi.data.results.failure[i].errors
                })
                nextID = nextID + 1;
            }
            
            setCustomerRows(rows)
            setShowCustomerTable(true)
            setShowEmployeeTable(false)
            setShowIRecordTable(false)
        }
        

        setLoading(false)
        setResponseMessage(resultApi.data.message)
    }

    function onSelected(event){
        setOption(event.target.value)
    }

    function handleOnDrop(data, file) {
        setCSVData(data)
        setFile(file)
        setShowImportButton(true)
    }

    function handleChange(data) {
        setCSVData(data)
    }

    function handleOnError(err, file, inputElem, reason) {
        console.log(err)
    }

    function handleOnRemoveFile() {
        setCSVData(null)
        setFile(null)
        setShowImportButton(false)
    }

    return (
        <div style={{width: '45rem'}}>
            <h4> Bulk Import</h4>
            
            <div style={{ fontFamily: 'Lato', backgroundColor: 'white', marginTop: '15px', marginBottom: '15px', border: '1px solid rgb(204, 204, 204)', borderRadius: '15px', padding: '10px 10px 10px 15px' }}>
                <h6><b>How to Upload</b></h6>
                <div>
                    <p style={{ display: 'inline', marginRight: '10px' }}>1. Download a template</p>
                    <a style={{ display: 'inline', marginRight: '10px', fontSize: '12px'}} href="/files/iRecord_import.csv"><i className='far fa-file'></i> iRecord_import.csv</a>
                    <a style={{ display: 'inline', marginRight: '10px', fontSize: '12px' }} href="/files/employee_import.csv"><i className='far fa-file'></i> employee_import.csv</a>
                    <a style={{ display: 'inline', fontSize: '12px' }} href="/files/customer_import.csv"><i className='far fa-file'></i> customer_import.csv</a>
                </div>
                <div>
                    <p style={{ display: 'inline', marginRight: '10px' }}>2. Fill out the template</p>
                    <p style={{ display: 'inline', fontSize: '13px', color: 'grey',}}><i>If using Excel, make sure to export or save as a .csv</i></p>
                </div>
                <div>
                    <p style={{ display: 'inline', marginRight: '10px' }}>3. Choose a file and choose import data type</p>
                    <p style={{ display: 'inline', fontSize: '13px', color: 'grey',}}><i>Make sure you select the right data type to match your import file</i></p>
                </div>
                <div>
                    <p style={{ display: 'inline', marginRight: '10px' }}>4. Import the file</p>
                    <p style={{ display: 'inline', fontSize: '13px', color: 'grey',}}><i>If any issues found, you can fix the cells that contain errors, save the file and re-submit.</i></p>
                </div>
                <br></br>               
                <Typography variant="subtitle1">Need Support? <Link className={classes.myClass} onClick={() => openLinkInTab("https://fieldworker.tawk.help/article/how-to-import-your-data-to-fieldworker")}>Access our knowledge base</Link> or contact our support team</Typography>
            </div>

            <span>
                <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    addRemoveButton
                    removeButtonColor='#659cef'
                    onRemoveFile={handleOnRemoveFile}
                    onChange={handleChange}
                >
                    <span>Drop CSV file here or click to upload.</span>
                </CSVReader>
            </span>

            <div style={{ display: 'flex', marginTop: '20px' }}>
                <select className="dropdown" value = {option} onChange={onSelected} style={{
                    border: '1px solid black', alignItems: 'center', borderWidth: '2px', borderRadius: '15px',
                    borderColor: 'rgb(204, 204, 204)',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'left',
                    padding: '10px',
                    cursor: 'pointer',
                    outline: 'none',
                    marginBottom: '20px',
                    width: '35rem',
                }}>
                    <option value="1">Bulk import your iRecords data</option>
                    <option value="2">Bulk import your Employees</option>
                    <option value="3">Bulk import your Customers</option>
                </select>
                
                <div onClick={() => importCustomers()} style={{ width: '45rem', maxWidth: '70rem', width: '10rem', marginTop: '5px', marginBottom: '20px', marginLeft: '1.5rem'}}>
                    <Button disabled={loading} className='' block style={{ backgroundColor: '#3FA7D6' }} variant="info">
                        {!!loading ? <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div> : 'Import'}
                    </Button>
                </div>
            </div>
            
            <div className={showIRecordTable ? 'iRecordTableDiv' : 'customerTableDiv hideMe'} style={{height: '400px', width: '50rem'}}>
                <hr></hr>
                <p>{responseMessage}</p>    
                <DataGrid
                    rows={iRecordRows}
                    columns={iRecordColumns}
                    pageSize={5}
                />
            </div>
            <div className={showCustomerTable ? 'customerTableDiv' : 'customerTableDiv hideMe'} style={{height: '400px', width: '50rem'}}>
                <hr></hr>
                <p>{responseMessage}</p>    
                <DataGrid
                    rows={customerRows}
                    columns={customerColumns}
                    pageSize={5}
                />
            </div>
            <div className={showEmployeeTable ? 'employeeTableDiv' : 'customerTableDiv hideMe'} style={{height: '400px', width: '50rem'}}>
                <hr></hr>
                <p>{responseMessage}</p>    
                <DataGrid
                    rows={employeeRows}
                    columns={employeeColumns}
                    pageSize={5}
                />
            </div>
            <Snackbar open={errorAlert != ''} autoHideDuration={30000} onClose={() => setErrorAlert('')}>
                <MuiAlert sx={{ width: '100%' }} severity={"error"} elevation={6} variant="filled" {...props}>
                    {errorAlert}
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.25 }}
                    onClick={() => setErrorAlert('')}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
        </div>
        
    );
}