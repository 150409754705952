import React, {Component} from 'react';
import { Form, Button } from 'react-bootstrap';
// import { Emoji } from 'emoji-mart'
import ccs from 'countrycitystatejson';
import normalize  from 'us-states-normalize'
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { APIURL } from '../../../../config'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
  })(TextField);

class PersonalDetails extends Component {

    state = {
        currentAddress: "",
        addressSuggestions: [],
    }

    createPhoneNumberList = () => {
        let code = ccs.getCountryByShort('US')
        let phoneList = this.props.phnNumList.map((phn, indx)=>(
            <div key={phn.phnId}>
            <div className="d-flex flex-row justify-content-between w-75">
                <h5>Phone Line {++indx}</h5><i className="fas fa-trash" id={"del"+phn.phnId} onClick={(event) => this.props.deletePhoneNumber(event)} style={{ fontSize: "1.5rem", cursor: "pointer" }}></i>
            </div>
            <Form.Row className={`mx-1`}>
                {/* <Form.Group as={Col} controlId={"country_code"}>
                    <Form.Label>COUNTRY CODE</Form.Label>
                    <Form.Control value={phn.country_code} onChange={(event) => this.props.handlePhoneListChange(phn.phnId,event)} />
                </Form.Group> */}
                {/* <Form.Group as={Col} controlId="country_code">
                    <Form.Label>COUNTRY CODE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <div className="d-flex flex-row justify-content-around" style={{width: '14.5rem',height:'2.4rem',border:'1px solid #D6D6D6',borderRadius:'5px', textAlign:'center'}}>
                        <div style={{marginLeft: 0, marginTop:5}}><Emoji emoji=':flag-us:' size={26}/></div> <div style={{marginRight: 5, marginTop:5}}>United States (+1)</div>
                    </div>
                </Form.Group> */}
                <CssTextField
                  style={{width: '30%', marginLeft:-10}}
                    className={`mx-1 my-3`}
                    label="COUNTRY CODE"
                    value={`${code.emoji} ${code.name}(${code.phone})`}
                    // onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="country_code"
                />
                {/* <Form.Group as={Col} controlId={"phone"}>
                    <Form.Label>PHONE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={phn.phone} onChange={(event) => this.props.handlePhoneListChange(phn.phnId,event)} />
                </Form.Group> */}
                 <CssTextField
                style={{width: '45%'}}
                    className={`mx-1 my-3`}
                    label="PHONE *"
                    value={phn.phone}
                    onChange={(event)=>{this.props.handlePhoneListChange(phn.phnId,event)}} 
                    variant="outlined"
                    id="phone"
                    inputProps={{maxLength: 50}}
                />
            </Form.Row>
        </div>
        ));
        return phoneList
    }

    handleAddressSuggestions = async(value) => {
        let newAddressSuggestions = await axios.get(APIURL + '/maps', { params: { input: value } })
        this.setState({addressSuggestions: newAddressSuggestions.data.predictions, currentAddress: value === undefined  ? '' : value})
    }

    handleAddressSelect = async(value) => {
        let newAddressDetails = await axios.get(APIURL + '/maps/details', { params: { place_id: value.place_id } })
        this.props.formChangeHandler({
            target: {
                id: 'address_maps'
            },
            value: newAddressDetails.data
        })
    }

    render() {
        let phoneList = this.createPhoneNumberList()
        // let states = this.props.states.map((state, indx) => <option key={indx}>{state}</option>)
        let states = ccs.getStatesByShort('US').filter( state => { return state.length > 2 });
        let cities = this.props.formVals.state ? ccs.getCities('US', normalize(this.props.formVals.state, { returnType: 'name' })) : [];

        states=states.map((state, indx) =>{return {title:normalize(state),value:state}})
        cities=cities.map((city, indx) => {return {value:city, title: city}})

        let add = this.props.formVals
        let currAddress = add.address_line_1 + ', ' + (!!add.address_line_2.trim() ? add.address_line_2 + ', ' : '') + add.city + ', ' + add.state + ', ' + add.zip

        return (
            (<div>
                <Form.Row style={{marginLeft: 0, marginRight: 0}} 
                // className={`mx-1`}
                >
                    {/* <Form.Group as={Col} controlId="first_name">
                        <Form.Label>FIRST NAME</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                        <Form.Control value={this.props.formVals.first_name} type="text" onChange={(event) => this.props.formChangeHandler(event)} />
                    </Form.Group> */}
                    <CssTextField
                    style={{width:'35%'}}
                        className={`mx-2 my-3`}
                        label="FIRST NAME *"
                        value={this.props.formVals.first_name}
                        onChange={this.props.formChangeHandler} 
                        variant="outlined"
                        id="first_name"
                        inputProps={{maxLength: 50}}
                    />
                    {/* <Form.Group as={Col} controlId="last_name">
                        <Form.Label>LAST NAME</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                        <Form.Control value={this.props.formVals.last_name} type="text" onChange={(event) => this.props.formChangeHandler(event)} />
                    </Form.Group> */}
                    <CssTextField
                    style={{width:'35%'}}
                        className={`mx-2 my-3`}
                        label="LAST NAME *"
                        value={this.props.formVals.last_name}
                        onChange={this.props.formChangeHandler} 
                        variant="outlined"
                        id="last_name"
                        inputProps={{maxLength: 50}}
                    />
                </Form.Row>
                {/* <Form.Group controlId="address_line_1">
                    <Form.Label>ADDRESS LINE 1</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={this.props.formVals.address_line_1} onChange={(event) => this.props.formChangeHandler(event)} />
                </Form.Group> */}
                <Form.Row style={{marginLeft: 0, marginRight: 0}}> 
                <Autocomplete
                        defaultValue={{description: /^[, ]+$/.test(currAddress) ? '' : currAddress}}
                        freeSolo
                        autoSelect
                        selectOnFocus
                        disablePortal
                        id="address_maps"
                        sx={{ width: 436 }}
                        popupIcon={null}
                        options={this.state.addressSuggestions}
                        getOptionLabel={(option) => option.description !== undefined ? option.description : option}
                        onChange={(event, value, reason) => {
                            if(reason === 'selectOption'){
                                this.handleAddressSelect(value)
                            }
                        }}
                        onInputChange={(event, value, reason) => {
                            if(reason === 'input'){
                                this.handleAddressSuggestions(value)
                            }
                        }}
                        renderInput={(params) => <CssTextField {...params} className={`mx-2 my-3 w-100`} label="ADDRESS *" variant="outlined" id="address_maps_TextField"/> }
                    />
                </Form.Row>
                <Form.Row className={"mx-2 my-3 w-100"} style={{marginLeft: 0, marginRight: 0}}>
                    <FormControl sx={{width: 436}}>
                        <InputLabel>Preferred comm. mode</InputLabel>
                        <Select
                            value={this.props.formVals.prefCommMode}
                            labelId="prefCommMode"
                            id="prefCommMode"
                            onChange={(event) => {
                                this.props.formChangeHandler({
                                    target: {
                                        id: 'prefCommMode',
                                        value: event.target.value,
                                    },
                                })
                            }}
                        >
                            <MenuItem value="Email">Email</MenuItem>
                            <MenuItem value="Phone">Phone</MenuItem>
                        </Select>
                    </FormControl>
                </Form.Row>
                {/*
                <CssTextField
                    // style={{width:'35%'}}
                        className={`mx-2 my-3 w-75`}
                        label="ADDRESS LINE 1 *"
                        value={this.props.formVals.address_line_1}
                        onChange={this.props.formChangeHandler} 
                        variant="outlined"
                        id="address_line_1"
                    />
                */}
                {/* <Form.Group controlId="address_line_2">
                    <Form.Label>ADDRESS LINE 2</Form.Label>
                    <Form.Control value={this.props.formVals.address_line_2} onChange={(event) => this.props.formChangeHandler(event)} />
                </Form.Group> */}
                {/*
                <CssTextField
                // style={{width:'35%'}}
                    className={`mx-2 my-3 w-75`}
                    label="ADDRESS LINE 2"
                    value={this.props.formVals.address_line_2}
                    onChange={this.props.formChangeHandler} 
                    variant="outlined"
                    id="address_line_2"
                />*/}
                <Form.Row style={{marginLeft: 0, marginRight: 0}} >
                    {/* <Form.Group as={Col} controlId="city">
                        <Form.Label>CITY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                        <Form.Control value={this.props.formVals.city} onChange={(event) => this.props.formChangeHandler(event)} />
                    </Form.Group> */}

                    {/* <Form.Group as={Col} controlId="state">
                        <Form.Label>STATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                        <Form.Control value={this.props.formVals.state} as="select" onChange={(event) => this.props.formChangeHandler(event)}>
                            <option value="">Select State</option>
                            {states}
                        </Form.Control>
                    </Form.Group> */}
                    {/*
                    <Autocomplete
                        id="state"
                        value={states.find((type)=> type.title.toUpperCase() === this.props.formVals.state.toUpperCase()) || null}
                        className={`mx-2 my-3`}
                        onChange={(e)=> this.props.formChangeHandler({target:{id:'state', value: e.target.innerHTML}})}
                        disableClearable={true}
                        options={states}
                        getOptionLabel={(option) => option.title}
                        style={{ width: '25%' }}
                        renderInput={(params) => <CssTextField {...params} label="STATE *" variant="outlined" />}
                    />*/}

                    {/* <Form.Group as={Col} controlId="city">
                        <Form.Label>CITY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                        <Form.Control value={this.props.formVals.city} as="select" onChange={(event) => this.props.formChangeHandler(event)}>
                            <option value="">Select City</option>
                            {cities}
                        </Form.Control>
                    </Form.Group> */}
                    {/*
                    <Autocomplete
                        id="city"
                        value={cities.find((type)=> type.title.toLowerCase() === this.props.formVals.city.toLowerCase()) || null}
                        className={`mx-2 my-3`}
                        onSelect={this.props.formChangeHandler}
                        options={cities}
                        getOptionLabel={(option) => option.title}
                        style={{ width: '46%' }}
                        renderInput={(params) => <CssTextField {...params} label="CITY *" variant="outlined" />}
                    />*/}
                </Form.Row>
                {/* <Form.Group controlId="zip">
                    <Form.Label>ZIP</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={this.props.formVals.zip} onChange={(event) => this.props.formChangeHandler(event)} />
                </Form.Group> */}
                {/*
                <CssTextField
                    // style={{width:'35%'}}
                        className={`mx-2 my-3 w-75`}
                        label="ZIP CODE *"
                        value={this.props.formVals.zip}
                        onChange={this.props.formChangeHandler} 
                        variant="outlined"
                        id="zip"
                    />*/}
                {/* <Form.Group controlId="email">
                    <Form.Label>Email Address</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={this.props.formVals.email} onChange={(event) => this.props.formChangeHandler(event)} />
                </Form.Group> */}
                <CssTextField
                    // style={{width:'35%'}}
                    className={`mx-2 my-3 w-75`}
                    label="EMAIL ADDRESS"
                    value={this.props.formVals.Cemail || ''}
                    onChange={this.props.formChangeHandler} 
                    variant="outlined"
                    id="Cemail"
                    inputProps={{maxLength: 60}}
                />
                {phoneList}
                <p style={{ cursor: "pointer" }} onClick={() => this.props.addPhoneNumber()}><i className="fas fa-plus-circle"></i> Add Another Phone Number</p>
                <Button className='w-75 ml-3' variant="primary" block type="button" onClick={() => this.props.onContinue("other_details")}>
                    Continue
                </Button>
            </div>)
        );
    }
}

export default PersonalDetails;