import React from 'react'
import { Form, Button } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import './recordActivity.css'
import axios from 'axios';
import withStyles from '@mui/styles/withStyles';
import {APIURL} from '../../../config'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
  })(TextField);

  
export default class RecordActivity extends React.Component {

    // load these values in as props where needed
    state = {
        recordActivity: {
            aTaskName: '',
            aType: '',
            aDescription: '',
            aProject: '',
            aLocation: '',
            aStartDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
            aEndDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
            aStartTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
            aEndTime: moment().add(1, 'minute').format('YYYY-MM-DDTHH:mm:ss'),
            aAssignedTo: '',
            aNotes: '',
        },
        showAlert: false,
        success: false,
        errorMsg: '',
        assignedToArray: [],
        assignedToArrayEmployeeNames: []
    }

    handleBasicDetsChange = (e) => {
        let newState = { ...this.state.recordActivity, [e.target.id]: e.target.value }
        this.setState({
            recordActivity: newState
        })
       
    }

    assignTo = (e,id) => {
        const employee = id
        let employeeName = this.props.employees.filter((emp) => emp.id === employee)[0]
        let currentEmployeeArray = this.state.assignedToArray
        let currentAssignedToArrayEmployeeNames = this.state.assignedToArrayEmployeeNames

        if (!currentEmployeeArray.includes(employee)) {
            currentEmployeeArray.push(employee)
            currentAssignedToArrayEmployeeNames.push(employeeName)
            this.setState({
                assignedToArray: currentEmployeeArray,
                assignedToArrayEmployeeNames: currentAssignedToArrayEmployeeNames
            })
        }
    }

    removeEmployee = (e,id) => {
        e.preventDefault()
        console.log(id)

        let employeeArray = this.state.assignedToArray
        const index = employeeArray.indexOf(id)
        if (index > -1) {
            employeeArray.splice(index, 1)
        }

        let newEmployeeNamesArray = []
        this.state.assignedToArrayEmployeeNames.forEach((emp) => {
            if (employeeArray.includes(emp.id)) {
                newEmployeeNamesArray.push(emp)
            }
        })

        this.setState({
            assignedToArray: employeeArray,
            assignedToArrayEmployeeNames: newEmployeeNamesArray
        })
    }

    handleProjLoc = (id, field) => {
        if(field === 'aProject'){
            this.setState({ recordActivity: { ...this.state.recordActivity, aProject: id, aLocation: '', aAssignedTo: '' } })
        }else{
            this.setState({ recordActivity: { ...this.state.recordActivity, aLocation: id,} })
        }
    }

    clearState = () => {
        const newState = {
            recordActivity: {
                aTaskName: '',
                aDescription: '',
                aProject: '',
                aLocation: '',
                aStartDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                aEndDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                aStartTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
                aEndTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
                aAssignedTo: '',
                aNotes: '',
            }
        }

        this.setState({
            recordActivity: newState.recordActivity,
            showAlert: false,
            errorMsg: '',
            assignedToArray: [],
            assignedToArrayEmployeeNames: []
        })
    }

    addActivityToDB = async (e) => {
        e.preventDefault()
        if (!!moment(this.state.recordActivity.aStartDate).isAfter(this.state.recordActivity.aEndDate)) {
            // alert('Start date must be before end date')
            this.setState({showAlert: true, errorMsg: 'Start date must be before end date'})
            return
        } else if(this.state.assignedToArray.length === 0 ){
            // alert('Fill out Employee For the Task')
            this.setState({showAlert: true,errorMsg: 'Fill out Employee For the Task'})
            return   
        }
        else {
            let finalData = await Promise.all(
                this.state.assignedToArray.map(async(emp) => {
                    console.log('emp: ', emp)
                    let data = {
                        empName: this.props.user.emp_name,
                        empId: this.props.user.emp_id,
                        company_id: this.props.comp_id,
                        tTitle: this.state.recordActivity.aTaskName,
                        tDesc: this.state.recordActivity.aDescription,
                        tProject: parseInt(this.state.recordActivity.aProject),
                        tPreReq: null,
                        tType: this.state.recordActivity.aType,
                        tLoc: parseInt(this.state.recordActivity.aLocation),
                        tStrtDate: moment(this.state.recordActivity.aStartDate).format('YYYY-MM-DDT') + moment(this.state.recordActivity.aStartTime).format('HH:mm:ss'),
                        tStartTime:moment(this.state.recordActivity.aStartDate).format('YYYY-MM-DDT') + moment(this.state.recordActivity.aStartTime).format('HH:mm:ss'),
                        tEndDate: moment(this.state.recordActivity.aEndDate).format('YYYY-MM-DDT') + moment(this.state.recordActivity.aEndTime).format('HH:mm:ss'),
                        tEndTime: moment(this.state.recordActivity.aEndDate).format('YYYY-MM-DDT') + moment(this.state.recordActivity.aEndTime).format('HH:mm:ss'),
                        tPriority: 'Low',
                        tIsBillable: true,
                        tAssTo: emp,
                        tNotes: this.state.recordActivity.aNotes,
                    };
                    let msg = await axios.post(APIURL+"/task", data);
                    // {data: {code: 500}} 
                    console.log('msg.data: ', msg.data)
                    console.log('data: ', data)
                    let employeeName = this.state.assignedToArrayEmployeeNames.find((employee) => employee.id === emp).employee_name
                    if(msg.data.code === 200){
                      return {success: true, employee:{id: emp, name: employeeName}}
                    }else{
                      return {success: false, employee:{id: emp, name: employeeName}}
                    }
                })
            )

            let validReq = finalData.every((emp)=> {return emp.success})
            console.log('Validating RA',validReq)
            if(!!validReq){
                //  clear states
                //  maintain states if error
                this.setState({success: true})
                this.clearState()
                //   this.setState({isLoading: false})
                }else{
                    this.setState({showAlert: true})
                }
            
            console.log('finalData: ', finalData)
            // console.log('save')
        }
    }

    render() {
        // console.log('from ract ' ,this.props.user,)
        let empList = this.state.recordActivity.aProject === ''?[]:this.props.employees.map((emp, indx) =>{
            if(!!this.props.projects.find((pro)=> pro.pid === +this.state.recordActivity.aProject).empAssn.find((employee)=> employee.id === emp.id)){
                return { title: emp.employee_name, value: emp.id }
            }else{return null}
        }).filter((empl)=> !!empl)

        let projList = this.props.projects.map((project,index)=>{
            if(!!project.empAssn && project.empAssn.find((emp)=>emp.id === this.props.user.emp_id)){
              return ({title: `${project.pname}`, value: `${project.pid}`})
            }else{return null}
          }).filter((proj)=> !!proj)
      
        let taskType = this.props.taskType.map((name,index)=>{
        return ({title: `${name}`, value: `${index}`})
        })
        
        let locList = this.props.locations.map((loc)=>{
            if(this.state.recordActivity.aProject !== ''){
              let projLoc = this.props.projects.find((pro)=> pro.pid === +this.state.recordActivity.aProject)
                if(!!projLoc && !!projLoc.proLoc.find((ploc)=>ploc.id === loc.id)){
                  // console.log(projLoc.proLoc)
                  return ({title: `${loc.location_addr1}, ${loc.location_addr2}, ${loc.location_city}, ${loc.location_state}, ${loc.location_zip}`, value: `${loc.id}`})
                }else{return null}
            }else{return null}
          }).filter((loc)=> !!loc);
        

        // console.log('this.props: ', this.props)
        // console.log('this.state: ', this.state)
        return (
            <div className="record-activity-form">
                <Form onSubmit={(e) => this.addActivityToDB(e)}>
                <h4>Record Activity</h4>
                {/* {this.state.showAlert && */}
                <Snackbar open={this.state.showAlert} autoHideDuration={6000} onClose={()=>{this.setState({showAlert: false})}}>
                    <MuiAlert onClose={()=>{this.setState({showAlert: false})}} severity="warning">
                        {this.state.errorMsg !=='' ? this.state.errorMsg:'Please Fill out missing form fields'}
                    </MuiAlert>
                </Snackbar>
                {/* // <Alert className='w-75' variant="info">
                //     {this.state.errorMsg !=='' ? this.state.errorMsg:'Please Fill out missing form fields'}
                // </Alert> */}
                {/* } */}
                {this.state.success &&
                <Snackbar open={this.state.success} autoHideDuration={6000} onClose={()=>{this.setState({success: false})}}>
                <MuiAlert onClose={()=>{this.setState({success: false})}} severity="success">
                    Successfully Added Task Assignments
                </MuiAlert>
              </Snackbar>
                }

                 <CssTextField
                    className='w-75 mx-3 my-2'
                    label="ACTIVITY TITLE"
                    value={this.state.recordActivity.aTaskName}
                    onChange={(e) => this.handleBasicDetsChange(e)} 
                    variant="outlined"
                    id="aTaskName"
                />
                 <CssTextField
                    className='w-75 mx-3 my-2'
                    label="ACTIVITY DESCRIPTION"
                    value={this.state.recordActivity.aDescription}
                    onChange={(e) => this.handleBasicDetsChange(e)} 
                    variant="outlined"
                    id="aDescription"
                />
                 <Autocomplete
                    id="aType"
                    value={taskType.find((type)=> type.title === this.state.recordActivity.aType) || null}
                    className='mx-3 my-3 w-75'
                    onChange={(e,newValue)=> {
                        console.log(newValue)
                        this.handleBasicDetsChange({target: {id: 'aType', value: newValue.title}})
                    }}
                    options={taskType}
                    getOptionLabel={(option) => option.title}
                    // style={{ width: 450 }}
                    renderInput={(params) => <CssTextField {...params} label="ACTIVITY TYPE" variant="outlined" />}
                />
                <Autocomplete
                    id="aProject"
                    value={projList.find((pro)=> pro.value === this.state.recordActivity.aProject) || null}
                    className='mx-3 my-3 w-75'
                    onChange={(e,newValue)=> {
                        let value = projList.find((proj)=> proj.title === newValue.title)
                        if(!!value){
                            this.handleProjLoc(value.value, 'aProject')
                        }
                        return
                        
                    }}
                    options={projList}
                    getOptionLabel={(option) => option.title}
                    // style={{ width: 450 }}
                    renderInput={(params) => <CssTextField {...params} label="PROJECT" variant="outlined" />}
                />
                
               <Autocomplete
                    id="aLocation"
                    value={locList.find((loc)=> loc.value === this.state.recordActivity.aLocation) || null}
                    className='mx-3 my-3 w-75'
                    onChange={(e,newValue)=> {
                        let value = locList.find((loc)=> loc.title === newValue.title)
                        if(!!value){
                            this.handleProjLoc(value.value, 'aLocation')
                        }
                        return
                        
                    }}
                    options={locList}
                    getOptionLabel={(option) => option.title}
                    // style={{ width: 450 }}
                    renderInput={(params) => <CssTextField {...params} label="LOCATION" variant="outlined" />}
                />
                <div className='column mx-3'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="flex-start">
                    <DatePicker
                    className='mr-2 my-3'
                    id="date-picker-dialog"
                    label="START DATE"
                    value={new Date(moment(this.state.recordActivity.aStartDate).format('YYYY-MM-DDTHH:mm:ss'))}
                    onChange={(d) => {this.setState({recordActivity: {...this.state.recordActivity, aStartDate: moment(d).format('YYYY-MM-DDTHH:mm:ss')}})}}
                    slotProps={{textField: {variant: 'outlined'}}}
                    />
                    <TimePicker
                    margin="normal"
                    id="time-picker"
                    label="START TIME"
                    className='mr-2 my-3'
                    value={new Date(moment(this.state.recordActivity.aStartTime).format('YYYY-MM-DDTHH:mm:ss'))}
                    onChange={(d) => {this.setState({recordActivity: {...this.state.recordActivity, aStartTime: moment(d).format('YYYY-MM-DDTHH:mm:ss')}})}}
                    slotProps={{textField: {variant: 'outlined'}}}
                    />
                </Grid>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="flex-start">
                    <DatePicker
                    className='mr-2 my-3'
                    id="date-picker-dialogB"
                    label="END DATE"
                    value={new Date(moment(this.state.recordActivity.aEndDate).format('YYYY-MM-DDTHH:mm:ss'))}
                    onChange={(d) => {this.setState({recordActivity: {...this.state.recordActivity, aEndDate: moment(d).format('YYYY-MM-DDTHH:mm:ss')}})}}
                    slotProps={{textField: {variant: 'outlined'}}}
                    />
                    <TimePicker
                    margin="normal"
                    id="time-pickerB"
                    label="END TIME"
                    className='mr-2 my-3'
                    value={new Date(moment(this.state.recordActivity.aEndTime).format('YYYY-MM-DDTHH:mm:ss'))}
                    onChange={(d) => {this.setState({recordActivity: {...this.state.recordActivity, aEndTime: moment(d).format('YYYY-MM-DDTHH:mm:ss')}})}}
                    slotProps={{textField: {variant: 'outlined'}}}
                    />
                </Grid>
            </LocalizationProvider>
            </div>
            <Autocomplete
                id="aAssignedTo"
                value={null}
                className='mx-3 my-2 w-75'
                onChange={(e,newValue)=> {

                    let value = empList.find((emp)=> emp.title === newValue.title)
                    if(!!value){
                        this.assignTo(e,value.value)
                    }
                    return
                    
                }}
                options={empList}
                getOptionLabel={(option) => option.title}
                // style={{ width: 450 }}
                renderInput={(params) => <CssTextField {...params} value='' label="EMPLOYEE" variant="outlined" />}
            />
            
            {this.state.assignedToArrayEmployeeNames.map((employee, indx) => {
                return(
                    
                    <div className='employee-wrapper w-75' key={indx}>
                        <div className="employee-wrapper-header">
                            <h3 style={{ width: '200px' }}>EMPLOYEE NAME</h3>
                            <h3 style={{ width: '200px' }}>EMPLOYEE ROLE</h3>
                        </div>
                        <div className="employee-wrapper-body">
                            <h3 style={{ width: '200px' }}>{employee.employee_name}</h3>
                            <h3 style={{ width: '200px' }}>{employee.emp_type}</h3>
                            <div className="right-side">
                                <button onClick={(e) => this.removeEmployee(e,employee.id)}>X</button>
                            </div>
                        </div>
                    </div>
                )
            })}
            
            <CssTextField
                className='w-75 m-3'
                label="NOTES/COMMENTS"
                value={this.state.recordActivity.aNotes}
                onChange={(e) => this.handleBasicDetsChange(e)} 
                variant="outlined"
                id="aNotes"
            />

            <Button className='w-75 mx-3' variant="primary" block style={{margin: 5}} type="submit">Save</Button>
            </Form>
            </div>
        );
    }

}