import React,{Component} from 'react';
import MapsBar from '../../container/MapsBar/MapsBar';
import HandyCard from '../../container/HandyCard/HandyCard'
import axios from 'axios';
import {APIURL,BINGAPIKEY} from '../../config'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from "@mui/material/TextField";
import moment from "moment";
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

  
class EmpLocate extends Component{
    constructor(props){
        super(props)
        this.state = {
            empLocData: [],
            isLoading: true,
            loggedToSegment: false,
            checkoutDetails: {checkoutTime: moment().format('YYYY-MM-DDTHH:mm'), reason: '', verification: '', updated_by: this.props.user.emp_id},
            showCheckoutDialog: false,
        }
    }

    submitCheckOut = async(checkoutDetails) => {
        if(moment(checkoutDetails.cardData.empLocdate).isAfter(moment(checkoutDetails.checkoutTime))) {
            alert('Checkout time cannot be before checkin time');
            return;
        }
        let data = { projId: checkoutDetails.cardData.tas_pro_id,
            empName: checkoutDetails.cardData.emp_first_name + '' + checkoutDetails.cardData.emp_last_name,
            empId: checkoutDetails.cardData.tas_emp_id,
            taskTitle: checkoutDetails.cardData.tas_title,
            companyId: this.props.user.comp_id,
            taskId: checkoutDetails.cardData.tas_id,
            date: moment(checkoutDetails.checkoutTime).format('YYYY-MM-DDTHH:mm:ss'),
            updated_by: this.props.user.emp_id,
            reason: checkoutDetails.reason,
            verification: checkoutDetails.verification
        }
        let empLocResponse = await axios.put(APIURL + '/locate/empLoc', { ...data });
        let tasCheckinResponse = await axios.put(APIURL + '/task/checkin', {tas_id: checkoutDetails.cardData.tas_id, checkin: false})
        this.toggleShowCheckOutDialog()
    }

    toggleShowCheckOutDialog = (cardData) => {
        this.setState({ showCheckoutDialog: !this.state.showCheckoutDialog, checkoutDetails: {...this.state.checkoutDetails, cardData: cardData} });
    }

    logToSegment = async () => {
        if(this.state.loggedToSegment) {
          return;
        }
        let page = String(window.location.href).split("/").pop()
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page,this.props.user.emp_id)
        analytics.page(page, {
            title:page,
            path:page,
        }, {
            userId:String(this.props.user.emp_id),
        })
        console.log("logged to segment successfully")
        this.setState({ loggedToSegment : true });
    }


    async componentDidMount() {
        this.props.checkTokenExpiry()
        this.logToSegment();
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        let empLoc = await axios.get(APIURL+"/locate/empLoc",{params: {comp_id: this.props.comp_id}})
        // console.log(empLoc.data)
        // for(var loc=0;loc<empLoc.data.length;loc++){
        //     axios.defaults.headers = {
        //         'Content-Type': 'application/json',
        //       }
        //     let empAdd = await axios.get("https://dev.virtualearth.net/REST/v1/Locations/"+empLoc.data[loc].emploc_lat+","+empLoc.data[loc].emploc_lon+"?key="+BINGAPIKEY)
        //     // console.log(empAdd.data.resourceSets[0].resources[0].name)
        //     empLoc.data[loc] = {...empLoc.data[loc], empAddress: !!empAdd.data.resourceSets[0].resources[0]?empAdd.data.resourceSets[0].resources[0].name:'Address Not Found'}
        // }
        // console.log(empLoc.data)
        this.setState({empLocData: empLoc.data, isLoading: false})
    }


    getMapsDataFormat(){
        let mapsData = this.state.empLocData.map((pushPinData)=>{
            return {
                "location":[pushPinData.emploc_lat, pushPinData.emploc_lon], 
                "addHandler":"mouseover",
                "infoboxOption": { title: pushPinData.tas_title, description: pushPinData.empAddress},
                "pushPinOption":{ color: 'red',title: pushPinData.emp_first_name + pushPinData.emp_last_name}
            }
        })

        return mapsData
    }
    
    render(){
        let empLocateCards = this.state.empLocData.map((data)=> {
            if(data.tas_is_active === 0 || data.tas_is_active === 1){
                return <HandyCard key={data.empLocId} employeelocate data={data} user={this.props.user} toggleShowCheckOutDialog={this.toggleShowCheckOutDialog}/>
            }
            return null;
        })
        return (
            (<div>
                <MapsBar
                mapsData={this.getMapsDataFormat()}/>
                <hr/>
                <h4>Last Known Activity:</h4>
                <div className="overflow-auto" style={{height:"40rem",scrollbarColor:"#3FA7D6"}}>
                {!!this.state.isLoading && 
                (<div className="d-flex flex-row w-100 justify-content-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>)}
                {!this.state.isLoading && empLocateCards}
                {!this.state.isLoading && this.state.empLocData.length === 0 && 
                    <div style={{marginTop: 100}} className='d-flex flex-row w-100 justify-content-center align-items-center'>
                        <div>
                            <img src='./Locationsgraphics.png' alt='Locationsgraphics' style={{width: '10rem', height: '10rem', margin: 10}}/>
                        </div>    
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <h5 style={{fontFamily: 'Lato',marginTop: 48,  marginBottom: 30}} className='text-center'>Your team haven’t made any client visits yet </h5>
                            <p style={{fontSize: 14, width: '70%',fontFamily: 'Lato'}} className='text-muted text-center text-wrap'>Once they have, you’ll see an overview all all visits here. Still need to invite your team? Let’s add them to FieldWorker now!</p>
                        </div>    
                    </div>
                }
                </div>
                <Dialog open={this.state.showCheckoutDialog} onClose={() => {}} className='locatePageCheckoutDialog'>
                    <DialogTitle>Confirm Manual Checkout Details</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please fill out the following information to complete the manual checkout.
                        </DialogContentText>
                        <hr></hr>
                        <TextField
                            id="checkoutTime"
                            label="Check-Out Time"
                            type="datetime-local"
                            value={this.state.checkoutDetails.checkoutTime}
                            onChange={(d) => {
                                this.setState({checkoutDetails: {...this.state.checkoutDetails, checkoutTime: moment(d.target.value.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm')}})
                            }}
                            // className={classes.textField}
                            style={{ width: '50%', marginBottom: '0px' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="reason"
                            label="Reason for Admin checkout"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={this.state.checkoutDetails.reason}
                            onChange={(d) => {
                                this.setState({checkoutDetails: {...this.state.checkoutDetails, reason: d.target.value}})
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="verification"
                            label="How was the task verified?"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={this.state.checkoutDetails.verification}
                            onChange={(d) => {
                                this.setState({checkoutDetails: {...this.state.checkoutDetails, verification: d.target.value}})
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.toggleShowCheckOutDialog}>Cancel</Button>
                    <Button onClick={() => this.submitCheckOut(this.state.checkoutDetails)}>Submit</Button>
                    </DialogActions>
                </Dialog>
            </div>)
        );
    }
}
export default EmpLocate