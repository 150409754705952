import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Divider, TextField, Grid, Autocomplete, Button, Modal, ListItemText } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import SaveButton from '../Components/SaveButton';
import CancelButton from '../Components/CancelButton';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { APIURL } from '../../../config';
import ccs from 'countrycitystatejson';
import normalize from 'us-states-normalize';
import moment from 'moment'

const providerType_EntityMap = {
    "State-specific Medicaid Provider ID": "The state numeric code",
    "NPI": "NPI",
    "Medicare ID": "CMS",
    "NCPDP ID": "NCPDP",
    "Federal Tax ID": "IRS",
    "State Tax ID": "The name of the state’s taxation division",
    "SSN": "SSA",
    "Other": "The name of the issuing entity",
};

const Provider = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [providerDetails, setProviderDetails] = useState([]);
    const [newProviderDetails, setNewProviderDetails] = useState({
        "prov_name": "",
        "prov_iden": "",
        "npi_id": "",
        "medicaid_number": "",
        "prov_iden_type": "",
        "prov_iden_issuing_entity": "",
        "prov_iden_start_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
        "prov_iden_end_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
        "prov_iden_status": null
    });
    const [errors, setErrors] = useState({ taxId: false });
    const [lastSavedCompanyDets, setLastSavedCompanyDets] = useState({});
    const [open, setOpen] = useState(false);

    const getData = async () => {
        try {
            const response = await axios.get(APIURL + `/company/provider`, { params: { company_id: props.comp_id } });
            setProviderDetails(response.data)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getData();
    }, [open]);

    const handleClose = () => {
        setNewProviderDetails({
            "prov_name": "",
            "prov_iden": "",
            "npi_id": "",
            "medicaid_number": "",
            "prov_iden_type": "",
            "prov_iden_issuing_entity": "",
            "prov_iden_start_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
            "prov_iden_end_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
            "prov_iden_status": null
        });
        setOpen(false);
        setEditMode(false);
    };

    const addProvider = async () => {
        validate();

        var data = {
            prov_name: newProviderDetails.prov_name,
            prov_iden: newProviderDetails.npi_id,
            prov_iden_type: 'NPI',
            prov_iden_issuing_entity: 'NPI',
            prov_iden_start_date: newProviderDetails.prov_iden_start_date,
            prov_iden_end_date: newProviderDetails.prov_iden_end_date,
            prov_iden_company_id: props.comp_id,
            prov_iden_status: newProviderDetails.prov_iden_status,
        };

        const msg = await axios.post(APIURL + "/company/provider", data);

        if (newProviderDetails.medicaid_number) {
            var data = {
                prov_name: newProviderDetails.prov_name,
                prov_iden: newProviderDetails.medicaid_number,
                prov_iden_type: 'Medicaid',
                prov_iden_issuing_entity: 'Medicaid',
                prov_iden_start_date: newProviderDetails.prov_iden_start_date,
                prov_iden_end_date: newProviderDetails.prov_iden_end_date,
                prov_iden_company_id: props.comp_id,
                prov_iden_status: newProviderDetails.prov_iden_status,
            };

            const msg = await axios.post(APIURL + "/company/provider", data);
        }

        handleClose();
    }

    const editProvider = async () => {
        var data = {
            prov_iden_id: newProviderDetails.prov_iden_id,
            prov_name: newProviderDetails.prov_name,
            prov_iden: newProviderDetails.prov_iden,
            prov_iden_type: newProviderDetails.prov_iden_type,
            prov_iden_issuing_entity: newProviderDetails.prov_iden_issuing_entity,
            prov_iden_start_date: newProviderDetails.prov_iden_start_date,
            prov_iden_end_date: newProviderDetails.prov_iden_end_date,
            prov_iden_company_id: props.comp_id,
            prov_iden_status: newProviderDetails.prov_iden_status,
        };

        const msg = await axios.put(APIURL + "/company/provider", data);

        handleClose();
    }

    const validate = () => {
        if (!newProviderDetails.prov_name.trim()) {
            alert(`Contract Name cannot be empty.`);
            return;
        } else if (!newProviderDetails.npi_id.trim()) {
            alert(`NPI ID cannot be empty.`);
            return;
        } else {
            
        }
    }

    const statuses = [{ value: 0, title: 'Inactive' }, { value: 1, title: 'Active' }];
    const types = [
        { value: 'State-specific Medicaid Provider ID', title: 'State-specific Medicaid Provider ID' },
        { value: 'NPI', title: 'NPI' },
        { value: 'Medicare ID', title: 'Medicare ID' },
        { value: 'NCPDP ID', title: 'NCPDP ID' },
        { value: 'Federal Tax ID', title: 'Federal Tax ID' },
        { value: 'State Tax ID', title: 'State Tax ID' },
        { value: 'SSN', title: 'SSN' },
        { value: 'Other', title: 'Other' },
    ];

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h5">Contracts</Typography>
            </Box>
            {providerDetails.map((formValues, index) => {
                return (
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item xs={2}>
                                <Typography variant="h6" sx={{ mt: 2 }}>{formValues.prov_name || ''}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={() => { setOpen(true); setEditMode(true); setNewProviderDetails(formValues); }}>
                                    <Edit />
                                </IconButton>
                            </Grid>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Contract Identifier
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{formValues.prov_iden || ''}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Contract Status
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{formValues.prov_iden_status === 1 ? 'Active' : 'Inactive'}</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Type
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{formValues.prov_iden_type}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Issuing Entity
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{formValues.prov_iden_issuing_entity}</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Start Date
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{moment(formValues.prov_iden_start_date).format('MM/DD/YYYY')}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    End Date
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{moment(formValues.prov_iden_end_date).format('MM/DD/YYYY')}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                )
            })}
            <Button
                onClick={() => setOpen(true)}
                sx={{ marginTop: 4 }}
                variant="contained"
                color="primary"
            >
                + Add New Contract
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        maxWidth: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2
                    }}
                >
                    <Typography variant="h6" component="div" sx={{ mb: 3 }}>
                        {editMode ? 'Edit Contract' : 'Add New Contract'}
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            label="Contract Name"
                            inputProps={{ maxLength: 255 }}
                            fullWidth
                            value={newProviderDetails.prov_name}
                            onChange={(e) => setNewProviderDetails({ ...newProviderDetails, prov_name: e.target.value })}
                            error={''}
                        />
                    </Box>

                    {editMode ? (
                        newProviderDetails.prov_iden_type === 'NPI' ? (
                            <Box sx={{ mb: 2 }}>
                                <TextField
                                    label="NPI ID"
                                    inputProps={{ maxLength: 256 }}
                                    fullWidth
                                    value={newProviderDetails.prov_iden}
                                    onChange={(e) => setNewProviderDetails({ ...newProviderDetails, npi_id: e.target.value, prov_iden: e.target.value })}
                                    error={''}
                                />
                            </Box>
                        )
                            :
                            (
                                <Box sx={{ mb: 2 }}>
                                    <TextField
                                        label="Medicaid Number"
                                        inputProps={{ maxLength: 256 }}
                                        fullWidth
                                        value={newProviderDetails.prov_iden}
                                        onChange={(e) => setNewProviderDetails({ ...newProviderDetails, medicaid_number: e.target.value, prov_iden: e.target.value })}
                                        error={''}
                                    />
                                </Box>
                            )
                    )
                        :
                        (
                            <>
                                <Box sx={{ mb: 2 }}>
                                    <TextField
                                        label="NPI ID"
                                        inputProps={{ maxLength: 256 }}
                                        fullWidth
                                        value={newProviderDetails.npi_id}
                                        onChange={(e) => setNewProviderDetails({ ...newProviderDetails, npi_id: e.target.value })}
                                        error={''}
                                    />
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <TextField
                                        label="Medicaid Number"
                                        inputProps={{ maxLength: 256 }}
                                        fullWidth
                                        value={newProviderDetails.medicaid_number}
                                        onChange={(e) => setNewProviderDetails({ ...newProviderDetails, medicaid_number: e.target.value })}
                                        error={''}
                                    />
                                </Box>
                            </>
                        )}
                    {/* <Box sx={{ mb: 2 }}>
                        <Autocomplete
                            id="type"
                            options={types}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Type"
                                    error={''}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.con_id}>
                                    <ListItemText primary={option.title} />
                                </li>
                            )}
                            value={{ value: newProviderDetails.prov_iden_type, title: newProviderDetails.prov_iden_type }}
                            onChange={(event, newValue) => {
                                setNewProviderDetails({ ...newProviderDetails, prov_iden_type: newValue.value, prov_iden_issuing_entity: providerType_EntityMap[newValue.value] });
                            }}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            label="Issuing Entity"
                            inputProps={{ maxLength: 256 }}
                            fullWidth
                            value={newProviderDetails.prov_iden_issuing_entity}
                            onChange={(e) => setNewProviderDetails({ ...newProviderDetails, prov_iden_issuing_entity: e.target.value })}
                            error={''}
                        />
                    </Box> */}
                    <Box sx={{ mb: 2 }}>
                        <Autocomplete
                            id="status"
                            options={statuses}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Status"
                                    error={''}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.con_id}>
                                    <ListItemText primary={option.title} />
                                </li>
                            )}
                            value={{ value: newProviderDetails.prov_iden_status, title: newProviderDetails.prov_iden_status === 1 ? 'Active' : 'Inactive' }}
                            onChange={(event, newValue) => {
                                setNewProviderDetails({ ...newProviderDetails, prov_iden_status: newValue.value });
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ mb: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid container justifyContent="flex-start">
                                    <DatePicker
                                        id="start"
                                        label="START DATE"
                                        value={new Date(moment(newProviderDetails.prov_iden_start_date).format('YYYY-MM-DDTHH:mm:ss'))}
                                        onChange={(d) => { setNewProviderDetails({ ...newProviderDetails, prov_iden_start_date: moment(d).format('YYYY-MM-DDTHH:mm:ss') }) }}
                                        slotProps={{textField: {variant: 'outlined'}}}
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid container justifyContent="flex-start">
                                    <DatePicker
                                        id="end"
                                        label="END DATE"
                                        value={new Date(moment(newProviderDetails.prov_iden_end_date).format('YYYY-MM-DDTHH:mm:ss'))}
                                        onChange={(d) => { setNewProviderDetails({ ...newProviderDetails, prov_iden_end_date: moment(d).format('YYYY-MM-DDTHH:mm:ss') }) }}
                                        slotProps={{textField: {variant: 'outlined'}}}
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={editMode ? editProvider : addProvider}
                    >
                        {editMode ? 'Save Changes' : 'Add Contract'}
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default Provider;