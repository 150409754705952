import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Records.css'

const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

const columns = [
    {
        field: 'log_type',
        headerName: 'Log Type',
        width: 125,
        hide: false
    },
    {
        field: 'tas_title',
        headerName: 'Activity/Client/CareGiver Name',
        width: 250,
        hide: false
    },
    {
        field: 'tas_type',
        headerName: 'Activity Type',
        width: 150,
        hide: false
    },
    {
        field: 'pro_title',
        headerName: 'Project Name',
        width: 250,
        hide: false
    },
    {
        field: 'errors',
        headerName: 'Error',
        width: 700,
        hide: false,
        renderCell: (params) => {
            let val = params.value.split('ERROR: ')
            if (val.length > 1 || val[0] === 'null' || val[0] === '') {
                val = val.slice(1);
            }

            let tobeDisplayed = Array.isArray(val) ? val.map((text, i) => {
                return <p style={{ lineHeight: 2 }} className='text-wrap mb-0' key={text + Math.random()}><span style={{ marginRight: 10 }} className="badge bg-secondary text-white">{++i}</span>{text}</p>
            }) : []
            return (
                <div style={{ minHeight: 50, width: '100%', justifyContent: 'center' }} className='d-flex flex-column overflow-auto'>
                    {tobeDisplayed.length === 0 ? '-' : tobeDisplayed}
                </div>
            )
        }
    },
    {
        field: 'sched_start_date',
        headerName: 'Scheduled Start Time',
        width: 250,
        hide: false,
        sortComparator: dateComparator
    },
    {
        field: 'sched_end_date',
        headerName: 'Scheduled End Time',
        width: 250,
        hide: false,
        sortComparator: dateComparator
    },
    {
        field: 'start_date',
        headerName: 'Start Date/Time',
        width: 250,
        hide: false,
        sortComparator: dateComparator
    },
    {
        field: 'end_date',
        headerName: 'End Date/Time',
        width: 250,
        hide: false,
        sortComparator: dateComparator
    },
    {
        field: 'transactionId',
        headerName: 'transaction Id',
        width: 250,
        hide: true,
    },
    {
        field: 'evvId',
        headerName: 'evvID',
        width: 125,
        hide: true
    },
    {
        field: 'invTimestamp',
        headerName: 'Invocation Timestamp',
        width: 250,
        hide: true,
        renderCell: (params) => {
            return (
                !!params.value ? moment(params.value).add(moment().utcOffset(), 'minutes').format('MM-DD-YYYY, HH:mm ') : '-'
            )
        },
        sortComparator: dateComparator
    },
];

export default function Records(props) {

    const [renderTable, setRenderTable] = useState(false);

    useEffect(() => {

    }, []);

    var rows = [];
    if (!!props.list.evvLogList) {
        rows = props.list.evvLogList.map((log, index) => {
            let filteredTasks = props.tasks.filter(t => t != undefined)

            const customer = props.customers.find(cus => cus.ClientID.toString() === log.custID);
            const clientFirstName = customer ? customer.ClientFirstName : '';
            const clientLastName = customer ? customer.ClientLastName : '';

            const employee = props.employees.find(emp => emp.EmployeeIdentifier.toString() === log.empID);
            const employeeFirstName = employee ? employee.EmployeeFirstName : '';
            const employeeLastName = employee ? employee.EmployeeLastName : '';

            try {
                return {
                    id: index,
                    log_type: !!log.visitID ? 'Visit' : !!log.custID ? 'Client' : 'Caregiver',
                    tas_title: !!log.custID ? (clientFirstName + ' ' + clientLastName) : !!log.empID ? (employeeFirstName + ' ' + employeeLastName) : filteredTasks.length > 0 && !!log.visitID ? filteredTasks.find(t => t.tas_id.toString() === log.visitID).tas_title : '-',
                    tas_type: filteredTasks.length > 0 ? (!!log.visitID ? filteredTasks.find(t => t.tas_id.toString() === log.visitID).tas_type : '-') : '-',
                    pro_title: filteredTasks.length > 0 ? (!!log.visitID ? filteredTasks.find(t => t.tas_id.toString() === log.visitID).pro_title : '-') : '-',
                    errors: !!log.errorMessage ? log.errorMessage : '',
                    sched_start_date: filteredTasks.length > 0 ? (!!log.visitID ? moment(filteredTasks.find(t => t.tas_id.toString() === log.visitID).tas_start_time, "YYYY-MM-DDTHH:mm:ss").format('LLL') : '-') : '-',
                    sched_end_date: filteredTasks.length > 0 ? (!!log.visitID ? moment(filteredTasks.find(t => t.tas_id.toString() === log.visitID).tas_end_time, "YYYY-MM-DDTHH:mm:ss").format('LLL') : '-') : '-',
                    start_date: filteredTasks.length > 0 ? (!!log.visitID ? moment(filteredTasks.find(t => t.tas_id.toString() === log.visitID).empLocdate, "YYYY-MM-DDTHH:mm:ss").format('LLL') : '-') : '-',
                    end_date: filteredTasks.length > 0 ? (!!log.visitID ? moment(filteredTasks.find(t => t.tas_id.toString() === log.visitID).checkoutEmpDate, "YYYY-MM-DDTHH:mm:ss").format('LLL') : '-') : '-',
                    transactionId: !!log.transactionID ? log.transactionID : '-',
                    evvId: !!log.evvID ? log.evvID : '-',
                    invTimestamp: !!log.invocationTimestamp ? log.invocationTimestamp : '-',
                }
            } catch {
                return {
                    id: index,
                };
            }
        })
    }

    function getTitle() {
        let status = <p></p>;
        let numberSummary = <div style={{ display: 'flex' }}>
            <div>
                <div>CareGiver Records: {props.list.emp_total_records_sent}</div>
                <div>CareGiver Records Success: {props.list.emp_succeededCount}</div>
                <div>CareGiver Records Failures: {props.list.emp_failedCount}</div>
            </div>
            <div style={{ borderLeft: '2px solid #95d0eb', margin: '0px 250px 0px 250px' }}>
                <div style={{ marginLeft: '20px' }}>Client Records: {props.list.cus_total_records_sent}</div>
                <div style={{ marginLeft: '20px' }}>Client Records Success: {props.list.cus_succeededCount}</div>
                <div style={{ marginLeft: '20px' }}>Client Records Failures: {props.list.cus_failedCount}</div>
            </div>
            <div style={{ borderLeft: '2px solid #95d0eb' }}>
                <div style={{ marginLeft: '20px' }}>Visit Records: {props.list.visit_total_records_sent}</div>
                <div style={{ marginLeft: '20px' }}>Visit Records Success: {props.list.visit_succeededCount}</div>
                <div style={{ marginLeft: '20px' }}>Visit Records Failures: {props.list.visit_failedCount}</div>
            </div>
        </div>

        if (props.list.total_records_sent !== 0 && props.list.failedCount === 0 && props.list.succeededCount === 0) {
            status = <b><span style={{ float: 'right' }}><i className='fas fa-exclamation-circle mr-2' style={{ color: 'red' }}></i>Failed to establish connection with SanData</span></b>
        } else if (props.list.evvLogList.length === 0) {
            status = <b><span style={{ float: 'right' }}> <i className='fas fa-times mr-2' style={{ color: 'grey' }}></i>THERE WERE NO RECORDS TO SEND</span></b>
        } else if (props.list.failedCount === 0) {
            status = <b><span style={{ float: 'right' }}> <i className='fas fa-check-circle mr-2' style={{ color: 'green' }}></i>SUCCESSFUL EVV SUBMISSION</span></b>
        } else if (props.list.failedCount > 0) {
            status = <b><span style={{ float: 'right' }}> <i className='fas fa-question-circle mr-2' style={{ color: '#ffa701' }}></i>INCOMPLETE SUBMISSIONS</span></b>
        }

        return (
            <div>
                <b>INVOCATION TIMESTAMP: {moment(props.list.timestamp).format('LLL')}</b>
                {status}
                {numberSummary}
            </div>
        )
    }

    const onToggle = (expanded) => {
        if (expanded) {
            setRenderTable(true);
        } else {
            setRenderTable(false);
        }
    }

    return (
        (<div style={{ width: '95%', margin: 'auto', marginBottom: '20px' }}>
            <Accordion onChange={(event, expanded) => onToggle(expanded)} className={'evvLog-accordian'}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {getTitle()}
                </AccordionSummary>
                <AccordionDetails>
                    {renderTable &&
                        <DataGrid
                            initialState={{ density: 'compact' }}
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            autoHeight={true}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            showToolbar slots={{ toolbar: GridToolbar, color: '#3fadf6' }}
                            getRowHeight={() => 'auto'}
                        />
                    }
                </AccordionDetails>
            </Accordion>
        </div>)
    );
}