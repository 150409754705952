import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import moment from "moment";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from "../../DataGrid/ControlledDataGrid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { TextField, Button, Box, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Modal, Button as RBButton } from "react-bootstrap";
import withStyles from "@mui/styles/withStyles";
import "./AccountsReceivable.css";

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#3FA7D6",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#3FA7D6",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "lightblue",
            },
            "&:hover fieldset": {
                borderColor: "#3FA7D6",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3FA7D6",
            },
        },
    },
})(TextField);

export default function AccountsReceivable(props) {
    const [gridId, setGridId] = useState(58);
    const apiRef = useGridApiRef();

    let currDataGridRows = useRef([]);
    const [dataGridRows, setDataGridRows] = useState([]);
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
    const [isLoading, setIsLoading] = useState(true);
    const [snackbar, setSnackbar] = useState(null);
    const [editNote, setEditNote] = useState(false);
    const [editRow, setEditRow] = useState(null);
    const [noteText, setNoteText] = useState('');
    const [totRec, setTotRec] = useState(0);

    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        async function fetchData() {
            let billableData = await axios.get(APIURL + "/accountsReceivable", {
                params: { companyID: props.comp_id, month: moment(currentMonth).format('YYYYMM') },
            });

            let totRec = 0;
            billableData.data.forEach((rec) => {
                totRec += rec.total;
            });

            setTotRec(totRec);
            getDataGridRows(billableData.data);
            setIsLoading(false);
        }

        fetchData();
    }, [currentMonth, snackbar]);

    function onDateChange(newValue) {
        setCurrMonth(moment(newValue).startOf("month"));
    }

    const columns = [
        { field: "billable_id", headerName: "Billable ID", hide: true },
        { field: "DDDID", headerName: "DDDID", width: 75 },
        { field: "customer_id", headerName: "Customer ID", hide: true },
        { field: "customer_name", headerName: "Customer Name", width: 200 },
        { field: "customer_status", headerName: "Customer Status", hide: true },
        {
            field: "customer_TransferOutDate",
            headerName: "Customer Transfer Out Date",
            hide: true,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "customer_TransferOutReason", headerName: "Customer Transfer Out Reason", hide: true },
        {
            field: "dob",
            headerName: "Customer DOB",
            hide: true,
            width: 120,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "plan_approval_date",
            headerName: "Active Plan Creation Date",
            hide: true,
            width: 120,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "AgencyAssignDate",
            headerName: "Agency Assign Date",
            hide: true,
            width: 120,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "project_id", headerName: "Project ID", hide: true },
        { field: "project_name", headerName: "Project Name", width: 200, hide: true },
        {
            field: "plan_version",
            width: 80,
            renderHeader: (params) => {
                return (
                    <div
                        style={{
                            maxHeight: "inherit",
                            width: "100%",
                            whiteSpace: "initial",
                            lineHeight: "16px",
                            verticalAlign: "bottom",
                        }}
                    >
                        Plan Version
                    </div>
                );
            },
        },
        {
            field: "plan_program",
            width: 80,
            renderHeader: (params) => {
                return (
                    <div
                        style={{
                            maxHeight: "inherit",
                            width: "100%",
                            whiteSpace: "initial",
                            lineHeight: "16px",
                            verticalAlign: "bottom",
                        }}
                    >
                        Plan Program
                    </div>
                );
            },
        },
        {
            field: "pro_starts_on",
            width: 100,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            },
            renderHeader: (params) => {
                return (
                    <div
                        style={{
                            maxHeight: "inherit",
                            width: "100%",
                            whiteSpace: "initial",
                            lineHeight: "16px",
                            verticalAlign: "bottom",
                        }}
                    >
                        Project Start Date
                    </div>
                );
            },
        },
        {
            field: "pro_ends_on",
            width: 100,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            },
            renderHeader: (params) => {
                return (
                    <div
                        style={{
                            maxHeight: "inherit",
                            width: "100%",
                            whiteSpace: "initial",
                            lineHeight: "16px",
                            verticalAlign: "bottom",
                        }}
                    >
                        Project End Date
                    </div>
                );
            },
        },
        { field: "proc_code", headerName: "Proc Code", width: 100 },
        { field: "unit", headerName: "Unit", width: 60 },
        { field: "rate", headerName: "Rate", width: 50 },
        { field: "total", headerName: "Total", width: 70 },
        { field: "account_code", headerName: "Account Code", width: 175 },
        {
            field: "billable_starts_on",
            width: 100,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            },
            renderHeader: (params) => {
                return (
                    <div
                        style={{
                            maxHeight: "inherit",
                            width: "100%",
                            whiteSpace: "initial",
                            lineHeight: "16px",
                            verticalAlign: "bottom",
                        }}
                    >
                        Billable Start Date
                    </div>
                );
            },
        },
        {
            field: "billable_ends_on",
            width: 100,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            },
            renderHeader: (params) => {
                return (
                    <div
                        style={{
                            maxHeight: "inherit",
                            width: "100%",
                            whiteSpace: "initial",
                            lineHeight: "16px",
                            verticalAlign: "bottom",
                        }}
                    >
                        Billable End Date
                    </div>
                );
            },
        },
        {
            field: "post_date",
            headerName: "Post Date",
            width: 100,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "priorAuthStatus",
            headerName: "Prior Auth Status",
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['On DDD Voucher'],
        },
        {
            field: "paymentStatus",
            headerName: "Payment Status",
            width: 200,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Voucher Payment Received'],
        },
        {
            field: "paymentDate",
            width: 90,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            },
            renderHeader: (params) => {
                return (
                    <div
                        style={{
                            maxHeight: "inherit",
                            width: "100%",
                            whiteSpace: "initial",
                            lineHeight: "16px",
                            verticalAlign: "bottom",
                        }}
                    >
                        Payment Date
                    </div>
                );
            },
        },
        {
            field: "notes",
            headerName: "Notes",
            width: 300,
            editable: true,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <div class="crop">{params.value}</div>
                    </Tooltip>
                );
            },
        },
        // {
        //     field: "billing_note_id",
        //     headerName: "Edit Note",
        //     width: 80,
        //     editable: true,
        //     renderCell: (params) => {
        //         return (
        //             <div>
        //                 <Button onClick={() => { setEditNote(true); setEditRow(params.row); setNoteText(params.row.notes) }}>
        //                     <i className="fas fa-edit"></i>
        //                 </Button>
        //             </div>
        //         )
        //     }
        // },
    ];

    function getDataGridRows(billableData) {
        let rows = billableData.map((data) => {
            // let dates = [];
            // if (data.account_code === 'PLAN TERMINATION') {
            //     dates.push(moment(data.pro_starts_on));
            //     dates.push(moment(data.YYYYMM).startOf('month'));
            // }
            // if (data.account_code === 'INITIAL PLAN') {
            //     dates.push(moment(data.pro_starts_on));
            // }
            // if (data.active_plan_creation_date) {
            //     dates.push(moment(data.active_plan_creation_date));
            // }
            // if (data.AgencyAssignDate) {
            //     dates.push(moment(data.AgencyAssignDate));
            // }
            // if (data.dob) {
            //     dates.push(moment(data.dob).add(21, 'years'));
            // }
            // var latestDate = moment(Math.max(...dates.map(date => date.valueOf())));

            let billableStartDate = '', billableEndDate = '';

            if (data.rate === 0) {
                billableStartDate = '-';
                billableEndDate = '-';
            } else if (data.account_code === 'ACTIVE PLAN' || data.account_code === 'PLAN RENEWED') {
                billableStartDate = moment(data.YYYYMM).startOf('month');
                billableEndDate = moment(data.YYYYMM).utc().endOf('month');
            } else if (data.account_code === 'PLAN CHANGE') {
                const postDate = moment(data.post_date).utc().format('MM/DD/YYYY');

                if (Math.abs(moment(postDate).diff(moment(data.YYYYMM).startOf('month'), 'days')) === (data.unit - 1)) {
                    billableStartDate = moment(data.YYYYMM).startOf('month');
                    billableEndDate = data.post_date;
                } else {
                    billableStartDate = data.post_date;
                    billableEndDate = moment(data.YYYYMM).utc().endOf('month');
                }
            } else if (data.account_code === 'INITIAL PLAN') {
                if (data.proc_code === 'T2024HI') {
                    billableStartDate = moment(data.YYYYMM).startOf('month');
                    billableEndDate = moment(data.YYYYMM).utc().endOf('month');
                } else if (data.proc_code === 'T2024HI:52') {
                    billableStartDate = moment(data.active_plan_creation_date);
                    billableEndDate = moment(data.YYYYMM).utc().endOf('month');
                } else {
                    billableStartDate = '-';
                    billableEndDate = '-';
                }
            } else if (data.account_code === 'PLAN TERMINATION') {
                if (data.cus_status === 'Dormant') {
                    billableStartDate = '';
                    billableEndDate = '';
                } else {
                    billableStartDate = moment(data.YYYYMM).startOf('month');
                    billableEndDate = data.pro_ends_on;
                }
            } else {
                billableStartDate = '';
                billableEndDate = '';
            }

            let billData = {
                billable_id: data.billable_id,
                DDDID: Array.isArray(data.DDDID) ? data.DDDID[0] : data.DDDID,
                customer_id: data.customer_id,
                customer_name: data.cus_name,
                customer_status: data.cus_status,
                customer_TransferOutDate: data.TransferOutDate ? data.TransferOutDate : '-',
                customer_TransferOutReason: data.TransferOutReason,
                dob: data.dob,
                plan_approval_date: data.active_plan_creation_date,
                AgencyAssignDate: data.AgencyAssignDate,
                project_id: data.project_id,
                project_name: data.pro_title,
                plan_version: data.plan_version !== 'null' ? data.plan_version : '',
                plan_program: data.plan_program,
                pro_starts_on: data.pro_starts_on,
                pro_ends_on: data.pro_ends_on,
                proc_code: data.proc_code,
                unit: data.unit,
                rate: data.rate,
                total: data.total,
                account_code: data.account_code,
                billable_starts_on: billableStartDate,
                billable_ends_on: billableEndDate,
                post_date: data.post_date,
                priorAuthStatus: data.prior_auth_status ? data.prior_auth_status : data.primaryID ? 'PA Found' : 'No PA Found',
                paymentStatus: data.payment_status ? data.payment_status : data.claim_ID ? data.Status : 'No Payment Found',
                paymentDate: data.CreateDate ? data.CreateDate : null,
                billing_note_id: data.billing_note_id,
                notes: data.billing_note,
                primaryID: data.primaryID,
                claim_ID: data.claim_ID
            };
            return billData;
        });
        currDataGridRows.current = rows;
        setDataGridRows(currDataGridRows.current);
    }

    const handleCellEditCommit = useCallback(
        async (p) => {
            try {
                let filteredRow = currDataGridRows.current.find((data) => data.billable_id === p.id);

                if (p.field === 'priorAuthStatus' && p.value === 'On DDD Voucher') {
                    await updatePriorAuthData(filteredRow.billable_id);
                    setSnackbar({
                        children: "Prior Auth Status successfully saved.",
                        severity: "success",
                    });
                }
                if (p.field === 'paymentStatus' && p.value === 'Voucher Payment Received') {
                    if (filteredRow.paymentStatus === 'No Payment Found') {
                        await updateClaimData(filteredRow.billable_id);
                        setSnackbar({
                            children: "Claim Status successfully saved.",
                            severity: "success",
                        });
                    } else {
                        setSnackbar({
                            children: "Can only change status if No Payment Found",
                            severity: "error",
                        });
                    }
                }
                // if (p.field === 'notes' && p.value && p.value.replace(/\s/g, '').length !== 0) {
                //     if (!filteredRow.billing_note_id) {
                //         await addBillingNote(filteredRow.billable_id, p.value);
                //         setSnackbar({
                //             children: "Note successfully saved.",
                //             severity: "success",
                //         });
                //     } else {
                //         await updateBillingNote(filteredRow.billing_note_id, p.value);
                //         setSnackbar({
                //             children: "Note successfully saved.",
                //             severity: "success",
                //         });
                //     }
                // }
            } catch (error) {
                setSnackbar({
                    children: error.message,
                    severity: "error"
                });
            }
        },
        []
    );

    const handleCellDoubleClick = (params) => {
        if (params.field === "notes") {
            setEditNote(true);
            setEditRow(params.row);
            setNoteText(params.row.notes);
        }
    };

    async function updatePriorAuthData(id) {
        await axios.put(APIURL + "/accountsReceivable/updatePriorAuth", {
            billable_id: id,
        });
    }

    async function updateClaimData(id) {
        await axios.put(APIURL + "/accountsReceivable/updateClaim", {
            billable_id: id,
        });
    }

    async function updateBillingNote(id, note) {
        try {
            if (note && note.replace(/\s/g, '').length !== 0) {
                await axios.put(APIURL + "/accountsReceivable/billingNote", {
                    billing_note_id: id,
                    billing_note: note,
                    empID: props.user.emp_id,
                });
                setSnackbar({
                    children: "Note successfully saved.",
                    severity: "success",
                });
            } else {
                setSnackbar({
                    children: "Note cannot be empty",
                    severity: "error"
                });
            }
        } catch (error) {
            setSnackbar({
                children: error.message,
                severity: "error"
            });
        }
        setEditNote(false);
        setEditRow(null);

    }

    async function addBillingNote(id, note) {
        try {
            if (note && note.replace(/\s/g, '').length !== 0) {
                await axios.post(APIURL + "/accountsReceivable/billingNote", {
                    billable_id: id,
                    billing_note: note,
                    empID: props.user.emp_id,
                });
                setSnackbar({
                    children: "Note successfully saved.",
                    severity: "success",
                });
            } else {
                setSnackbar({
                    children: "Note cannot be empty",
                    severity: "error"
                });
            }
        } catch (error) {
            setSnackbar({
                children: error.message,
                severity: "error"
            });
        }
        setEditNote(false);
        setEditRow(null);
    }

    if (isLoading) {
        return (
            <div className="d-flex flex-row w-100 justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <Typography
                    style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        fontFamily: "Lato",
                        marginBottom: 10,
                    }}
                >
                    Accounts Receivable
                </Typography>
                <div id="claims-main"
                    style={{ height: "100%", width: "calc(100vw - 500px)", backgroundColor: "#fafafa" }}
                    className="d-flex row justify-content-start">
                    <div
                        style={{ height: '100%', width: "calc(100vw - 500px)", marginTop: 30 }}
                        className="col-lg-12 col-sm-7 d-flex flex-column justify-content-start"
                    >
                        <div className="flex-container" style={{ display: "flex" }}>
                            <div
                                className="flex-item"
                                style={{
                                    flexDirection: "row-reverse",
                                    height: "40px",
                                    marginTop: "5px",
                                    marginBottom: "20px",
                                    marginLeft: "20px",
                                }}
                            >
                                <Button
                                    size="large"
                                    className="DayButton"
                                    onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                </Button>
                                <LocalizationProvider
                                    class="DatePickerParent"
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        label="Select Date"
                                        views={["year", "month"]}
                                        sx={{marginTop: '10px'}}
                                        slotProps={{ textField: { size: 'small' } }}
                                        value={new Date(currentMonth)}
                                        onChange={(newValue) => {
                                            onDateChange(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                                <Button
                                    size="large"
                                    className="DayButton"
                                    onClick={() => onDateChange(currentMonth.add(1, "month"))}
                                >
                                    <i className="fas fa-arrow-right"></i>
                                </Button>
                            </div>
                            <div className="right">
                                <div className="flex-item-u">
                                    <h5><span style={{ textDecoration: 'underline' }}>Total Receivable:</span></h5>
                                    <h5>$ {totRec.toFixed(2)}</h5>
                                </div>
                            </div>
                        </div>
                        <Box sx={{ width: '100%', height: "calc(100vh - 250px)" }}>
                            <ControlledDataGrid
                                gridId={gridId}
                                user={props.user}
                                apiRef={apiRef}
                                initialState={{ sorting: { sortModel: [{ field: "post_date", sort: "desc" }] } }}
                                getRowId={(row) => row.billable_id}
                                rows={dataGridRows}
                                columns={columns}
                                columnVisibilityModel={{
                                    billable_id: false,
                                    customer_id: false,
                                    dob: false,
                                    plan_approval_date: false,
                                    AgencyAssignDate: false,
                                    project_id: false,
                                    project_name: false,
                                }}
                                // slots={{
                                //     Toolbar: GridToolbar,
                                // }}
                                checkboxSelection={false}
                                disableRowSelectionOnClick
                                // density="compact"
                                onCellEditCommit={handleCellEditCommit}
                                onCellDoubleClick={handleCellDoubleClick}
                            />
                            {!!snackbar && (
                                <Snackbar
                                    open
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    onClose={handleCloseSnackbar}
                                    autoHideDuration={6000}
                                >
                                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                                </Snackbar>
                            )}
                        </Box>
                    </div>
                    {editNote &&
                        <Modal show={editNote} onHide={() => { setEditNote(false); setEditRow(null); }} size="lg" backdrop="static" keyboard={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>{editRow.billing_note_id ? 'Edit Note' : 'Add Note'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <CssTextField
                                        multiline
                                        value={noteText}
                                        onChange={(event) => {
                                            setNoteText(event.target.value);
                                        }}
                                        style={{ margin: '5px', width: '100%' }}
                                        inputProps={{ maxLength: 2000 }}
                                        minRows={8}
                                        label="Note"
                                        variant="outlined"
                                        id="bNote"
                                    />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <RBButton
                                    style={{
                                        width: '100%',
                                        padding: '5px',
                                        margin: '5px',
                                    }}
                                    onClick={() => editRow.billing_note_id ? updateBillingNote(editRow.billing_note_id, noteText) : addBillingNote(editRow.billable_id, noteText)}
                                >
                                    {editRow.billing_note_id ? 'Edit Note' : 'Add Note'}
                                </RBButton>
                            </Modal.Footer>
                        </Modal>
                    }
                </div>
            </div>
        )
    }
}