import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { ONBOARDAPIURL, APIURL } from '../../../config';
import { Box, Stack, TextField, Typography, Button } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Unstable_Grid2';

const Onboarding = (props) => {
    const [rows, setRows] = useState([]);
    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        employee_email: '',
    });
    const [formErrors, setFormErrors] = useState({
        first_name: false,
        last_name: false,
        employee_email: false,
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        const rows = props.invites.map((data) => ({
            fw_log_id: data.fw_log_id,
            action_subtype: data.action_subtype,
            systime: data.systime,
            status: data.empEmail
        }));

        setRows(rows);
    }, [props.invites]);

    const clearForm = () => {
        setForm({
            first_name: '',
            last_name: '',
            employee_email: '',
        });
        setFormErrors({
            first_name: false,
            last_name: false,
            employee_email: false,
        });
        setMessage('');
        setError(false);
    };

    const handleOnboardingChange = (e) => {
        setForm({
            ...form,
            [e.target.id]: e.target.value,
        });
        setFormErrors({
            ...formErrors,
            [e.target.id]: false,
        });
    };

    const handleValidationOnClick = async (e) => {
        e.preventDefault();
        const newFormErrors = {
            first_name: form.first_name === '',
            last_name: form.last_name === '',
            employee_email: form.employee_email === '',
        };

        if (newFormErrors.first_name || newFormErrors.last_name || newFormErrors.employee_email) {
            setFormErrors(newFormErrors);
            return;
        }

        await addOnboardingFormToDB();
    };

    const addOnboardingFormToDB = async () => {
        const pBusinessType = props.user?.comp_settings.find(
            (setting) => setting.feature_value === 'primaryBusinessType'
        )
            ? props.user.comp_settings.filter((setting) => setting.feature_value === 'primaryBusinessType')[0].feature_id
            : 100;

        const data = {
            comp_id: props.user.comp_id,
            company_name: props.user.company_name,
            user_name: props.user.emp_name,
            first_name: form.first_name,
            last_name: form.last_name,
            employee_email: form.employee_email,
            employee_type:
                pBusinessType === 101 ? 'Support Coordinator' : pBusinessType === 104 ? 'Case Manager' : 'Fieldworker',
            emp_id: props.user.emp_id,
        };

        try {
            const empConfirm = await axios.post(`${ONBOARDAPIURL}/onboard/initaddEmployee`, { ...data });

            if (empConfirm.data.code === 200) {
                clearForm();
                setMessage('User invited successfully.');

                // Activity log
                const sha1 = require('sha1');
                const sessionID = window.localStorage.getItem('Fw4_access_Token');
                const hash = sha1(sessionID);
                const log_data = {
                    email: props.user.email,
                    actionType: 'Invite Employee',
                    actionSubType: data.employee_email,
                    sessionID: hash,
                };
                await axios.post(`${APIURL}/activity_log`, log_data);
            } else {
                setError(true);
                setMessage(empConfirm.data.message);
            }
        } catch (error) {
            console.error(error);
            setError(true);
            setMessage('An error occurred while inviting user.');
        }
    };

    const columns = [
        { field: 'fw_log_id', headerName: 'FW LOG ID', hide: true },
        { field: 'action_subtype', headerName: 'Invite Email', width: 300 },
        {
            field: 'systime',
            headerName: 'Invite Date',
            width: 200,
            renderCell: (params) => {
                return (
                    <div>
                        {moment(params.value).isValid()
                            ? moment(params.value).utc().format('MM/DD/YYYY, hh:mm A')
                            : params.value}
                    </div>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Invite Status',
            width: 100,
            renderCell: (params) => {
                return <div>{params.value ? 'Joined' : 'Pending'}</div>;
            }
        }
    ];

    return (
        (<Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">Employee Onboarding</Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Invite employees and view sent invites
                        </Typography>
                    </Stack>
                </Grid>
                <Grid xs={props.rightColumnSize}>
                    <Box sx={{ display: 'flex', width: '100%', gap: 2, justifyContent: 'space-between' }}>
                        <TextField
                            label="First Name"
                            id="first_name"
                            value={form.first_name}
                            onChange={handleOnboardingChange}
                            required
                            fullWidth
                            margin="normal"
                            error={formErrors.first_name}
                            helperText={formErrors.first_name ? 'First name is required' : ''}
                            size='small'
                        />
                        <TextField
                            label="Last Name"
                            id="last_name"
                            value={form.last_name}
                            onChange={handleOnboardingChange}
                            required
                            fullWidth
                            margin="normal"
                            error={formErrors.last_name}
                            helperText={formErrors.last_name ? 'Last name is required' : ''}
                            size='small'
                        />
                        <TextField
                            label="Email Address"
                            id="employee_email"
                            value={form.employee_email}
                            onChange={handleOnboardingChange}
                            required
                            fullWidth
                            margin="normal"
                            error={formErrors.employee_email}
                            helperText={formErrors.employee_email ? 'Email address is required' : ''}
                            size='small'
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 1 }}>
                        <Button
                            onClick={handleValidationOnClick}
                            variant="contained"
                            color="primary"
                            size='large'
                        >
                            Invite
                        </Button>
                    </Box>
                    <Grid item xs={12}>
                        <Box sx={{ height: 400, mt: 2 }}>
                            <DataGridPro
                                getRowId={(row) => row.fw_log_id}
                                rows={rows}
                                columns={columns}
                                initialState={{ sorting: { sortModel: [{ field: 'systime', sort: 'desc' }] } }}
                                slots={{ toolbar: GridToolbar }}
                                checkboxSelection={false}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </Grid>
                    {message && (
                        <Typography variant="body1" align="center" color={error ? 'error' : 'success'}>
                            {message}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Box>)
    );
};

export default Onboarding;
