import React from 'react'
import axios from 'axios'
import {APIURL} from '../../config'
import {Spinner} from 'react-bootstrap'
import moment from 'moment'
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from "react-router-dom";
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })


export default class PayrollContainer extends React.Component{
    state = {
        payStubsList: [],
        com_info: [],
        payrollExisting: [],
        isLoading: true,success: false, error: false,message: '',
        inProgress: false, payroll_id: null, pay_period_id: null,
        loggedToSegment: false,
    }

    activatePayroll =  async(id) => {
        this.setState({inProgress: true, payroll_id: null,pay_period_id: id})
        console.log('Will Add payroll using pay period ID', id)
        let data = {
            "company_id": this.props.comp_id,
            "pay_period_id": id,
            "pay_period_status": "ACTIVE",
            "closed_on": null,
            "closed_by": null
        }
        let activateResult = await axios.post(APIURL+'/payroll', {...data})
        if(activateResult.data.code === 200){
            let newPayRoll = {
                "payroll_id": activateResult.data.id,
                "company_id": this.props.comp_id,
                "pay_period_id": id,
                "pay_period_status": "ACTIVE",
                "closed_on": null,
                "closed_by": null
              } 
            this.setState({payrollExisting: [newPayRoll,...this.state.payrollExisting],success: true, error: false,payroll_id: null, pay_period_id: null,inProgress: false,message: 'PAYROLL ACTIVATED'})
        }else{
            this.setState({success: false, error: true,payroll_id: null, pay_period_id: null,inProgress: false,message: 'FAILED TO ACTIVATE PAYROLL. PLEASE TRY AGAIN !!'}) 
        }
    }

    closePayroll =  async(id) => {
        this.setState({inProgress: true, payroll_id: id,pay_period_id: null})

        let selectedPayroll = this.state.payrollExisting.find((pay)=> pay.payroll_id === id)
        let closeResult = await axios.put(APIURL+'/payroll', {...selectedPayroll, pay_period_status: 'CLOSED',closed_by: this.props.user.emp_id })
        console.log('Will Close payroll using pay roll ID', id)
        if(closeResult.data.code === 200){
            let newList = this.state.payrollExisting.map((stub)=> {
                if(stub.payroll_id === +id){
                    stub = {...stub, pay_period_status: 'CLOSED'}
                    console.log(stub)
                }
                return stub
            })

            this.setState({payrollExisting: newList, success: true, error: false, payroll_id: null, pay_period_id: null, inProgress: false, message: 'PAYROLL CLOSED'})
           
        }else{
            this.setState({success: false, error: true, payroll_id: null, pay_period_id: null, inProgress: false,message: 'FAILED TO CLOSE PAYROLL. PLEASE TRY AGAIN !!'})
           
        }
    }

    logToSegment = async () => {
        if(this.state.loggedToSegment) {
          return;
        }
        let page = String(window.location.href).split("/").pop()
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page,this.props.user.emp_id)
        analytics.page(page, {
            title:page,
            path:page,
        }, {
            userId:String(this.props.user.emp_id),
        })
        console.log("logged to segment successfully")
        this.setState({ loggedToSegment : true });
    }

    async componentDidMount(){
        // company?comp_id=2004
        let compInfo = await axios.get(APIURL+'/company', {params: {comp_id: this.props.comp_id}})
        let payrollExisting = await axios.get(APIURL+'/payroll', {params: {company_id: this.props.comp_id}})
        let payStubsList = []
        if(compInfo.data.length !== 0){
            console.log(compInfo.data)
            let type;
            if(compInfo.data[0].payCycle === 'Bi-Weekly'){
                type = 'BIWEEK'
            } else if(compInfo.data[0].payCycle === 'Semi-Monthly'){
                type = 'SEMIMONTH'
            } else if(compInfo.data[0].payCycle === 'Monthly'){
                type = 'MONTH'
            } else{
                type = 'WEEK'
            }
            let day = compInfo.data[0].startoftheWeek === 0?'Sun'
                        :compInfo.data[0].startoftheWeek === 1?'Mon'
                        :compInfo.data[0].startoftheWeek === 2?'Tue'
                        :compInfo.data[0].startoftheWeek === 3?'Wed'
                        :compInfo.data[0].startoftheWeek === 4?'Thu'
                        :compInfo.data[0].startoftheWeek === 5?'Fri'
                        :'Sat'
            console.log(type,day)
            payStubsList = await axios.get(APIURL+'/pay_period', {params: {payroll_type: type, payroll_day:day}})

            // Sort Rows Properly
            const year_start = moment().startOf('year').subtract(1, 'days');;
            const year_end = moment().endOf('year');
            let currentYearList = []
            for (let i=payStubsList.data.length-1; i>= 0; i--){
                if(moment(payStubsList.data[i].start_date).isBetween(year_start, year_end)){
                currentYearList.push(payStubsList.data.splice(i, 1)[0])
                }
            }
            currentYearList.sort((a, b) => (moment(a) > moment(b)) ? 1 : -1)
            payStubsList.data = currentYearList.concat(payStubsList.data)
        }
        
        this.setState({payStubsList: payStubsList.data,com_info: compInfo.data,payrollExisting:payrollExisting.data, isLoading: false})
    }

    render(){
        if(!!this.state.isLoading){
            return (<div className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
            </div></div>)
        }else if(!this.state.isLoading && this.state.payStubsList.length === 0){
            return (<div> <p>payroll component goes here with props</p> </div>)
        }else{
        //     let paytabs = this.state.payStubsList.map((payStub)=> {
        //         return (
        //             <div class="card" style={{width: '18rem'}}>
        //                 <div class="card-body">
        //                     <h5 class="card-title">PAY DATE: {moment(payStub.pay_date).format('MM/DD/YYYY')}</h5>
        //                     <h6 class="card-subtitle mb-2 text-muted">{moment(payStub.start_date).format('MMM DD')} - {moment(payStub.end_date).format('MMM DD')}</h6>
        //                     {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
        //                     {/* <a href="#" class="card-link">Card link</a> */}
        //                     {/* <a href="#" class="card-link">Another link</a> */}
        //                 </div>
        //             </div>
        //     )
        // })

        const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

        let cols = [
            {
                field: 'cycle_number',
                headerName: 'CYCLE',
                flex: 1,
                hide: false
            },
            {
                field: 'status',
                headerName: 'STATUS',
                flex: 1.1,
                hide: false,
                renderCell : (params) =>{
                    // console.log('from render cell ', params.row.id)
                    return (
                        <div>
                        <i style={{color: params.value==='OPEN'?"#3fa7d6":params.value==='ACTIVE'?'green':"red", margin: 10,}} 
                        className={params.value==='OPEN'?"fas fa-lock-open":params.value==='ACTIVE'?'fas fa-check-circle':"fas fa-lock"}
                        >  {params.value}</i>
                       </div>
                    )
                }
            },
            {
                field: 'start_date',
                headerName: 'START DATE',
                flex: 1.2,
                hide: false,
                sortComparator: dateComparator
            },
            {
                field: 'end_date',
                headerName: 'END DATE',
                flex: 1.2,
                hide: false,
                sortComparator: dateComparator
            },
            {
                field: 'pay_date',
                headerName: 'PAY DATE',
                flex: 1.1,
                hide: false,
                sortComparator: dateComparator
            },
            {
                field: 'pay_period_id',
                headerName: 'ID',
                flex: 1.1,
                hide: true
            },
            {
                field: 'actions',
                headerName: 'ACTIONS',
                flex: 1.1,
                hide: false,
                renderCell : (params) =>{
                    // console.log('from render cell ', params.row.status)
                    return (
                        <div>
                        {/* {!!params.row.inProgress && <button disabled={true} style={{borderRadius: 15,backgroundColor: '#3fa7d6', margin: 10}} 
                        className={"fas fa-circle"}
                        >  {'LOADING'}</button>} */}
                        {!!params.row.inProgress && <div className='row'> <Spinner style={{height: 15,width:15}} className='my-2 mx-1' animation="border" variant="info" /> <strong>Loading </strong></div>}
                        {!params.row.inProgress && params.row.status === 'OPEN' && <button onClick={params.row.actions.activateStub} disabled={this.state.inProgress} style={{borderRadius: 15,color: '#3FA7D6', margin: 10}} 
                        className={"fas fa-check-circle"}

                        >  {'ACTIVATE'}</button>}
                        {!params.row.inProgress && params.row.status === 'ACTIVE' && <button onClick={params.row.actions.closeStub} disabled={this.state.inProgress} style={{borderRadius: 15,color: 'green', margin: 10}} 
                        className={"fas fa-lock"}

                        >  {'CLOSE'}</button>}
                        {!params.row.inProgress && params.row.status === 'CLOSED' && <button disabled={true} style={{borderRadius: 15,color: 'red', margin: 10}} 
                        className={"fas fa-lock"}

                        >  {'CLOSED'}</button>}
                       </div>
                    )
                }
            }
            
        ]
        console.log(this.state.payStubsList)
        let rows= this.state.payStubsList.map((stub)=> {
            let stubStatus = this.state.payrollExisting.find((pay)=> pay.pay_period_id === stub.pay_period_id)
            let newstub = {...stub,
                 id: stub.pay_period_id, 
                 start_date: moment(stub.start_date).utc().format('MM/DD/YYYY'),
                 end_date: moment(stub.end_date).utc().format('MM/DD/YYYY'),
                 pay_date: moment(stub.pay_date).utc().format('MM/DD/YYYY'),
                 status: !stubStatus?'OPEN':stubStatus.pay_period_status,
                 actions: {
                     activateStub: () => {this.activatePayroll(stub.pay_period_id)},
                     closeStub: () => {this.closePayroll(stubStatus.payroll_id)}
                 },
                 inProgress : (this.state.inProgress && (this.state.pay_period_id === stub.pay_period_id || (!!stubStatus && stubStatus.payroll_id === this.state.payroll_id)) ),
                 cycle_number: stub.payroll_number
                }
            return newstub
        })

            return (
                (<div className='d-flex flex-column' 
                // style={{height: '60rem'}}
                >
                    <Snackbar open={this.state.success} autoHideDuration={6000} onClose={()=>{this.setState({success : false})}}>
                    <MuiAlert onClose={()=>{this.setState({success : false})}} severity="success">
                    {this.state.message}
                    </MuiAlert>
                    </Snackbar>
                    <Snackbar open={this.state.error} autoHideDuration={6000} onClose={()=>{this.setState({error : false})}}>
                    <MuiAlert onClose={()=>{this.setState({error : false})}} severity="danger">
                    {this.state.message}
                    </MuiAlert>
                    </Snackbar>
                    <div className='d-flex row'>
                    
                    <Link to={'/timesheets'}><i className="fas fa-arrow-left m-2 ml-4" onClick={()=>{this.props.history.goBack()}}></i></Link>
                    <h3 style={{fontFamily: 'Lato'}}>PAYROLL SETTINGS</h3>
                    </div>
                    {/* <hr/> */}
                    <div style={{width: '1000px', marginLeft: 30,marginTop: 20}} >
                     <DataGrid 
                    initialState={{ density: 'compact'}}
                    style = {{color: '#000000'}}
                    rows={rows}
                    columns={cols} 
                    // onRowSelected={(params)=> this.toggleCheckBox(params.data.id)}
                    hideFooterSelectedRowCount={true}
                    showToolbar 
                    slots={{toolbar: GridToolbar, color: '#3fadf6'}}
                    autoHeight={true}
                    pageSize={!this.props.pagesize?26:this.props.pagesize}
                     />
                    </div>
                </div>)
            );
        }
    }
}