import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../config";
import moment from "moment";
import { Box } from "@mui/material";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import './reports.css';

export default function CaseManagerCaseload(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [caseLoadData, setCaseLoadData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let caseLoadData = await axios.get(APIURL + "/customers/getAssociatedCustomersAndTaskTypes", {
                params: { compID: props.comp_id, empID: props.user.emp_id, empType: props.user.type },
            });

            let rows = caseLoadData.data.map((data, idx) => {
                let caseLoadData = {
                    id: idx,
                    emp_id: data.pro_manager_id,
                    emp_last_name: data.ManagerLastName,
                    emp_first_name: data.ManagerFirstName,
                    pro_id: data.pro_id,
                    pro_title: data.pro_title,
                    cus_name: data.cus_name,
                    cus_medicaid_id: data.medicaid_id,
                    cus_task_types: data.cusTaskTypes ? data.cusTaskTypes.map((item) => item.EntityValue).join(', ') : '-',
                };
                return caseLoadData;
            });

            setCaseLoadData(rows);
            setIsLoading(false);
        }

        fetchData();
    }, []);

    const columns = [
        { field: "id", headerName: "ID", hide: true },
        { field: "emp_id", headerName: "Employee ID", hide: true },
        { field: "emp_last_name", headerName: "Case Manager Last Name", width: 210 },
        { field: "emp_first_name", headerName: "Case Manager First Name", width: 210 },
        { field: "pro_id", headerName: "Case ID", hide: true },
        { field: "pro_title", headerName: "Case Name", hide: true },
        { field: "cus_name", headerName: "Customer Name", width: 200 },
        { field: "cus_medicaid_id", headerName: "Customer Medicaid ID", width: 200 },
        { field: "cus_task_types", headerName: "Associated Task Types", width: 800 },
    ];

    return (
        (<div>
            {isLoading ?
                <div className="d-flex flex-row w-100 justify-content-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '100%', height: "calc(100vh - 175px)" }}>
                        <DataGridPro
                            initialState={{ sorting: { sortModel: [{ field: "emp_last_name", sort: "asc" }] } }}
                            getRowId={(row) => row.id}
                            rows={caseLoadData}
                            columns={columns}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            checkboxSelection={false}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </div>
            }
        </div>)
    );
};