import React from "react";
import { Button } from "react-bootstrap";
import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#3FA7D6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3FA7D6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "lightblue",
      },
      "&:hover fieldset": {
        borderColor: "#3FA7D6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3FA7D6",
      },
    },
  },
})(TextField);

// isCustomer

const BasicDets = (props) => {
  const { customerList, empList } = props;
  let selCust = [];
  let pSponsor = [];
  // let pContact = []
  let pManager = [];
  // let pCustomer = customerList.map((cust, indx) => <option key={indx} value={cust.id}>{cust.customer_name}</option>)
  let pCustomer = customerList.map((cust, indx) => {
    return {
      value: !props.edit ? cust.id : cust.cus_id,
      title: !props.edit ? cust.customer_name : cust.cus_name,
    };
  });
  if (props.edit) {
    selCust.push(customerList[0].cus_is_company);
    // pSponsor.push(<option key={customerList[0].cus_id} value={customerList[0].cus_id}>{customerList[0].cus_name}</option>)
    pSponsor.push({
      value: customerList[0].cus_id,
      title: customerList[0].cus_name,
    });
    // pContact = contactList.map((cont, indx) => <option key={indx} value={cont.con_id}>{cont.con_first_name + " " + cont.con_last_name}</option>)
    pManager = !props.proj_employees
      ? []
      : props.proj_employees
          .filter(
            (emp) =>
              emp.emp_is_active === true
          )
          .map((emp, indx) => {
            return {
              value: emp.emp_id,
              title: emp.emp_first_name + " " + emp.emp_last_name,
            };
          });
  } else {
    selCust = customerList
      .filter(({ id }) => id === +props.formVals.pCustomer)
      .map((cus) => {
        return cus.cus_is_company;
      });
    // pSponsor = customerList.filter(({ id }) => id === +props.formVals.pCustomer).map((cust, indx) => <option key={indx} value={cust.id}>{cust.customer_name}</option>)
    pSponsor = customerList
      .filter(({ id }) => id === +props.formVals.pCustomer)
      .map((cust, indx) => {
        return { value: cust.id, title: cust.customer_name };
      });
    // pContact = contactList.map((cont, indx) => <option key={indx} value={cont.id}>{cont.contact_name}</option>)
    // pManager = empList.filter((emp)=> (emp.emp_type === "Manager" || emp.emp_type === "Admin")).map((emp, indx) => <option key={indx} value={emp.id}>{emp.employee_name}</option>)
    pManager = empList
      .filter((emp) => emp.emp_type === "Manager" || emp.emp_type === "Admin")
      .map((emp, indx) => {
        return { value: emp.id, title: emp.employee_name };
      });
  }
  pSponsor = [{ value: "No Sponsor", title: "No Sponsor" }, ...pSponsor];

  // // selCust = selCust.map((cus)=> cus.cus_is_company)
  // console.log(empList)
  return (
    <div>
      {/* <Form.Group as={Col} controlId="pTitle">
                <Form.Label>PROJECT TITLE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.pTitle} type="text" onChange={props.handlebasicDetailsChange} />
            </Form.Group> */}
      <CssTextField
        // style={{width:'35%'}}
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        label="PROJECT TITLE"
        value={props.formVals.pTitle}
        onChange={props.handlebasicDetailsChange}
        variant="outlined"
        id="pTitle"
        required
        inputProps={{ maxLength: 100 }}
        // disabled={!!this.props.edit?true:false}
      />

      {/* <Form.Group as={Col} controlId="pDesc">
                <Form.Label>DESCRIPTION</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.pDesc} type="text" onChange={props.handlebasicDetailsChange} />
            </Form.Group> */}
      <CssTextField
        // style={{width:'35%'}}
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        label="PROJECT DESCRIPTION"
        value={props.formVals.pDesc}
        onChange={props.handlebasicDetailsChange}
        variant="outlined"
        id="pDesc"
        required
        inputProps={{ maxLength: 250 }}
        // disabled={!!this.props.edit?true:false}
      />

      <CssTextField
        // style={{width:'35%'}}
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        label="PROJECT ACTIVE PLAN VERSION"
        value={props.formVals.active_plan_version || ""}
        onChange={props.handlebasicDetailsChange}
        variant="outlined"
        id="active_plan_version"
        required
        inputProps={{ maxLength: 20 }}
        // disabled={!!this.props.edit?true:false}
      />

      {/* <Form.Group as={Col} controlId="pCustomer">
                <Form.Label>CUSTOMER</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.pCustomer} as="select" onChange={props.handlebasicDetailsChange} disabled={props.edit}>
                    {props.edit ? <option>{props.customerList[0].cus_name}</option> : <option value="">Select Customer</option>}
                    {pCustomer}
                </Form.Control>
            </Form.Group> */}
      <Autocomplete
        id="pCustomer"
        value={pCustomer.find((type) => type.value === props.formVals.pCustomer)}
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        onChange={(event, newValue) => {
          console.log(newValue)
          let val = pCustomer.find((man) => man.title === newValue.title);
          props.handlebasicDetailsChange({
            target: { id: "pCustomer", value: !!val ? val.value : "" },
          });
        }}
        options={pCustomer}
        getOptionLabel={(option) => option.title}
        // style={{ width: '46%' }}
        renderInput={(params) => (
          <CssTextField {...params} label="CUSTOMER *" variant="outlined" />
        )}
        disabled={props.edit}
      />

      {/* <Form.Group as={Col} controlId="pSponsor">
                <Form.Label>SPONSOR</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={!props.formVals.pSponsor?"":props.formVals.pSponsor} as="select" onChange={props.handlebasicDetailsChange}>
                    <option value="">Select Sponsor</option>
                    <option value="No Sponsor">No Sponsor</option>
                    {!selCust[0] ? pSponsor : pContact}
                </Form.Control>
            </Form.Group> */}
      <Autocomplete
        id="pSponsor"
        value={
          pSponsor.find((type) => type.value === props.formVals.pSponsor) ||
          null
        }
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        onChange={(event, newValue) => {
          let val = pSponsor.find((man) => man.title === newValue.title);
          props.handlebasicDetailsChange({
            target: { id: "pSponsor", value: !!val ? val.value : "No Sponsor" },
          });
        }}
        options={pSponsor}
        getOptionLabel={(option) => option.title}
        // style={{ width: '46%' }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            label="PROJECT SPONSOR"
            variant="outlined"
          />
        )}
        disabled={props.edit}
      />

      {/* <Form.Group as={Col} controlId="pContact">
                <Form.Label>CONTACT</Form.Label>
                <Form.Control value={props.formVals.pContact} as="select" onChange={props.handlebasicDetailsChange}>
                    <option value="">Select Contact</option>
                    <option value="No Contact">No Contact</option>
                    {pContact}
                </Form.Control>
            </Form.Group> */}

      {/* <Form.Group as={Col} controlId="pManager">
                <Form.Label>MANAGER</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.pManager} as="select" onChange={props.handlebasicDetailsChange}>
                    <option value="">Select Manager</option>
                    {pManager}
                </Form.Control>
            </Form.Group> */}
      <Autocomplete
        id="pManager"
        required
        value={
          pManager.find((type) => type.value === props.formVals.pManager) ||
          null
        }
        className={props.edit ? `mx-2 my-3 w-100` : `mx-2 my-3 w-75`}
        onChange={(event, newValue) => {
          let val = pManager.find((man) => man.title === newValue.title);
          props.handlebasicDetailsChange({
            target: { id: "pManager", value: !!val ? val.value : "" },
          });
        }}
        options={pManager}
        getOptionLabel={(option) => option.title}
        // style={{ width: '46%' }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            label="PROJECT MANAGER *"
            variant="outlined"
          />
        )}
        // disabled={props.edit}
      />

      <Button
        className={!!props.edit ? "w-100 ml-2" : "w-75 ml-2"}
        variant="primary"
        block
        type="button"
        onClick={() => props.onContinue("dateLocation")}
      >
        Continue
      </Button>
    </div>
  );
};

export default BasicDets;
