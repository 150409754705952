import React, { useEffect, useState, useRef } from 'react'
import { Form, submission } from '@formio/react';
import axios from 'axios';
import { APIURL } from '../../../config';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const IntakeForm = (props) => {
    const [json_schema, setJsonSchema] = useState({});
    const [data, setData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState([]);

    const dataRef = useRef({});


    const token = props.match.params.token;

    useEffect(() => {
        console.log('Form component loaded', token);
        if (!token) {
            window.location.replace('/'); // Redirect to login page
        }
        getData();
    }, []);

    const getData = async () => {
        try {
            console.log('Fetching data for token:', token);
            const tokenObj = await axios.get(APIURL + '/tokens', { params: { token: token } });
            console.log('Token object:', tokenObj);
            let data = JSON.parse(tokenObj.data[0].entity_data);
            const response = await axios.get(APIURL + '/task_form/form', { params: { form_id: data.form_id } });
            console.log('Form response:', response);
            setJsonSchema(JSON.parse(response.data[0].json_schema));
            dataRef.current = {
                form_id: data.form_id,
                task_id: data.task_id,
                customer_id: data.customer_id,
                employee_id: tokenObj.data[0].created_by
            }
        } catch (error) {
            window.location.replace('/'); // Redirect to login page
        }
    }

    const handleSubmit = async (submission) => {
        try {            
            const response = await axios.post(APIURL + '/customer_form', {
                customer_id: dataRef.current.customer_id,
                task_id: dataRef.current.task_id,
                data: JSON.stringify(submission),
                form_type: dataRef.current.form_id,
                emp_id: dataRef.current.employee_id
            });
            setSubmitted(true);
        } catch (error) {
            console.log('Error submitting data:', error);
        }
    }

    
    return (
        <Box sx={{ maxWidth: '800px', margin: 'auto' }}>
            {submitted ? 
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '80vh' }}>
                    <CheckCircleOutlineIcon sx={{ fontSize: '90px', color: 'green' }} />
                    <Typography variant='h4'>Form submitted successfully</Typography>
                </Box> :
                <Form
                    form={json_schema}
                    submission={data}
                    onSubmit={handleSubmit}
                    onError={(errors) => {
                        setErrors(errors);
                    }}
                />
            }
        </Box>
    )
}

export default IntakeForm;