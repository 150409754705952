import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Grid, FormControl, TextField, Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: 12,
    },
    link: {
        fontSize: 15,
    },
}));

const formFields = {};

export default function TimesheetsFilter(props) {
    const classes = useStyles();

    const [timesheetFilterData, settimesheetFilterData] = useState(formFields);

    useEffect(() => {
        let tempObj = localStorage.getItem("timesheetFilterData");
        if (!tempObj && props.filterResultsOutput === "") {
            settimesheetFilterData({ ...formFields });
        }
        if (tempObj) {
            tempObj = JSON.parse(tempObj);
            settimesheetFilterData({ ...tempObj });
        }
    }, []);

    const applyFilters = (e) => {
        let temp = { ...timesheetFilterData };
        props.onFormSubmission(temp);
    };

    const handleFieldsData = (e, name, key) => {
        let temp = { ...timesheetFilterData };
        switch (key) {
            case "date":
                {
                    temp[name] = moment(new Date(e)).format("MM/DD/YYYY");
                }
                break;
            case "time":
                {
                    temp[name] = moment(new Date(e));
                }
                break;
            case "hours":
                {
                    const re = /^[0-9]*[.,]?[0-9]*$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                        temp[name] = e.target.value;
                    }
                }
                break;
            case "datetime":
                {
                    temp[name] = moment(new Date(e)).format("MM/DD/YYYY hh:mm a");
                }
                break;

            default:
                {
                    temp[name] = e.target.value;
                }
                break;
        }
        settimesheetFilterData({ ...temp });
    };

    return (
        <Grid
            container
            justify="center"
            style={{
                height: "85%",
                width: "22%",
                border: "1px solid gery",
                borderRadius: "5px",
                backgroundColor: "#ffffff",
                position: "fixed",
                zIndex: 1,
                right: 0,
                boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%)",
                padding: 12,
                overflowY: "auto",
            }}
        >
            <Grid container>
                <Grid
                    container
                    style={{ borderBottom: "1px solid grey", paddingBottom: "0px" }}
                    justifyContent="space-between"
                >
                    <Grid item xs={11}>
                        <Typography
                            variant="subtitle2"
                            style={{
                                fontSize: 12,
                                fontStyle: "italic",
                                paddingBottom: "5px",
                                fontFamily: "Lato",
                                fontWeight: "bold",
                                color: "#495057",
                                display: "inline-block",
                            }}
                            align="left"
                        >
                            Select desired fields to run a customized report:
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <CancelIcon
                            style={{ cursor: "pointer" }}
                            alignItems="right"
                            onClick={(e) => {
                                props?.closeCustomReport(e);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className="mb-1 mt-3">
                    <Typography
                        variant="h1"
                        style={{ fontSize: 14, fontFamily: "Lato" }}
                        mb={1}
                        className="font-weight-bold"
                    >
                        Date Entered
                    </Typography>
                    <FormControl size="small" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Today's date"
                                value={moment(timesheetFilterData.TaskcreateDate).isValid() ? dayjs(timesheetFilterData.TaskcreateDate) : null}
                                onChange={(newValue) => {
                                    handleFieldsData(newValue, "TaskcreateDate", "date");
                                }}
                                slotProps={{textField: {variant: 'outlined', size: 'small'}}}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <hr
                        style={{ borderTop: "1px solid #f0f0f0", margin: "5px 0 5px 0" }}
                    />
                </Grid>
                <Grid item xs={12} className="mb-1">
                    <Typography
                        variant="h1"
                        style={{ fontSize: 14, fontFamily: "Lato" }}
                        mb={1}
                        className="font-weight-bold"
                    >
                        Project
                    </Typography>
                    <FormControl size="small" fullWidth>
                        <TextField
                            id="outlined-name-1"
                            size="small"
                            label="Enter"
                            value={timesheetFilterData.Project}
                            onChange={(e) => {
                                handleFieldsData(e, "Project", "");
                            }}
                            margin="normal"
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <hr
                        style={{ borderTop: "1px solid #f0f0f0", margin: "5px 0 5px 0" }}
                    />
                </Grid>

                <Grid item xs={12} className="mb-1">
                    <Typography
                        variant="h1"
                        style={{ fontSize: 14, fontFamily: "Lato" }}
                        className="font-weight-bold"
                    >
                        Task Type
                    </Typography>
                    <FormControl size="small" fullWidth>
                        <TextField
                            id="outlined-name-2"
                            size="small"
                            label="Enter"
                            value={timesheetFilterData.TaskType}
                            onChange={(e) => {
                                handleFieldsData(e, "TaskType", "");
                            }}
                            margin="normal"
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <hr
                        style={{ borderTop: "1px solid #f0f0f0", margin: "5px 0 5px 0" }}
                    />
                </Grid>

                <div style={{ display: 'flex' }}>
                    <Grid item xs={12} className="mb-1 mr-5">
                        <Typography
                            variant="h1"
                            style={{ fontSize: 14, fontFamily: "Lato" }}
                            mb={1}
                            className="font-weight-bold"
                        >
                            Employee First Name
                        </Typography>
                        <FormControl size="small" fullWidth>
                            <TextField
                                id="outlined-name-1"
                                size="small"
                                label="Enter"
                                value={timesheetFilterData.FirstName}
                                onChange={(e) => {
                                    handleFieldsData(e, "FirstName", "");
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className="mb-1">
                        <Typography
                            variant="h1"
                            style={{ fontSize: 14, fontFamily: "Lato" }}
                            mb={1}
                            className="font-weight-bold"
                        >
                            Employee Last Name
                        </Typography>
                        <FormControl size="small" fullWidth>
                            <TextField
                                id="outlined-name-1"
                                size="small"
                                label="Enter"
                                value={timesheetFilterData.LastName}
                                onChange={(e) => {
                                    handleFieldsData(e, "LastName", "");
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                </div>
                <Grid item xs={12}>
                    <hr
                        style={{ borderTop: "1px solid #f0f0f0", margin: "5px 0 5px 0" }}
                    />
                </Grid>

                <Grid item xs={12} className="mb-1">
                    <Typography
                        variant="h1"
                        style={{ fontSize: 14, fontFamily: "Lato" }}
                        mb={1}
                        className="font-weight-bold"
                    >
                        Activity Date
                    </Typography>
                    <FormControl size="small" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Today's date"
                                value={moment(timesheetFilterData.RecActivityDate).isValid ? moment(timesheetFilterData.RecActivityDate) : null}
                                onChange={(newValue) => {
                                    handleFieldsData(newValue, "RecActivityDate", "date");
                                }}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <hr
                        style={{ borderTop: "1px solid #f0f0f0", margin: "5px 0 5px 0" }}
                    />
                </Grid>

                <Grid item xs={12} className="mb-1">
                    <FormControl size="small" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Typography
                                variant="h1"
                                style={{ fontSize: 14, fontFamily: "Lato" }}
                                className="font-weight-bold"
                            >
                                Hours
                            </Typography>
                            <TextField
                                id="outlined-name"
                                label="Enter"
                                size="small"
                                inputProps={{
                                    className: classes.input,
                                    pattern: "^[0-9]*[.,]?[0-9]*$",
                                }}
                                value={timesheetFilterData.WeeklyHrs}
                                onChange={(newValue) => {
                                    handleFieldsData(newValue, "WeeklyHrs", "hours");
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>

                <Grid item xs={12} className="mb-1 mt-4">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Button
                            onClick={(e) => {
                                localStorage.removeItem("timesheetFilterData");
                                props.onFormSubmission({});
                                settimesheetFilterData({});
                            }}
                            variant="primary"
                            size="sm"
                            className="font-weight-bold"
                            style={{ fontSize: 14, fontFamily: "Lato" }}
                        >
                            RESET
                        </Button>

                        <Button
                            onClick={(e) => {
                                localStorage.setItem(
                                    "timesheetFilterData",
                                    JSON.stringify(timesheetFilterData)
                                );
                                applyFilters();
                            }}
                            variant="primary"
                            size="sm"
                            className="font-weight-bold"
                            style={{ fontSize: 14, fontFamily: "Lato" }}
                        >
                            RUN REPORT
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};