import React, { Component } from 'react'
import { ReactBingmaps } from 'react-bingmaps'
import { BINGAPIKEY } from '../../config'
import axios from 'axios'
import { Modal, Button } from 'react-bootstrap'
import { APIURL } from '../../config'
import './top_appointment.css'
import CheckoutModal from '../Checkout/CheckoutModal'
import SubtasksAddModal from '../Checkout/SubtasksAddModal'
import moment from 'moment'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'

class Card extends Component {
    constructor(props) {
        super(props)
        this.state = {
            time: '',
            day: '',
            checkedIn: false,
            tsModal: false,
            subtasksCheckoutModal: false,
            subtasksAddModal: false,
            cancelModal: false,
            rescheduleModal: false,
            matchingForm: undefined,
            matchingFormID: undefined,
            rescheduleFrom: undefined,
            rescheduleTo: undefined,
            forms: [],
        }
        this.setDetail = this.setDetail.bind(this)
        this.getForms = this.getForms.bind(this)
        this.checkIn = this.checkIn.bind(this)
    }

    componentDidMount() {
        // console.log(this.props.detail)
        console.log(this.props);
        if(this.props.detail) {
            this.setDetail();
            this.getForms();
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.detail !== this.props.detail && this.props.detail) {
            this.setDetail();
            this.getForms();
        }
    }

    async setDetail() {
        let contact = await axios.get(`${APIURL}/employees`, { params: { com_id: this.props.detail.detail.pro_com_id, emp_id: this.props.detail.detail.tas_emp_id } })
        await this.setState({
            time: this.props.detail.detail.tas_start_time,
            day: this.props.detail.key.split('_')[0],
            appointment_title: this.props.detail.detail.tas_title,
            start_time: this.props.detail.detail.tas_start_time,
            locations: this.props.detail.detail.locInfo.map((obj) => {
                return `${obj.loc_addr_line1}, ${obj.loc_addr_line2}, ${obj.loc_city}, ${obj.loc_state}`
            }),
            lat: parseFloat(this.props.detail.detail.locInfo.loc_lat),
            lon: parseFloat(this.props.detail.detail.locInfo.loc_lon),
            contact: contact.data.length > 0 ? contact.data[0] : undefined,
            type: this.props.detail.detail.tas_type,
            checkedIn: !!this.props.detail.detail.checkin ? this.props.detail.detail.checkin : false,
            rescheduleFrom: this.props.detail.detail.tas_start_time,
            rescheduleTo: this.props.detail.detail.tas_end_time
        })
    }

    async getForms() {
        let taskTypes = await axios.get(`${APIURL}/dropdowns/dropdownType/fullTaskType`, { params: { comp_id: this.props.detail.detail.pro_com_id } })
        console.log(taskTypes);
        let taskTypeId = taskTypes.data.filter((type) => type.EntityValue === this.props.detail.detail.tas_type)[0].ddID
        if(taskTypeId) {
            let forms = await axios.get(`${APIURL}/task_form`, { params: { taskType_id: taskTypeId, company_id: this.props.detail.detail.pro_com_id } })
            this.setState({ forms: forms.data })
        }
    }
        

    checkIn() {
        this.setState({
            checkedIn: true
        })
    }

    processCheckoutConfirmButton() {
        if (!this.state.checkedIn && !!this.props.canCheckIN) {
            this.props.onCheckIn(this.props.detail.detail.tas_id);
            this.checkIn();
            this.setState({ tsModal: false })
        } else if (!!this.state.checkedIn) {
            // this.checkIn(); 
            this.setState({ tsModal: false })
            this.props.onCheckOut(this.props.detail.detail.tas_id)
        } else {
            this.props.showWarnMod()
            this.setState({ tsModal: false })
            console.log('Already Checked In to a different Task')
        }
    }

    render() {
        return (
            <div className='card'>
                <div className='card_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {!moment(this.state.start_time).isValid() ? this.state.start_time : moment(this.state.start_time).format('YYYY-MM-DD, hh:mm A')}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        <i className="far fa-calendar-alt"></i>
                        <div style={{ marginLeft: '2%' }}>{this.state.day}</div>
                    </div>
                    <div />
                </div>
                <div style={{ display: 'flex', width: '100%', margin: '1%' }}>
                    <div style={{ width: '70% ', marginRight: '2%' }}>
                        <div className='card_title_card'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='card_title_card_bullet' />
                                <div>
                                    {this.state.appointment_title}
                                </div>
                            </div>
                            <div className='card_start_time'>
                                <div style={{ marginRight: '1%' }}><i className="far fa-clock"></i></div>
                                {!moment(this.state.start_time).isValid() ? moment(this.props.detail.detail.tas_start_date).utc().format('YYYY-MM-DD ,') + this.state.start_time : moment(this.state.start_time).format('YYYY-MM-DD, hh:mm A')}
                                {/* {moment(this.state.start_time).format('YYYY-MM-DD, HH:mm A')} */}
                            </div>
                        </div>
                        <div className='card_map'>
                            <ReactBingmaps
                                id={this.props.index}
                                bingmapKey={BINGAPIKEY}
                                center={[this.state.lat, this.state.lon]}
                                pushPins={[{ location: [this.state.lat, this.state.lon], option: { color: '#06D6A0' } }]}
                                navigationBarMode={"compact"}
                            />
                        </div>
                        <div className='card_check_in'>
                            <div className='card_check_in_button'
                                onClick={() => {
                                    this.setState({ subtasksCheckoutModal: true });
                                }
                                }
                                style={{ color: this.state.checkedIn ? 'white' : 'black', background: this.state.checkedIn ? '#06D6A0' : 'white', height:'40px', overflow:'hidden',marginTop: '1%'}}
                            >
                                <div style={{ marginRight: '3%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {this.state.checkedIn ? <i className="fas fa-check-circle"></i> : <i className="far fa-circle"></i>}
                                </div>
                                <div>{this.state.checkedIn ? 'Check Out' : 'Check In'}</div>
                            </div>
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', height:'50px', overflow:'hidden', margin:'auto'}}>
                                {this.state.locations && this.state.locations.map((addr) => {
                                            return (<div className='card_check_in_location' style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '2%' }}>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div>
                                    {addr}  
                                    </div>
                                </div>)
                                })}
                            </div>
                            {/*<Button size="sm" style={{ marginLeft: '10%' }} onClick={() => this.setState({ subtasksAddModal: true })}>Schedule a Subtask</Button>*/}
                        </div>
                    </div>
                    <div style={{ width: '30% ', marginRight: '2%' }}>
                        <div className='notice_reminder'>
                            <div style={{ display: 'flex', margin: '0.2%', color: '#3FA7D6' }}>
                                <i style={{ fontSize: 25 }} className="fas fa-user-circle"></i>
                            </div>
                            <div>
                                <div className='notice_reminder_title d-flex flex-column'>
                                    {/* {this.props.detail.detail.empInfo[0].emp_name} */}
                                    <p className='m-0'>{this.state.contact && `${this.state.contact.emp_first_name} ${this.state.contact.emp_last_name}`}</p>
                                    <p className='m-0 text-muted'>({this.state.contact && `${this.state.contact.emp_type}`})</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', margin: '1%' }}>
                                {/* <i className="fas fa-chevron-right fa-xs"></i> */}
                            </div>
                        </div>
                        <div className='contact_card'>
                            <div className='contact_card_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    {/* <i className="fas fa-info-circle fa-2x mx-2"></i> */}
                                    <strong style={{ fontSize: 15 }}>TASK INFO</strong>
                                </div>
                                {/* <div className='contact_call_icon m-0'>
                                    <i className="fas fa-info-circle fa-2x"></i>
                                </div> */}
                            </div>
                            <div style={{ marginTop: 0 }} className='contact'>
                                {/* <div style={{ marginRight: '5%' }}>
                                    <i className="fas fa-info-circle fa-3x"></i>
                                </div> */}
                                <div>
                                    <div className='contact_name mt-1'>
                                        <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-folder mx-2"></i>
                                        : <em className='mx-2'>{this.props.detail && `${this.props.detail.detail.pro_title}`}</em>
                                    </div>
                                    <div className='contact_name mb-1'>
                                        <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-briefcase mx-2"></i>
                                        : <em className='mx-2'>{this.props.detail && `${this.props.detail.detail.cusInfo[0].cus_name}`}</em>
                                    </div>
                                    <div className='contact_name_subtitle mb-1'>
                                        <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-stopwatch mx-2"></i> :
                                        <em className='mx-2'>
                                            {this.state.contact && !!moment(this.props.detail.detail.tas_end_time).isValid() && `${moment.duration(moment(this.props.detail.detail.tas_end_time).diff(moment(this.props.detail.detail.tas_start_time))).humanize()}, ${moment(this.props.detail.detail.tas_end_time).format('MMM DD hh:mm A')}`}
                                            {this.state.contact && !moment(this.props.detail.detail.tas_end_time).isValid() && `${moment(this.props.detail.detail.tas_end_date).format('MMM DD')} ${this.props.detail.detail.tas_end_time}`}
                                        </em>
                                    </div>
                                    <div className='contact_name_subtitle'>
                                        <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-tasks mx-2"></i> :
                                        <em className='mx-2'>
                                            {this.state.contact && `${this.props.detail.detail.tas_type}`}
                                        </em>
                                        {/* {this.state.contact && `${this.state.contact.emp_level}`} */}
                                    </div>
                                </div>
                            </div>
                            <div className='center'>
                            <div className='contact_description'>
                                <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-sticky-note mx-2"></i> :
                                <em className='mx-2'>
                                    {this.state.contact && `${this.props.detail.detail.tas_notes}`}
                                </em>
                                {/* {this.state.type} */}
                            </div>
                            <div className='contact_description-tooltip'>
                                <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-sticky-note mx-2"></i> :
                                <em className='mx-2'>
                                    {this.state.contact && `${this.props.detail.detail.tas_notes}`}
                                </em>
                                {/* {this.state.type} */}
                            </div>
                            </div>
                        </div>
                        <div className={`card_request`}>
                            <div style={{ fontSize: '11px' }}>Actions</div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '5%', marginBottom: '5%' }}>
                                {<button style={{ background: !!this.state.checkedIn ? 'grey' : '#3fa7d6' }} disabled={!!this.state.checkedIn ? true : false} className={`card_reschedule`} onClick={() => this.setState({ rescheduleModal: !this.state.rescheduleModal })}>
                                    <div style={{ marginRight: '5%' }}><i className="far fa-calendar-alt"></i></div>
                                    <div>Reschedule</div>
                                </button>}
                                <button style={{ background: !!this.state.checkedIn ? 'grey' : 'red' }} disabled={!!this.state.checkedIn ? true : false} onClick={() => this.setState({ cancelModal: !this.state.cancelModal })} className={`card_cancel`}>
                                    <div style={{ marginRight: '5%', color: 'white' }}><i className={`far fa-calendar-times`}></i></div>
                                    <div>Cancel Appointment</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <CheckoutModal
                    user_id={this.props.user.emp_id}
                    task={this.props.detail}
                    task_id={this.props.detail.detail.tas_id}
                    cus_id={this.props.detail.detail.cusInfo[0].cus_id}
                    show={this.state.subtasksCheckoutModal}
                    hide={() => { this.setState({ subtasksCheckoutModal: !this.state.subtasksCheckoutModal }) }}
                    isCheckedIn={this.state.checkedIn}
                    processCheckout={() => this.processCheckoutConfirmButton()}
                    modalTitle={'Check ' + (!!this.state.checkedIn ? 'Out' : 'In') + ' Confirmation'}
                    modalBody={'Checking ' + (!!this.state.checkedIn ? 'Out of' : 'In to') + ' Task: ' + (this.props.detail.detail.tas_title) + ' at ' + (moment().format('HH:mm A')) + '.'}
                    matchingForm={this.state.matchingForm}
                    matchingFormID={this.state.matchingFormID}
                    emp_id={this.props.detail.detail.tas_emp_id}
                    forms={this.state.forms}
                ></CheckoutModal>
                <SubtasksAddModal
                    task_id={this.props.detail.detail.tas_id}
                    show={this.state.subtasksAddModal}
                    hide={() => { this.setState({ subtasksAddModal: !this.state.subtasksAddModal }) }}
                    processCheckout={() => this.processCheckoutConfirmButton()}
                    modalTitle={'Check ' + (!!this.state.checkedIn ? 'Out' : 'In') + ' Confirmation'}
                    modalBody={this.state.appointment_title}
                ></SubtasksAddModal>
                <Modal
                    show={this.state.tsModal}
                    onHide={() => { this.setState({ tsModal: !this.state.tsModal }) }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Check {!!this.state.checkedIn ? 'Out' : 'In'} Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Checking you {!!this.state.checkedIn ? 'Out of' : 'In to'} Task - {this.props.detail.detail.tas_title} at {moment().format('HH:mm A')} ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ tsModal: !this.state.tsModal }) }}>
                            Close
                        </Button>
                        <Button onClick={() => {
                            if (!this.state.checkedIn && !!this.props.canCheckIN) {
                                this.props.onCheckIn(this.props.detail.detail.tas_id);
                                this.checkIn();
                                this.setState({ tsModal: false })
                            } else if (!!this.state.checkedIn) {
                                // this.checkIn(); 
                                this.setState({ tsModal: false })
                                this.props.onCheckOut(this.props.detail.detail.tas_id)
                            } else {
                                this.props.showWarnMod()
                                this.setState({ tsModal: false })
                                console.log('Already Checked In to a different Task')
                            }
                        }} style={{ height: '2.5rem' }} variant="primary">Confirm</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.cancelModal}
                    onHide={() => { this.setState({ cancelModal: !this.state.cancelModal }) }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Appointment Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Task - {this.props.detail.detail.tas_title} will be deleted. Please Confirm?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ cancelModal: !this.state.cancelModal }) }}>
                            Close
                        </Button>
                        <Button onClick={() => { this.props.onCancel(this.props.detail.detail.tas_id); this.setState({ cancelModal: false }) }} style={{ height: '2.5rem' }} variant="primary">Confirm</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.rescheduleModal}
                    onHide={() => { this.setState({ rescheduleModal: !this.state.rescheduleModal }) }}
                    backdrop="static"
                    keyboard={false}
                    width
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Reschedule Appointment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='reschedule_modal' style={{height: '130px'}}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                localeText={{ start: "From Date", end: "To Date" }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    <DateTimePicker
                                        slotProps={{textField: {variant: 'outlined'}}}
                                        label="Start Date"
                                        value={moment(this.state.rescheduleFrom).isValid() ? dayjs(this.state.rescheduleFrom) : null}
                                        onChange={(newValue) => {
                                            this.setState({ rescheduleFrom: newValue })
                                        }}
                                        sx={{ margin: '1rem' }}
                                    />
                                    <DateTimePicker
                                        slotProps={{textField: {variant: 'outlined'}}}
                                        label="End Date"
                                        value={moment(this.state.rescheduleTo).isValid() ? dayjs(this.state.rescheduleTo) : null}
                                        onChange={(newValue) => {
                                            this.setState({ rescheduleTo: newValue })
                                        }}
                                        sx={{ margin: '1rem' }}
                                    />
                                </div>
                            </LocalizationProvider>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ rescheduleModal: !this.state.rescheduleModal }) }}>
                            Close
                        </Button>
                        <Button onClick={() => { this.props.onReschedule(this.props.detail.detail.tas_id, this.state.rescheduleFrom, this.state.rescheduleTo); this.setState({ rescheduleModal: false }) }} style={{ height: '2.5rem' }} variant="primary">Confirm</Button>
                    </Modal.Footer>
                </Modal>        
            </div>
        )
    }
}

export default Card
