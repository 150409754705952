import React, { useState, useEffect } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from "@mui/material/TextField";
import moment from 'moment';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import agent from '../../utils/agent';

import './PreAuthTask.css';

const PreAuthTask = (props) => {
    const [date, setDate] = useState(new Date());
    const [value, setValue] = useState("1");
    const [loader, setLoader] = useState(true);
    const [preAuthData, setPreAuthData] = useState([]);

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            const res1 = await getPreAuthTaskData(date);
            if (mounted && res1.status === 200) {
                setPreAuthData(res1?.data?.data);
                setLoader(false);
            }
        }
        fetchData();
        return () => { mounted = false };
    }, [date]);

    const getPreAuthTaskData = async (date) => {
        return await agent.PreAuth.getPreAuthTask(date !== undefined ? date : new Date())
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const TaskDatePicker = () => {
        return (
            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        views={['year', 'month']}
                        margin="normal"
                        label="Select Date"
                        format="MM/yyyy"
                        value={new Date(date)}
                        onChange={(d) => {
                            setLoader(true);
                            setDate(d)
                        }}
                        maxDate={new Date()}
                        slotProps={{textField: {variant: 'outlined'}}}
                    />
                </LocalizationProvider>
            </div>
        )
    }


    const TaskContainer = ({ data }) => {
        return data && data.length > 0 ? data.map((task, i) => {
            return (
                <div key={i} className="TaskCard">
                    <div className="TaskHeader">
                        <h6>{task.tas_title}</h6>
                        <li>
                            <span>
                                {!moment(task.tas_start_time).isValid() ? `${moment(task.tas_start_date).add(1,'days').format('MMM DD')}, ${task.tas_start_time}` : task.tas_start_time.length === 24 ? moment(task.tas_start_time.toString().slice(0, task.tas_start_time.length - 3)).format('MMM DD, HH:mm A') : moment(task.tas_start_time).format('MMM DD, HH:mm A')}  -  {!moment(task.tas_end_time).isValid() ? `${moment(task.tas_end_date).add(1,'days').format('MMM DD')}, ${task.tas_end_time}` : task.tas_end_time.length === 24 ? moment(task.tas_end_time.toString().slice(0, task.tas_end_time.length - 3)).format('MMM DD, HH:mm A') : moment(task.tas_end_time).format('MMM DD, HH:mm A')}
                            </span>
                        </li>
                    </div>
                    <div className="TaskHeaderTwo">
                        <span>{task.tas_type !== "null" ? task.tas_type : ''}</span>
                    </div>
                    <hr className="HorizontalLine"></hr>
                    <div>
                        <span><i className="fas fa-user-alt"></i></span>
                        <span style={{ marginLeft: "15px" }}>{task.emp_first_name + " " + task.emp_last_name}</span>
                    </div>
                    <div>
                        <span>
                            <i className="fas fa-info-circle"></i>
                        </span>
                        <span style={{ marginLeft: "15px" }}>{task.tas_description}</span>
                    </div>
                </div>
            )
        }) : (
            <div className='noTaskFound'>
                <img src='./taskmanagement-01.png' alt='taskmanagement-01' style={{ width: '10rem', height: '10rem' }} />
                <h4>Oops! No task found</h4>
            </div>
        );
    }

    const TaskTab = () => (
        <TabContext value={value}>
            <Box sx={{ width: '100%', height: "100%", borderRadius: "5px", marginTop: "10px" }}>
                <Tabs TabIndicatorProps={{
                    style: {
                        backgroundColor: "#3FA7D6"
                    },
                }}
                    value={value}
                    onChange={handleChange}>
                    <Tab style={{ color: '#3FA7D6' }} value="1" label="Authorized Task" />
                    <Tab style={{ color: '#3FA7D6' }} value="2" label="Unauthorized Task" />
                </Tabs>
            </Box>
            <TabPanel style={{ backgroundColor: "rgb(235, 248, 255)" }} value="1"><TaskContainer data={preAuthData ? preAuthData.authorizedTask : []} /></TabPanel>
            <TabPanel style={{ backgroundColor: "rgb(235, 248, 255)" }} value="2"><TaskContainer data={preAuthData ? preAuthData.unAuthorizedTask : []} /></TabPanel>
        </TabContext>
    )


    return <div className='d-flex flex-column'>
        <div className='d-flex row'>
            <i className="fas fa-arrow-left m-2 ml-4" style={{ cursor: "pointer" }} onClick={() => { props.history.goBack() }}></i>
            <h4>Pre Auth Task List</h4>
        </div>
        <div style={{ margin: "10px 30px" }}>
            <TaskDatePicker />
            {loader ? (
                <div className='loaderContainer'>
                    <div style={{ color: '#3fa7d6' }} className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : <TaskTab />}
        </div>
    </div>;
}

export default PreAuthTask;