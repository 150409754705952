import React from 'react';
import { Form, Modal, Button, Col } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import './RecurrenceModal.css'

function RecurrenceModal(props) {
    const useStyles  = makeStyles({
        root: {
          '& label.Mui-focused': {
            color: '#3FA7D6',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#3FA7D6',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'lightblue',
            },
            '&:hover fieldset': {
              borderColor: '#3FA7D6',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#3FA7D6',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '3FA7D6'
            }
          },
        },
    });

    const classes = useStyles();

    return (
        (<Modal style={{ zIndex: 2000 }}  show={props.showModal} onHide={props.handleClose} dialogClassName="modal-style" centered>
            <Modal.Header>
              <Modal.Title>Custom Recurrence</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 py-3">
                <Form.Group>
                    <Form.Row className="d-flex align-items-center justify-content-between mb-4">
                        <span style={{fontSize: "18px"}}>Repeat every</span>
                        <TextField 
                            className={classes.root}
                            id="recur-num"
                            name="num"
                            type="number"
                            InputProps={{ inputProps: { min: "1", max: "30"} }}
                            value={props.num}
                            variant="outlined"
                            onChange={(e) => {props.setNum(parseInt(e.target.value))}}
                            onKeyDown={(e) => {e.preventDefault()}}
                            style={{caretColor: "transparent"}}/>
                        <Autocomplete
                            id="recur-option"
                            name="option"
                            value={props.option}
                            options={["day", "week", "month", "year"]}
                            getOptionLabel={(option) => option}
                            disableClearable
                            onKeyDown={(e) => {e.preventDefault()}}
                            onChange={(e, val) => {props.setOption(val)}}
                            style={{ width: 120, caretColor: "transparent"}}
                            renderInput={(params) => <TextField className={classes.root} {...params} variant="outlined" />}/>
                    </Form.Row >
                    {props.option === "week" &&
                        <Form.Row id="recur-repeat-on" className="mb-3">
                            <span className="mr-2" style={{fontSize: "18px"}}>Repeat on</span>
                            <Col>
                                <Form.Check label="Monday" type="checkbox" name="Mon" onChange={props.handleCheckBox} checked={props.days.includes("Mon")}/>
                                <Form.Check label="Tuessday" type="checkbox" name="Tue" onChange={props.handleCheckBox} checked={props.days.includes("Tue")}/>
                                <Form.Check label="Wednesday" type="checkbox" name="Wed" onChange={props.handleCheckBox} checked={props.days.includes("Wed")}/>
                                <Form.Check label="Thrusday" type="checkbox" name="Thu" onChange={props.handleCheckBox} checked={props.days.includes("Thu")}/>
                                <Form.Check label="Friday" type="checkbox" name="Fri" onChange={props.handleCheckBox} checked={props.days.includes("Fri")}/>
                                <Form.Check label="Saturday" type="checkbox" name="Sat" onChange={props.handleCheckBox} checked={props.days.includes("Sat")}/>
                                <Form.Check label="Sunday" type="checkbox" name="Sun" onChange={props.handleCheckBox} checked={props.days.includes("Sun")}/>
                            </Col>
                        </Form.Row>
                    }
                    <Form.Group>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Form.Row className="mb-2 d-flex align-items-center">Ends</Form.Row>
                            <Form.Row className="mb-2 d-flex align-items-center">
                                <Form.Check 
                                    className="on-radio"
                                    label="On" 
                                    type="radio" 
                                    name="ends" 
                                    id="recur-on" 
                                    checked={!props.disabled}
                                    onChange={() => props.setDisabled(!props.disabled)}/>
                                <DatePicker
                                    margin="normal"
                                    id="recur-end-date"
                                    format="MM/DD/YYYY"
                                    maxDate={props.project ? props.project.end_date : moment(props.endOnDate).format()}
                                    value={new Date(moment(props.endOnDate).format())}
                                    onChange={(date) => {props.setEndOnDate(date)}}
                                    disabled={props.disabled}
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                        />
                                    )}
                                    slotProps={{
                                        popper: {
                                            style: { zIndex: 2000 },
                                        },
                                    }} />
                            </Form.Row>
                            <Form.Row className="d-flex align-items-center">
                                <Form.Check 
                                    className="after-radio"
                                    label="After" 
                                    type="radio" 
                                    name="ends" 
                                    id="recur-after"
                                    checked={props.disabled}
                                    onChange={() => props.setDisabled(!props.disabled)}/>
                                <TextField 
                                    className={classes.root + " mr-3"}
                                    id="rec-num-of-repeats"
                                    type="number"
                                    name="num-of-repeats"
                                    InputProps={{ inputProps: { min: "1", max: "30"} }}
                                    value={props.numberOfRepeats}
                                    variant="outlined"
                                    onChange={(e) => {props.setNumberOfRepeats(parseInt(e.target.value))}}
                                    onKeyDown={(e) => {e.preventDefault();}}
                                    style={{caretColor: "transparent"}}
                                    disabled={!props.disabled}/>
                                <Form.Text className={!props.disabled ? "text-muted" : ""} style={{fontSize: "18px"}}>Occurences</Form.Text>
                            </Form.Row>
                        </LocalizationProvider>
                    </Form.Group>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer style={{borderTop: "0 none", justifyContent: "center"}}>
                <Button 
                    className="w-25 mx-4" 
                    variant="outline-primary" 
                    onClick={props.handleCancel} 
                    onChange={() => props.setDays(["Monday"])}>
                    Cancel
                </Button>
                <Button className="w-25 mx-4" onClick={props.handleSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>)
    );
};

export default RecurrenceModal;
