import React, { useEffect, useState } from 'react';
import {
    Box, Typography, IconButton, Divider, TextField, Autocomplete, Grid, ListItemText
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Edit from '@mui/icons-material/Edit';
import SaveButton from '../Components/SaveButton';
import CancelButton from '../Components/CancelButton';
import moment from 'moment';
import axios from 'axios';
import { APIURL } from '../../../config';
import dd from "../../../manifestdd.json";

const EmploymentInformation = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [formValues, setFormValues] = useState({ ...props.employee });
    const [lists, setLists] = useState({
        empList: [],
        freqList: [],
        typeList: [],
        genericstatusList: [],
        levelList: [],
        statusList: []
    });

    useEffect(() => {
        setFormValues({ ...props.employee, emp_join_date: moment(props.employee.emp_join_date).utc().format('YYYY-MM-DD') });
    }, [props.employee]);

    useEffect(() => {        
        let empList = props.empList?.filter((emp) => emp.emp_type === 'Admin' || emp.emp_type === 'Manager')
            .map((emp) => ({ value: emp.emp_id, title: `${emp.emp_first_name} ${emp.emp_last_name}` }));
        empList = [{ value: '', title: 'No Manager' }, ...empList];

        const freqList = props.compensationType?.map((type) => ({ value: type.EntityValue, title: type.EntityValue }));
        const typeList = dd.generic.employeeType?.map((type) => ({ value: type.value, title: type.value }));
        const genericstatusList = dd.generic.employeeisActive?.map((type) => ({
            value: (type.value === 'true') ? true : false,
            title: (type.value === 'true') ? 'Active' : 'Terminated'
        }));
        const levelList = props.levelList?.map((level) => ({ value: level, title: level }));
        const statusList = props.statusList?.map((stat) => ({ value: stat, title: stat }));

        setLists({
            empList,
            freqList,
            typeList,
            genericstatusList,
            levelList,
            statusList
        });
    }, [props.empList, props.compensationType, props.levelList, props.statusList]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === 'emp_is_active') {
            setFormValues({ ...formValues, [id]: value, date_of_termination: null, termination_reason: '' });
        } else {
            setFormValues({ ...formValues, [id]: value });
        }
    };

    const handleDateChange = (date, field) => {
        setFormValues({ ...formValues, [field]: date });
        handleInputChange({ target: { id: field, value: date } });
    };

    const handleCancel = () => {
        setFormValues({ ...props.employee, emp_join_date: moment(props.employee.emp_join_date).utc().format('YYYY-MM-DD') });
        setEditMode(false);
    };

    const handleSave = async () => {
        try {
            const data = {
                ...formValues,
                emp_id: props.user_id,
                email: formValues.empEmail,
                first_name: formValues.emp_first_name,
                last_name: formValues.emp_last_name,
                address1: formValues.emp_addr_line1,
                address2: formValues.emp_addr_line2,
                city: formValues.emp_city,
                state: formValues.emp_state,
                zip: formValues.emp_zip,
                phoneList: formValues.emp_phone_number,
                join_date: formValues.emp_join_date ? moment(formValues.emp_join_date).format("YYYY-MM-DD") : formValues.emp_join_date,
                status: formValues.emp_status,
                employee_type: formValues.emp_type,
                employee_level: formValues.emp_level,
                vacation_days: formValues.emp_vacation,
                personal_days_off: formValues.emp_personal,
                dob: formValues.dob ? moment(formValues.dob).format("YYYY-MM-DD") : formValues.dob,
                terminationDate: formValues.date_of_termination ? moment(formValues.date_of_termination).format("YYYY-MM-DD") : formValues.date_of_termination,
                terminationReason: formValues.termination_reason,
                company_id: props.comp_id,
                emp_manager_id: formValues.emp_manager_id,
                telemedRoom: formValues.emp_doxylink,
                emp_stateRegistraionID: formValues.emp_stateRegistraionID,
                emp_stateRegistraionType: formValues.emp_stateRegistraionType,
                emp_professionalLicenseNumber: formValues.emp_professionalLicenseNumber,
                compensation_type: formValues.compensation_type,
                compensation_rate: formValues.compensation_rate
            };

            const response = await axios.put(`${APIURL}/employees`, data);
            props.setEmployee({ ...props.employee, ...formValues });
            setEditMode(false);
        } catch (error) {
            console.error(error);
        }
    };

    const isAdmin = props.type === "Admin";

    return (
        (<Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h5">Employment Information</Typography>
                {editMode ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <CancelButton onClick={handleCancel} />
                        <SaveButton onClick={handleSave} />
                    </Box>
                ) : (
                    isAdmin && (
                        <IconButton onClick={() => setEditMode(true)}>
                            <Edit />
                        </IconButton>
                    )
                )}
            </Box>
            <Box spacing={1}>
                <Grid container spacing={2}>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Employee Type</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <Autocomplete
                                id="emp_type"
                                value={lists.typeList.find(type => type.title === formValues.emp_type) || null}
                                options={lists.typeList}
                                getOptionLabel={(option) => option.title}
                                onChange={(e, value) => handleInputChange({ target: { id: 'emp_type', value: value?.value } })}
                                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth size="small" sx={{ left: -14 }} InputProps={{ ...params.InputProps, style: { paddingLeft: 8 } }} />}
                                disableClearable
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_type || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Employee Level</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <Autocomplete
                                id="emp_level"
                                value={lists.levelList.find(level => level.title === formValues.emp_level) || null}
                                options={lists.levelList}
                                getOptionLabel={(option) => option.title}
                                onChange={(e, value) => handleInputChange({ target: { id: 'emp_level', value: value?.value } })}
                                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth size="small" sx={{ left: -14 }} InputProps={{ ...params.InputProps, style: { paddingLeft: 8 } }} />}
                                disableClearable
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_level || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />

                <Grid container spacing={2}>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Designation</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <Autocomplete
                                id="emp_status"
                                value={lists.statusList.find(status => status.title === formValues.emp_status) || null}
                                options={lists.statusList}
                                getOptionLabel={(option) => option.title}
                                onChange={(e, value) => handleInputChange({ target: { id: 'emp_status', value: value?.value } })}
                                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth size="small" sx={{ left: -14 }} InputProps={{ ...params.InputProps, style: { paddingLeft: 8 } }} />}
                                disableClearable
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_status || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Employee’s Supervisor</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <Autocomplete
                                id="emp_manager_id"
                                value={lists.empList.find(emp => emp.value === formValues.emp_manager_id) || lists.empList.find(emp => emp.value === '')}
                                options={lists.empList}
                                getOptionLabel={(option) => option.title}
                                onChange={(e, value) => handleInputChange({ target: { id: 'emp_manager_id', value: value?.value } })}
                                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth size="small" sx={{ left: -14 }} InputProps={{ ...params.InputProps, style: { paddingLeft: 8 } }} />}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.value}>
                                        <ListItemText primary={option.title} />
                                    </li>
                                )}
                                disableClearable
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>
                                {formValues.emp_manager_id ? lists.empList.find(emp => emp.value === formValues.emp_manager_id)?.title : 'No Manager'}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />

                <Grid container spacing={2}>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Join Date</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    slotProps={{ textField: { size: 'small' } }}
                                    sx={{marginLeft: "-15px"}}
                                    value={moment(formValues.emp_join_date)}
                                    onChange={(date) => handleDateChange(date, 'emp_join_date')}
                                    format="MM/DD/YYYY"
                                    disableFuture
                                />
                            </LocalizationProvider>
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{moment(formValues.emp_join_date).isValid() ? moment(formValues.emp_join_date).format('MM/DD/YYYY') : ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Compensation Type</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <Autocomplete
                                id="compensation_type"
                                value={lists.freqList.find(type => type.title === formValues.compensation_type) || null}
                                options={lists.freqList}
                                getOptionLabel={(option) => option.title}
                                onChange={(e, value) => handleInputChange({ target: { id: 'compensation_type', value: value?.value } })}
                                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth size="small" sx={{ left: -14 }} InputProps={{ ...params.InputProps, style: { paddingLeft: 8 } }} />}
                                disableClearable
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.compensation_type || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />

                <Grid container spacing={2}>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Compensation Rate</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <TextField id="compensation_rate" sx={{ left: -14 }} value={formValues.compensation_rate || ''} onChange={handleInputChange} fullWidth size="small" variant="outlined" />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.compensation_rate || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Professional License Number</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <TextField id="emp_professionalLicenseNumber" sx={{ left: -14 }} value={formValues.emp_professionalLicenseNumber || ''} onChange={handleInputChange} fullWidth size="small" variant="outlined" />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_professionalLicenseNumber || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />

                <Grid container spacing={2}>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>State Registration ID</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField id="emp_stateRegistraionID" sx={{ left: -14 }} value={formValues.emp_stateRegistraionID || ''} onChange={handleInputChange} fullWidth size="small" variant="outlined" />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_stateRegistraionID || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>State Registration Type</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <TextField id="emp_stateRegistraionType" sx={{ left: -14 }} value={formValues.emp_stateRegistraionType || ''} onChange={handleInputChange} fullWidth size="small" variant="outlined" />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_stateRegistraionType || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />

                <Grid container spacing={2}>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Vacation Days</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <TextField id="emp_vacation" sx={{ left: -14 }} value={formValues.emp_vacation || ''} onChange={handleInputChange} fullWidth size="small" variant="outlined" />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_vacation || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Personal Days</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <TextField id="emp_personal" sx={{ left: -14 }} value={formValues.emp_personal || ''} onChange={handleInputChange} fullWidth size="small" variant="outlined" />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_personal || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />

                <Grid container spacing={2}>
                    <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Employment Status</Typography></Grid>
                    <Grid item xs={4}>
                        {editMode && isAdmin ? (
                            <Autocomplete
                                id="emp_is_active"
                                value={lists.genericstatusList.find(type => type.value === formValues.emp_is_active) || null}
                                options={lists.genericstatusList}
                                getOptionLabel={(option) => option.title}
                                onChange={(e, value) => handleInputChange({ target: { id: 'emp_is_active', value: value?.value } })}
                                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth size="small" sx={{ left: -14 }} InputProps={{ ...params.InputProps, style: { paddingLeft: 8 } }} />}
                                disableClearable
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_is_active ? 'Active' : 'Terminated'}</Typography>
                        )}
                    </Grid>
                    {!formValues.emp_is_active && (
                        <>
                            <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Date of Termination</Typography></Grid>
                            <Grid item xs={4}>
                                {editMode && isAdmin ? (
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            sx={{marginLeft : "-15px"}}
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={moment(formValues.date_of_termination).isValid() ? moment(formValues.date_of_termination) : null}
                                            onChange={(date) => handleDateChange(date, 'date_of_termination')}
                                            format="MM/DD/YYYY"
                                            disableFuture
                                        />
                                    </LocalizationProvider>
                                ) : (
                                    <Typography variant="body1" sx={{ my: 1 }}>{moment(formValues.date_of_termination).isValid() ? moment(formValues.date_of_termination).format('MM/DD/YYYY') : ''}</Typography>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
                <Divider sx={{ my: 1 }} />

                {!formValues.emp_is_active && (
                    <Grid container spacing={2}>
                        <Grid item xs={2}><Typography variant="body1" sx={{ color: 'gray', my: 1 }}>Termination Reason</Typography></Grid>
                        <Grid item xs={10}>
                            {editMode && isAdmin ? (
                                <TextField id="termination_reason" sx={{ left: -14 }} value={formValues.termination_reason || ''} onChange={handleInputChange} fullWidth size="small" variant="outlined" />
                            ) : (
                                <Typography variant="body1" sx={{ my: 1 }}>{formValues.termination_reason || ''}</Typography>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Box>)
    );
};

export default EmploymentInformation;
