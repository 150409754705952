import React, { useState, useEffect } from 'react';
import { Box, Typography, Radio, Stack, FormControlLabel, FormGroup, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';
import axios from 'axios';
import { APIURL } from '../../../config';

const Payer = (props) => {
    const [selectedPayer, setSelectedPayer] = useState(null);
    const [originalPayer, setOriginalPayer] = useState(null);

    const handleRadioChange = (event) => {
        setSelectedPayer(props.payerList.find(payer => payer.payer_name === event.target.value));
    }

    const handleSave = async () => {
        try {
            let data = { ...props.companyDetail, payerId: selectedPayer.payer_id, company_id: props.comp_id };
            await axios.put(`${APIURL}/company`, { ...data });

            setOriginalPayer(selectedPayer);

            // activity log
            const sha1 = require('sha1');
            let sessionID = window.localStorage.getItem('Fw4_access_Token');
            let hash = sha1(sessionID);
            let log_data = {
                emp_id: props.user_id,
                actionType:'Configuration Settings Changed',
                actionSubType:'Payer information changed',
                sessionID: hash
            }
            await axios.post(`${APIURL}/activity_log`, log_data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        let payer = props.payerList.find(payer => payer.payer_id === props.companyDetail.payerId);
        setSelectedPayer(payer);
        setOriginalPayer(payer);
    }, [props.payerList, props.companyDetail]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            Payer
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Who is the payer for your business?
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <Box sx={{ minHeight: 22 }}>
                        </Box>
                        <FormGroup>
                            <RadioGroup name="payer" value={selectedPayer?.payer_name || ''} onChange={handleRadioChange}>
                                <Grid container spacing={0}>
                                    {props.payerList.map((payer, index) => {
                                        return (
                                            <Grid item xs={6} key={index}>
                                                <FormControlLabel sx={{ height: 30 }} control={<Radio value={payer.payer_name} />} label={payer.payer_name} />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </RadioGroup>
                        </FormGroup>
                        <SaveButton onClick={handleSave} disabled={JSON.stringify(selectedPayer) === JSON.stringify(originalPayer)} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Payer;
