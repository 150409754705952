import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../config";
import { Button, IconButton, Box, Typography } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MUIRichTextEditor from 'mui-rte';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const myTheme = createTheme({
    overrides: {
        MUIRichTextEditor: {
            anchorLink: {
                color: "lightblue",
                textDecoration: "underline",
            },
        },
    },
});


export default function WhatsNew(props) {
    const [whatsNewVersions, setWhatsNewVersions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchWhatsNewVersions = async () => {
            try {
                let response = await axios.get(`${APIURL}/system/whatsnew`);
                setWhatsNewVersions(response.data);
                response.data.sort((a, b) => new Date(b.DeploymentDate) - new Date(a.DeploymentDate));
                setCurrentIndex(0);
            } catch (error) {
                console.error(error);
            }
        };

        fetchWhatsNewVersions();
    }, []);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex < whatsNewVersions.length - 1 ? prevIndex + 1 : prevIndex));
    };

    const isJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    return (
        (<Box display="flex" alignItems="center" justifyContent="center" position="relative" minHeight="200px">
            {whatsNewVersions.length > 0 && (
    <IconButton 
        onClick={handlePrev} 
        disabled={currentIndex === 0}
        sx={{ position: 'absolute', left: 50, top: '75%' }}
    >
        <ArrowBackIosIcon />
    </IconButton>
)}
            <Box minWidth={600} sx={{ paddingX: '40px' /* Adjust based on arrow button size */ }}>
                {whatsNewVersions.length > 0 ? (
                    <div>
                        <Typography variant="h5">{whatsNewVersions[currentIndex].Title} (Version {whatsNewVersions[currentIndex].Version})</Typography>
                        <Typography variant="body1">{new Date(whatsNewVersions[currentIndex].DeploymentDate).toLocaleDateString()}</Typography>
                        <Typography variant="body2" component="div">
                            {whatsNewVersions[currentIndex].Description.split(`\\n`).map((line, index) => (
                                <ThemeProvider theme={myTheme}>
                                    <MUIRichTextEditor
                                        defaultValue={isJSON(line) ? line : `{"blocks":[{"key":"block1","text":"${line.replace(/\n/g, ' ').replace(/\t/g, ' ').replace(/"/g, '\\"')}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`}
                                        readOnly={true}
                                        toolbar={false}
                                        editorStyle={{ textAlign: 'left' }}
                                    />
                                </ThemeProvider>
                            ))}
                        </Typography>
                    </div>
                ) : <>Check back later to find the latest updates to the Fieldworker App!</>}
            </Box>
            {whatsNewVersions.length > 0 && (
                <IconButton 
                    onClick={handleNext} 
                    disabled={currentIndex === whatsNewVersions.length - 1}
                    sx={{ position: 'absolute', right: 50, top: '75%' }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            )}
        </Box>)
    );
}