import * as React from "react";
import { useState, useEffect } from "react";
import { APIURL } from '../../../config';
import axios from 'axios';
import moment from "moment";
import { Checkbox, Tooltip, Box } from "@mui/material";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../../components/DataGrid/ControlledDataGrid';

export default function Checklists(props) {
    const [gridId, setGridId] = useState(50);
    const apiRef = useGridApiRef();
    const [taskData, setTaskData] = useState([]);
    const [taskID, setTaskID] = useState(null);

    useEffect(async () => {
        let tasks = await axios.get(APIURL + "/task/checklist", { params: { comp_id: props.comp_id, emp_id: props.user.emp_id } });
        setTaskData(tasks.data)
        console.log(tasks.data);
    }, [taskID]);

    let rows = taskData.map((data, idx) => {
        let taskData = {
            id: idx,
            tas_id: data.tas_id,
            cus_name: data.cus_name,
            mt_period: data.mt_period,
            question: data.tas_notes,
            comments: data.tas_description,
            checkin: data.checkin
        };
        return taskData;
    });

    const columns = [
        { field: "cus_name", headerName: "Customer Name", width: 200 },
        { 
            field: "mt_period", 
            headerName: "MT Period", 
            width: 100,
            renderCell: (params) => {
                return (
                    <div>
                        {moment(params.value).format('MMM YYYY')}
                    </div>
                )
            }
        },
        { field: "question", headerName: "Question", width: 600 },
        {
            field: "comments",
            headerName: "Comments",
            width: 600,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <div class="crop">{params.value}</div>
                    </Tooltip>
                )
            },
        },
        {
            field: "checkin",
            headerName: "Mark as Complete",
            width: 110,
            renderHeader: (params) => {
                return (
                    <div
                        style={{
                            maxHeight: "inherit",
                            width: "100%",
                            whiteSpace: "initial",
                            lineHeight: "16px",
                            verticalAlign: "bottom",
                        }}
                    >
                        Mark as Complete
                    </div>
                );
            },
            renderCell: (params) => {
                return (
                    !params.value ?
                        <div>
                            <Checkbox onClick={() => {
                                updateTaskStatusToComplete(params.row.tas_id)
                            }} />
                            {/* <Button size="small" variant="contained" onClick={() => {
                                updateTaskStatusToComplete(params.row.tas_id)
                            }}>
                                Complete
                            </Button> */}
                        </div> :
                        <div>
                            <Checkbox disabled checked />
                            {/* <Button size="small" variant="contained" disabled>
                                Complete
                            </Button> */}
                        </div>
                )
            }
        },
    ]

    async function updateTaskStatusToComplete(taskId) {
        setTaskID(taskId)
        let result = await axios.put(APIURL + '/task/checkin', { tas_id: taskId, checkin: true })
        setTaskID(null)
        console.log(result)
    }

    return (
        <div>
            <h4 style={{ marginTop: '10px', marginBottom: '10px' }}>Outstanding follow-up items</h4>
            <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: '100%', height: "calc(100vh - 190px)" }}>
                    <ControlledDataGrid
                        gridId={gridId}
                        user={props.user}
                        initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
                        apiRef={apiRef}
                        getRowId={(row) => row.tas_id}
                        rows={rows}
                        columns={columns}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        pagination
                        sx={{
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                display: "none"
                            }
                        }}
                    />
                </Box>
            </div>
        </div>
    );
}