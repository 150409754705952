import React, { useState, useEffect, useRef } from 'react';
import { APIURL, SCOPE } from '../../../config';
import axios from 'axios';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import './MonthlyMonitoring.css';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import QuarterlyAnnualTool from './QuarterlyAnnualTool/QuarterlyAnnualTool';
import MonthlyTool from './MonthlyTool/MonthlyTool';
import { Typography } from '@mui/material';
import qaSchema from './QuarterlyAnnualTool/schema';
import monthlySchema from './MonthlyTool/schema';
import { useReactToPrint } from 'react-to-print';
import CircularProgress from '@mui/material/CircularProgress';
import lodash from 'lodash';
import FileName from '../Modals/FileName';
import LinearProgress from '@mui/material/LinearProgress';
import ButtonGroup from '@mui/material/ButtonGroup';
import useAxiosWithMsal from 'src/hooks/useAxiosWithMsal';


const MonthlyMonitoring = (props) => {
    const { executeAxiosCall } = useAxiosWithMsal();

    const [qaData, setQAData] = useState(null);
    const [monthlyData, setMonthlyData] = useState(null);
    const [printData, setPrintData] = useState(null);

    const [mm, setMM] = useState(null);
    const [info , setInfo] = useState(null);
    const [qaDependentInfo, setQADependentInfo] = useState(null);
    const [monthlyDependentInfo, setMonthlyDependentInfo] = useState(null);
    const [initArtifact, setInitArtifact] = useState(null);
    const [prevQuarterlyData, setPrevQuarterlyData] = useState(null);
    const [reqMTType , setReqMTType] = useState(null);
    const [prevMonthComments, setPrevMonthComments] = useState(null);
    const [contactTypes, setContactTypes] = useState([]);

    const [type, setType] = useState();
    const [validate, setValidate] = useState(false);
    const [validSections, setValidSections] = useState([false]);

    const [isLoading, setIsLoading] = useState(true);
    const [isPrinting, setIsPrinting] = useState(false);

    const [expand, setExpand] = useState(false);
    const [autosave, setAutosave] = useState(false);
    const [saveQueue, setSaveQueue] = useState([]);
    const isSavingRef = useRef(false);
    const [autosaveSeconds, setAutosaveSeconds] = useState(100);
    const [secondsLeft, setSecondsLeft] = useState(100);
    const [autoSaving, setAutoSaving] = useState(false);
    const [autosaveError, setAutosaveError] = useState(false);

    const [showFileName, setShowFileName] = useState(false);
    const [fileName, setFileName] = useState('');

    const [saving, setSaving] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showConfirm, setShowConfirm] = useState(false);
    const handleConfirmClose = () => setShowConfirm(false);
    const handleConfirmShow = () => {!saving && !submitting ? setShowConfirm(true) : setShowConfirm(false)};

    const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
    const handleSessionExpiredModalClose = () => setShowSessionExpiredModal(false);
    const handleSessionExpiredModalShow = () => setShowSessionExpiredModal(true);

    const ref = useRef();
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        onAfterPrint: () => {
            setIsPrinting(false);
        }
    });

    const createReport = window.createReport;
    const mtNA = props.user.comp_settings.find(setting => setting.feature_id === 52 && setting.feature_value === 'true') ? true : false
    const identifyAnnualVisit = props.user.comp_settings.find(setting => setting.feature_id === 55 && setting.feature_value === 'true') ? true : false;
    const templateString = props.user.comp_settings.find(setting => setting.feature_id === 53) ? props.user.comp_settings.find(setting => setting.feature_id === 53).feature_value : null;

    const getData = async () => {
        let mm = await executeAxiosCall('GET', `/monthlymonitoring/${props.data.task.tas_id}`);
        const res = await executeAxiosCall('GET', "/task/monthlymonitoring", { emp_id: props.emp_id, task_id: props.data.task.tas_id });

        //activity log
        var sha1 = require('sha1');
        let sessionID=window.localStorage.getItem('Fw4_access_Token')
        var hash = sha1(sessionID);
        var log_data={
            emp_id: props.emp_id,
            actionType:'MT Form Action',
            actionSubType:'MT opened',
            actionDetail: mm.data? mm.data[0].monthly_monitoring_id : null,
            sessionID:hash
        }                                                                                                                                                                                                                                                               
        let msg = await axios.post(APIURL+"/activity_log", log_data );

        const prevMonthMT = await executeAxiosCall('GET', "/monthlymonitoring/last/month", { cus_id: props.data.cus_id, created_on: moment(props.data.mt_date).add(1 , 'day').format('YYYY-MM-DD'), months: 1 });
        const prevTwoMonthsMTType = await executeAxiosCall('GET', "/monthlymonitoring/last/month", { cus_id: props.data.cus_id, created_on: moment(props.data.mt_date).format('YYYY-MM-DD'), months: 2 });
        let followUpItems = []
        let reqMTType = null;

        followUpItems = await executeAxiosCall('GET', "/monthlymonitoring/follow-up-items/" + props.data.cus_id, { created_on: moment(props.data.mt_date).format('YYYY-MM-DD') });

        reqMTType = prevTwoMonthsMTType.data.length < 2 ? null : prevTwoMonthsMTType.data[0].mt_type === "Monthly" && prevTwoMonthsMTType.data[1].mt_type === "Monthly" ? "Quarterly" : null;
        setReqMTType(reqMTType);

        const prevQuarterlyAnnualMT = await executeAxiosCall('GET', "/monthlymonitoring/last/quaterly-annual", { cus_id: props.data.cus_id, created_on: moment(props.data.mt_date).format('YYYY-MM-DD') });        setPrevQuarterlyData(prevQuarterlyAnnualMT.data ? JSON.parse(prevQuarterlyAnnualMT.data.data) : null);

        let data = {...res.data.data, followUpItems: followUpItems?.data ? followUpItems.data : []};
        setInfo(data);

        let isMMData;
        if(mm.data) {
            isMMData = mm.data[0].data === null || mm.data[0].data === "" || mm.data[0].data === "{}" ? false : true;
            setInitArtifact(mm.data[0].task_id ? mm.data[0].task_id : null);
            setMM(mm.data[0] ? mm.data[0] : null);
        }

        const contactTypes = await executeAxiosCall('GET', "/dropdowns/dropdownType/contactType", { comp_id: props.user.comp_id });
        await setContactTypes(contactTypes.data);

        if(isMMData) {
            setType(mm.data[0].mt_type ? mm.data[0].mt_type : 'Monthly');
            setFormData(mm.data[0].mt_type ? mm.data[0].mt_type : 'Monthly', mm.data?mm.data[0]:null, data, prevQuarterlyAnnualMT.data ? JSON.parse(prevQuarterlyAnnualMT.data.data) : null, prevMonthMT.data[0] ? JSON.parse(prevMonthMT.data[0].data) : null, contactTypes.data);
        } else {
            setType(reqMTType ? reqMTType : 'Monthly');
            setFormData(reqMTType ? reqMTType : 'Monthly', null, data, prevQuarterlyAnnualMT.data ? JSON.parse(prevQuarterlyAnnualMT.data.data) : null, prevMonthMT.data[0] ? JSON.parse(prevMonthMT.data[0].data) : null, contactTypes.data);
        }
    }

    const saveFile = async () => {
        try {
            const fileHandle = await window.showSaveFilePicker({
                id: "monthly-monitoring",
                suggestedName: fileName + ".docx",
                types: [
                    {
                        description: 'Word Document',
                        accept: {
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                        }
                    }
                ]
            });
            let template = await executeAxiosCall(
                'GET', 
                "/monthlymonitoring/template/" + type.toLowerCase(), 
                null, 
                { 
                    headers: { 
                        "Content-Type": "application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                    }, 
                    responseType: 'arraybuffer' 
                }
            );
            template = template.data;
    
            const report = await createReport({
                template,
                data: type === "Monthly" ? monthlyData : qaData,
                cmdDelimiter: ['{', '}'],
                fixSmartQuotes: true,
                noSandbox: true
            });
            const blob = new Blob([report], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            const writable = await fileHandle.createWritable();
            await writable.write(blob);
            await writable.close();

            await executeAxiosCall(
                'PUT',
                "/monthlymonitoring/downloaded/" + mm.monthly_monitoring_id,
                null,
                {
                    headers: { 
                        "Content-Type": "application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                    }
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    const setFormData = (type, mm, info, prevQuarterlyData, prevMonthData, contactTypes) => {
        let monthlySchemaClone = lodash.cloneDeep(monthlySchema);
        let qaSchemaClone = lodash.cloneDeep(qaSchema);

        if(prevMonthData) {
            prevMonthData = Object.keys(prevMonthData).length === 0 ? null : prevMonthData;
        }

        if(mm) {
            if(mm.mt_type === type) {
                let obj = JSON.parse(mm.data);
                if(type==='Monthly') {
                    setMonthlyData(setPrefilledData(obj, prevQuarterlyData, prevMonthData, info, "Monthly", mm, contactTypes));
                    setQAData(setPrefilledData(qaSchemaClone, prevQuarterlyData, prevMonthData, info, "Quarterly", mm, contactTypes));
                } else {
                    setQAData(setPrefilledData(obj, prevQuarterlyData, prevQuarterlyData, info, type, mm, contactTypes));
                    setMonthlyData(setPrefilledData(monthlySchemaClone, prevQuarterlyData, prevMonthData, info, "Monthly", mm, contactTypes));
                }
            } else {
                setMonthlyData(setPrefilledData(monthlySchemaClone, prevQuarterlyData, prevMonthData, info, "Monthly", mm, contactTypes));
                setQAData(setPrefilledData(qaSchemaClone, prevQuarterlyData, prevMonthData, info, "Quarterly", mm, contactTypes));
            }
        } else {
            setMonthlyData(setPrefilledData(monthlySchemaClone, prevQuarterlyData, prevMonthData, info, "Monthly", mm, contactTypes));
            setQAData(setPrefilledData(qaSchemaClone, prevQuarterlyData, prevMonthData, info, "Quarterly", mm, contactTypes));
        } 
    }

    useEffect(() => {
        if(props.data) {
            getData();
            setIsLoading(false)
        }  
    }, [props.data]);

    useEffect(() => {
        if(isPrinting === true) {
            setTimeout(() => {
                handlePrint();   
            }, 100);
        }
    }, [isPrinting]);

    useEffect(() => {
        if(info?.cus_name && info?.checkoutEmpDate && type) {
            if(templateString) {
                const values = {
                    customer_name: info.cus_name.replace(' ', '_'),
                    month: moment(info.checkoutEmpDate).utc().format('MMM'),
                    year: moment(info.checkoutEmpDate).utc().format('YYYY'),
                    type: type
                };
                const replacedString = templateString.replace(/{(.*?)}/g, (match, key) => {
                    return values[key] || match; // Use the value from the 'values' object or keep the placeholder if not found
                });
                setFileName(replacedString);
            } else {
                setFileName(info.cus_name.replace(' ', '_') + '_' + moment(info.checkoutEmpDate).utc().format('MMM_YYYY') + `_${type}_Form`,);
            }
        }
    }, [info, type])

    const addComment = (question, prevMonthMT) => {
        if(!prevMonthMT) {
            return null;
        }

        let data = prevMonthMT;

        for(let section in data) {
            if(data[section].hasOwnProperty("follow_up")) {
                for(let item of data[section]["follow_up"].answer) {
                    if(item.question === question) {
                        return item.comment;
                    }
                }
            }
        }

        return null;

    }

    const setPrefilledData = (obj, prevQAMT, prevMonthMT, data, type, mm, contactTypes) => {

        let monthlySchemaClone = lodash.cloneDeep(monthlySchema);
        let qaSchemaClone = lodash.cloneDeep(qaSchema);

        obj.section_1.a.answer = data?.cus_name;
        obj.section_1.b.answer = data?.DDDID;
        obj.section_1.c.c1.answer = data?.NJCATSelfcare;
        obj.section_1.c.c2.answer = data?.NJCATBehavioral;
        obj.section_1.c.c3.answer = data?.NJCATMedical;
        obj.section_1.e.answer = data?.Program;
        obj.section_1.d.answer = data?.DDD_tier;
        obj.section_1.h.answer = moment(data?.checkoutEmpDate).diff(moment(data?.pro_starts_on), 'months') < 12 ? "yes" : "no";
        obj.section_1.i.answer = data?.com_name;
        obj.section_1.j.answer = data?.emp_first_name + " " + data?.emp_last_name;
        obj.section_1.k.answer = props.isSubscibedToTimeManagement ? moment(data?.checkoutEmpDate).format("MM/DD/YYYY") : moment(obj?.section_1?.k?.answer).isValid() ? moment(obj?.section_1?.k?.answer).format("MM/DD/YYYY") : "";
        obj.section_1.n.answer = type;
        
        if(type === 'Monthly') {
            obj.section_1.r.options = monthlySchemaClone.section_1.r.options;
        } else {
            obj.section_1.r.options = qaSchemaClone.section_1.r.options;
        }

        contactTypes?.forEach(type => {
            if(!obj.section_1.r.options.find(option => option.value === type.toLowerCase().replace(' ', '_'))) {
                obj.section_1.r.options.push({
                    value: type.toLowerCase().replace(' ', '_'),
                    label: type
                })
            }
        });
        
        if(prevMonthMT) {
            obj.section_1.f.answer = prevMonthMT.section_1.f.answer && obj.section_1.f.answer === "" ? prevMonthMT.section_1.f.answer : obj.section_1.f.answer;
        }
        
        let items = data.followUpItems? data.followUpItems.map((item, index) => {
            return {
                id: index,
                mt_period: item.mt_period,
                tas_id: item.tas_id,
                question: item.tas_notes,
                comment: addComment(item.tas_notes, prevMonthMT),
                a: {
                    question: "Follow Up Item:",
                    answer: item.tas_description
                },
                b: {
                    question: "Complete/Resolved?",
                    answer: item.checkin ? "yes" : "no"
                },
                c: {
                    question: "If no, briefly describe the status and planned action:",
                    answer: obj.section_2.find(i => i.question === item.tas_notes)?.c?.answer
                }
            }
        }):[];

        obj.section_2.forEach((item, index) => {
            if(!items.find(i => i.mt_period === item.mt_period && i.question === item.question)) {
                let newItem = { ...item, id: items.length + 1 };
                items.push(newItem);
            } else {
                let i = items.findIndex(i => i.mt_period === item.mt_period && i.question === item.question);
                items[i] = { ...item, id: items[i].id, tas_id: items[i].tas_id };
            }
        });

        obj.section_2 = items;

        let comments = items.filter(item => item.b.answer === "no").map(item => {
            return {
                question: item.question,
                comment: item.a.answer
            }
        });
        setPrevMonthComments(comments);

        if(type === 'Quarterly' || type === 'Annual') {
            obj.section_10.a.answer = data?.medicaid_eligible ? "yes": "no";
            obj.section_10.b.answer = data?.MedicaidEndDate ? "yes": "no";
            if(data?.medicaid_eligible) {
                obj.section_10.follow_up.answer = obj.section_10.follow_up.answer.filter(item => item.id !== 'a')
            }
            obj.section_10.follow_up.answer = obj.section_10.follow_up.answer.filter(item => item.id !== 'b')
            if(data?.MedicaidEndDate) {
                obj.section_10.follow_up.answer.push({
                    id: 'b',
                    follow_up_item: '',
                    comment: "Medicaid End Date: " + moment(data.MedicaidEndDate).utc().format("MM/DD/YYYY"),
                    question: 'b.	Is the Individual’s Medicaid scheduled to terminate? (Indicated by yellow litmus)' 
                })
            }
            obj.section_9.q3.answer = obj.section_9.q3.answer === "" ? data?.Program : obj.section_9.q3.answer;
            obj.section_12.f.answer = ["Aa", "Ba", "Ca", "Da", "Ea", "Fa"].includes(obj.section_1.d.answer)?"yes":"no";
            obj.section_15.a.answer = props.user.emp_name
            obj.section_15.b.answer = moment().format("MM/DD/YYYY")
            obj.section_15.c.answer = "Support Coordinator"
            obj.meta_data = {
                is_annual: {
                    question: "Is this an Annual Visit?",
                    answer: mm?.is_annual === true ? "yes" : "no"
                },
                loc16: {
                    question: "Does the Individual spend more than 16 hours per week in the current location?",
                    answer: mm?.loc16 === true? "yes" : "no"
                },
            }
            setQADependentInfo({
                section_1: {
                    p: data?.cus_phone,
                    f: prevQAMT?.section_1?.f?.answer,
                    u: prevQAMT?.section_1?.u?.answer,
                    v: {
                        v1: prevQAMT?.section_1?.v?.v1?.answer,
                        v2: prevQAMT?.section_1?.v?.v2?.answer
                    },
                    w: prevQAMT?.section_1?.w?.answer,
                    x: {
                        x1: prevQAMT?.section_1?.x?.x1?.answer,
                        x2: prevQAMT?.section_1?.x?.x2?.answer
                    },
                    y: prevQAMT?.section_1?.y?.answer,
                    z: {
                        z1: prevQAMT?.section_1?.z?.z1?.answer,
                        z2: prevQAMT?.section_1?.z?.z2?.answer
                    }
                },
                section_7: {
                    a: prevQAMT?.section_7?.a?.answer,
                },
                section_9: {
                    q3: prevQAMT?.section_9?.q3?.answer ? prevQAMT?.section_9?.q3?.answer : data?.Program,
                    a: prevQAMT?.section_9?.a?.answer,
                    b: prevQAMT?.section_9?.b?.answer
                }
            });
            if(prevQAMT) {
                obj.section_1.u.answer = prevQAMT.section_1.u.answer && obj.section_1.u.answer === "" ? prevQAMT.section_1.u.answer : obj.section_1.u.answer;
                obj.section_1.v.v1.answer = prevQAMT.section_1.v.v1.answer && obj.section_1.v.v1.answer === "" ? prevQAMT.section_1.v.v1.answer : obj.section_1.v.v1.answer;
                obj.section_1.v.v2.answer = prevQAMT.section_1.v.v2.answer && obj.section_1.v.v2.answer === "" ? prevQAMT.section_1.v.v2.answer : obj.section_1.v.v2.answer;
                obj.section_1.w.answer = prevQAMT.section_1.w.answer && obj.section_1.w.answer === "" ? prevQAMT.section_1.w.answer : obj.section_1.w.answer;
                obj.section_1.x.x1.answer = prevQAMT.section_1.x.x1.answer && obj.section_1.x.x1.answer === "" ? prevQAMT.section_1.x.x1.answer : obj.section_1.x.x1.answer;
                obj.section_1.x.x2.answer = prevQAMT.section_1.x.x2.answer && obj.section_1.x.x2.answer === "" ? prevQAMT.section_1.x.x2.answer : obj.section_1.x.x2.answer;
                obj.section_1.y.answer = prevQAMT.section_1.y.answer && obj.section_1.y.answer === "" ? prevQAMT.section_1.y.answer : obj.section_1.y.answer;
                obj.section_1.z.z1.answer = prevQAMT.section_1.z.z1.answer && obj.section_1.z.z1.answer === "" ? prevQAMT.section_1.z.z1.answer : obj.section_1.z.z1.answer;
                obj.section_1.z.z2.answer = prevQAMT.section_1.z.z2.answer && obj.section_1.z.z2.answer === "" ? prevQAMT.section_1.z.z2.answer : obj.section_1.z.z2.answer;
                obj.section_7.a.answer = prevQAMT.section_7.a.answer && obj.section_7.a.answer === "" ? prevQAMT.section_7.a.answer : obj.section_7.a.answer;
                obj.section_9.a.answer = prevQAMT.section_9.a.answer && obj.section_9.a.answer === "" ? prevQAMT.section_9.a.answer : obj.section_9.a.answer;
                obj.section_9.b.answer = prevQAMT.section_9.b.answer && obj.section_9.b.answer === "" ? prevQAMT.section_9.b.answer : obj.section_9.b.answer;
                obj.section_9.q3.answer = prevQAMT.section_9.q3.answer ? prevQAMT.section_9.q3.answer : data?.Program;
            }
        } else {
            obj.section_6.a.answer = data?.medicaid_eligible? "yes": "no";
            obj.section_6.b.answer = data?.MedicaidEndDate? "yes": "no";
            obj.section_10.a.answer = props.user.emp_name
            obj.section_10.b.answer = moment().format("MM/DD/YYYY")
            obj.section_10.c.answer = "Support Coordinator"
            if(data?.medicaid_eligible) {
                obj.section_6.follow_up.answer = obj.section_6.follow_up.answer.filter(item => item.id !== 'a')
            }
            obj.section_6.follow_up.answer = obj.section_6.follow_up.answer.filter(item => item.id !== 'b')
            if(data?.MedicaidEndDate) {
                obj.section_6.follow_up.answer.push({
                    id: 'b',
                    follow_up_item: '',
                    comment: "Medicaid End Date: " + moment(data.MedicaidEndDate).utc().format("MM/DD/YYYY"),
                    question: 'b.	Is the Individual’s Medicaid scheduled to terminate? (Indicated by yellow litmus)'
                })
            }
            setMonthlyDependentInfo({
                section_1: {
                    p: data?.cus_phone,
                    f: prevQAMT?.section_1?.f?.answer,
                }
            });
        }
        return obj;
    }

    const saveFollowUpItems = async (mm_id) => {
        let data =type === 'Monthly'? monthlyData : qaData;
        let items = [];
        for(let section in data) {
            if(data[section].hasOwnProperty("follow_up")) {
                for(let item of data[section]["follow_up"].answer) {
                    if(item.follow_up_item !== "" && item.follow_up_item !== null) {
                        let taskData = {
                            comp_id: props.user.comp_id,
                            tas_title: "Follow Up Item: "  + section + item.id + " " + moment().format("MMM YYYY"),
                            tas_description: item.follow_up_item,
                            tas_pro_id: info.pro_id,
                            tas_start_date: moment(),
                            tas_end_date: moment(),
                            tas_start_time: moment(),
                            tas_end_time: moment(),
                            tas_notes: item?.question || '',
                            tas_mm_id: item.not_required ? null : mm_id, 
                            emp_id: props.user.emp_id
                        };
                        items.push(taskData);
                    }
                }
            }
        }

        let result = await executeAxiosCall('POST', "/monthlymonitoring/follow-up-items/" + mm_id, { items: items });

        for(let item in data.section_2) {
            if(data.section_2[item].b.answer === "yes") {
                let response = await executeAxiosCall('PUT', "/task/checkin", {tas_id: data.section_2[item].tas_id, checkin: true});
            } else {
                let response = await executeAxiosCall('PUT', "/task/checkin", {tas_id: data.section_2[item].tas_id, checkin: false});
            }
        }
        return data;
    }

    const save = async (autosave = false) => {
        let tokenStatus = await props.checkTokenExpiry();
        if(!tokenStatus) {
            handleSessionExpiredModalShow();
            return;
        }
        let data = type === 'Monthly' ? monthlyData : qaData;
        let result1 = {data: {code: 500}};
        let result2 = {data: {code: 500}};
        let status = false;
        
        let body = {
            data: JSON.stringify(data),
            task_id: props.data.task.tas_id,
            emp_id: props.emp_id,
            submitted: mm?mm.submitted:false,
            approved: false,
            comment: mm?mm.comment:null,
            submitted_on: mm?mm.submitted_on:null,
            reviewed_on: mm?mm.reviewed_on:null,
            comment: mm?.comment ? mm.comment : '',
            mt_period: moment(props.data.mt_date).format("MMM YYYY"),
            mt_type: type,
            is_annual: data?.meta_data?.is_annual?.answer === "yes" ? true : false,
            loc16:  data?.meta_data?.loc16?.answer === "yes" ? true : false,
        }
        //activity log
        var sha1 = require('sha1');
        let sessionID=window.localStorage.getItem('Fw4_access_Token')
        var hash = sha1(sessionID);
        var log_data={
            emp_id: props.emp_id,
            actionType:'MT Form Action',
            actionSubType: autosave ? 'MT autosaved' : 'MT saved',
            actionDetail: mm?mm.monthly_monitoring_id:null,
            sessionID:hash
        }                                                                                                                                                                                                                                                               
        let msg = await executeAxiosCall('POST', "/activity_log", log_data);

        if(initArtifact) {
            body = {...body, id: initArtifact}
            result1 = await executeAxiosCall('PUT', "/monthlymonitoring", body);
        } else {
            result2 = await executeAxiosCall('POST', "/monthlymonitoring", body);
        }

        if(result1.data?.code === 200 || result2.data?.code === 200) {
            if(result2.data.code === 200) {
                setInitArtifact(props.data.task.tas_id);
                setMM({...body, monthly_monitoring_id: result2.data.mm_id})
                let id_res = await executeAxiosCall('PUT', "/task/mmid", {tas_id: props.data.task.tas_id, tas_mm_id: result2.data.mm_id});
                body.data = JSON.stringify(await saveFollowUpItems(result2.data.mm_id));
                status = true;
            } else {
                let id_res = await executeAxiosCall('PUT', "/task/mmid", {tas_id: props.data.task.tas_id, tas_mm_id: mm.monthly_monitoring_id});
                body.data = JSON.stringify(await saveFollowUpItems(mm.monthly_monitoring_id));
                status = true;
            }
            if(props.data.task.tas_mm_status === null){
                let task_res = await executeAxiosCall('PUT', "/task/status", {tas_id: props.data.task.tas_id, tas_mm_status: "Continue The Form"});
            }
            if(data?.section_1?.m?.answer === "home_visit") {
                let project_res = await executeAxiosCall('PATCH', "/project", {pro_id: info?.pro_id, LastAnnualVisitDate: info?.checkoutEmpDate});
            }
        }
        if(result1.data?.code === 400 || result2.data?.code === 400) {
            status = true;
        }
        
        return status;
    }

    const onSave = async (close = false, autosave = false) => {
        try {
            autosave ? setAutoSaving(true) : setSaving(true);
            let status = await save(autosave);
    
            if(status) {
                if(!autosave) {
                    props.setAlert("success", "SUCCESSFULLY SAVED FORM");
                } else {
                    setAutosaveError(false);
                }
                if(close) {
                    onClose();
                    props.rerender();
                } else {
                    autosave ? setAutoSaving(false) : setSaving(false);
                }
            } else {
                if(autosave) {
                    setAutoSaving(false);
                    setAutosaveError(true);
                    return;
                }
                setSaving(false);
                props.setAlert("error", <span>SOMETHING WENT WRONG ON OUR END, PLEASE TRY SAVING THE FORM AGAIN <small>{moment().format("MMM DD, YYYY HH:mm:ss")}</small></span>);
            }
        } catch (error) {
            if(autosave) {
                setAutoSaving(false);
                setAutosaveError(true);
                return;
            }
            setSaving(false);
            if(error.response) {
                if(error.response.status === 401) {
                    handleSessionExpiredModalShow();
                } else {
                    props.setAlert("error", <span>SOMETHING WENT WRONG ON OUR END, PLEASE TRY SAVING THE FORM AGAIN <small>{moment().format("MMM DD, YYYY HH:mm:ss")}</small></span>);
                }
            } else if(error.request) {
                if(!navigator.onLine) {
                    props.setAlert("error", <span>IT SEEMS YOU ARE OFFLINE, PLEASE CHECK YOUR INTERNET CONNECTION AND TRY AGAIN <small>{moment().format("MMM DD, YYYY HH:mm:ss")}</small></span>);
                    
                } else {
                    props.setAlert("error", <span>UNABLE TO CONNECT, PLEASE TRY SAVING THE FORM AGAIN <small>{moment().format("MMM DD, YYYY HH:mm:ss")}</small></span>);
                }
            }
        }
    }

    const submit = async () => {
        let tokenStatus = await props.checkTokenExpiry();
        if(!tokenStatus) {
            handleSessionExpiredModalShow();
            return;
        }
        let data = type === 'Monthly'? monthlyData : qaData;
        let result1 = {data: {code: 500}};
        let result2 = {data: {code: 500}};
        let status = false;

        let body = {
            data: JSON.stringify(data),
            task_id: props.data.task.tas_id,
            emp_id: props.emp_id,
            submitted: true,
            cus_name: data?.section_1?.a?.answer,
            approved: false,
            submitted_on: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            reviewed_on: mm?mm.reviewed_on:null,
            comment: mm?.comment ? mm.comment : '',
            mt_period: moment().format("MMM YYYY"),
            mt_type:type,
            is_annual: data?.meta_data?.is_annual?.answer === "yes" ? true : false,
            loc16:  data?.meta_data?.loc16?.answer === "yes" ? true : false,
        }

        if(initArtifact) {
            body = {...body, id: initArtifact}
            result1 = await executeAxiosCall('PUT', "/monthlymonitoring", body);
        } else {
            result2 = await executeAxiosCall('POST', "/monthlymonitoring", body);
        }

        if(result1.data?.code === 200 || result2.data?.code === 200) {
            setMM({...mm, submitted: true, submitted_on: moment().utc().format('YYYY-MM-DD HH:mm:ss')});
            let task_res = await executeAxiosCall('PUT', "/task/status", {
                empId: props.user.emp_id, 
                empName: props.user.emp_name, 
                companyId: props.user.comp_id, 
                tas_id: props.data.task.tas_id, 
                tas_mm_status: "Waiting For Approval", 
                tas_title: props.data.task.tas_title, 
                pro_customer_id: props.data.task.pro_customer_id,
                cusName: props.data.cus_name,
            });

            if(result2.data.code === 200) {
                setInitArtifact(props.data.task.tas_id);
                setMM({...body, monthly_monitoring_id: result2.data.mm_id})
                let id_res = await executeAxiosCall('PUT', "/task/mmid", {tas_id: props.data.task.tas_id, tas_mm_id: result2.data.mm_id});
                await saveFollowUpItems(result2.data.mm_id);
                status = true;
            } else {
                let id_res = await executeAxiosCall('PUT', "/task/mmid", {tas_id: props.data.task.tas_id, tas_mm_id: mm.monthly_monitoring_id});
                await saveFollowUpItems(mm.monthly_monitoring_id);
                status = true;
            }

            if(data?.section_1?.m?.answer === "home_visit") {
                let project_res = await executeAxiosCall('PATCH', "/project", {pro_id: info?.pro_id, LastAnnualVisitDate: info?.checkoutEmpDate });
            }
        }
        if(result1.data?.code === 400 || result2.data?.code === 400) {
            status = true;
        }

        //activity log
        var sha1 = require('sha1');
        let sessionID=window.localStorage.getItem('Fw4_access_Token')
        var hash = sha1(sessionID);
        var log_data={
            emp_id: props.emp_id,
            actionType:'MT Form Action',
            actionSubType:'MT submitted',
            actionDetail: mm?mm.monthly_monitoring_id: result2.data?.mm_id,
            sessionID:hash
        }                                                                                                                                                                                                                                                               
        let msg = await executeAxiosCall('POST', "/activity_log", log_data);

        return status;
    }

    const onSubmit = async () => {
        try {
            setSubmitting(true);
            let status = await submit();

            if(status) {
                props.setAlert("success", "SUCCESSFULLY SUBMITTED FORM");
                onClose();
                props.rerender();
            } else {
                setSubmitting(false);
                props.setAlert("error", <span>SOMETHING WENT WRONG ON OUR END, PLEASE TRY SUBMITTING THE FORM AGAIN <small>{moment().format("MMM DD, YYYY HH:mm:ss")}</small></span>);
            }
        } catch (error) {
            setSubmitting(false);
            if(error.response) {
                if(error.response.status === 401) {
                    handleSessionExpiredModalShow();
                } else {
                    props.setAlert("error", <span>SOMETHING WENT WRONG ON OUR END, PLEASE TRY SUBMITTING THE FORM AGAIN <small>{moment().format("MMM DD, YYYY HH:mm:ss")}</small></span>);
                }
            } else if(error.request) {
                if(!navigator.onLine) {
                    props.setAlert("error", <span>IT SEEMS YOU ARE OFFLINE, PLEASE CHECK YOUR INTERNET CONNECTION AND TRY AGAIN <small>{moment().format("MMM DD, YYYY HH:mm:ss")}</small></span>);
                    
                } else {
                    props.setAlert("error", <span>UNABLE TO CONNECT, PLEASE TRY SUBMITTING THE FORM AGAIN <small>{moment().format("MMM DD, YYYY HH:mm:ss")}</small></span>);
                }
            }
        }
    }

    useEffect(() => {
        let show = props.show;
        let autosave = null;
        let autoSaveCountdown = null;

        if(show) {
            autosave = setInterval(async () => {
                setAutosave(true);  
            }, autosaveSeconds * 1000);
    
            autoSaveCountdown = setInterval(() => {
                setSecondsLeft((prevSeconds) => {
                    if(prevSeconds <= 1) {
                        return autosaveSeconds;
                    } else {
                        return prevSeconds - 1;
                    }
                });
            }, 1000);
        } else {
            setSecondsLeft(autosaveSeconds);
        }
        return () => {
            setAutosave(false);
            clearInterval(autoSaveCountdown);
            clearInterval(autosave);
        }
    }, [props.show]);

    useEffect(async () => {
        if(autosave) {
            enqueueSave(() => onSave(false, true));
            setAutosave(false);
        }
    }, [autosave, monthlyData, qaData, mm, type])

    useEffect(() => {
        if (saveQueue.length > 0 && !isSavingRef.current) {
            processSaveQueue();
        }
    }, [saveQueue]);

    const enqueueSave = (saveOperation) => {
        setSaveQueue((currentQueue) => [...currentQueue, saveOperation]);
    };

    const processSaveQueue = async () => {
        if (isSavingRef.current || saveQueue.length === 0 || !props.show) {
            return;
        }
        isSavingRef.current = true;
        const [nextSave] = saveQueue;

        try {
            await nextSave();
        } catch (error) {
            console.error('Error saving:', error);
        } finally {
            setSaveQueue((currentQueue) => currentQueue.slice(1));
            isSavingRef.current = false;
        }
    };


    const validateAndSubmit = () => {
        if(validateForm()) {
            enqueueSave(onSubmit);
        } else {
            setValidate(true);
        }
        handleClose()
    }

    const validateForm = () => {
        let isFormValid = true;
        validSections.forEach( section => {
            if(section === false) {
                isFormValid = false;
            }
        })
        return isFormValid;
    }

    const onClose = () => {
        setValidate(false);
        setInitArtifact(null);
        setMonthlyData(null);
        setQAData(null);
        setMM(null);
        setReqMTType(null);
        setType(null);
        setSaving(false);
        setSubmitting(false);
        setSaveQueue([]);
        isSavingRef.current = false;
        props.handleClose();
    }

    return (
        <div>
            <Modal show={showSessionExpiredModal} onHide={handleSessionExpiredModalClose} centered>
                <Modal.Body>
                    <Typography sx={{ my: "15px", fontWeight: "bold" }}>Your session has expired and form has been saved. Please reload the page</Typography>
                </Modal.Body>
                <Modal.Footer style={{borderTop: "0 none", paddingTop: '0', justifyContent: "space-around"}}>
                    <Button variant="contained" onClick={handleSessionExpiredModalClose}>
                        Close
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => window.location.reload()}>
                        Reload Page
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={props.show} onHide={handleConfirmShow} dialogClassName="mm-modal-width" className='mm-modal' centered>
                <Modal.Header className="mm-header" style={{ width: "100%" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
                        <Box sx={{ flex: 1 }}></Box>
                        <Typography variant="h5" sx={{ fontWeight: "bold", alignSelf: "center", flex: 1, margin: 0 }}>{!type ? '' : type === "Monthly" ? "Monthly" : "Quarterly"} Monitoring Tool</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            value={type}
                            exclusive
                            onChange={(e) => {setType(e.target.value);setValidate(false);}}
                            sx={{ flex: 1, justifyContent: "flex-end"}}
                            disabled={isLoading || reqMTType !== null || mm?mm?.approved:false}
                        >
                            <ToggleButton value="Monthly">Monthly</ToggleButton>
                            <ToggleButton value="Quarterly">Quarterly</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
            </Modal.Header>
            {!type && 
                <Box sx={{ display: 'flex', height: "60vh", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </Box>
            }
            <Box sx={{ display: type === "Monthly" ? "block" : "none" }}>
                <MonthlyTool
                    data={monthlyData?monthlyData : {}}
                    onChange={(data) => {setMonthlyData(data)}}
                    expand={expand}
                    validate={validate}
                    setValidSections={type === "Monthly" ? setValidSections : (e) => {}}
                    comments={prevMonthComments ? prevMonthComments : []}
                    isSubscibedToTimeManagement={props.isSubscibedToTimeManagement}
                    dependentInfo={monthlyDependentInfo}
                    resubmit={props.data?.task?.tas_mm_status === "Rejected"}
                    note={mm?.comment}
                    setNote={(e) => {setMM({...mm, comment: e.target.value})}}
                    mtNA={mtNA}
                />
                {type === "Monthly" && isPrinting &&
                    <Box sx={{ overflow: 'hidden', height: 0 }}>
                        <MonthlyTool // fully expanded for printing and downloading
                            data={printData}
                            onChange={(data) => {return}}
                            ref={ref}
                            expand={true}
                            mtNA={mtNA}
                        /> 
                    </Box>
                }
            </Box>                 
            <Box sx={{ display: type === "Quarterly" ? "block" : "none" }}>
                <QuarterlyAnnualTool
                    data={qaData?qaData : {}}
                    onChange={(data) => {setQAData(data)}}
                    expand={expand}
                    validate={validate}
                    setValidSections={type === "Quarterly" ? setValidSections : (e) => {}}
                    comments={prevMonthComments ? prevMonthComments : []}
                    isSubscibedToTimeManagement={props.isSubscibedToTimeManagement}
                    dependentInfo={qaDependentInfo}
                    resubmit={props.data?.task?.tas_mm_status === "Rejected"}
                    note={mm?.comment}
                    setNote={(e) => {setMM({...mm, comment: e.target.value})}}
                    mtNA={mtNA}
                    identifyAnnualVisit={identifyAnnualVisit}
                />
                {type === "Quarterly" && isPrinting && 
                    <Box sx={{ overflow: 'hidden', height: 0 }} >
                        <QuarterlyAnnualTool // fully expanded for printing and downloading
                            data={printData}
                            onChange={(data) => {return}}
                            ref={ref}
                            expand={true}
                            mtNA={mtNA}
                            identifyAnnualVisit={identifyAnnualVisit}
                        /> 
                    </Box>
                }
            </Box>
                <Modal.Footer>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                        <Box sx={{ width: "161px" }}>
                            <Typography variant="body2" color={autosaveError ? "error" : "textSecondary"}>
                                {autosaveError ? "Autosave failed" : autoSaving ? 
                                    "AutoSaving..." : "Autosave in " + secondsLeft + " " + (secondsLeft === 1 ? "second" : "seconds")

                                }
                            </Typography>
                            <LinearProgress variant="determinate" value={(autosaveSeconds - secondsLeft) / autosaveSeconds * 100} color={autosaveError ? "error" : "primary"} />
                        </Box>
                        <ButtonGroup variant="contained">
                            <Button onClick={() => saveFile()}>
                                Download
                            </Button>
                            <Button  onClick={() => {setPrintData(type === "Monthly" ? monthlyData : qaData);setIsPrinting(true)}}>
                                Print
                            </Button>
                            <Button  data-backdrop="false" onClick={handleShow}>
                                Submit
                            </Button>
                            <LoadingButton variant="contained" loading={saving} onClick={() => enqueueSave(() => onSave(false))} disabled={saving}>
                                {"Save"}
                            </LoadingButton>
                            <LoadingButton variant="contained" loading={saving} loadingIndicator={"Saving..."} onClick={() => enqueueSave(() => onSave(true))} disabled={saving}> 
                                {"Save and Close"}
                            </LoadingButton>
                        </ButtonGroup>
                    </Box>
                </Modal.Footer>
            </Modal>
            <Modal className="submit-modal" show={show} onHide={handleClose} centered>
                <Modal.Body style={{ margin: "20px 0" }}>
                    <Typography>Are you sure you want to submit the MT form?</Typography>
                    <Typography>Once submitted, you cannot re-assign MT to another task this month.</Typography>
                </Modal.Body>
                <Modal.Footer style={{borderTop: "0 none", paddingTop: '0', justifyContent: "space-around"}}>
                   <LoadingButton loading={submitting} style={{width: 75}} variant="contained" onClick={validateAndSubmit}>{"Yes"}</LoadingButton>
                   <Button style={{width: 75}} variant="contained" color="secondary" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <Modal className="confirm-modal" show={showConfirm} onHide={handleConfirmClose} centered>
                <Modal.Body style={{ margin: "20px 0" }}>
                    <Typography>Are you sure you want to leave without saving?</Typography>
                </Modal.Body>
                <Modal.Footer style={{borderTop: "0 none", paddingTop: '0', justifyContent: "space-around"}}>
                   <Button variant="contained" style={{width: 75}} onClick={() => {handleConfirmClose();onClose()}}>Yes</Button>
                   <Button variant="contained" color="secondary"  style={{width: 75}} onClick={handleConfirmClose}>No</Button>
                </Modal.Footer>
            </Modal>         
        </div>
    )
}

export default MonthlyMonitoring;
