import { useState, useEffect, memo } from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import lodash from "lodash";
import moment from "moment";

const ControlledDatePicker = (props) => {
    const [value, setValue] = useState(null);
    const [error, setError] = useState(false);
    const [read, setRead] = useState(true);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    useEffect(() => {
        setRead(props.read);
    }, [props.read]);
    
    useEffect(() => {
        if(read !== true) {
            if(props.disabled === true) {
                setValue("");
                if(props.onChange !== undefined) props.onChange(null);
                if(!isValid(value)) setError(false);
            } else {
                if(!isValid(props.value)) {
                    if(props.dependentValue)
                        props.onChange(props.dependentValue);
                }
                setError(true);
            }
        }
    }, [props.disabled]);

    useEffect(() => {
        if(props.setSectionError !== undefined && props.section !== undefined) 
            error ? props.setSectionError((prev) => {
                let newPrev = [...prev];
                newPrev[props.section].push("date");
                return newPrev;
            }) : 
            props.setSectionError((prev) => {
                let newPrev = [...prev];
                const index = newPrev[props.section].indexOf('date');
                if (index > -1) {
                    newPrev[props.section].splice(index, 1);
                }
                return newPrev;
            });
    }, [error]);

    useEffect(() => {
        if(!props.disabled) 
            setError(!isValid(value));
    }, [value, props.validate]);

    const isValid = (value) => {
        return !(value === null || value === "" || value === undefined) && (new Date(value) instanceof Date && !isNaN(new Date(value).valueOf()));
    }

    return (
        <>
        {props.value !== undefined ? 
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    readOnly={props.readOnly}
                    disabled={props.disabled}
                    value={moment(value).isValid() ? new Date(value) : null}
                    onChange={e => {setValue(e);props.onChange(e)}}
                    slotProps={{
                        textField: {
                            sx: {
                                ...props.sx
                            },
                            variant: 'standard',
                            error: error && props.validate
                        }
                    }}
                    maxDate={props.maxDate?new Date(props.maxDate):null}
                />
            </LocalizationProvider> :
            <></>
        }
        </>

    );
}

export default memo(ControlledDatePicker,
    (prevProps, nextProps) => {
        if(lodash.isEqual(prevProps.sx, nextProps.sx) && lodash.isEqual(prevProps.value, nextProps.value) && lodash.isEqual(prevProps.disabled, nextProps.disabled) 
        && prevProps.onChange == nextProps.onChange && lodash.isEqual(prevProps.readOnly, nextProps.readOnly) && lodash.isEqual(prevProps.error, nextProps.error)
        && lodash.isEqual(prevProps.validate, nextProps.validate) && lodash.isEqual(prevProps.setSectionError, nextProps.setSectionError) && lodash.isEqual(prevProps.section, nextProps.section)) {
            return true;
        } else {
            return false;
        }
    }
);
