import React from 'react';
import './TaskInfo.css';
import moment from 'moment';

const ArtInfo = (props) => {
    return (
        (<div className="taskItem">
            <div className="taskHeading">
               <span className="taskName">{props.data.file_name}</span>
               <span>{moment(props.data.submitted.replace(/Z/g, "")).format('D MMM YYYY, HH:MM')}</span>
            </div>
            <div className="d-flex flex-column">
                {    props.data.taskName!== "" &&
                    <div><i style={{ fontSize: "1rem", paddingRight: "16px", paddingLeft: "0px" }} className="fas fa-puzzle-piece"></i><span className="taskTime">{props.data.taskName}</span></div>
                }
                <div><i style={{ fontSize: "1rem", paddingRight: "20px", paddingLeft: "0px" }} className="fas fa-user-tie"></i><span className="taskTime">{props.data.empName}</span></div>
            </div>
        </div>)
    );
};

export default ArtInfo;
