import React, { useState, useEffect } from "react"
import axios from 'axios'
import {APIURL} from '../../../config';
import Record from './Record/Record.js';
import moment from 'moment'
import { TextField, Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './Logs.css'
import { alpha } from '@mui/material/styles'
//import { Button } from 'react-bootstrap'
import { addDays } from "date-fns";
import MessagePage from '../../MessagePages/MessagePage'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })


export default function Logs(props) {

    const [evvReports, setEvvReports] = useState([]);
    const [evvTasks, setEvvTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState();
    const [currentDay, setCurrentDay] = useState(moment().startOf('day'));
    const [currentDayEnd, setCurrentDayEnd] = useState(moment().endOf('day'));
    const [loggedToSegment, setLoggedToSegment] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
    const [currentMonthEnd, setCurrentMonthEnd] = useState(moment().startOf('month').add(1,'month'));

    const logToSegment = () => {
        if(loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        console.log(page,window.location.href)
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page)
        analytics.page(page.toUpperCase() + " : Activity",{
            title:page.toUpperCase() + " : Activity",
            path:page.toUpperCase() + " : Activity",
        }, {
          userId:String(props.user.emp_id),
        })
        console.log("logged to segment successfully")
        setLoggedToSegment(true);
    }

    useEffect(() => {
        async function fetchData(){
            let evvReport = await axios.get(APIURL+'/evv',{params: {comp_id: props.comp_id, start: currentMonth.format("YYYY-MM-DDTHH:mm:ss"), end: currentMonthEnd.format("YYYY-MM-DDTHH:mm:ss")}})
            if(!!evvReport){
                if(!!evvReport.data){
                    let newEVVReports = evvReport.data.processes.slice();
                    newEVVReports.sort((a,b)=> parseInt(moment(b.invocationTimestamp).format('YYYYMMDDHHmm')) - parseInt(moment(a.invocationTimestamp).format('YYYYMMDDHHmm')))
                    setEvvReports(newEVVReports);
                    setEvvTasks(evvReport.data.tasks.slice());
                    setIsLoading(false);
                }
            }
        }
        logToSegment();

        fetchData();
    }, []);

    async function updateSelected(id){
        if(selected === id){
            this.setState({selected: null})
        }else{
            // set state trigger the render
            this.setState({selected: id})
        }
    }

    const getRecordComponents = () => {
        let list = evvReports.map((evv)=> {
            if(moment(evv.invocationTimestamp).isBetween(currentDay, currentDayEnd)){
                let tasks = [];
                if(!!evv.evvLogList){
                    for(let i=0; i<evv.evvLogList.length; i++){
                        if(!!evv.evvLogList[i].externalVisitId){
                            tasks.push(evvTasks.find(t => t.tas_id === evv.evvLogList[i].externalVisitId))
                        }
                    }
                }
                
                return (
                    <Record updateSelected={updateSelected} isSelected={selected === evv.evvProcessLogId} key={evv.evvProcessLogId} list={evv} tasks={tasks}/>
                )
            }
        })
        
        list = list.filter(report => report !== undefined)
        if(list.length === 0){
            return <MessagePage title="No Logs found" text={"We could not find any EVV Logs for " + currentDay.format("MMMM DD, YYYY") + "."} image="./nosearchresult.png"></MessagePage>
        }

        return list;
    }

    const fetchMonthlyData = async (newValue) => {
        setIsLoading(true);
        let evvReport = await axios.get(APIURL+'/evv',{params: {comp_id: props.comp_id, start: moment(newValue).startOf('month').format("YYYY-MM-DDTHH:mm:ss"), end: moment(newValue).startOf('month').add(1,'month').format("YYYY-MM-DDTHH:mm:ss")}})
        if(!!evvReport){
            if(!!evvReport.data){
                let newEVVReports = evvReport.data.processes.slice();
                console.log(evvReport.data);
                newEVVReports.sort((a,b)=> parseInt(moment(b.invocationTimestamp).format('YYYYMMDDHHmm')) - parseInt(moment(a.invocationTimestamp).format('YYYYMMDDHHmm')))
                setEvvReports(newEVVReports);
                setEvvTasks(evvReport.data.tasks.slice());
                setIsLoading(false);
            }
        }
    }

    const onDateChange = async (newValue) => {
        console.log("start DATE CHANGE")
        if (moment(newValue).startOf('day').isBefore(currentMonth) || moment(newValue).startOf('day').isAfter(currentMonthEnd)) {
            await fetchMonthlyData(newValue);
            setCurrentMonth(moment(newValue).startOf('month'));
            setCurrentMonthEnd(moment(newValue).endOf('month'));
        }
        setCurrentDay(moment(newValue).startOf('day'));
        setCurrentDayEnd(moment(newValue).endOf('day'));
        console.log("end DATE CHANGE")
    }

    return(
        <div id='evvLogs-main' style={{height: '60rem'}}>
            
            {isLoading && <div className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
                </div></div>}
            
            {!isLoading && 
                <div style={{display: 'flex', width: '700px'}}>
                    <h4 style={{marginTop: '1rem'}}> EVV Activity</h4>
                    <div style={{height: '40px', marginTop: '5px', marginBottom: '20px', marginLeft: '20px'}}>
                        <Button size="large" className='DayButton' onClick={() => onDateChange(currentDay.subtract(24, "h"))}><i className="fas fa-arrow-left"></i></Button>
                        <LocalizationProvider class='DatePickerParent' dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Select Date"
                                value={new Date(currentDay)}
                                sx={{marginTop: '10px'}}
                                slotProps={{ textField: { size: 'small' } }}
                                onChange={(newValue) => {
                                    onDateChange(newValue)
                                }}
                            />
                        </LocalizationProvider>
                        <Button size="large" className='DayButton' onClick={() => onDateChange(currentDay.add(24, "h"))}><i className="fas fa-arrow-right"></i></Button>
                    </div>
                </div>
            }

            {!isLoading &&
                <div id='evvLogs-body' className='overflow-auto' style={{height: '85vh'}}>
                    {evvReports.length===0 && <MessagePage title="No Logs Found" text="We could not find any EVV Logs were found for this company." image="./nosearchresult.png"></MessagePage>}
                    {evvReports.length!==0 && getRecordComponents()}
                </div>
            }
        </div>
    );

}