import * as React from "react";
import { Box } from '@mui/material';
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';

export default function CompanyRenwalDataTabe(props) {
  const [gridId, setGridId] = useState(38);
  const apiRef = useGridApiRef();
  const classes = useStyles();
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    // {
    //   field: "pro_title",
    //   headerName: "Plan",
    // },
    { field: "ddd_id", headerName: "DDD ID", width: 90 },
    { field: "program", headerName: "Program" },
    { field: "customer_name", headerName: "Customer Name" },
    { field: "coordinator_name", headerName: "Coordinator Name" },
    { field: "dob", headerName: "DOB" },
    {
      field: "plan_approval_date",
      headerName: "Plan Approval Date",
    },
    { field: "plan_start_date", headerName: "Plan Start Date" },
    { field: "plan_end_date", headerName: "Plan End Date" },
  ]

  const handlePaginationModelChange = (paginationModel) => {
    console.log("paginationModel", paginationModel);
    if (paginationModel.pageSize !== pageSize) {
      setPageSize(paginationModel.pageSize);
      setPageNo(0);
    } else {
      setPageNo(paginationModel.page);
    }
  };
  
  let rows = props?.data.map((inv, index) => {
    let invDets = {
      rowId: inv?.pro_id + Math.random().toString(36).substring(2, 12) + index,
      hierarchy: [inv.reports_span, inv.pro_title + " - " + inv.pro_id],
      id: inv?.pro_id,
      // pro_title: inv?.pro_title,
      ddd_id: inv?.DDD_ID,
      program: inv?.Program,
      customer_name: inv?.customer_details[0]?.cus_name || "",
      coordinator_name: inv?.coordinator_name || "",
      dob: inv?.customer_details[0]?.dob
        ? moment(inv?.customer_details[0]?.dob).format("MM/DD/YYYY")
        : "",
      plan_approval_date: inv?.plan_approval_date
        ? moment(inv?.plan_approval_date).format("MM/DD/YYYY")
        : "",
      plan_start_date: inv?.pro_starts_on
        ? moment(inv?.pro_starts_on).format("MM/DD/YYYY")
        : "",
      plan_end_date: inv?.pro_ends_on
        ? moment(inv?.pro_ends_on).format("MM/DD/YYYY")
        : "",
    };
    return invDets;
  });

  return (
    <Box sx={{ height: '77vh', width: "100%", marginTop: 1 }}>
      {rows.length === 0 ?
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "500px", marginTop: 10 }}
        >
          <img
            src="./nosearchresult.png"
            alt="nosearchresult"
            style={{ width: "25rem", height: "15rem", margin: 10 }}
          />
          <h5
            style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
            className="text-center"
          >
            There is currently no data available{" "}
          </h5>
        </div>
        :
        <Box sx={{ width: '100%', height: "calc(100vh - 220px)" }}>
          <ControlledDataGrid
            gridId={gridId}
            user={props.user}
            initialState={{ sorting: { sortModel: [{ field: "customer_name", sort: "asc" }] } }}
            apiRef={apiRef}
            className={classes.GridTableBox}
            rows={rows}
            columns={columns}
            pagination
            rowsPerPageOptions={[20, 50, 100]}
            paginationModel={{ page: pageNo, pageSize: pageSize }}
            onPaginationModelChange={(paginationModel) => handlePaginationModelChange(paginationModel)}
            disableRowSelectionOnClick
            treeData
            getTreeDataPath={(row) => row.hierarchy}
            getRowId={(row) => row.rowId}
          />
        </Box>
      }
    </Box>
  );
}
const useStyles = makeStyles((theme) => ({
  GridTableBox: {
    "& .MuiDataGrid-columnHeader:first-child": {
      width: "315px !important",
      maxWidth: "315px !important",
    },
    "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:first-child":
    {
      width: "315px !important",
      maxWidth: "315px !important",
    },
  },
}));
