import React, { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { Typography, Grid, TextField, Box, Select, FormControl, MenuItem, InputLabel, Snackbar, Alert } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledFormDataGrid from "../../../components/DataGrid/ControlledFormDataGrid";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import axios from 'axios'
import { APIURL } from '../../../config';
import dayjs from 'dayjs';

export default function FormReport(props) {
    const apiRef = useGridApiRef();

    const [Form_Reports, setForm_Reports] = useState([]);
    const [currReport, setCurrReport] = useState(null);
    const [reportID, setReportID] = useState('');
    const [reportFormID, setReportFormID] = useState('');
    const [reportName, setReportName] = useState('');
    const [custList, setCustList] = useState([]);
    const [custID, setCustID] = useState('');
    const [custName, setCustName] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [endDate, setEndDate] = useState(moment().endOf('month'));
    const [formData, setFormData] = useState([]);
    const [formColumns, setFormColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(async () => {
        const Form_Reports = await axios.get(APIURL + '/forms/form_report', { params: { report_company_id: props.comp_id } });
        const associatedCustomers = await axios.get(APIURL + '/customers/v2/associated', { params: { company_id: props.comp_id, employee_id: props.user.emp_id } })

        setForm_Reports(Form_Reports.data);
        setCustList(associatedCustomers.data);
        setLoading(false);
    }, []);

    const handleReportChange = (event) => {
        setFormData([]);
        setReportName(event.target.value);
    };

    const handleReportMenuItemClick = (event) => {
        const currReport = Form_Reports.filter((report) => report.report_id === parseInt(event.target.id))[0];

        setReportID(event.target.id);
        setReportFormID(currReport?.report_form_id);
        setCurrReport(currReport);
    }

    const handleCustChange = (event) => {
        setFormData([]);
        setCustName(event.target.value);
    };

    const handleCustMenuItemClick = (event) => {
        setCustID(event.target.id);
    }

    const onSearch = async () => {
        if (!reportID) {
            setSnackbar({
                children: "Please select a Form from the dropdown!",
                severity: "error",
            });
            return;
        }

        try {
            const formsWithData = await axios.get(APIURL + '/customer_form/formsWithData/' + 0, { params: { compID: props.comp_id, formID: reportFormID, custID: custID, startDate: moment(startDate).format('MM/DD/YYYY'), endDate: moment(endDate).format('MM/DD/YYYY') } });

            if (formsWithData.data.length === 0) {
                setSnackbar({
                    children: "No data available!",
                    severity: "error",
                });
                return;
            }

            let formComponents;
            let rows = formsWithData.data.map((data, idx) => {
                formComponents = data.json_schema.components;

                let formRows = {
                    id: idx,
                    cus_name: data.cus_name,
                    emp_name: data.emp_first_name ? data.emp_first_name + ' ' + data.emp_last_name : '-',
                    tas_title: data.tas_title,
                    tas_start_time: moment(data.empLocdate).utc().format('MM/DD/YYYY hh:mm A'),
                    tas_end_time: moment(data.checkoutEmpDate).utc().format('MM/DD/YYYY hh:mm A'),
                    tas_loc: data.locInfo[0].loc_addr_line1 + ', ' + data.locInfo[0].loc_addr_line2 + ', ' + data.locInfo[0].loc_city + ', ' + data.locInfo[0].loc_state + ' ' + data.locInfo[0].loc_zip,
                    ...data.formData.data
                }
                return formRows;
            });

            // Case: If Select Boxes component is used from Form Builder
            rows = rows.map(row => {
                for (let key in row) {
                    if (typeof row[key] === 'object' && row[key] !== null) {
                        row[key] = Object.keys(row[key])
                            .filter(k => row[key][k])
                            .join(', ');
                    }
                }
                return row;
            });

            let columns = [
                { field: "id", headerName: "ID", hide: true },
                { field: "cus_name", headerName: "Customer Name", width: 150 },
                { field: "emp_name", headerName: "Employee Name", width: 150 },
                { field: "tas_title", headerName: "Task Name", width: 200 },
                { field: "tas_start_time", headerName: "Task Start Date", width: 150, },
                { field: "tas_end_time", headerName: "Task End Date", width: 150, },
                { field: "tas_loc", headerName: "Task Location", width: 200, wrap: true },
            ];

            if (formComponents) {
                formComponents.forEach((component) => {
                    if (component.key !== 'submit' && component.input) {
                        columns.push({ field: component.key, headerName: component.label });
                    }
                })
            }

            setFormColumns(columns);
            setFormData(rows);
        } catch (error) {
            setSnackbar({
                children: error.message,
                severity: "error"
            });
        }
    }

    return (
        <div style={{ marginRight: '40px' }}>
            <Typography
                style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "Lato",
                    marginBottom: 10,
                }}
            >
                Form Reports
            </Typography>
            <div>
                {!!loading && (
                    <div className="d-flex flex-row w-100 justify-content-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {!loading &&
                    <div>
                        <div style={{ display: 'flex', marginBottom: 20 }}>
                            <div style={{ marginRight: 40 }}>
                                <FormControl sx={{ minWidth: 200 }}>
                                    <InputLabel id="employeeSelectLabel">Select Report *</InputLabel>
                                    <Select
                                        labelId="reportSelectLabel"
                                        id="reportSelect"
                                        value={reportName}
                                        label="Select Report"
                                        onChange={handleReportChange}
                                    >
                                        {Form_Reports.map((report) => (
                                            <MenuItem
                                                id={report.report_id}
                                                key={report.report_id}
                                                value={report.report_name}
                                                onClick={handleReportMenuItemClick}
                                            >
                                                {report.report_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ marginRight: 40 }}>
                                <FormControl sx={{ minWidth: 200 }}>
                                    <InputLabel id="employeeSelectLabel">Select Customer</InputLabel>
                                    <Select
                                        labelId="custSelectLabel"
                                        id="custSelect"
                                        value={custName}
                                        label="Select Customer"
                                        onChange={handleCustChange}
                                    >
                                        {custList.map((cust) => (
                                            <MenuItem
                                                id={cust.cus_id}
                                                key={cust.cus_id}
                                                value={cust.cus_name}
                                                onClick={handleCustMenuItemClick}
                                            >
                                                {cust.cus_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <Grid style={{ display: 'flex' }}>
                                <Grid item xs={6}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        localeText={{ start: "From Date", end: "To Date" }}
                                    >
                                        <DateRangePicker
                                            value={dayjs([startDate, endDate])}
                                            onChange={(newValue) => {
                                                setStartDate(new Date(newValue[0]));
                                                setEndDate(new Date(newValue[1]));
                                                setFormData([]);
                                            }}
                                            slotProps={{ fieldSeparator: { children: 'to' } }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid style={{ marginLeft: 40 }} item xs={1}>
                                    <Button
                                        onClick={() => { onSearch(); }}
                                        variant="primary"
                                        size="lg"
                                        className="font-weight-bold"
                                        style={{ fontSize: 14, fontFamily: "Lato" }}
                                    >
                                        SEARCH
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        {formData.length !== 0 &&
                            <div style={{ margin: "auto", height: '80vh', width: '100%', justifyContent: 'center' }}>
                                <ControlledFormDataGrid
                                    reportID={reportID}
                                    reportHeader={currReport?.report_header}
                                    reportSubheader1={currReport?.report_subheader1}
                                    reportSubheader2={currReport?.report_subheader2}
                                    user={props.user}
                                    apiRef={apiRef}
                                    getRowId={(row) => row.id}
                                    rows={formData}
                                    columns={formColumns}
                                    checkboxSelection={false}
                                    disableRowSelectionOnClick
                                />
                            </div>
                        }
                        {!!snackbar && (
                            <Snackbar
                                open
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                onClose={handleCloseSnackbar}
                                autoHideDuration={6000}
                            >
                                <Alert {...snackbar} onClose={handleCloseSnackbar} />
                            </Snackbar>
                        )}
                    </div>
                }
            </div>
        </div>
    )
};