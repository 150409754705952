import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Typography, Grid, InputLabel, MenuItem, FormControl, Select, TextField, Chip, OutlinedInput, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useTheme } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import dayjs from "dayjs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 12,
  },
  link: {
    fontSize: 15,
  },
}));

const formFields = {
  date: null,
  Time: null,
  servieProvider: "",
  servieReciever: "",
  taskType: [],
  locations: "",
};

export default function EVVFilter(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [evvFilterData, setevvFilterData] = useState(formFields);
  const [taskType, setPersonName] = useState([]);

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  function getStyles(name, taskType, theme) {
    return {
      fontWeight:
        taskType.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  useEffect(() => {
    let tempObj = localStorage.getItem("evvFilterData");
    if (!tempObj && props.filterResultsOutput === '') {
      setevvFilterData({ ...formFields });
    }
    if (tempObj) {
      tempObj = JSON.parse(tempObj);
      setevvFilterData({ ...tempObj });
      setPersonName(tempObj.taskType);
    }
  }, []);

  const handleChangeTaskType = (e) => {
    console.log(">>>>", e.target.value);
    const {
      target: { value },
    } = e;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    setevvFilterData({
      ...evvFilterData,
      taskType: e.target.value
    });
  };

  const handleFieldsData = (e, name, key) => {
    let temp = { ...evvFilterData };
    switch (key) {
      case "date":
        {
          temp[name] = moment(new Date(e)).format("MM/DD/YYYY");
        }
        break;
      case "time":
        {
          temp[name] = moment(new Date(e));
        }
        break;
      case "multi":
        {
          const {
            target: { value },
          } = e;
          temp[name] = typeof value === "string" ? value.split(",") : value;
        }
        break;
      default:
        {
          temp[name] = e.target.value;
        }
        break;
    }
    setevvFilterData({ ...temp });
  };
  const applyFilters = (e) => {
    console.log("applyFilters Final>>>>", evvFilterData);
    props.onFormSubmission(evvFilterData);
  };
  return (
    <Grid
      container
      justify="center"
      style={{
        height: "85%",
        width: "22%",
        border: "1px solid gery",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        position: "fixed",
        zIndex: 1,
        right: 0,
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%)",
        padding: 12,
        overflowY: "auto",
        top: 80
      }}
    >
      <Grid container>
        <Grid container
          style={{ borderBottom: "1px solid grey", paddingBottom: '0px' }}>
          <Grid item xs={11}
            justifyContent="space-between">
            <Typography
              variant="subtitle2"
              style={{
                fontSize: 12,
                fontStyle: "italic",
                paddingBottom: "5px",
                fontFamily: "Lato",
                fontWeight: "bold",
                color: "#495057",
                display: 'inline-block'
              }}
              align="left"
            >
              Select desired fields to run a customized report:
            </Typography>
          </Grid>
          <Grid item xs={1}
            align="right"
          >
            <CancelIcon
              style={{ cursor: 'pointer' }}
              alignItems="right"
              onClick={(e) => {
                console.log("Close filter");
                props?.closeCustomReport(e);
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className="mt-3">
          <Typography
            variant="h1"
            style={{ fontSize: 14, fontFamily: "Lato", }}
            mb={1}
            className="font-weight-bold"
          >
            Service Provider
          </Typography>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={evvFilterData.servieProvider}
              label="Select"
              onChange={(newValue) => {
                handleFieldsData(newValue, "servieProvider", "");
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'Service 1'}>Service 1</MenuItem>
              <MenuItem value={'Service 2'}>Service 2</MenuItem>
              <MenuItem value={'Service 3'}>Service 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid>

        <Grid item xs={12} className="mb-1">
          <Typography
            variant="h1"
            style={{ fontSize: 14, fontFamily: "Lato", }}
            mb={1}
            className="font-weight-bold"
          >
            Service Reciever
          </Typography>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={evvFilterData.servieReciever}
              label="Select"
              onChange={(newValue) => {
                handleFieldsData(newValue, "servieReciever", "");
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'Service 1'}>Service 1</MenuItem>
              <MenuItem value={'Service 2'}>Service 2</MenuItem>
              <MenuItem value={'Service 3'}>Service 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid>

        <Grid item xs={12} className="mb-1">
          <Typography
            variant="h1"
            style={{ fontSize: 14, fontFamily: "Lato", }}
            mb={1}
            className="font-weight-bold"
          >
            Type
          </Typography>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={taskType}
              onChange={handleChangeTaskType}
              input={
                <OutlinedInput id="select-multiple-chip" label="Select" />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={() => { }}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, taskType, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid>
        <Grid item xs={12} className="mb-1">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography
              variant="h1"
              style={{ fontSize: 14, fontFamily: "Lato", }}
              mb={1}
              className="font-weight-bold"
            >
              Date
            </Typography>
            <DatePicker
              label="Today's date"
              value={dayjs(evvFilterData.date).isValid() ? moment(evvFilterData.date) : null}
              onChange={(newValue) => {
                handleFieldsData(newValue, "date", "date");
              }}
              slotProps={{textField: {variant: 'outlined', size: 'small'}}}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid>
        <Grid item xs={12} className="mb-1">
          <FormControl size="small" fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Typography
                variant="h1"
                style={{ fontSize: 14, fontFamily: "Lato" }}
                mb={1}
                className="font-weight-bold"
              >
                Time
              </Typography>
              <TimePicker
                size="small"
                label="Today's time"
                value={moment(evvFilterData.Time).isValid() ? dayjs(evvFilterData.Time) : null}
                onChange={(newValue) => {
                  handleFieldsData(newValue, "Time", "time");
                }}
                slotProps={{textField: {variant: 'outlined', size: 'small'}}}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid>

        <Grid item xs={12} className="mb-2">
          <Typography
            variant="h1"
            style={{ fontSize: 14, fontFamily: "Lato", }}
            mb={1}
            className="font-weight-bold"
          >
            Locations
          </Typography>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={evvFilterData.locations}
              label="Select"
              onChange={(newValue) => {
                handleFieldsData(newValue, "locations", "");
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'Location 1'}>Location 1</MenuItem>
              <MenuItem value={'Location 2'}>Location 2</MenuItem>
              <MenuItem value={'Location 3'}>Location 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} className="mb-1 mt-2">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              onClick={(e) => props?.openCustomReport(e)}
              variant="primary"
              size="sm"
              className="font-weight-bold"
              style={{ fontSize: 14, fontFamily: "Lato", }}
            >
              RESET
            </Button>

            <Button
              onClick={(e) => {
                localStorage.setItem("evvFilterData", JSON.stringify(evvFilterData));
                applyFilters();
                console.log("Applied filters ready for API", evvFilterData);
              }}
              variant="primary"
              size="sm"
              className="font-weight-bold"
              style={{ fontSize: 14, fontFamily: "Lato", }}
            >
              RUN REPORT
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};