import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIURL } from '../../../config';
import {
    Box,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Paper,
    Divider,
    Autocomplete,
    Stack
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';

const EmployeeRelationship = (props) => {
    const [empList, setEmpList] = useState([]);
    const [emp, setEmp] = useState(null);
    const [empID, setEmpID] = useState('');
    const [empRelation, setEmpRelation] = useState([]);
    const [empType, setEmpType] = useState([]);
    const [type, setType] = useState('');
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        setEmpList(props.empList?.sort((a, b) => a.emp_first_name.localeCompare(b.emp_first_name)));
        const filteredEmpType = props.empType?.filter((type) => !['Admin', 'Fieldworker', 'Case Manager'].includes(type));
        setEmpType(filteredEmpType || []);
    }, [props.empList, props.empType]);

    useEffect(() => {
        async function getEmpType(emp) {
            if (empID) {
                let hrData = (await axios.get(APIURL + '/employees/hrstructure', { params: { comp_id: props.comp_id, emp_id: empID } })).data
                setEmpRelation(hrData);
                setLeft(empList.filter((emp) => !hrData.map((rel) => rel.relatedto).includes(emp.emp_id) && emp.emp_id !== Number(empID)));
                setRight(empList.filter((emp) => hrData.filter((rel) => rel.relationtype === type).map((rel) => rel.relatedto).includes(emp.emp_id) && emp.emp_id !== Number(empID)));
            }
        }

        getEmpType(empID);
    }, [empID]);

    useEffect(() => {
        if (empID) {
            setRight(empList.filter((emp) => empRelation.filter((rel) => rel.relationtype === type).map((rel) => rel.relatedto).includes(emp.emp_id) && emp.emp_id !== Number(empID)));
        }
    }, [type]);

    const handleOnAdd = async () => {
        try {
            const payload = {
                empData: right.map((emp) => { return { id: emp.emp_id } }),
                ID: empID,
                type: type,
                comp_id: props.comp_id
            };



            if(type !== null && type !== '' && type !== undefined){
                const addResult = await axios.put(`${APIURL}/employees/hrstructure`, payload);
                console.log(addResult);

                if (addResult?.data?.code === 200) {
                    const updatedRelations = await axios.get(`${APIURL}/employees/hrstructure`, { params: { comp_id: props.comp_id, emp_id: empID } });
                    setEmpRelation(updatedRelations.data);
                    setChanged(false);
                }
            } else {
                alert('Please select a role for the employee')
            }
            
        } catch (error) {
            console.error(error);
        }
    };

    const handleEmpChange = (event, value) => {
        setEmp(value);
        setEmpID(value?.emp_id || '');
    };

    const handleRoleChange = (event, value) => {
        setType(value);
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        setLeft(empList.filter((emp) => emp.emp_first_name.toLowerCase().includes(value) || emp.emp_last_name.toLowerCase().includes(value)));
    };

    const handleToggle = (item, from, to) => () => {
        setChanged(true);
        if (from.includes(item)) {
            setLeft(left.filter((emp) => emp !== item));
            setRight([...right, item]);
        } else {
            setRight(right.filter((emp) => emp !== item));
            setLeft([...left, item]);
        }
    };

    const customList = (title, items) => (
        <Paper sx={{ width: 300 }}>
            <Typography variant="h6" align="center">{title}</Typography>
            <Divider />
            <List dense component="div" role="list" sx={{ overflow: 'auto', height: 270 }}>
                {items.map((item) => {
                    const labelId = `transfer-list-item-${item.emp_id}-label`;
                    return (
                        <ListItem key={item.emp_id} role="listitem" button onClick={handleToggle(item, left, right)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={right.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={item.emp_first_name + ' ' + item.emp_last_name} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">Employee Relationship</Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Manage the relationships between employees by selecting roles and relating employees
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Grid container spacing={2}>
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', width: '100%' }}>
                            <Autocomplete
                                id="employee-select"
                                options={empList}
                                getOptionLabel={(option) => option.emp_first_name + ' ' + option.emp_last_name}
                                onChange={handleEmpChange}
                                renderInput={(params) => <TextField {...params} label="Select Employee" margin="normal" />}
                                fullWidth
                                size="small"
                                renderOption={(props, item) => (
                                    <li {...props} key={item.key}>
                                        <ListItemText primary={item.emp_first_name + ' ' + item.emp_last_name} />
                                    </li>
                                )}
                            />
                            <Autocomplete
                                id="role-select"
                                options={empType}
                                getOptionLabel={(option) => option}
                                onChange={handleRoleChange}
                                renderInput={(params) => <TextField {...params} label="Select Role" margin="normal" />}
                                fullWidth
                                disabled={!emp}
                                size="small"
                                renderOption={(props, item) => (
                                    <li {...props} key={item.key}>
                                        <ListItemText primary={item} />
                                    </li>
                                )}
                            />
                            <TextField
                                label="Search Employees"
                                value={searchTerm}
                                onChange={handleSearch}
                                fullWidth
                                margin="normal"
                                disabled={!type}
                                size="small"
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} my={1}>
                            {emp && type ?
                                <Typography variant="body1" align="center">{emp?.emp_first_name + ' ' + emp?.emp_last_name} is a {type} of</Typography>
                                : 
                                <Box sx={{ height: 24 }} />
                            }
                        </Box>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid xs={5}>{customList('Choose Employees', left)}</Grid>
                            <Grid xs={2}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            setRight(right.concat(left));
                                            setLeft([]);
                                            setChanged(true);
                                        }}
                                        disabled={!left.length}
                                        aria-label="move all right"
                                        sx={{ width: 100 }}
                                    >
                                        ≫
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        disabled={!right.length}
                                        onClick={() => {
                                            setLeft(left.concat(right));
                                            setRight([]);
                                            setChanged(true);
                                        }}
                                        aria-label="move all left"
                                        sx={{ width: 100 }}
                                    >
                                        ≪
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid xs={5}>{customList('Selected Employees', right)}</Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <SaveButton onClick={handleOnAdd} disabled={!changed} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EmployeeRelationship;
