import React from "react"
import { Modal, Button, Dropdown, DropdownButton } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { APIURL } from '../../config'
import axios from 'axios'
import moment from "moment"
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import { Array.isArray } from "@material-ui/data-grid"
import './TimeSummary.css'
import WeeklyTimesheetAlt from '../../components/TimeManagement/WeeklyTimesheetAlt'
import SummaryTable from '../../components/TimeManagement/SummaryTable'
import EmployeeHeader from "../../components/TimeManagement/EmployeeHeader"
import PreAuthCsv from "../../components/PreAuthCSV/PreAuthCsv"
// import { DataGrid } from '@mui/x-data-grid';
import './TimeSummaryAlt.css'
import MessagePage from "../../components/MessagePages/MessagePage"
import { Link } from "react-router-dom";
import DurationPicker from "../../components/TimeManagement/DurationPicker"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';
import dayjs from "dayjs"


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

const filterEmployeeListOptions = createFilterOptions({
    ignoreCase: true,
    matchFrom: 'any',
    stringify: option => option.employee_name,
    trim: true,
})

export default class TimeSummary extends React.Component {
    state = {
        tsData: [],
        csvAdd: false,
        add: false,
        edit: false,
        editInProg: false,
        addInProg: false,
        end: moment().endOf('week').format("YYYY[-]MM[-]DD"),
        isLoading: false,
        duration: 'Bi-Weekly',
        num_entries: 20,
        start: moment().startOf('week').format("YYYY[-]MM[-]DD"),
        addFormError: false, editFormError: false, success: false,
        editDataForm: { projDets: {}, taskDets: {}, empDets: { emp_id: null }, start_time: moment().format('YYYY-MM-DDTHH:mm'), end_time: moment().format('YYYY-MM-DDTHH:mm'), duration: null, tNotes: '' },
        addDataForm: { tTitle: '', proj_id: null, emp_id: null, sDate: moment().format('YYYY-MM-DDTHH:mm'), eDate: moment().format('YYYY-MM-DDTHH:mm'), tType: null, duration: null, tNotes: '' },
        projects: [], typeList: [], location: [], employees: [], managedEmployees: [],
        currentEmployeeName: "None Selected",
        currentEmployee: null,
        empData: null,
        company: null,
        empTimeData: null,
        deleteTaskSuccess: false,
        payStubsList: [],
        loggedToSegment: false,
        isSupport: this.props.user?.comp_settings.find(setting => setting.feature_id === 101 && setting.feature_value === 'primaryBusinessType') ? true : false,
        empTasksAvailable: false,
        isSupportCoordinationCompany: false
    }

    setCurrentEmployee = async (name, id) => {
        await this.setState({ currentEmployeeName: name, currentEmployee: id });
        await this.getEmpData();
    }

    deleteTask = async (tas_id, task_name, tas_pro_id) => {
        let data = { id: tas_id, empId: this.props.user.emp_id, empName: this.props.user.emp_name, companyId: this.props.comp_id, taskName: task_name, projectId: tas_pro_id, timesheetEntry: true }
        let tasDelete = await axios.delete(
            APIURL + '/task',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
                    "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
                    "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
                    Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
                },
                data,
            }
        );
        if (tasDelete.status === 200) {
            this.setState({ deleteTaskSuccess: true })
            this.forceUpdate()
        }
        return tasDelete;
    }

    setWeek = (dates) => {
        this.setState({ start: dates[0], end: dates[6] })
    }

    getData = async () => {
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        this.setState({ isLoading: true })
        if (this.props.user.type === 'Manager') {
            // debugger
            let managersEmp = await axios.get(APIURL + '/employees/manager', { params: { emp_id: this.props.user.emp_id } })
            this.setState({ managedEmployees: managersEmp.data })
        }
        // console.log(managersEmp.data)
        let dropdowns = {
            projects: (await axios.get(APIURL + '/dropdowns/dropdownType/projectslist', { params: { comp_id: this.props.user.comp_id } })).data,
            employees: (await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: this.props.user.comp_id } })).data,
            // typeList: (await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: this.props.user.comp_id, employeeType: this.props.user.type } })).data,
            location: (await axios.get(APIURL + '/dropdowns/dropdownType/locationslist', { params: { comp_id: this.props.user.comp_id } })).data
        }

        let empTasks = await axios.get(APIURL + "/employees/EmployeeTasks", { params: { comp_id: this.props.user.comp_id, employee_id: this.props.user.emp_id } });
        if (empTasks.data.length !== 0) {
            let empTaskTypes = []

            empTasks.data.forEach((task) => {
                empTaskTypes.push(task.EntityValue)
            })

            this.setState({ empTasksAvailable: true })
            this.setState({ typeList: empTaskTypes })
        }

        let tsDataApi = await axios.get(APIURL + '/timesheets', {
            params: {
                comp_id: this.props.comp_id, emp_id: this.props.user.emp_id, timesheetsSummaryFix: true
            }
        })
        const settings = await axios.get(APIURL + '/config/settings', { params: { comp_id: this.props.user.comp_id } })
        // console.log('GET DATA',dropdowns.data,!!isArray(dropdowns.data))
        if (!Array.isArray(tsDataApi.data) && !Array.isArray(dropdowns.data)) {
            window.location.reload()
        }

        let tsData = tsDataApi.data.map((ts) => {
            let newobj = {
                id: ts.id,
                editIcon: 'fa fas-edit', add: () => this.addRecord(ts.id), edit: () => this.editRecord(ts.id), emp_name: ts.empDets.emp_first_name + ' ' + ts.empDets.emp_last_name,
                start_time: ts.start_time.split('').length === 24 ? moment(ts.start_time).utc().format('DD MMM YYYY, HH:mm a') : moment(ts.start_time).format('DD MMM YYYY, HH:mm a'), end_time: ts.end_time.split('').length === 24 ? moment(ts.end_time).utc().format('DD MMM YYYY, HH:mm a') : moment(ts.end_time).format('DD MMM YYYY, HH:mm a'), task_name: !ts.taskDets ? 'N/A' : ts.taskDets.tas_title, project_name: !ts.projDets ? 'N/A' : ts.projDets.pro_title, scheduled: !ts.taskDets ? '0' : !moment(ts.taskDets.tas_end_time).isValid() ? '0' : moment.duration(moment(ts.taskDets.tas_end_time).diff(ts.taskDets.tas_start_time)).asHours().toFixed(2), worked: moment.duration(moment(ts.end_time).diff(ts.start_time)).asHours().toFixed(2), account_code: !ts.taskDets ? 'N/A' : ts.taskDets.tas_type, notes: ts.notes
            }
            return newobj
        })

        console.log(this.state.isSupport)
        await this.setState({
            ogData: tsDataApi.data,
            tsData: tsData,
            projects: dropdowns.projects,
            employees: dropdowns.employees,
            // typeList: dropdowns.typeList,
            location: dropdowns.location,
            isLoading: false,
            isSupport: settings.data.find(setting => setting.feature_id === 101 && setting.feature_value === 'primaryBusinessType') ? true : false
        })
        console.log(this.state.isSupport)
        // debugger
    }

    getEmpData = async () => {
        this.setState({ isLoading: true });
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        let empRecordDataAPI = await axios.get(APIURL + '/timesheets', { params: { emp_id: this.state.currentEmployee } })
        let empTimeSheetsAPI = await axios.get(
            APIURL + '/time_sheet',
            {
                params: {
                    company_id: this.props.comp_id,
                    emp_id: this.state.currentEmployee,
                    type: 'Fieldworker',
                }
            }
        )
        let empData1 = empRecordDataAPI.data.map((ts) => {

            try {
                let newobj = {
                    id: ts.id,
                    tas_pro_id: ts.taskDets.tas_pro_id,
                    tas_id: !!ts.taskDets ? ts.taskDets.tas_id : '',
                    start_time: ts.start_time.split('').length === 24 ? moment(ts.start_time).utc().format('DD MMM YYYY, hh:mm a') : moment(ts.start_time).format('DD MMM YYYY, hh:mm a'),
                    end_time: ts.end_time.split('').length === 24 ? moment(ts.end_time).utc().format('DD MMM YYYY, hh:mm a') : moment(ts.end_time).format('DD MMM YYYY, hh:mm a'),
                    task_name: !ts.taskDets ? 'N/A' : ts.taskDets.tas_title, project_name: !ts.projDets ? 'N/A' : ts.projDets.pro_title,
                    task_type: !ts.taskDets ? 'N/A' : ts.taskDets.tas_type,
                    scheduled: !ts.taskDets ? '0' : !moment(ts.taskDets.tas_end_time).isValid() ? '0' : moment.duration(moment(ts.taskDets.tas_end_time).diff(ts.taskDets.tas_start_time)).asHours().toFixed(2),
                    worked: moment.duration(moment(ts.end_time).diff(ts.start_time)).asHours().toFixed(2),
                    account_code: !ts.taskDets ? 'N/A' : ts.taskDets.tas_type,
                    notes: !ts.taskDets ? 'N/A' : ts.taskDets.tas_notes
                }
                // Debugger for notes
                if (!!ts.taskDets) {
                    if (ts.taskDets.tas_is_active === 2) {
                        newobj = {}
                    }
                }
                return newobj
            } catch (error) {
                console.log(error)
                return null
            }
        })

        // Remove null entries
        empData1 = empData1.filter(entry => entry !== null);

        this.setState({ isLoading: false, empData: empData1, empTimeSheets: empTimeSheetsAPI.data.reverse() });
        return;
    }

    getPayrollData = async () => {
        this.setState({ isLoading: true });

        let compInfo = await axios.get(APIURL + '/company', { params: { comp_id: this.props.comp_id } })
        let payrollExisting = await axios.get(APIURL + '/payroll', { params: { company_id: this.props.comp_id } })
        let payStubsList = []
        if (compInfo.data.length !== 0) {
            console.log(compInfo.data[0].payCycle)
            let type;
            switch (compInfo.data[0].payCycle) {
                case "Bi-Weekly":
                    type = 'BIWEEK';
                    break;
                case "Monthly":
                    type = 'MONTH';
                    break;
                case "Semi-Monthly":
                    type = 'SEMIMONTH';
                    break;
                default:
                    type = 'WEEK'
            }
            let day = compInfo.data[0].startoftheWeek === 0 ? 'Sun'
                : compInfo.data[0].startoftheWeek === 1 ? 'Mon'
                    : compInfo.data[0].startoftheWeek === 2 ? 'Tue'
                        : compInfo.data[0].startoftheWeek === 3 ? 'Wed'
                            : compInfo.data[0].startoftheWeek === 4 ? 'Thu'
                                : compInfo.data[0].startoftheWeek === 5 ? 'Fri'
                                    : 'Sat'
            console.log(type, day)
            payStubsList = await axios.get(APIURL + '/pay_period', { params: { payroll_type: type, payroll_day: day } })
        }

        this.setState({ payStubsList: payStubsList.data, isLoading: false, company: compInfo.data[0] })
    }

    logToSegment = async () => {
        if (this.state.loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        if (page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page, this.props.user.emp_id)
        analytics.page(page, {
            title: page,
            path: page,
        }, {
            userId: String(this.props.user.emp_id),
        })
        console.log("logged to segment successfully")
        this.setState({ loggedToSegment: true });
    }

    componentDidMount = async () => {
        await this.logToSegment();
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        await this.props.checkTokenExpiry();
        await this.getData();
        if (this.props.user.type === 'Fieldworker' || this.props.user.type === 'Support Coordinator' || this.props.user.type === 'Case Manager') {
            await this.setCurrentEmployee(this.props.user.emp_name, this.props.user.emp_id)
        }
        await this.getPayrollData();
        await this.checkAutomaticPayroll();

        let isSupportCoordinationCompany = false;
        try{
            isSupportCoordinationCompany = (await axios.get(APIURL + '/config/settings', { params: { comp_id: this.props.user.comp_id, feature_id: 101 } })).data.length > 0;
        } catch(err){
            console.log(err);
        }
        this.setState({isSupportCoordinationCompany: isSupportCoordinationCompany})
    }

    checkAutomaticPayroll = async () => {
        let id = null;
        const currentDate = moment();
        for (const entry of this.state.payStubsList) {
            const startDate = moment(entry.start_date.replace(/.Z/g, ""));
            const endDate = moment(entry.end_date.replace(/.Z/g, ""));

            if (currentDate.isBetween(startDate, endDate, undefined, '[]')) {
                id = entry.pay_period_id;
            }
        }

        this.setState({ inProgress: true, payroll_id: null, pay_period_id: id })
        console.log('Will Add payroll using pay period ID', id)
        let data = {
            "company_id": this.props.comp_id,
            "pay_period_id": id,
            "pay_period_status": "ACTIVE",
            "closed_on": null,
            "closed_by": null
        }
        if(id){
            let activateResult = await axios.post(APIURL + '/payroll', { ...data })
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!this.state.empTasksAvailable) {
            if (prevState.addDataForm.cust_id !== this.state.addDataForm.cust_id && this.state.addDataForm.cust_id) {
                let custTasks = await axios.get(APIURL + "/customers/CustomerTasks", { params: { comp_id: this.props.comp_id, customer_id: this.state.addDataForm.cust_id } })
                let cusTaskTypes = []
                if (custTasks.data.length !== 0) {
                    custTasks.data.forEach((task) => {
                        cusTaskTypes.push(task.EntityValue)
                    })
                    this.setState({ typeList: cusTaskTypes, addDataForm: { ...this.state.addDataForm, tType: null } })
                }
                else {
                    let taskTypes = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: this.props.comp_id, employeeType: this.props.user.type } })
                    this.setState({ typeList: taskTypes.data, addDataForm: { ...this.state.addDataForm, tType: null } })
                }
            }
        }
    }

    editRecord = async (id) => {
        // console.log(id, 'Was Pressed')

        let toEdit = this.state.ogData.find((ts) => {
            if (!!ts.taskDets) {
                if (ts.taskDets.tas_id === id) {
                    return true;
                }
            }
            return false;
        })
        let totalMinutes = moment(toEdit.end_time).diff(moment(toEdit.start_time), 'minutes')
        let minutes = (totalMinutes % 60).toString().padStart(2, '0');
        let hours = ((totalMinutes - minutes) / 60).toString().padStart(2, '0');

        // find record with the id
        // set editformdata state

        this.setState({ edit: !this.state.edit, editDataForm: { ...toEdit, duration: hours + ':' + minutes }, add: false, addDataForm: { tTitle: '', proj_id: null, sDate: moment().format('YYYY-MM-DDThh:mm'), eDate: moment().format('YYYY-MM-DDThh:mm') } })
    }

    addRecord = async (id) => {
        // console.log(id, 'Was Pressed')
        let toAdd = this.state.ogData.find((ts) => ts.id === id)
        console.log(toAdd)
        // find record with the id
        // set editformdata state
        this.setState({ add: !this.state.add, addDataForm: { ...this.state.addDataForm, emp_id: toAdd.empDets.emp_id, tTitle: !!toAdd.taskDets ? toAdd.taskDets.tas_title : '', tType: !!toAdd.taskDets ? toAdd.taskDets.tas_type : '', proj_id: !!toAdd.projDets ? toAdd.projDets.pro_id : '' } })
    }

    changeConfig = async (e) => {
        // console.log(e.target.id,e.target.value)
        if (e.target.id === 'duration') {
            let duration = e.target.value
            if (duration === 'Weekly') {
                this.setState({ duration: duration, start: moment(this.state.end).add(-1, 'weeks').format('YYYY-MM-DD') })
            } else if (duration === 'Bi-Weekly') {
                this.setState({ duration: duration, start: moment(this.state.end).add(-2, 'weeks').format('YYYY-MM-DD') })
            } else if (duration === 'Monthly') {
                this.setState({ duration: duration, start: moment(this.state.end).add(-1, 'months').format('YYYY-MM-DD') })
            } else if (duration === 'Quaterly') {
                this.setState({ duration: duration, start: moment(this.state.end).add(-3, 'months').format('YYYY-MM-DD') })
            } else {
                this.setState({ duration: duration, start: moment(this.state.end).add(-1, 'years').format('YYYY-MM-DD') })
            }
            this.getData()
        } else if (e.target.id === 'num_entries') {
            await this.setState({ num_entries: parseInt(e.target.value) })
            await this.setState({ isLoading: true })
            await this.setState({ isLoading: false })
        }
        else {
            // if(!!moment(e.target.value).isValid()){
            let duration = this.state.duration
            if (duration === 'Weekly') {
                this.setState({ end: e.target.value, start: moment(e.target.value).add(-1, 'weeks').format('YYYY-MM-DD') })
            } else if (duration === 'Bi-Weekly') {
                this.setState({ end: e.target.value, start: moment(e.target.value).add(-2, 'weeks').format('YYYY-MM-DD') })
            } else if (duration === 'Monthly') {
                this.setState({ end: e.target.value, start: moment(e.target.value).add(-1, 'months').format('YYYY-MM-DD') })
            } else if (duration === 'Quaterly') {
                this.setState({ end: e.target.value, start: moment(e.target.value).add(-3, 'months').format('YYYY-MM-DD') })
            } else {
                this.setState({ end: e.target.value, start: moment(e.target.value).add(-1, 'years').format('YYYY-MM-DD') })
            }
        }
    }

    validateEditForm = async () => {
        // debugger
        let isVal = false
        if (this.state.isSupport) {
            if (/^[0-9]{2}:[0-9]{2}$/.test(this.state.editDataForm.duration) && !!this.state.editDataForm.projDets) {
                isVal = !isVal
            }
        } else {
            if ((!moment(this.state.editDataForm.start_time.substring(0, 16)).isSameOrAfter(moment(this.state.editDataForm.end_time.substring(0, 16))) && !!this.state.editDataForm.projDets)
            ) {
                isVal = !isVal
            }
        }

        if (!!isVal) {
            // console.log('passed validation test')
            this.setState({ editInProg: true })
            let updateId = this.state.editDataForm.id
            await this.updateEntry(updateId)
            this.setState({ editInProg: false })
            // this.setState({isLoading: false})
        } else {
            // Alert User 
            this.setState({ editFormError: true, editInProg: false })
            console.log('Validation Failed')
        }
    }

    updateEntry = async (id) => {
        let minutes = this.state.isSupport ? (this.state.editDataForm.duration.split(":").map(function (x) { return parseInt(x, 10); }).reduce(function (a, b) { return a * 60 + b; })) : 0

        let empLocData = {
            empId: this.props.user.emp_id,
            empName: this.props.user.emp_name,
            companyId: this.props.comp_id,
            taskName: this.state.addDataForm.tTitle !== '' ? this.state.addDataForm.tTitle : `${this.props.user.emp_name} - ${moment().format('YYYY-DD-MM,HH:mm')} `,
            proj_id: this.state.editDataForm.projDets.pro_id,
            start: this.state.isSupport ? moment(this.state.editDataForm.start_time).set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm:ss') : moment(this.state.editDataForm.start_time.replace(/Z/g, "")).format('YYYY-MM-DD HH:mm:ss'),
            end: this.state.isSupport ? moment(this.state.editDataForm.start_time).set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).add(minutes, 'm').format('YYYY-MM-DD HH:mm:ss') : moment(this.state.editDataForm.end_time.replace(/Z/g, "")).format('YYYY-MM-DD HH:mm:ss'),
            id: id,
            tNotes: this.state.editDataForm.tNotes
        }


        console.log(empLocData);
        let projData = this.state.projects.find((obj) => obj.pid === this.state.editDataForm.projDets.pro_id)
        let response = await axios.put(APIURL + '/timesheets', { ...empLocData })
        // let response = {data: {code: 200}}
        console.log(response)

        // Change Note
        let taskUpdateInfo = {
            empId: this.props.user.emp_id,
            empName: this.props.user.emp_name,
            taskId: this.state.editDataForm.taskDets.tas_id,
            tTitle: this.state.editDataForm.taskDets.tas_title,
            tDesc: this.state.editDataForm.taskDets.tas_description,
            tType: this.state.editDataForm.taskDets.tas_type,
            tStrtDate: this.state.editDataForm.taskDets.tas_start_date,
            tEndDate: this.state.editDataForm.taskDets.tas_end_date,
            tStartTime: this.state.editDataForm.taskDets.tas_start_time,
            tEndTime: this.state.editDataForm.taskDets.tas_end_time,
            tPriority: this.state.editDataForm.taskDets.tas_priority,
            tIsBillable: this.state.editDataForm.taskDets.tas_billable_by_default,
            tNotes: this.state.editDataForm.taskDets.tas_notes,
            tProject: this.state.editDataForm.taskDets.tas_pro_id,
            tAssTo: this.state.editDataForm.taskDets.tas_emp_id,
            tLoc: this.state.editDataForm.taskDets.tas_loc_id,
            tPreReq: 'NULL',
            company_id: this.state.editDataForm.taskDets.tas_com_id,
            user_id: this.props.user.emp_id
        }
        let tas_update_response = await axios.put(APIURL + '/task', taskUpdateInfo)
        console.log(tas_update_response)

        if (response.data.code === 200 || response.status === 200) {
            let updateTs = this.state.editDataForm

            let ogData = this.state.ogData.map((ts) => {
                if (ts.id === id) {
                    if (this.state.isSupport) {
                        return {
                            ...updateTs,
                            start: this.state.editDataForm.start_time.length === 24 ? this.state.editDataForm.start_time : this.state.editDataForm.start_time.toString() + ':00.000Z',
                            end: moment(this.state.editDataForm.start_time).add(minutes, 'm').toISOString(),
                            duration: updateTs.duration,
                            projDets: { ...updateTs.projDets, pro_id: projData.pid, pro_title: projData.pname },
                            tNotes: updateTs.tNotes
                        }
                    } else {
                        return {
                            ...updateTs,
                            start_time: updateTs.start_time.length === 24 ? moment(updateTs.start_time).utc().format('YYYY-MM-DDTHH:mm') : moment(updateTs.start_time).format('YYYY-MM-DDTHH:mm'),
                            end_time: updateTs.end_time.length === 24 ? moment(updateTs.end_time).utc().format('YYYY-MM-DDTHH:mm') : moment(updateTs.end_time).format('YYYY-MM-DDTHH:mm'),
                            projDets: { ...updateTs.projDets, pro_id: projData.pid, pro_title: projData.pname },
                            tNotes: updateTs.tNotes
                        }
                    }
                } else {
                    return ts
                }
            })
            this.setState({ isLoading: true })
            let tsData = this.state.tsData.map((ts) => {
                if (ts.id === this.state.editDataForm.id) {
                    if (this.state.isSupport) {
                        ts = {
                            ...ts,
                            project_name: projData.pname
                        }
                    }
                    else {
                        ts = {
                            ...ts,
                            project_name: projData.pname,
                            worked: moment.duration(moment(this.state.editDataForm.end_time.split('').splice(0, 19).join('')).diff(this.state.editDataForm.start_time.split('').splice(0, 19).join(''))).asHours().toFixed(2), start_time: moment(this.state.editDataForm.start_time.split('').splice(0, 19).join('')).format('DD MMM YYYY, HH:mm a'), end_time: moment(this.state.editDataForm.end_time.split('').splice(0, 19).join('')).format('DD MMM YYYY, HH:mm a')
                        }
                    }
                }
                return ts
            })
            await this.setState({ ogData: ogData, tsData: tsData })
            await this.setState({
                editFormError: false, editDataForm: { taskDets: {}, empDets: { emp_id: null }, start_time: moment().format('YYYY-MM-DDTHH:mm'), end_time: moment().format('YYYY-MM-DDTHH:mm') }, edit: false,
                isLoading: false,
                success: true
            })
            // debugger
            // this.props.navigation.navigate('CompletedTimesheet')
        } else {
            console.log('alert user of error', response.data.code)
            this.setState({ editFormError: true })
        }
    }

    addEntry = async () => {
        let minutes = this.state.isSupport ? (this.state.addDataForm.duration.split(":").map(function (x) { return parseInt(x, 10); }).reduce(function (a, b) { return a * 60 + b; })) : 0

        let projData = this.state.projects.find((obj) => obj.pid === this.state.addDataForm.proj_id)
        let empData = this.state.employees.find((obj) => obj.id === this.state.addDataForm.emp_id)
        let locDets = this.state.location.find((loc) => loc.id === this.state.projects.find((pro) => pro.pid === this.state.addDataForm.proj_id).proLoc[0].id)
        let data = {
            empId: this.props.user.emp_id,
            empName: this.props.user.emp_name,
            company_id: this.props.user.comp_id,
            tTitle: this.state.addDataForm.tTitle !== '' ? this.state.addDataForm.tTitle : `${this.props.user.emp_name} - ${moment().format('YYYY-DD-MM, HH:mm')} `,
            tDesc: 'Time Sheet Entry',
            tProject: this.state.addDataForm.proj_id,
            tPreReq: 'NULL',
            tType: this.state.addDataForm.tType,
            tStrtDate: this.state.isSupport ? moment(this.state.addDataForm.sDate).set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm:ss') : moment(this.state.addDataForm.sDate).format('YYYY-MM-DD HH:mm:ss'),
            tEndDate: this.state.isSupport ? moment(this.state.addDataForm.sDate).set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).add(minutes, 'm').format('YYYY-MM-DD HH:mm:ss') : moment(this.state.addDataForm.eDate).format('YYYY-MM-DD HH:mm:ss'),
            tStartTime: this.state.isSupport ? moment(this.state.addDataForm.sDate).set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm:ss') : moment(this.state.addDataForm.sDate).format('YYYY-MM-DD HH:mm:ss'),
            tEndTime: this.state.isSupport ? moment(this.state.addDataForm.sDate).set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).add(minutes, 'm').format('YYYY-MM-DD HH:mm:ss') : moment(this.state.addDataForm.eDate).format('YYYY-MM-DD HH:mm:ss'),
            tLoc: locDets.id,
            tActive: 1,
            tAssTo: this.state.addDataForm.emp_id,
            tPriority: 'Low',
            tIsBillable: true,
            tNotes: this.state.addDataForm.tNotes || 'N/A',
            user_id: this.props.user.emp_id
        };
        console.log(data)
        let tasRes = await axios.post(APIURL + '/task', data)
        // let tasRes = {data:{code: 200, message: 'Task Insert Failed'}}
        if (tasRes.data.code === 200) {
            let empLocData = {
                assignedById: this.props.user.emp_id,
                projId: data.tProject,
                empId: data.tAssTo,
                empName: this.props.user.emp_name,
                companyId: this.props.comp_id,
                taskName: this.state.addDataForm.tTitle !== '' ? this.state.addDataForm.tTitle : `${this.props.user.emp_name} - ${moment().format('YYYY-DD-MM,HH:mm')} `,
                locId: data.tLoc,
                taskId: tasRes.data.id,
                emploc_lat: parseFloat(locDets.location_lat),
                emploc_lon: parseFloat(locDets.location_lon),
                start_time: data.tStartTime,
                end_time: data.tEndTime,
                timesheet_status: 1
            }
            let tsRes = await axios.post(APIURL + '/timesheets', { ...empLocData })
            // let tsRes = {data:{code: 200, message: 'Timesheet Insert Failed'}}
            if (tsRes.data.code === 200) {
                console.log('Successfull', data, empLocData)
                await this.setState({ isLoading: true })
                let tsEntryv1 = {
                    start_time: data.tStartTime,
                    end_time: data.tEndTime,
                    id: tsRes.data.id,
                    project_name: projData.pname,
                    worked: moment.duration(moment(empLocData.end_time).diff(empLocData.start_time)).asHours().toFixed(2),
                    account_code: data.tType,
                    emp_name: empData.employee_name,
                    task_name: data.tTitle,
                    add: () => this.addRecord(tsRes.data.id), edit: () => this.editRecord(tsRes.data.id),
                    editIcon: 'fas fa-edit'
                }
                let tsEntry = {
                    start_time: empLocData.start_time,
                    end_time: empLocData.end_time,
                    id: tsRes.data.id,
                    empDets: { emp_id: empData.id, emp_first_name: empData.employee_name.split(' ')[0], emp_last_name: empData.employee_name.split(' ')[1], emp_type: empData.emp_type },
                    projDets: { pro_id: empLocData.projId, pro_title: projData.pname }, locDets: { loc_id: locDets },
                    taskDets: { tas_title: data.tTitle, tas_id: empLocData.taskId, tas_start_date: data.tStrtDate, tas_end_date: data.tEndDate, tas_start_time: data.tStartTime, tas_end_time: data.tEndTime, tas_type: data.tType }
                }
                await this.setState({ addFormError: false, success: true, add: false, ogData: [tsEntry, ...this.state.ogData], tsData: [tsEntryv1, ...this.state.tsData] })
                await this.setState({
                    addDataForm: {
                        tTitle: '', proj_id: null, emp_id: null, sDate: moment().format('YYYY-MM-DDTHH:mm'), eDate: moment().format('YYYY-MM-DDTHH:mm'), tType: null
                    }
                })
                await this.setState({ isLoading: false })
            }
            else {
                console.log('Not Successfull', data, empLocData)
                this.setState({ addFormError: true, add: true, isLoading: false })
                return
            }
        } else {
            console.log('alert user of error')
            this.setState({ addFormError: true, add: true, isLoading: false })
            return
        }

    }

    validateAddForm = async () => {
        let isVal = false

        if (this.state.isSupport) {
            if (/^[0-9]{2}:[0-9]{2}$/.test(this.state.addDataForm.duration) && this.state.addDataForm.tType !== null && this.state.addDataForm.tType !== '' && !!this.state.addDataForm.proj_id) {

                isVal = !isVal
            }
        } else {
            if (this.state.addDataForm.tType !== null && this.state.addDataForm.tType !== '' && !!this.state.addDataForm.proj_id &&
                (!!moment(this.state.addDataForm.eDate).isAfter(moment(this.state.addDataForm.sDate)))
            ) {

                isVal = !isVal
            }
        }
        if (!!isVal) {

            console.log('passed validation test')
            await this.setState({ addInProg: true })
            await this.addEntry()
            await this.setState({ addInProg: false })
            // this.setState({add: false})
        } else {
            // Alert User 
            this.setState({ addFormError: true, add: true, addInProg: false })
            console.log('Validation Failed')
        }

    }

    setAdd = (id, start, end) => {
        console.log(this.state.isSupport)
        this.setState({ addDataForm: { tTitle: '', proj_id: null, emp_id: id, sDate: moment(start).add(0, 'm'), eDate: moment(end).add(1, 'm'), tType: null, tNotes: '' }, });
        this.setState({ add: !this.state.add });
        return;
    }

    setCsvAdd = () => this.setState({ csvAdd: !this.state.csvAdd });

    setEdit = () => {
        this.setState({ edit: !this.state.edit });
        return;
    }

    sortTimesheetDatesByProperty(property) {
        return function (a, b) {
            if (moment(a[property].substring(0, 10), 'YYYY[-]MM[-]DD') > moment(b[property].substring(0, 10), 'YYYY[-]MM[-]DD'))
                return -1;
            else if (moment(a[property].substring(0, 10), 'YYYY[-]MM[-]DD') < moment(b[property].substring(0, 10), 'YYYY[-]MM[-]DD'))
                return 1;
            return 0;
        }
    }

    getWeeks() {
        if (this.state.currentEmployee != null && this.state.empTimeSheets != null && this.state.empData != null) {
            var myEmpTimeSheets = this.state.empTimeSheets;
            let companyPayCycle = !!this.state.company?.payCycle ? this.state.company?.payCycle : 'Weekly'
            myEmpTimeSheets.sort(this.sortTimesheetDatesByProperty("start_date"));
            myEmpTimeSheets = myEmpTimeSheets.filter((timesheet) => {
                if (timesheet.payroll_type == 'WEEK' && companyPayCycle == 'Weekly') {
                    return true;
                } else if (timesheet.payroll_type == 'BIWEEK' && companyPayCycle == 'Bi-Weekly') {
                    return true;
                } else if (timesheet.payroll_type == 'SEMIMONTH' && companyPayCycle == 'Semi-Monthly') {
                    return true;
                } else if (timesheet.payroll_type == 'MONTH' && companyPayCycle == 'Monthly') {
                    return true;
                }
                return false
            })
            myEmpTimeSheets = myEmpTimeSheets.map(empTime => {
                return <WeeklyTimesheetAlt key={empTime.time_sheet_id} preOpen={{ start: this.state.start, end: this.state.end }} getEmpData={this.getEmpData} deleteTask={this.deleteTask} editRecord={this.editRecord} empData={this.state.empData} empTimeSheet={empTime} setAdd={this.setAdd} user={this.props.user} isSupportCoordinationCompany={this.state.isSupportCoordinationCompany}></WeeklyTimesheetAlt>;
            })
            myEmpTimeSheets.filter(ts => ts !== undefined)
            if (myEmpTimeSheets.length === 0) {
                return <MessagePage title="No Timesheets Found" text="We could not find any Timesheets for this employee" image="./nosearchresult.png"></MessagePage>
            }
            return myEmpTimeSheets
        }
        else {
            return;
        }
    }

    toggleCSVAdd = () => this.setState({ csvAdd: !this.state.csvAdd })

    renderCSVModal = () => {
        return (
            <Modal
                show={this.state.csvAdd}
                onHide={this.toggleCSVAdd}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Import CSV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PreAuthCsv toggleCSVAdd={this.toggleCSVAdd} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        this.setState({
                            csvAdd: !this.state.csvAdd
                        })
                    }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        // Stuff for Modals
        let projList = []
        let empList = []
        if (this.props.user.type === 'Fieldworker' || this.props.user.type === 'Support Coordinator' || this.props.user.type === 'Case Manager') {
            // If this user is a regular worker
            //
            empList = this.state.employees.filter((empl) => empl.id === this.props.user.emp_id).map((emp) => {
                return { title: emp.employee_name, value: emp.id }
            })
            if (!!this.state.addDataForm.emp_id || !!this.state.editDataForm.empDets.emp_id) {
                projList = this.state.projects.filter((proj) => !!proj.empAssn && proj.empAssn.find((emp) => emp.id === this.state.addDataForm.emp_id || emp.id === this.state.editDataForm.empDets.emp_id)).map((pro) => {
                    return { title: pro.pname, value: pro.pid, cus_id: pro.cus_id }
                })
            }
            // console.log('Filter the list to logged in user')
        } else if (this.props.user.type === 'Manager') {
            // If this user is a manager:
            // 1) Map managed Employees into empList
            empList = this.state.managedEmployees.map((emp) => {
                return { title: emp.emp_first_name + ' ' + emp.emp_last_name, value: emp.emp_id }
            })
            empList = [{ title: this.props.user.emp_name, value: this.props.user.emp_id }, ...empList]
            // 2) ???
            if (!!this.state.addDataForm.emp_id || !!this.state.editDataForm.empDets.emp_id) {
                projList = this.state.projects.filter((proj) => !!proj.empAssn && proj.empAssn.find((emp) => emp.id === this.state.addDataForm.emp_id || emp.id === this.state.editDataForm.empDets.emp_id)).map((pro) => {
                    return { title: pro.pname, value: pro.pid, cus_id: pro.cus_id }
                })
            }
        } else {
            // console.log('No Filters')
            empList = this.state.employees.map((emp) => {
                return { title: emp.employee_name, value: emp.id }
            })
            if (!!this.state.addDataForm.emp_id || !!this.state.editDataForm.empDets.emp_id) {
                projList = this.state.projects.filter((em) => !!em.empAssn && em.empAssn.find((emp) => emp.id === this.state.addDataForm.emp_id || emp.id === this.state.editDataForm.empDets.emp_id)).map((pro) => {
                    return { title: pro.pname, value: pro.pid, cus_id: pro.cus_id }
                })
            }
        }
        let typeList = this.state.typeList.map((type, indx) => {
            return { title: type, value: indx }
        })

        return (
            (<div className="timesheet-summary-main">
                {(this.props.user.type === 'Admin' || this.props.user.type.includes('illing') || this.props.user.type.includes('ayroll') || this.props.user.type.includes('Manager')) &&
                    <div className="employee-sidebar timesheet-summary-child">
                        <button className="new-timesheet-button" onClick={() => this.setState({ currentEmployee: null, currentEmployeeName: null })}>Timesheets Summary</button>
                        <Link to={'/payroll'}><button className="new-timesheet-button my-2">Payroll Settings</button></Link>

                        <button className="new-timesheet-button" onClick={() => { this.setAdd() }}>New Time Entry</button>
                        {/* <button className="new-timesheet-button my-2" onClick={() => { this.setCsvAdd() }}>Pre Auth CSV</button>
                        <button className="new-timesheet-button my" onClick={() => { this.props.history.push('/preAuthTask') }}>Pre Auth Task List</button> */}

                        <Autocomplete
                            disablePortal
                            options={this.state.employees.map((e) => {
                                return {
                                    label: `${e.employee_name} - ${e.id}`,
                                    id: e.id,
                                    name: e.employee_name
                                }
                            })}
                            // getOptionLabel={(option) => {return option.label}}
                            sx={{ width: '100%', marginTop: '1.5rem' }}
                            // filterOptions={filterEmployeeListOptions}
                            // includeInputInList
                            autoComplete={true}
                            // key={this.state.currentEmployee.id}
                            value={this.state.currentEmployeeName}
                            onChange={async (_, optionSelected) => {
                                console.log('onChange - option selected', optionSelected)
                                if (optionSelected) {
                                    const selectedEmp = this.state.employees.find(emp => emp.employee_name == optionSelected.name)

                                    await this.setCurrentEmployee(selectedEmp.employee_name, selectedEmp.id)
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Employees" />}
                        />
                        {/* <Select
                            value={this.state.currentEmployeeName}
                            onChange={async (optionSelected) =>{
                                console.log('onChange - option selected', optionSelected)
                                if (optionSelected) {
                                    const selectedEmp = this.state.employees.find(emp => emp.id == optionSelected.value.id)
                                    await this.setCurrentEmployee(selectedEmp.employee_name, selectedEmp.id)
                                }
                            }}
                            options={this.state.employees.map((e) => {
                                return {
                                    value: {name: e.employee_name, id: e.id},
                                    label: e.employee_name,
                                }
                            })}
                        /> */}
                    </div>
                }
                {/*
                {(this.props.user.type === 'Manager') &&
                    <div className="employee-sidebar timesheet-summary-child">
                        <button className="new-timesheet-button" onClick={() => { this.setAdd() }}> NEW TIME ENTRY </button>
                        <DropdownButton id="employee-select-dropdown" title="Employees">
                            {

                                this.state.managedEmployees.map(emp => {
                                    return <Dropdown.Item key={emp.emp_id} className="timesummary-employee-selection-dropdown-item" id={emp.id} onClick={() => { this.setCurrentEmployee(emp.emp_first_name + " " + emp.emp_last_name, emp.emp_id) }}>{emp.emp_first_name + " " + emp.emp_last_name}</Dropdown.Item>
                                })

                            }
                        </DropdownButton>
                    </div>
                }*/}
                {!!this.state.isLoading &&
                    <div className="d-flex flex-row justify-content-center w-100">
                        <div style={{ color: '#3FA7D6', height: '10rem', width: '10rem', marginTop: 120 }} className="spinner-border text-info" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {(this.state.employees !== null && this.state.currentEmployee === null && !this.state.isLoading && this.props.user.type !== 'Fieldworker' && this.props.user.type !== 'Support Coordinator' && this.props.user.type !== 'Case Manager') &&
                    <div className="employee-weekly-timesheets timesheet-summary-child">
                        {(!this.state.isLoading) &&
                            <div>
                                <SummaryTable company={this.state.company} editRecord={this.editRecord} payStubsList={this.state.payStubsList} setWeek={this.setWeek} companyEmployeeList={this.state.employees} managedEmployees={this.state.managedEmployees} user={this.props.user} comp_id={this.props.comp_id} setCurrentEmployee={this.setCurrentEmployee}></SummaryTable>
                            </div>
                        }
                    </div>
                }
                {(this.state.currentEmployee != null && !this.state.isLoading) &&
                    <div className="employee-weekly-timesheets timesheet-summary-child">
                        <EmployeeHeader comp_id={this.props.user.comp_id} emp_id={this.state.currentEmployee} />
                        {this.getWeeks()}
                    </div>
                }
                {/* Modals Here */}
                {this.renderCSVModal()}
                <Modal
                    show={this.state.add}
                    onHide={() => { this.setState({ addInProg: false, add: !this.state.add }) }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton={false}>
                        <Modal.Title>ADD NEW RECORD</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Autocomplete
                            id="combo-box-emp"
                            className='my-2 mx-1'
                            value={!!this.state.addDataForm.emp_id ? empList.find((pro) => pro.value === this.state.addDataForm.emp_id) : null}

                            options={empList}
                            onSelect={(e) => {
                                // console.log(e.target.value)

                                let value = empList.find((pro) => pro.title === e.target.value)
                                if (!!value) {
                                    this.setState({ addDataForm: { ...this.state.addDataForm, proj_id: null, emp_id: value.value } })
                                }

                            }}
                            getOptionLabel={(option) => option.title}
                            style={{ width: 330 }}
                            renderInput={(params) => <TextField {...params} label="EMPLOYEE" variant="outlined" />}
                            error={this.state.addFormError && !this.state.addDataForm.emp_id}
                            label="EMPLOYEE" variant="outlined" />
                        <Autocomplete
                            id="combo-box-proj"
                            className='my-2 mx-1'
                            value={!!this.state.addDataForm.proj_id ? projList.find((pro) => pro.value === this.state.addDataForm.proj_id) : null}

                            options={projList}
                            onSelect={(e) => {
                                console.log(e.target.value)

                                let value = projList.find((pro) => pro.title === e.target.value)
                                if (!!value) {
                                    this.setState({ addDataForm: { ...this.state.addDataForm, proj_id: value.value, cust_id: value.cus_id } })
                                }

                            }}
                            getOptionLabel={(option) => option.title}
                            style={{ width: 330 }}
                            renderInput={(params) => <TextField {...params} label="PROJECT" variant="outlined" />}
                            error={this.state.addFormError && !this.state.addDataForm.proj_id}
                            label="PROJECT" variant="outlined" />
                        <TextField
                            // required
                            id="outlined"
                            label="TASK TITLE"
                            onChange={(e) => { this.setState({ addDataForm: { ...this.state.addDataForm, tTitle: e.target.value } }) }}
                            // defaultValue="Hello World"
                            value={this.state.addDataForm.tTitle || ''}
                            variant="outlined"
                            className='my-2 mx-1'
                            style={{ width: 330, fontSize: 20 }}
                        />
                        <Autocomplete
                            id="combo-box-type"
                            className='my-2 mx-1'
                            // value={!!this.state.editDataForm.projDets?projList.find((pro)=> pro.value === this.state.editDataForm.projDets.pro_id):null}
                            // value={!!this.state.addDataForm.tType ? typeList.find((pro) => pro.title === this.state.addDataForm.tType.title) : null}
                            value={this.state.addDataForm.tType || ''}
                            options={typeList}
                            onChange={(e, newValue) => {
                                console.log(newValue)
                                this.setState({ addDataForm: { ...this.state.addDataForm, tType: newValue.title } })
                            }}
                            getOptionLabel={(option) => option.title !== undefined ? option.title : option}
                            style={{ width: 330 }}
                            renderInput={(params) => <TextField {...params} label="TASK TYPE" variant="outlined" />}
                            error={this.state.addFormError && !this.state.addDataForm.tType}
                            variant="outlined" />
                        <TextField
                            // required
                            id="outlined"
                            label="NOTES"
                            onChange={(e) => { this.setState({ addDataForm: { ...this.state.addDataForm, tNotes: e.target.value } }) }}
                            value={this.state.addDataForm.tNotes || ''}
                            variant="outlined"
                            className='my-2 mx-1'
                            style={{ width: 330, fontSize: 20 }}
                        />
                        {
                            !this.state.isSupport ?
                                <>
                                    <TextField
                                        id="datetime-localA"
                                        label="CHECK-IN TIME"
                                        error={this.state.addFormError && !(Number(moment(this.state.addDataForm.eDate).format('YYYYMMDDHHmm')) - Number(moment(this.state.addDataForm.sDate).format('YYYYMMDDHHmm')) > 0)}
                                        type="datetime-local"
                                        value={moment(this.state.addDataForm.sDate).format('YYYY-MM-DDTHH:mm')}
                                        onChange={(d) => {
                                            console.log('just changed', d.target.value)
                                            this.setState({ addDataForm: { ...this.state.addDataForm, sDate: d.target.value } })
                                        }}
                                        // className={classes.textField}
                                        className='my-2'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        id="datetime-localB"
                                        label="CHECK-OUT TIME"
                                        error={this.state.addFormError && !(Number(moment(this.state.addDataForm.eDate).format('YYYYMMDDHHmm')) - Number(moment(this.state.addDataForm.sDate).format('YYYYMMDDHHmm')) > 0)}
                                        type="datetime-local"
                                        value={moment(this.state.addDataForm.eDate).format('YYYY-MM-DDTHH:mm')}
                                        onChange={(d) => {
                                            console.log('just changed', d.target.value)
                                            this.setState({ addDataForm: { ...this.state.addDataForm, eDate: d.target.value } })
                                        }}
                                        // className={classes.textField}
                                        className='my-2'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </> :
                                <>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date"
                                            value={moment(this.state.addDataForm.sDate).isValid() ?  dayjs(this.state.addDataForm.sDate) : null}
                                            className='my-2 mx-1'
                                            onChange={(date) => {
                                                this.setState({ ...this.state, addDataForm: { ...this.state.addDataForm, sDate: moment(date.$d).format('YYYY-MM-DD') } })
                                            }}
                                            sx={{ width: "100%", fontSize: 20 }}
                                            slotProps={{textField: {variant: 'outlined'}}}
                                        />
                                    </LocalizationProvider>
                                    <DurationPicker
                                        style={{ width: "100%", fontSize: 20 }}
                                        className='my-2 mx-1'
                                        value={this.state.addDataForm.duration || ''}
                                        onChange={(validDuration) => { this.setState({ ...this.state, addDataForm: { ...this.state.addDataForm, duration: validDuration } }) }}
                                    />
                                </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                addInProg: false, add: !this.state.add, addDataForm: {
                                    tTitle: '', proj_id: null, emp_id: null, sDate: moment().format('YYYY-MM-DDTHH:mm'), eDate: moment().format('YYYY-MM-DDTHH:mm'), tType: null
                                }
                            })
                        }}>
                            Cancel
                        </Button>
                        <Button disabled={this.state.addInProg} onClick={() => this.validateAddForm()} style={{ height: '2.5rem' }} variant="primary">{this.state.addInProg ?
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : 'Save'}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.edit}
                    onHide={() => { this.setState({ editInProg: false, edit: !this.state.edit }) }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>EDIT TIMESHEET RECORD</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Autocomplete
                            disabled={!!this.state.editDataForm.projDets && !!this.state.editDataForm.projDets.pro_title ? true : false}
                            id="combo-box-demo"
                            value={!!this.state.editDataForm.projDets ? projList.find((pro) => pro.value === this.state.editDataForm.projDets.pro_id) : null}
                            className='my-3'
                            onSelect={(e) => {
                                console.log(e.target.value)

                                let value = projList.find((pro) => pro.title === e.target.value)
                                if (!!value) {
                                    this.setState({ editDataForm: { ...this.state.editDataForm, projDets: { ...this.state.editDataForm.projDets, pro_id: value.value } } })
                                }

                            }}
                            options={projList}
                            getOptionLabel={(option) => option.title}
                            style={{ width: 330 }}
                            renderInput={(params) => <TextField {...params} label="PROJECT" variant="outlined" />}
                        />
                        <TextField
                            // required
                            id="outlined"
                            label="NOTES"
                            value={this.state.editDataForm.taskDets.tas_notes || ''}
                            variant="outlined"
                            className='my-2 mx-1'
                            style={{ width: 330, fontSize: 20 }}
                            onChange={(d) => {
                                console.log(d);
                                this.setState({ editDataForm: { ...this.state.editDataForm, taskDets: { ...this.state.editDataForm.taskDets, tas_notes: d.target.value } } })
                            }}
                        />
                        {
                            !this.state.isSupport ?
                                <>
                                    <TextField
                                        id="datetime-localA"
                                        label="CHECK-IN TIME"
                                        type="datetime-local"
                                        error={this.state.editFormError && !(Number(moment(this.state.editDataForm.end_time.split('').slice(0, 19).join('')).format('YYYYMMDDHHmm')) - Number(moment(this.state.editDataForm.start_time.split('').slice(0, 19).join('')).format('YYYYMMDDHHmm')) > 0)}
                                        value={moment(this.state.editDataForm.start_time.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm')}
                                        onChange={(d) => {
                                            console.log('just changed', d.target.value)
                                            this.setState({ editDataForm: { ...this.state.editDataForm, start_time: moment(d.target.value.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm') } })
                                        }}
                                        // className={classes.textField}
                                        className='my-3'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        id="datetime-localB"
                                        label="CHECK-OUT TIME"
                                        error={this.state.editFormError && !(Number(moment(this.state.editDataForm.end_time.split('').slice(0, 19).join('')).format('YYYYMMDDHHmm')) - Number(moment(this.state.editDataForm.start_time.split('').slice(0, 19).join('')).format('YYYYMMDDHHmm')) > 0)}
                                        type="datetime-local"
                                        value={moment(this.state.editDataForm.end_time.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm')}
                                        onChange={(d) => {
                                            console.log('just changed', d.target.value)
                                            this.setState({ editDataForm: { ...this.state.editDataForm, end_time: moment(d.target.value.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm') } })
                                        }}
                                        // className={classes.textField}
                                        className='my-3'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </> :
                                <>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date"
                                            value={moment(this.state.editDataForm.start_time).isValid() ? dayjs(this.state.editDataForm.start_time) : null}
                                            className='my-2 mx-1'
                                            onChange={(date) => {
                                                this.setState({ ...this.state, editDataForm: { ...this.state.editDataForm, start_time: moment(date.$d).format('YYYY-MM-DD') } })
                                            }}
                                            sx={{ width: "100%", fontSize: 20 }}
                                            slotProps={{textField: {variant: 'outlined'}}}
                                        />
                                    </LocalizationProvider>
                                    <DurationPicker
                                        style={{ width: "100%", fontSize: 20 }}
                                        className='my-2 mx-1'
                                        value={this.state.editDataForm.duration || ''}
                                        onChange={(validDuration) => { this.setState({ ...this.state, editDataForm: { ...this.state.editDataForm, duration: validDuration } }) }}
                                    />
                                </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ editInProg: false, edit: !this.state.edit }) }}>
                            Cancel
                        </Button>
                        <Button disabled={this.state.editInProg} onClick={() => this.validateEditForm()} style={{ height: '2.5rem' }} variant="primary">{this.state.editInProg ?
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : 'Save'}</Button>
                    </Modal.Footer>
                </Modal>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={this.state.deleteTaskSuccess} autoHideDuration={6000} onClose={() => this.setState({ deleteTaskSuccess: false })}>
                    <MuiAlert onClose={() => this.setState({ deleteTaskSuccess: false })} severity="success">
                        Task Deleted!
                    </MuiAlert>
                </Snackbar>
            </div>)
        );
    }
}