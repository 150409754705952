import React from "react";
import { createRef, useImperativeHandle } from "react";
import axios from "axios";
import {APIURL} from '../../config';
import moment from "moment";
// import TimesheetReport from "./TimesheetReport";
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import HomeIcon from '@mui/icons-material/Home';
import ClearIcon from '@mui/icons-material/Clear';
//import { Icon } from "@mui/material";
import { jsonToCSV } from 'react-papaparse';
import { CSVDownloader } from 'react-papaparse';
import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

const columns = [
    
    { field: 'cus_id', headerName: 'DDD ID', flex: 0.7 },
    // { field: 'CustomerName', headerName: 'Customer Name', width: 160 },
    { field: 'ProjectName', headerName: 'Project Name', flex: 1.9 },
    { field: 'ProjectManager', headerName: 'Project Manager', flex: 1.0 },
    { 
        field: 'ProjectEndDate',
        headerName: 'Project End Date',
        flex: 0.9,
        valueFormatter: (value, row, column, apiRef) => (
            value.format('MM-DD-YYYY')
        )},
    //{ field: 'TaskType', headerName: 'Task Type', width: 130 },
    {
        field: 'ThirdLastVisit',
        headerName: moment().subtract(3, "month").format('MMM')+" Visit",
        flex: 0.8,
        renderCell : (params) =>{ 
            // console.log(params)
            // console.log(params.row.ThirdLastVisit)
            let type = typeof params.row.ThirdLastVisit !== "string" ? params.row.ThirdLastVisit.tas_type : "";
            return(
            <div  className="d-flex flex-row m-1 mt-2" >
            { params.row.ThirdLastVisit.tas_start_date}
            { params.row.ThirdLastVisit.tas_type==="Phone Call" && <PhoneEnabledIcon fontSize="small" style = {{color : "#3FA7D6"}} />}
            { type.endsWith("Visit") && <DirectionsWalkIcon fontSize="small" style = {{color : "#3FA7D6"}} />} 
            {/* { params.row.ThirdLastVisit.tas_type==="Home Visit" && <HomeIcon fontSize="small" style = {{color : "#3FA7D6"}} />}  */}
            { params.row.ThirdLastVisit.tas_type!=="Phone Call" && !type.endsWith("Visit") && <ClearIcon  style = {{color : "red", margin : 10}} />} 
            {/* { params.row.ThirdLastVisit.tas_type!=="Phone Call" && params.row.ThirdLastVisit.tas_type!=="Visit" && params.row.ThirdLastVisit.tas_type!=="Home Visit"  && <ClearIcon  style = {{color : "red", margin : 10}} />} */}
            </ div>   
        )}
    },
    {
        field: 'SecondLastVisit',
        headerName: moment().subtract(2, "month").format('MMM')+" Visit",
        flex: 0.8,
        renderCell : (params) =>{ 
            let type = typeof params.row.SecondLastVisit !== "string" ? params.row.SecondLastVisit.tas_type : "";
            return(
            <div  className="d-flex flex-row m-1 mt-2" >
            { params.row.SecondLastVisit.tas_start_date}
            { params.row.SecondLastVisit.tas_type==="Phone Call" && <PhoneEnabledIcon fontSize="small" style = {{color : "#3FA7D6"}} />}
            { type.endsWith("Visit") && <DirectionsWalkIcon fontSize="small" style = {{color : "#3FA7D6"}} />} 
            {/* { params.row.SecondLastVisit.tas_type==="Home Visit" && <HomeIcon fontSize="small" style = {{color : "#3FA7D6"}} />}   */}
            { params.row.SecondLastVisit.tas_type!=="Phone Call" && !type.endsWith("Visit") && <ClearIcon  style = {{color : "red", margin : 10}} />}
            {/* { params.row.SecondLastVisit.tas_type!=="Phone Call" && params.row.SecondLastVisit.tas_type!=="Visit" && params.row.SecondLastVisit.tas_type!=="Home Visit"  && <ClearIcon  style = {{color : "red", margin : 10}} />} */}
            </ div>   
        )}
    },
    {
        field: 'LastVisit',
        headerName:  moment().subtract(1, "month").format('MMM')+" Visit",
        flex: 0.8,
        renderCell : (params) =>{ 
            let type = typeof params.row.LastVisit !== "string" ? params.row.LastVisit.tas_type : "";
            return(
            <div  className="d-flex flex-row m-1 mt-2" >
            { params.row.LastVisit.tas_start_date}
            { params.row.LastVisit.tas_type==="Phone Call" && <PhoneEnabledIcon fontSize="small" style = {{color : "#3FA7D6"}} />}
            { type.endsWith("Visit") && <DirectionsWalkIcon fontSize="small" style = {{color : "#3FA7D6"}} />} 
            {/* { params.row.LastVisit.tas_type==="Home Visit" && <HomeIcon fontSize="small" style = {{color : "#3FA7D6"}} />}  */}
            { params.row.LastVisit.tas_type!=="Phone Call" && !type.endsWith("Visit") && <ClearIcon  style = {{color : "red", margin : 10}} />} 
            {/* { params.row.LastVisit.tas_type!=="Phone Call" && params.row.LastVisit.tas_type!=="Visit" && params.row.LastVisit.tas_type!=="Home Visit"  && <ClearIcon  style = {{color : "red", margin : 10}} />} */}
            </ div>   
        )}
    },
    {
        field: 'NextVisit',
        headerName: moment().format('MMM')+" Visit",
        flex: 0.8,
        renderCell : (params) =>{ 
            let type = typeof params.row.NextVisit !== "string" ? params.row.NextVisit.tas_type : "";
            return(
            <div  className="d-flex flex-row m-1 mt-2" >
            { params.row.NextVisit.tas_start_date}
            { params.row.NextVisit.tas_type==="Phone Call" && <PhoneEnabledIcon fontSize="small" style = {{color : "#3FA7D6"}} />}
            { type.endsWith("Visit") && <DirectionsWalkIcon fontSize="small" style = {{color : "#3FA7D6"}} />} 
            {/* { params.row.NextVisit.tas_type==="Home Visit" && <HomeIcon fontSize="small" style = {{color : "#3FA7D6"}} />}   */}
            { params.row.NextVisit.tas_type!=="Phone Call" && !type.endsWith("Visit") && <ClearIcon  style = {{color : "red", margin : 10}} />}
            {/* { params.row.NextVisit.tas_type!=="Phone Call" && params.row.NextVisit.tas_type!=="Visit" && params.row.NextVisit.tas_type!=="Home Visit"  && <ClearIcon  style = {{color : "red", margin : 10}} />} */}
            </ div>   
        )}
    },
    {
        field: 'SecondNextVisit',
        headerName: moment().add(1, "month").format('MMM')+" Visit",
        flex: 0.8,
        renderCell : (params) =>{ 
            let type = typeof params.row.SecondNextVisit !== "string" ? params.row.SecondNextVisit.tas_type : "";
            return(
            <div  className="d-flex flex-row m-1 mt-2" >
            { params.row.SecondNextVisit.tas_start_date}
            { params.row.SecondNextVisit.tas_type==="Phone Call" && <PhoneEnabledIcon fontSize="small" style = {{color : "#3FA7D6"}} />}
            { type.endsWith("Visit") && <DirectionsWalkIcon fontSize="small" style = {{color : "#3FA7D6"}} />} 
            {/* { params.row.SecondNextVisit.tas_type==="Home Visit" && <HomeIcon fontSize="small" style = {{color : "#3FA7D6"}} />}  */}
            { params.row.SecondNextVisit.tas_type!=="Phone Call" && !type.endsWith("Visit") && <ClearIcon  style = {{color : "red", margin : 10}} />} 
            {/* { params.row.SecondNextVisit.tas_type!=="Phone Call" && params.row.SecondNextVisit.tas_type!=="Visit" && params.row.SecondNextVisit.tas_type!=="Home Visit"  && <ClearIcon  style = {{color : "red", margin : 10}} />} */}
            </ div>   
        )}
    },
    {
        field: 'ThirdNextVisit',
        headerName: moment().add(2, "month").format('MMM')+" Visit",
        flex: 0.8,
        renderCell : (params) =>{ 
            let type = typeof params.row.ThirdNextVisit !== "string" ? params.row.ThirdNextVisit.tas_type : "";
            return(
            <div  className="d-flex flex-row m-1 mt-2" >
            { params.row.ThirdNextVisit.tas_start_date}
            { params.row.ThirdNextVisit.tas_type==="Phone Call" && <PhoneEnabledIcon fontSize="small" style = {{color : "#3FA7D6"}} />}
            { type.endsWith("Visit") && <DirectionsWalkIcon fontSize="small" style = {{color : "#3FA7D6"}} />} 
            {/* { params.row.ThirdNextVisit.tas_type==="Home Visit" && <HomeIcon fontSize="small" style = {{color : "#3FA7D6"}} />}   */}
            { params.row.ThirdNextVisit.tas_type!=="Phone Call" && !type.endsWith("Visit") && <ClearIcon  style = {{color : "red", margin : 10}} />}
            {/* { params.row.ThirdNextVisit.tas_type!=="Phone Call" && params.row.ThirdNextVisit.tas_type!=="Visit" && params.row.ThirdNextVisit.tas_type!=="Home Visit"  && <ClearIcon  style = {{color : "red", margin : 10}} />} */}
            </ div>   
        )}
    },
    
];
   
class Report extends React.Component{
    constructor(props) {
        super(props);
        this.apiRef = createRef();
    }
    state = {loading:true, TaskReports: null, gridId: 46, loggedToSegment: false}

    logToSegment = async () => {
        if(this.state.loggedToSegment) {
          return;
        }
        let page = String(window.location.href).split("/").pop()
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page,this.props.user.emp_id)
        analytics.page(page + " : Caseload Report", {
            title:page + " : Caseload Report",
            path:page + " : Caseload Report",
        }, {
            userId:String(this.props.user.emp_id),
        })
        console.log("logged to segment successfully")
        this.setState({ loggedToSegment : true });
    }

    async componentDidMount(){
        
        let task_list = (this.props.user.type==='Admin' || this.props.user.type==='Manager')? await axios.get(APIURL+"/reports/manager",{params:{emp_id: this.props.user.emp_id}}) : await axios.get(APIURL+"/reports/employee",{params:{emp_id: this.props.user.emp_id}})
        task_list = task_list.data.filter(project => moment(project.pro_ends_on).isAfter(moment().subtract(3, "month")))

        for (let i = 0; i < task_list.length; i++) {
            task_list[i].allTasks = task_list[i].completedTasks.concat(task_list[i].activeTasks)
        }
        
        let reports = task_list.map((rep)=>{
            
            let tasks = rep
            
            tasks = {...tasks,
                thirdlastvisits: rep.completedTasks.filter((tas)=>
                ((tas.tas_type && tas.tas_type.endsWith("Visit")) || tas.tas_type === "Phone Call")
                &&  ( 
                    (moment(tas.tas_start_date).month()===(moment().subtract(3, "month").month()) && moment.max(tas.tas_start_date))
                    )),

                secondlastvisits: rep.completedTasks.filter((tas)=>
                ((tas.tas_type && tas.tas_type.endsWith("Visit")) || tas.tas_type === "Phone Call")
                &&  ( 
                    (moment(tas.tas_start_date).month()===(moment().subtract(2, "month").month()) && moment.max(tas.tas_start_date))
                    )),

                lastvisits: rep.completedTasks.filter((tas)=>
                ((tas.tas_type && tas.tas_type.endsWith("Visit")) || tas.tas_type === "Phone Call")
                &&  ( 
                    (moment(tas.tas_start_date).month()===(moment().subtract(1, "month").month()) && moment.max(tas.tas_start_date))
                    )),

                nextvisits: rep.allTasks.filter((tas)=>
                ((tas.tas_type && tas.tas_type.endsWith("Visit")) || tas.tas_type === "Phone Call")
                &&  ( 
                    (moment(tas.tas_start_date).month()===(moment().month()) && moment.max(tas.tas_start_date))
                    )),

                secondnextvisits: rep.allTasks.filter((tas)=>
                ((tas.tas_type && tas.tas_type.endsWith("Visit")) || tas.tas_type === "Phone Call")
                &&  ( 
                    (moment(tas.tas_start_date).month()===(moment().add(1, "month").month()) && moment.max(tas.tas_start_date))
                    )),

                thirdnextvisits:rep.allTasks.filter((tas)=>
                ((tas.tas_type && tas.tas_type.endsWith("Visit")) || tas.tas_type === "Phone Call")
                &&  ( 
                    (moment(tas.tas_start_date).month()===(moment().add(2, "month").month()) && moment.max(tas.tas_start_date))
                    )), 


            }
            delete tasks.completedTasks
            delete tasks.activeTasks
            delete tasks.allTasks

            
            tasks.thirdlastvisits.sort((a,b)=>b.tas_created_at - a.tas_created_at)
            tasks.secondlastvisits.sort((a,b)=>b.tas_created_at - a.tas_created_at)
            tasks.lastvisits.sort((a,b)=>b.tas_created_at - a.tas_created_at)
            tasks.nextvisits.sort((a,b)=>b.tas_created_at - a.tas_created_at)
            tasks.secondnextvisits.sort((a,b)=>b.tas_created_at - a.tas_created_at)
            tasks.thirdnextvisits.sort((a,b)=>b.tas_created_at - a.tas_created_at)
            return tasks
        })

        this.logToSegment();

        this.setState({TaskReports:reports, loading:false })
        //console.log(reports)
        //console.log(moment().subtract(3, "month").month())
        //console.log(this.state.TaskReports)
    }


  render(){
    
    let rows = !this.state.TaskReports?[]:this.state.TaskReports.map((Reportsarray,index)=>{
        let row = { 
            id: index,
            cus_id: (Reportsarray.cus_id!==null?Reportsarray.cus_id: "-"), 
            //CustomerName: Reportsarray.cus_name,
            ProjectName: Reportsarray.pro_title,
            ProjectManager: (Reportsarray.ManagerFirstName ? Reportsarray.ManagerFirstName : '') + ' ' + (Reportsarray.ManagerLastName ? Reportsarray.ManagerLastName : ''),
            ProjectEndDate: moment(Reportsarray.pro_ends_on).utc(),
            ThirdLastVisit:(!!Reportsarray.thirdlastvisits[0]?Reportsarray.thirdlastvisits[0]:"-"),
            SecondLastVisit:(!!Reportsarray.secondlastvisits[0]?Reportsarray.secondlastvisits[0]:"-"),
            LastVisit:(!!Reportsarray.lastvisits[0]?Reportsarray.lastvisits[0]:"-"),
            NextVisit:(!!Reportsarray.nextvisits[0]?Reportsarray.nextvisits[0]:"-"),
            SecondNextVisit:(!!Reportsarray.secondnextvisits[0]?Reportsarray.secondnextvisits[0]:"-"),
            ThirdNextVisit:(!!Reportsarray.thirdnextvisits[0]?Reportsarray.thirdnextvisits[0]:"-"),
            //icons: 
            //==="Phone Call"?<PhoneEnabledIcon />:Reportsarray.thirdnextvisits[0].tas_type==="Visit"? <DirectionsWalkIcon />: <HomeIcon />
            }
            return row
        } 
    )

    /*rows = rows.filter((row) => {
        if(moment(row.ProjectEndDate).isAfter(moment().subtract(90, 'days'))){
            return true
        }
        return false
    })*/

    /*rows = rows.sort((a,b) => {
        if(moment(a.ProjectEndDate).isAfter(moment(b.ProjectEndDate))){
            return 1;
        }
        return -1;
    })*/

    let row_csv = rows.map((rep2)=>{
        
        rep2[`DDD ID`] = rep2.cus_id
        //rep2[`Customer Name`] = rep2.CustomerName
        rep2[`Project Name`] = rep2.ProjectName
        rep2[`Project End Date`] = rep2.ProjectEndDate
        //rep2['Task type'] = rep2.
            rep2={...rep2, 
            [`${moment().subtract(3, "month").format('MMM')} Visit`]: rep2.ThirdLastVisit === '-'? rep2.ThirdLastVisit:rep2.ThirdLastVisit.tas_start_date+"("+rep2.ThirdLastVisit.tas_type+")",
            [`${moment().subtract(2, "month").format('MMM')} Visit`]: rep2.SecondLastVisit === '-'? rep2.SecondLastVisit:rep2.SecondLastVisit.tas_start_date+"("+rep2.SecondLastVisit.tas_type+")",
            [`${moment().subtract(1, "month").format('MMM')} Visit`]: rep2.LastVisit === '-'? rep2.LastVisit:rep2.LastVisit.tas_start_date+"("+rep2.LastVisit.tas_type+")",
            [`${moment().format('MMM')} Visit`]: rep2.NextVisit === '-'? rep2.NextVisit:rep2.NextVisit.tas_start_date+"("+rep2.NextVisit.tas_type+")",
            [`${moment().add(1, "month").format('MMM')} Visit`]: rep2.SecondNextVisit === '-'? rep2.SecondNextVisit:rep2.SecondNextVisit.tas_start_date+"("+rep2.SecondNextVisit.tas_type+")",
            [`${moment().add(2, "month").format('MMM')} Visit`]: rep2.ThirdNextVisit === '-'? rep2.ThirdNextVisit:rep2.ThirdNextVisit.tas_start_date+"("+rep2.ThirdNextVisit.tas_type+")"
        }  
            
            delete rep2.id
            delete rep2.cus_id
            //delete rep2.CustomerName
            delete rep2.ProjectName
            delete rep2.ProjectEndDate
            delete rep2.ThirdLastVisit
            delete rep2.SecondLastVisit
            delete rep2.LastVisit
            delete rep2.NextVisit
            delete rep2.SecondNextVisit
            delete rep2.ThirdNextVisit
            return rep2      
    })
    const csv = jsonToCSV(
        row_csv
      )
      //console.log(row_csv,'json to csv object',csv)
    return(
        <div>
            {this.state.loading && !this.state.TaskReports?(
            <div className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
            </div></div>):(
                <div style={{ height: '80vh', width: '100%' }}> 
                {this.state.TaskReports.length !== 0 &&  <div style={{display: 'flex' , justifyContent: 'flex-end'}}>
               <CSVDownloader data={csv} filename={'task report'} bom={true} style={{float: 'right'}} >
                    <button type="button" className="btn btn-sm btn-outline-info m-1">Download report </button>
                </CSVDownloader>
                </div>}
                {this.state.TaskReports.length === 0 && 
                 <div className='d-flex flex-column justify-content-center align-items-center' style={{height: '300px',margin: 5}}>
                    <img src='./taskmanagement-01.png' alt='taskmanagement-01' style={{width: '10rem', height: '10rem'}}/>
                    <h5 style={{fontFamily: 'Lato',fontSize: 18,marginTop: 48,  marginBottom: 30}}>You or your employees dont have any Tasks yet </h5>
                    <p style={{fontFamily: 'Lato',fontSize: 14}} className='text-muted'> Add new tasks and Check-in/out of tasks for graphical analysis of completed , ongoing and upcoming tasks.</p>
                </div>
                }
                {this.state.TaskReports.length !== 0 && 
                    <Box sx={{ width: 'calc(100vw - 250px)', height: "calc(100vh - 150px)" }}>
                        <ControlledDataGrid gridId={this.state.gridId} user={this.props.user} initialState={{ sorting: { sortModel: [{ field: "ProjectName", sort: "asc" }] } }} apiRef={this.apiRef} spacing={0} rows={rows} columns={columns} pageSize={!this.props.pagesize?15:this.props.pagesize} /> 
                    </Box>}
            </div>
            )}
      </div>
    )
  }
}

const Reports = React.forwardRef((props, ref) => {
    const apiRef = useGridApiRef();
    useImperativeHandle(ref, () => apiRef.current);
  
    return <Report {...props} apiRef={apiRef} />;
});
  
export default Reports

