import * as React from "react";
import { useState } from "react";
import moment from "moment";
import { Button, Box } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';

export default function TasksReport(props) {
    const [gridId, setGridId] = useState(42);
    const apiRef = useGridApiRef();

    let rows = props?.data.map((data, idx) => {
        let cusName = data.cus_name.split(/\s+/)
        let taskDuration = moment(data.empLocdate).isValid() && moment(data.checkoutEmpDate).isValid()
            ? (
                moment(data.checkoutEmpDate).diff(
                    moment(data.empLocdate),
                    "m"
                ) / 60
            ).toFixed(2)
            : 0
        let taskData = {
            id: idx,
            tas_id: data.tas_id,
            tas_start_date: data.empLocdate,
            emp_id: data.emp_id,
            emp_last_name: data.emp_last_name,
            emp_first_name: data.emp_first_name,
            emp_local_id: data.emp_local_id,
            cus_id: data.cus_id,
            cus_last_name: cusName[1],
            cus_first_name: cusName[0],
            cus_local_id: data.cus_local_id,
            tas_type: data.tas_type,
            tas_duration: taskDuration,
            compensation_rate: data.compensation_rate
        };
        return taskData;
    });

    const columns = [
        { field: "id", headerName: "ID", hide: true },
        { field: "tas_id", headerName: "Task ID", hide: true },
        {
            field: "tas_start_date",
            headerName: "Task Date",
            width: 100,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('M/D/YYYY') : params.value}</div>
                )
            },
            valueFormatter: (value, row, column, apiRef) => moment(value).isValid() ? moment(value).utc().format('M/D/YYYY') : value,
        },
        { field: "emp_id", headerName: "Employee ID", hide: true },
        { field: "emp_last_name", headerName: "Employee Last Name", width: 150 },
        { field: "emp_first_name", headerName: "Employee First Name", width: 150 },
        { field: "emp_local_id", headerName: "Employee Local ID", width: 130 },
        { field: "cus_id", headerName: "Customer ID", hide: true },
        { field: "cus_last_name", headerName: "Customer Last Name", width: 150 },
        { field: "cus_first_name", headerName: "Customer First Name", width: 150 },
        { field: "cus_local_id", headerName: "Customer Local ID", width: 130 },
        { field: "tas_type", headerName: "Task Type", width: 200 },
        { field: "tas_duration", headerName: "Task Duration (Hrs)", width: 140 },
        { field: "compensation_rate", headerName: "Rate", width: 100 },
        { field: "", headerName: "", hide: true },
    ]

    const handleExportCsv = () => {
        const csvData = (['Date', 'Last Name', 'First Name', 'Employee Number', 'Project', '', '', 'Account Code', '', '', 'Hours', 'Cost Rate', 'ot h', 'ot p', 'tot', 'pid']).join(',') + '\n';
        const csvRows = rows.map((row) => [moment(row['tas_start_date']).utc().format('M/D/YYYY'), row['emp_last_name'], row['emp_first_name'], row['emp_local_id'], row['cus_last_name'], row['cus_first_name'], row['cus_local_id'], row['tas_type'], row[''], row[''], row['tas_duration'], row['compensation_rate'], row[''], row[''], row[''], row['']].join(',')).join('\n');
        const csvString = csvData + csvRows;
        const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const csvUrl = URL.createObjectURL(csvBlob);
        const tempLink = document.createElement('a');
        tempLink.href = csvUrl;
        tempLink.setAttribute('download', 'FW_PayrollDataFeed.csv');
        tempLink.click();
    };

    return (
        <div style={{ justifyContent: 'space-between' }}>
            <div style={{ marginTop: '-55px', float: 'right' }}>
                <Button variant="contained" onClick={handleExportCsv} endIcon={<DownloadIcon />}>Export CSV</Button>
            </div>
            {rows.length === 0 ?
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: "500px", marginTop: 10 }}
                >
                    <img
                        src="./nosearchresult.png"
                        alt="nosearchresult"
                        style={{ width: "25rem", height: "15rem", margin: 10 }}
                    />
                    <h5
                        style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
                        className="text-center"
                    >
                        There is currently no data available{" "}
                    </h5>
                </div>
                :
                <div style={{ margin: "auto", height: '75vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '100%', height: "calc(100vh - 290px)" }}>
                        <ControlledDataGrid
                            gridId={gridId}
                            user={props.user}
                            initialState={{ sorting: { sortModel: [{ field: "tas_start_date", sort: "desc" }] } }}
                            apiRef={apiRef}
                            getRowId={(row) => row.id}
                            rows={rows}
                            columns={columns}
                            checkboxSelection={false}
                            disableRowSelectionOnClick
                            rowsPerPageOptions={[]}
                        />
                    </Box>
                </div>
            }
        </div>
    );
}