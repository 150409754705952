import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ReactBingmaps } from 'react-bingmaps';
import {BINGAPIKEY} from '../../../../config'
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import RecurrenceModal from './RecurrenceModal';
import './DateLocation.css'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';

function DateLocation(props) {
    const CssTextField = withStyles({
        root: {
          '& label.Mui-focused': {
            color: '#3FA7D6',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#3FA7D6',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'lightblue',
            },
            '&:hover fieldset': {
              borderColor: '#3FA7D6',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#3FA7D6',
            },
          },
        },
    })(TextField);
    
    let locList = props.locList.map((loc, indx) => {return {value:loc.id,title: loc.location_addr1 + " " + loc.location_addr2 + ", " + loc.location_city + ", " + loc.location_state + ", " + loc.location_zip}})

    console.log(props.formVals.tLoc);
    const [showModal, setShowModal] = useState(false);
    const [recText, setRecText] = useState("Make Recurrence");
    const [saved, setSaved] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const [num, setNum] = useState(1);
    const [option, setOption] = useState("week");
    const [days, setDays] = useState(["Mon"]);
    const [endOnDate, setEndOnDate] = useState(moment());
    const [disabled, setDisabled] = useState(false);
    const [numberOfRepeats, setNumberOfRepeats] = useState(1);
    const [numLocs, setNumLocs] = useState(props.formVals.tLoc.length > 0 ? props.formVals.tLoc.length : 1);
    const [tLoc, setTLoc] = useState(props.formVals.tLoc.length > 0 ? props.formVals.tLoc : []);

    useEffect(() => {
        setTLoc(props.formVals.tLoc)
    }, [props.formVals.tLoc]);
    
    const handleSubmit = () => {
        let recDets = {};
        let recText = "";

        recDets.num = num;
        recDets.option = option;
        if(option === "day") {
            recText = "Daily";
        } else if(option === "month") {
            recText = "Monthly";
        } else if(option === "year") {
            recText = "Yearly";
        } else if(option === "week") {
            recDets.days = days;   
            recText = "Weekly on ";
            days.forEach((day, i) => {
                if(i !== 0) {
                    recText += " and "
                }
                recText += day;
            })
        } else if(option !== "week") {
            recDets.days = [];
        }
        
        setRecText(recText);

        if(!disabled) {
            recDets.endsOn = moment(endOnDate).format("YYYY-MM-DD");
            recDets.numberOfRepeats = 0;
        } else {
            recDets.endsOn = null;
            recDets.numberOfRepeats = numberOfRepeats;
        }
 
        setSaved(true);
        props.handleRecurrenceSubmit(recDets);
        handleClose();
    }

    const handleCancel = () => {
        handleClose();
        if(!saved) {
            resetRecDets();
        }
    }

    const resetRecDets =() => {
        setSaved(false);
        props.handleRecurrenceCancel();
        setRecText("Make Recurrence");
        setNum(1);
        setOption("week");
        setDays(["Mon"]);
        setEndOnDate(moment());
        setDisabled(false);
        setNumberOfRepeats(0);
    }

    const handleTrashClick = () => {
        setSaved(false);
        resetRecDets();
    }

    const handleCheckBox = (e) => {
        let name = e.target.name;

        if(e.target.checked === true) {
            setDays([...days, name]);
        } 
        if(e.target.checked === false) {
            if(days.length <= 1) {
                e.target.checked = true;
            } else {
                setDays(days.filter((day) => { return day !== name }));
            }
        }
    }

    return (
        <div className='mx-3'>
            <div>
                {console.log("full reload")}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container justifyContent="space-between">
                    <DateTimePicker
                    label="TASK START TIME"
                    className={props.edit?`mx-1 my-3`:`mx-1 my-3`}
                    value={dayjs(props.formVals.tStrtDate).isValid() ? dayjs(props.formVals.tStrtDate) : null}
                    onChange={(d) => {props.handleProjLoc(dayjs(d).format('YYYY-MM-DDTHH:mm:ss'),'taskStartTime')}}
                    slotProps={{textField: {variant: 'outlined'}}}
                    />
                    <DateTimePicker
                    label="TASK END TIME"
                    className={props.edit?`mx-1 my-3`:`mx-1 my-3`}
                    value={dayjs(props.formVals.tEndDate).isValid() ? dayjs(props.formVals.tEndDate) : null}
                    onChange={(d) => {props.handleProjLoc(dayjs(d).format('YYYY-MM-DDTHH:mm:ss'),'taskEndTime')}}
                    slotProps={{textField: {variant: 'outlined'}}}
                    />
                        
                        {/*<DatePicker
                        disableOpenPicker
                        className={props.edit?'w-50':'mr-2'}
                        margin="normal"
                        // variant="inline"
                        id="tStrtDate"
                        label="TASK START DATE"
                        format="MM/dd/yyyy"
                        value={moment(props.formVals.tStrtDate).format()}
                        onChange={(d) => {props.handleProjLoc(d,'tStrtDate')}}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        renderInput={(props) => (
                            <CssTextField
                                {...props}
                                className='taskform-datelocation-datepicker'
                            />
                        )}
                        />
                        {/* <CssTextField
                            id="tStartTime"
                            label="TASK START TIME"
                            type="time"
                            // defaultValue="07:30"
                            value={moment(props.formVals.tStartTime).format('HH:mm:ss')}
                            className={props.edit?'w-50':'mr-2 my-2'}
                            onChange={(d) => props.handleProjLoc(d,'tStartTime')}
                        /> 
                        <TimePicker
                        disableOpenPicker
                        className={props.edit?'w-50':'mr-2'}
                        id="tStartTime"
                        label="TASK START TIME"
                        value={moment(props.formVals.tStartTime).format()}
                        onChange={(d) => props.handleProjLoc(d,'tStartTime')}
                        renderInput={(props) => (
                            <CssTextField
                                {...props}
                                className='taskform-datelocation-datepicker'
                            />
                        )}
                        />*/}
                    </Grid>
                </LocalizationProvider>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <p style={{marginBottom: '5px', marginTop: '5px' }}>Task Start Time</p>
                            <input type="datetime-local"
                                value={moment(props.formVals.tStrtDate).format('YYYY-MM-DDTHH:mm')}
                                onChange={(d) => { props.handleProjLoc(d.target.value,'taskStartTime') }}
                                style={{
                                    backgroundColor: 'transparent',
                                    border: '1px solid #afdae5',
                                    borderRadius: '4px',
                                    height: '57px',
                                    padding: '5px',
                                    width: '90%',
                                    marginBottom: '15px',
                                }}
                            />
                        </div>
                        <div>
                            <p style={{marginBottom: '5px', marginTop: '5px' }}>Task End Time</p>
                            <input type="datetime-local"
                                value={moment(props.formVals.tEndDate).format('YYYY-MM-DDTHH:mm')}
                                onChange={(d) => { props.handleProjLoc(d.target.value,'taskEndTime') }}
                                style={{
                                    backgroundColor: 'transparent',
                                    border: '1px solid #afdae5',
                                    borderRadius: '4px',
                                    height: '57px',
                                    padding: '5px',
                                    width: '90%'
                                }}
                            />
                        </div> */}
                        {/*
                        <CssTextField
                            margin="normal"
                            label="TASK START TIME"
                            type="datetime-local"
                            error={false}
                            value={moment(props.formVals.tStrtDate).format('YYYY-MM-DDTHH:mm')}
                            onChange={(d) => { props.handleProjLoc(d.target.value,'taskStartTime') }}
                            className={'taskform-datelocation-datepicker'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <CssTextField
                            margin="normal"
                            label="TASK END TIME"
                            type="datetime-local"
                            error={false}
                            value={moment(props.formVals.tEndDate).format('YYYY-MM-DDTHH:mm')}
                            onChange={(d) => { props.handleProjLoc(d.target.value,'taskEndTime') }}
                            className={'taskform-datelocation-datepicker'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        */}
                        {/*<DatePicker
                        disableOpenPicker
                        className={props.edit?'w-50':'mr-2'}
                        margin="normal"
                        id="tEndDate"
                        label="TASK END DATE"
                        format="MM/dd/yyyy"
                        value={moment(props.formVals.tEndDate).format()}
                        onChange={(d) => { props.handleProjLoc(d,'tEndDate')}}
                        renderInput={(props) => (
                            <CssTextField
                                {...props}
                                className='taskform-datelocation-datepicker'
                            />
                        )}
                        />
                        <TimePicker
                        disableOpenPicker
                        className={props.edit?'w-50':'mr-2'}
                        margin="normal"
                        id="tEndTime"
                        label="TASK END TIME"
                        value={moment(props.formVals.tEndTime).add(1,'minutes').format()}
                        onChange={(d) => { props.handleProjLoc(d,'tEndTime')}}
                        renderInput={(props) => (
                            <CssTextField
                                {...props}
                                className='taskform-datelocation-datepicker'
                            />
                        )}
                        />*/}
                    {/* </div> */}
                {/* </LocalizationProvider> */}
            </div>
            {/* <div className='row'>
            <div className='col-6'>
            <Form.Group controlId="tStrtDate">
                <Form.Label>START DATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.tStrtDate} type="date" onChange={(e) => props.handleDateLocDetsChange(e)} required />
            </Form.Group>

            <Form.Group controlId="tEndDate">
                <Form.Label>END DATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.tEndDate} onChange={(e) => props.handleDateLocDetsChange(e)} type="date" />
            </Form.Group>
            </div>
            <div className='col-6'>
            <div className="d-flex flex-column justify-content-center">
            <Form.Label>TIME <span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span></Form.Label>
            <div className="d-flex flex-row">
                <div className="form-group row">
                    <div>
                        <input style={{ width: "72px" }} type="number" className="form-control" id="tStartTime" min="1" max="12" value={props.timeAlloc[0]} onChange={(e) => props.handleDateLocDetsChange(e)} />
                    </div>
                    <div className="btn-group-vertical">
                        <button className="fas fa-chevron-up" style={{ margin: 0, fontSize: "10px", backgroundColor: "white", border: 0, outline: "none" }} type="button" id="timeStrtAdd"
                            onClick={(e) => props.handleTimer(e)}>
                        </button>
                        <button className="fas fa-chevron-down" style={{ margin: 0, fontSize: "10px", backgroundColor: "white", border: 0, outline: "none" }} type="button" id="timeStrtMinus"
                            onClick={(e) => props.handleTimer(e)}>
                        </button>
                    </div>
                </div>
                <div className='ml-3'>
                    <input className="form-check-input d-none timeStrtIndicator" name="timeStrtIndicator" type="radio" id="strtRadio1" value="AM"
                        onChange={(e) => props.handleTimer(e)}
                        checked={props.timeIndicator[0] === "AM"}
                    />
                    <label style={{ padding: 5, borderRadius: "0.3rem" }} className="form-check-label" htmlFor="strtRadio1">AM</label>
                    <input className="form-check-input d-none timeStrtIndicator" name="timeStrtIndicator" type="radio" id="strtRadio2" value="PM"
                        onChange={(e) => props.handleTimer(e)}
                        checked={props.timeIndicator[0] === "PM"}
                    />
                    <label style={{ padding: 5, borderRadius: "0.3rem" }} className="form-check-label" htmlFor="strtRadio2">PM</label>
                </div>
                </div>
                <Form.Label style={{ paddingBlock: 15, paddingRight: 30, marginBottom: -10}}>TIME <span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span></Form.Label>
                <div className="d-flex flex-row">
                <div className="form-group row">
                    <div>
                        <input style={{ width: "72px" }} type="number" className="form-control" id="tEndTime" min="1" max="12" value={props.timeAlloc[1]} onChange={(e) => props.handleDateLocDetsChange(e)} />
                    </div>
                    <div className="btn-group-vertical">
                        <button className="fas fa-chevron-up" style={{ margin: 0, fontSize: "10px", backgroundColor: "white", border: 0, outline: "none" }} type="button" id="timeEndAdd"
                            onClick={(e) => props.handleTimer(e)}>
                        </button>
                        <button className="fas fa-chevron-down" style={{ margin: 0, fontSize: "10px", backgroundColor: "white", border: 0, outline: "none" }} type="button" id="timeEndMinus"
                            onClick={(e) => props.handleTimer(e)}>
                        </button>
                    </div>
                </div>
                <div className='ml-3'>
                    <input className="d-none timeEndIndicator" name="timeEndIndicator" type="radio" id="endRadio1" value="AM"
                        onChange={(e) => props.handleTimer(e)}
                        checked={props.timeIndicator[1] === "AM"}
                    />
                    <label style={{ padding: 5, borderRadius: "0.3rem" }} className="form-check-label" htmlFor="endRadio1">AM</label>
                    <input className="d-none timeEndIndicator" name="timeEndIndicator" type="radio" id="endRadio2"
                        onChange={(e) => props.handleTimer(e)}
                        checked={props.timeIndicator[1] === "PM"}
                        value="PM" />
                    <label style={{ padding: 5, borderRadius: "0.3rem" }} className="form-check-label" htmlFor="endRadio2">PM</label>
                </div>
                </div>
                </div>
                </div>
            </div> */}

            {/* <Form.Group controlId="tLoc">
                <Form.Label>LOCATION</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.tLoc} as="select" onChange={props.handleMapsPin} required>
                    <option value="">Select Location</option>
                    {locList}
                </Form.Control>
            </Form.Group> */}
            {!props.edit &&
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }} className="mb-3">
                        <div style={{ display: 'flex', alignItems: 'center', width: "100%", marginRight: "1rem" }}>
                            <IconButton 
                                variant="link" 
                                style={{color:"#3FA7D6"}}
                                onClick={(e) => {
                                    e.target.style.textDecoration = "none";
                                    handleShow();
                                }}
                                onMouseOver={(e) => {document.querySelector("#make-recur").style.textDecoration = "underline";}}
                                onMouseLeave={(e) => {document.querySelector("#make-recur").style.textDecoration = "none";}}>
                                    <ReplayIcon/>
                            </IconButton>
                            <Typography style={{color:"#3FA7D6"}} id="make-recur">{recText}</Typography>
                        </div>
                        <IconButton
                            hidden={!saved}
                            onClick={handleTrashClick}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                    <RecurrenceModal 
                        showModal={showModal}
                        project={props.project}
                        setRecText={setRecText}
                        setNum={setNum} setOption={setOption} setDays={setDays} setDisabled={setDisabled}
                        setEndOnDate={setEndOnDate} setNumberOfRepeats={setNumberOfRepeats}
                        num={num} option={option} days={days} endOnDate={endOnDate} 
                        numberOfRepeats={numberOfRepeats} disabled={disabled}
                        handleSubmit={handleSubmit} handleCancel={handleCancel} handleCheckBox={handleCheckBox}
                        handleClose={handleClose}
                        handleRecurrenceSubmit={props.handleRecurrenceSubmit}
                        handleRecurrenceCancel={props.handleRecurrenceCancel}>
                    </RecurrenceModal>
                </>
            }
            <div>
                { Array(numLocs).fill(0).map((_, i) => {
                    return <div style={{display: 'flex', alignItems: 'center' }} className={`mb-3`}>
                       <Autocomplete
                            id="tLoc"
                            key={i}
                            sx={{ width: '100%', marginRight: '1rem' }}
                            value={tLoc[i]}
                            disableClearable
                            onChange={(e, newValue)=> {
                                let newTLoc = [...tLoc]
                                newTLoc[i] = newValue
                                props.handleMapsPin({ id: 'tLoc', value: newTLoc })
                                console.log(tLoc, newValue)
                                return
                            }}
                            onSelect={(e, newValue)=> {
                                console.log(tLoc)
                            }}
                            // options that filter out already selected locations by id
                            options={locList.filter((loc)=> !tLoc.map((loc)=> loc?.value).includes(loc?.value))}
                            getOptionLabel={(option) => option.title !== undefined ? option.title : option}
                            // style={{ width: 450 }}
                            renderInput={(params) => <CssTextField {...params} label="LOCATION *" variant="outlined" />}
                        /> 
                        <IconButton onClick={()=>{
                                setNumLocs(Math.max(numLocs-1, 1));
                                props.handleMapsPin({ id: 'tLoc', value: tLoc.filter((loc, indx)=> indx !== i) })
                            }} disabled={numLocs <= 1}>
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                 })}
                <div style={{display: 'flex', alignItems: 'center' }} className="mb-3">
                    <IconButton sx={{ color: '#3FA7D6' }} onClick={()=>setNumLocs(Math.min(numLocs+1, 3))} disabled={numLocs >= 3}>
                        <AddIcon/>
                    </IconButton>
                    <Typography sx={{ color: '#3FA7D6' }}>Add Location</Typography>
                </div>
     

            </div>
            {(props.mapRender === "task" || props.edit) &&
                <div className="mb-2" style={{ width: '100%', height: "250px" }}>
                    <ReactBingmaps
                        id={props.mapRender}
                        bingmapKey={BINGAPIKEY}
                        center={props.currentPin}
                        infoboxesWithPushPins={props.mapsPushPin}
                        zoom={8}>
                    </ReactBingmaps>
                </div>}
            <Button className={`w-100`} variant="primary" block type="button" onClick={() => props.onContinue("finalDetails")} 
            // disabled={!props.error ? false : true}
            >
                Continue
            </Button>
        </div>
    );
};

export default DateLocation;