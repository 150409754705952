import React, { useState, useEffect } from "react";
import { Typography, Snackbar, IconButton, Alert, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReportCard from "./ReportCard";
import TimeAndExpensesReport from "./TimeAndExpensesReport"
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

var sha1 = require("sha1");

export default function Company(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedScreen, setSelectedScreen] = useState(null);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports", {
                params: { empID: props.user.emp_id },
            })
            .then((response) => {
                // { "primaryBusinessType": "Service Providers", "featureValue": 102 },
                // { "primaryBusinessType": "Case Management", "featureValue": 104 },
                let pBusinessType = props.user?.comp_settings.find(setting => setting.feature_value === 'primaryBusinessType') ? props.user.comp_settings.filter((setting) => setting.feature_value === "primaryBusinessType")[0].feature_id : 101;

                let data = response?.data?.reports.filter(function (ele) { return ele.report_group == "ADMINISTRATION" && (ele.report_type === 1 || ele.report_name === 'Time & Expenses Report'); }) || [];
                if (![102, 104].includes(pBusinessType)) {
                    data = data.filter((report) => report.report_name !== 'Time & Expenses Report');
                }

                setData(data);
            });

        setIsLoading(false);
    };

    const getReport = async (data, type) => {
        setSelectedScreen(null);

        const report_name = data?.report_details?.report_name || data?.report_name;
        switch (report_name) {
            case "Payroll Report":
                getPayrollReport(type);
                break;
            case "Debarment Report":
                getDebarReport(type);
                break;
            case "NJ Consolidated Debarment Report":
                getConsolidatedDebarReport(type);
                break;
            default:
                break;
        }
    }

    const getPayrollReport = async (type) => {
        axios
            .get(APIURL + "/jsreport/timesheet", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Payroll Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getDebarReport = async (type) => {
        axios
            .get(APIURL + "/jsreport/debarment", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Debar Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getConsolidatedDebarReport = async (type) => {
        axios
            .get(APIURL + "/jsreport/consolidatedDebarment", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Consolidated Debar Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    }

    const openPDF = (response, reportAction = "open") => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        if (reportAction === "download") {
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = "Report-" + new Date() + ".pdf";
            link.click();
        } else {
            window.open(fileURL, "_blank");
        }
    };

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
            empID: props.user.emp_id
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    const markAllAsFavorite = () => {
        const notFavorite = data.filter((report) => report.status == 0);
        notFavorite.forEach((report) => markFavourite(report, 1));
    }

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ margin: 10, width: '75vw' }}>
                <div>
                    <Typography
                        style={{
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                            fontFamily: "Lato",
                            marginBottom: 10,
                        }}
                    >
                        Company Reports
                    </Typography>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', width: '70vw' }}>
                    <div></div>
                    <div>
                        <Button variant="contained" onClick={markAllAsFavorite}>Mark all as "Favorite"</Button>
                    </div>
                </div>

                <div style={{ marginTop: 20, marginBottom: 10, width: '70vw' }}>
                    <div>
                        {data.map((e) => (
                            e.report_type === 1 ?
                                <ReportCard
                                    data={e}
                                    openReport={(type, data) => getReport(type, data)}
                                    markFavourite={(type, data) => markFavourite(type, data)}
                                />
                                :
                                <ReportCard
                                    data={e}
                                    openReport={(e) => { setSelectedScreen(e.report_name) }}
                                    markFavourite={(type, data) => markFavourite(type, data)}
                                />
                        ))}
                    </div>
                </div>
                <div style={{ bottom: "10px" }}>
                    <div>
                        {selectedScreen === "Time & Expenses Report" ? <TimeAndExpensesReport {...props} /> : null}
                    </div>
                </div>

                <Snackbar
                    open={successText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setSuccessText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"success"}
                        elevation={6}
                        variant="filled"
                    >
                        {successText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setSuccessText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={errorText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setErrorText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"error"}
                        elevation={6}
                        variant="filled"
                    >
                        {errorText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setErrorText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
            </div>
        )
    }
};