import React from 'react';
import { Form, Button } from 'react-bootstrap';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import ccs from 'countrycitystatejson';
import './OtherDetails.css'

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
})(TextField);

const otherDetails = (props) => {
    // let locType = props.locType.map((type,indx)=> <option key={indx}>{type}</option>)
    // let locStatus = props.locStatus.map((status,indx)=> <option key={indx}>{status}</option>)
    let locType = props.locType.map((type,indx)=> {return{value: type, title: type}})
    let locStatus = props.locStatus.map((status,indx)=> {return{value: status, title: status}})
    let code = ccs.getCountryByShort('US')
    return (
        <div>
            {/* <Form.Row>
                <Form.Group as={Col} controlId="association_date">
                    <Form.Label>ASSOCIATION DATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control type="date" value={props.formVals.association_date} onChange={(event) => props.formChangeHandler(event)} required/>
                </Form.Group>
            </Form.Row> */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="flex-start">
                    <DatePicker
                    className={'mr-2 w-75'}
                    id="association_date"
                    label="ASSOCIATION DATE"
                    value={props.formVals.association_date !== '' ? new Date(moment(props.formVals.association_date).format('YYYY-MM-DDTHH:mm:ss')) : null}
                    onChange={(d) => {props.formChangeHandler({target: {value: moment(d).format('YYYY-MM-DDTHH:mm:ss'),id : 'association_date'}})}}
                    slotProps={{textField: {variant: 'outlined'}}}
                    />
                    {/* <KeyboardTimePicker
                    className={props.edit?'w-50':'mr-2'}
                    margin="normal"
                    id="tStartTime"
                    label="TASK START TIME"
                    value={moment(props.formVals.tStrtDate).format('YYYY-MM-DDTHH:mm:ss')}
                    onChange={(d) => props.handleProjLoc(d,'tStrtDate')}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    /> */}
                </Grid>
            </LocalizationProvider>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="country_code">
                    <Form.Label>COUNTRY CODE</Form.Label>
                    <Form.Control type="text" value={props.formVals.country_code} onChange={(event) => props.formChangeHandler(event)} required/>
                </Form.Group> */}

                {/* <Form.Group as={Col} controlId="country_code">
                    <Form.Label>COUNTRY CODE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <div className="d-flex flex-row justify-content-around" style={{width: '14.5rem',height:'2.4rem',border:'1px solid #D6D6D6',borderRadius:'5px', textAlign:'center'}}>
                        <div style={{marginLeft: 0, marginTop:5}}><Emoji emoji=':flag-us:' size={26}/></div> <div style={{marginRight: 5, marginTop:5}}>United States (+1)</div>
                    </div>
                </Form.Group>
                 */}
                  <CssTextField
                  style={{width: '30%'}}
                    className={`mx-1 my-3`}
                    label="COUNTRY CODE"
                    value={`${code.emoji} ${code.name}(${code.phone})`}
                    // onChange={props.formChangeHandler} 
                    variant="outlined"
                    required
                    id="country_code"
                />
                {/* <Form.Group as={Col} controlId="phone">
                    <Form.Label>PHONE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control type="text" value={props.formVals.phone} onChange={(event) => props.formChangeHandler(event)} required/>
                </Form.Group> */}
                <CssTextField
                style={{width: '45%'}}
                    className={`mx-1 my-3`}
                    label="PHONE *"
                    value={props.formVals.phone}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="phone"
                />
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="location_status">
                    <Form.Label>LOCATION STATUS</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control as="select" value={props.formVals.location_status} onChange={(event) => props.formChangeHandler(event)} required>
                        <option value="">Select status</option>
                        {locStatus}
                    </Form.Control>
                </Form.Group> */}
                <Autocomplete
                    id="location_status"
                    value={locStatus.find((type)=> type.title === props.formVals.location_status) || null}
                    className={`mx-1 my-3 w-75`}
                    onChange={(e,newValue) => {
                        this.onLocationFormChangeHandler({target:{id:'location_status',value:newValue.title}})
                    }}
                    options={locStatus}
                    getOptionLabel={(option) => option.title}
                    // style={{ width: 450 }}
                    renderInput={(params) => <CssTextField {...params} label="LOCATION STATUS *"  variant="outlined" />}
                />
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="location_type">
                    <Form.Label>LOCATION TYPE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control as="select" value={props.formVals.location_type} onChange={(event) => props.formChangeHandler(event)} required>
                        <option value="">Select Type</option>
                        {locType}
                    </Form.Control>
                </Form.Group> */}
                <Autocomplete
                    id="location_type"
                    value={locType.find((type)=> type.title === props.formVals.location_type) || null}
                    className={`mx-1 my-3 w-75`}
                    onChange={(e,newValue) => {
                        this.onLocationFormChangeHandler({target:{id:'location_type',value:newValue.title}})
                    }}
                    options={locType}
                    getOptionLabel={(option) => option.title}
                    // style={{ width: 450 }}
                    renderInput={(params) => <CssTextField {...params} label="LOCATION TYPE *"  variant="outlined" />}
                />
            </Form.Row>
            <Button className='w-75 ml-3' block type="submit">Create Location</Button>
        </div>
    );
};

export default otherDetails;