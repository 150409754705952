import * as React from "react";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Box } from "@mui/material";
import { useGridApiRef, gridClasses } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';

export default function ManagementReviewReports(props) {
  const [gridId, setGridId] = useState(40);
  const apiRef = useGridApiRef();

  const classes = useStyles();
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  // console.log("ManagementReviewReports:", props);
  const handlePaginationModelChange = (paginationModel) => {
    console.log("paginationModel", paginationModel);
    if (paginationModel.pageSize !== pageSize) {
      setPageSize(paginationModel.pageSize);
      setPageNo(0);
    } else {
      setPageNo(paginationModel.page);
    }
  };

  const columns = [
    {
      field: "cus_id",
      headerName: "Customer ID",
      width: 100,
    },
    {
      field: "cus_name",
      headerName: "Customer Name",
      width: 180,
    },
    {
      field: "support_coordinator",
      headerName: "Support Coordinator",
      width: 180,
    },
    {
      field: "DDDID",
      headerName: "DDD ID",
      width: 100,
    },
    {
      field: "visit_type",
      headerName: "Visit Type",
      width: 100,
    },
    { field: "tas_description", headerName: "Follow up items", flex: 1 },
  ]

  let rows_test = props?.data ? props.data : [];
  // console.log("Management Rev rows_test", rows_test);
  let rows_final = [];
  props?.data?.map((cus, index) => {
    let temp_Obj1 = {
      cus_id: cus.cus_id,
      cus_name: cus.cus_name,
      DDDID: cus?.DDDID,
      support_coordinator: cus.support_coordinator,
      visit_type: cus?.visit_type,
      hierarchy: [cus?.cus_id + " " + cus?.tas_mm_id],
      rowId:
        cus?.cus_id +
        index +
        Math.random().toString(36).substring(2, 12) +
        index,
    };
    if (cus?.follow_up_items?.length > 0) {
      temp_Obj1.tas_description = "";
      cus?.follow_up_items?.map((tas, ind) => {
        temp_Obj1.tas_description +=
          ind + 1 + ". " + tas?.tas_description + "\n";
      });
    } else {
      temp_Obj1.tas_description = "-";
    }
    rows_final.push(temp_Obj1);
  });
  // console.log("Management Rev rows_test", rows_final);
  return (
    <Grid>
      {rows_final.length === 0 ?
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "500px", marginTop: 10 }}
        >
          <img
            src="./nosearchresult.png"
            alt="nosearchresult"
            style={{ width: "25rem", height: "15rem", margin: 10 }}
          />
          <h5
            style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
            className="text-center"
          >
            There is currently no data available{" "}
          </h5>
        </div>
        :
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ height: '77vh', width: "100%", marginTop: 1 }}>
              <Box sx={{ width: '100%', height: "calc(100vh - 220px)" }}>
                <ControlledDataGrid
                  gridId={gridId}
                  user={props.user}
                  initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
                  apiRef={apiRef}
                  className="table123"
                  rows={rows_final}
                  columns={columns}
                  disableRowSelectionOnClick
                  // autoHeight
                  pagination
                  rowsPerPageOptions={[20, 50, 100]}
                  paginationModel={{ page: pageNo, pageSize: pageSize }}
                  onPaginationModelChange={(paginationModel) => handlePaginationModelChange(paginationModel)}
                  getRowId={(row) => row.rowId}
                  getRowHeight={() => "auto"}
                  sx={{
                    [`& .${gridClasses.cell}`]: {
                      py: 1,
                      whiteSpace: "pre-line",
                    },
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      }
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({}));
