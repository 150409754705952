import React, { useEffect, useState } from 'react';
import '../CaseInfo/CaseInfo.css';
import { APIURL } from '../../config';
import axios from 'axios';
import moment from 'moment';

const CaseInfo = (props) => {
    const [totalCases, setTotalCases] = useState();
    const [notesAdded, setNotesAdded] = useState();
    const [newCases, setNewCases] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const projectsResponse = await axios.get(`${APIURL}/project/v1/count`, {
                    params: { company_id: props.user.comp_id },
                });
                const commentsResponse = await axios.get(`${APIURL}/comments`, {
                    params: { company_id: props.user.comp_id },
                });    
                console.log(projectsResponse);
           
                const newCasesThisMonth = projectsResponse.data.filter((project) =>
                    moment(project.pro_created_at).isAfter(moment().startOf('month'))
                );                

                setTotalCases(projectsResponse.data.length);
                setNotesAdded(commentsResponse.data.length);
                setNewCases(newCasesThisMonth.length);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [props.user.comp_id]);

    return (
        <div
            style={{ border: '1px solid #3FA7D6' }}
            className="jumbotron jumbotron-fluid bg-white p-2"
        >
            <div className="container d-flex flex-lg-row flex-md-row flex-sm-column justify-content-between p-0">
                {/* Total Caseload */}
                <div className="d-flex flex-column">
                    <div className="m-0">
                        <h3>
                            <span>
                                <i
                                    style={{ color: '#3FA7D6' }}
                                    className="fas fa-briefcase-medical p-1 mx-2"
                                ></i>
                            </span>
                            {totalCases}
                        </h3>
                    </div>
                    <div className="my-0 ml-1">
                        <p>Total Caseload</p>
                    </div>
                </div>

                {/* Case Notes Added */}
                <div className="d-flex flex-column">
                    <div className="m-0">
                        <h3>
                            <span>
                                <i
                                    style={{ background: '#3FA7D6' }}
                                    className="far fa-chart-bar text-white p-1 mx-2"
                                ></i>
                            </span>
                            {notesAdded}
                        </h3>
                    </div>
                    <div className="my-0 ml-1">
                        <p>Case Notes Added</p>
                    </div>
                </div>

                {/* New Cases */}
                <div className="d-flex flex-column">
                    <div className="m-0">
                        <h3>
                            <span>
                                <i
                                    style={{
                                        color: '#3FA7D6',
                                        borderRadius: '50%',
                                        padding: 3,
                                    }}
                                    className="fas fa-briefcase mx-2"
                                ></i>
                            </span>
                            {newCases}
                        </h3>
                    </div>
                    <div className="my-0 ml-1">
                        <p>New Cases</p>
                    </div>
                </div>
            </div>
            <hr style={{ background: '#3FA7D6' }} className="m-0" />
        </div>
    );
};

export default CaseInfo;
