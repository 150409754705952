import React from "react";
import moment from "moment";
import TimesheetReport from "./TimesheetReport";
import Reportsv1 from "./Reportsv1";
import EvvReport from "./EvvReport";
import axios from "axios";
import { APIURL } from "../../config";
import { Modal, Button } from "react-bootstrap";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CaseManager from "./CaseManager";
import Grid from "@mui/material/Grid";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import MessagePage from "../MessagePages/MessagePage";
import Billings from "./Billings";
import Company from "./Company";
import "./reports.css";
import Favorites from "./Favorites";
import EvvInvoice from "./EVVInvoice";
import EVVContainer from "./EVVContainer";
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })
var sha1 = require("sha1");

const initialCaseManagerFilterState = {
  sort_order: "asc",
  sort_by: "",
  page: 0,
  page_size: 10,
  customer_id: null,
  dateRange: [null, null],
  dateForType: null,
  reportType: null,
  customer: {},
};
const initialBillingFilterState = {
  sort_order: "asc",
  sort_by: "",
  // dateRange: [null, null],
  dateRange: [
    moment().startOf("month").format("YYYY-MM-DD"),
    moment().endOf("month").format("YYYY-MM-DD"),
  ],
};
const initialCompanyFilterState = {
  dateRange: [
    moment().startOf("month").format("YYYY-MM-DD"),
    moment().endOf("month").format("YYYY-MM-DD"),
  ],
};

class Reports extends React.Component {
  state = {
    user: null,
    comp_id: null,
    loading: true,
    loadingReport: false,
    showModal: false,
    toggleView: !!this.props.page ? true : false,
    TaskReports: [],
    value: "1",
    url: "",
    key: "favoriteReports",
    favoritesData: [],
    allReportsData: [],
    companyReports: [],
    billingReports: [],
    billingFilterData: initialBillingFilterState,
    cus_DDDIDs: [],
    caseManagerReports: [],
    errorText: "",
    successText: "",
    caseManagerReportsLength: 0,
    page: 0,
    page_size: 10,
    caseManagerFilterData: initialCaseManagerFilterState,
    customerList: [],
    openEVV: false,
    planRenewalData: null,
    MTReportsData: null,
    MRevReportsData: null,
    ActiveCustomerData: null,
    TasksData: null,
    LateMTData: null,
    period: moment().startOf("month"),
    MTSummaryData: null,
    SessionData: null,
    Session2Data: null,
    selectedCustomer: null,
    sessionDate: moment().format("YYYY-MM-DD"),
    companyFilterData: initialCompanyFilterState,
    tabValue: "1",
    timesheetPageSize: 20,
    timesheetPageNo: 0,
    loggedToSegment: false,
  };

  componentDidMount() {
    this.logToSegment(" : Favorites")
    this.setState({ comp_id: this.props.user.comp_id, user: this.props.user })
    this.getAllReports().then(() => {
      this.getFavorites()
      if(this.props.source === "fromBilling") {
        this.onSideBarChange("billing");
      }
      else if(this.props.source === "fromEVV" || this.props.source === "fromEVVAZ") {
        this.onSideBarChange("evvTest");
      }
    });
    
  }

  logToSegment = async (val) => {
    let page = String(window.location.href).split("/").pop()
    if(page.includes("fieldworker") || page === "" || page === "#") {
        page = "Home";
    } else {
        page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
    }
    if(this.props.source === "fromEVV") {
      page = "EVV : Reports";
    } else if(this.props.source === "fromBilling") {
      page = "Billing : Reports";
    } else if(this.props.source === "fromEVVAZ") {
      page = "EVVAZ : Reports";
    }
    console.log(page,this.props.user.emp_id)
    analytics.page(page + val, {
      title:page + val,
      path:page + val,
    }, {
      userId:String(this.props.user.emp_id),
    })
    console.log("logged to segment successfully")
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.key !== this.state.key) {
      switch (this.state.key) {
        case "favoriteReports":
          this.getFavorites();
          this.logToSegment(" : Favorites");
          break;
        case "caseManager":
          this.logToSegment(" : Case Manager");  
          this.getCustomersList().then(() =>
            this.getCaseManagementAllReports()
          );
          break;
        case "company":
          this.logToSegment(" : Administration : Company");
          this.getCustomersList();
          {
            // this.getPlanRenewalReports().then(() =>
            // this.getMTCompletionsDetailsReports()
            // )
          }
          break;
        case "billing":
          this.logToSegment(" : Billing");
          this.getCustomersList();
          break;
        case "evvTest":
          this.logToSegment(" : EVV");
          break;
        default:
          break;
      }
    }
    if (prevState.period !== this.state.period) {
      this.getLateMTReport();
    }
  }

  async getCustomersList() {
    this.setState({ loadingReport: true });
    axios
      .get(APIURL + "/customers/v2/associated", {
        params: {
          company_id: this.props.user.comp_id,
          employee_id: this.state.user.emp_id
        },
      })
      .then((response) => {
        // console.log("dddddddddddddddddddddddddddddddddd", response);
        if (response && response?.data) {
          this.setState({
            ...this.state,
            customerList: response?.data.filter((cus) => cus.cus_status === 'Active'),
          });
        }
      });
  }

  async getPlanRenewalReports() {
    this.logToSegment(" : Administration : Plan Renewal Report");
    this.setState({ loadingReport: true });
    axios
      .get(APIURL + `/project/getPlanRenewalReports`, {
        params: { company_id: this.props.user.comp_id, emp_id: this.state.user.emp_id },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          planRenewalData: response?.data,
          tabValue: "2",
        });
        // console.log(">>>>>>>>>>>>>>>>DDDDDDDDDDDDDDDDDDD ", response?.data);
        this.setState({ loadingReport: false });
      })
      .catch((err) => {
        this.setState({ loadingReport: false });
        console.log(err);
      });
  }

  // await axios.get(APIURL + `/project/getPlanRenewalReports`, { params: { company_id: props.comp_id } })

  async getPayrollReport(type) {
    this.setState({ loadingReport: true });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };
    axios
      .get(APIURL + "/jsreport/timesheet", {
        params: {
          com_id: this.props.user.comp_id,
          emp_id: this.props.user.emp_id,
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => this.openPDF(response, type));

    //activty log

    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "Payroll Report",
      sessionID: hash,
    };
    let msg = await axios.post(APIURL + "/activity_log", log_data);
  }
  onPageChange = (value) => {
    // console.log("onPageChange", value, this.state);
    this.setState({
      ...this.state,
      timesheetPageNo: value,
    });
  };
  onPageSizeChange = (value) => {
    // console.log("onPageSizeChange value", value, this.state.timesheetPageNo);
    this.setState({
      ...this.state,
      timesheetPageNo: 0,
      timesheetPageSize: value,
    });
  };
  async getDebarReport(type) {
    this.setState({ loadingReport: true });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };
    axios
      .get(APIURL + "/jsreport/debarment", {
        params: {
          com_id: this.props.user.comp_id,
          emp_id: this.props.user.emp_id,
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => this.openPDF(response, type));

    //activty log
    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "Debar Report",
      sessionID: hash,
    };
    let msg = await axios.post(APIURL + "/activity_log", log_data);
  }

  async getConsolidatedDebarReport(type) {
    this.setState({ loadingReport: true });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };
    axios
      .get(APIURL + "/jsreport/consolidatedDebarment", {
        params: {
          com_id: this.props.user.comp_id,
          emp_id: this.props.user.emp_id,
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => this.openPDF(response, type));

    //activty log
    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "Consolidated Debar Report",
      sessionID: hash,
    };
    let msg = await axios.post(APIURL + "/activity_log", log_data);
  }

  async getCaseManagementAllReports(
    paginationData = {
      page: 0,
      page_size: 10,
      sort_order: "asc",
      sort_by: "cus_name",
      customer_id: null,
      reportType: null,
    }
  ) {
    // console.log(
    //   "??????? NNNNNNNNNNNNN AAAA isValid",
    //   moment(
    //     new Date(this.state?.caseManagerFilterData?.dateRange[0])
    //   ).isValid()
    // );
    // console.log(
    //   "??????? NNNNNNNNNNNNN BBBBB isValid",
    //   moment(
    //     new Date(this.state?.caseManagerFilterData?.dateRange[1])
    //   ).isValid()
    // );

    this.setState({
      loadingReport: true,
      caseManagerFilterData: {
        ...this.state?.caseManagerFilterData,
        page: paginationData?.page,
        page_size: paginationData?.page_size,
        sort_order: paginationData?.sort_order,
        sort_by: paginationData?.sort_by,
        customer_id: paginationData?.customer_id,
      },
    });

    const filterForPayload = {
      company_id: this.props.user.comp_id,
      emp_id: this.state.user.emp_id,
      sort_order: paginationData?.sort_order,
      sort_by: paginationData?.sort_by,
      page_size: paginationData?.page_size,
      page: paginationData?.page,
      customer_id: paginationData?.customer_id,
      report_type: this.state?.caseManagerFilterData?.reportType,
      start_date:
        this.state?.caseManagerFilterData?.dateRange[0] &&
        moment(
          new Date(this.state?.caseManagerFilterData?.dateRange[0])
        ).isValid()
          ? moment(
              new Date(this.state?.caseManagerFilterData?.dateRange[0])
            ).format("YYYY-MM-DD")
          : null,
      end_date:
        this.state?.caseManagerFilterData?.dateRange[1] &&
        moment(
          new Date(this.state?.caseManagerFilterData?.dateRange[1])
        ).isValid()
          ? moment(
              new Date(this.state?.caseManagerFilterData?.dateRange[1])
            ).format("YYYY-MM-DD")
          : null,
      reports_filter_date:
        this.state.caseManagerFilterData.dateForType &&
        moment(new Date(this.state.caseManagerFilterData.dateForType)).isValid()
          ? moment(
              new Date(this.state.caseManagerFilterData.dateForType)
            ).format("YYYY-MM-DD")
          : null,
    };

    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>> PAYLOAD :::: ", filterForPayload);
    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };
    console.log("this.props", this.props);
    axios
      .get(APIURL + "/customers/customersForReportsCentre", {
        params: filterForPayload,
      })
      .then((response) => {
        console.log("case manager response", response);
        if (response && response?.data) {
          this.setState({
            ...this.state,
            caseManagerReports: response?.data?.customers,
            caseManagerReportsLength: response?.data?.count,
          });
          this.setState({ loadingReport: false });
        }
      });

    //activty log
    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "Case Management Report",
      sessionID: hash,
    };
    let msg = await axios.post(APIURL + "/activity_log", log_data);
  }

  getCaseManagementReport(data, type) {
    let dateType = this.state.caseManagerFilterData.reportType;
    let dateFilter;
    let fromDate;
    let toDate;

    if (dateType === 'Monthly') {
      if (this.state.caseManagerFilterData.dateForType) {
        fromDate = moment(new Date(this.state.caseManagerFilterData.dateForType)).startOf('month');
        toDate = moment(new Date(this.state.caseManagerFilterData.dateForType)).endOf('month');

        let newDate = new Date(this.state.caseManagerFilterData.dateForType);
        dateFilter = newDate.getMonth() + 1;
      }
      else {
        fromDate = moment().startOf('month');
        toDate = moment().endOf('month');

        let newDate = new Date();
        dateFilter = newDate.getMonth() + 1;
      }
    } else if (dateType === 'Quarterly') {
      const newDate = new Date();
      const year = newDate.getFullYear();
      const quarter = Math.floor((newDate.getMonth() / 3));
      const quarterStart = new Date(year, quarter * 3, 1);
      const quarterEnd = new Date(year, quarter * 3 + 3, 0);
      dateFilter = { 
        quarterStart: quarterStart, 
        quarterEnd: quarterEnd 
      }

      fromDate = moment(quarterStart);
      toDate = moment(quarterEnd);
    } else if (dateType === 'Yearly') {
      if (this.state.caseManagerFilterData.dateForType) {
        fromDate = moment(new Date(this.state.caseManagerFilterData.dateForType)).startOf('year');
        toDate = moment(new Date(this.state.caseManagerFilterData.dateForType)).endOf('year');

        let newDate = new Date(this.state.caseManagerFilterData.dateForType);
        dateFilter = newDate.getFullYear();
      }
      else {
        fromDate = moment().startOf('year');
        toDate = moment().endOf('year');

        let newDate = new Date();
        dateFilter = newDate.getFullYear();
      }
    } else if (!dateType || dateType === '') {
      dateType = 'None';
      if (this.state.caseManagerFilterData.dateRange[0] && this.state.companyFilterData.dateRange[1]) {
        fromDate = moment(new Date(this.state.caseManagerFilterData.dateRange[0]));
        toDate = moment(new Date(this.state.caseManagerFilterData.dateRange[1]));

        dateFilter = {
          start: new Date(this.state.caseManagerFilterData.dateRange[0]),
          end: new Date(this.state.caseManagerFilterData.dateRange[1])
        }
      } else {
        fromDate = moment().startOf('month');
        toDate = moment().endOf('month');

        dateType = 'Monthly';
        let newDate = new Date();
        dateFilter = newDate.getMonth() + 1;
      }
    } else {
      fromDate = moment().startOf('month');
      toDate = moment().endOf('month');
      
      dateType = 'Monthly';
      let newDate = new Date();
      dateFilter = newDate.getMonth() + 1;
    }

    this.setState({ loadingReport: true });
    // console.log("this.props", this.props, data, type);
    this.setState({ value: 2 });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };
    axios
      .get(APIURL + "/jsreport/cm_invoice", {
        params: {
          com_id: this.props.user.comp_id,
          cus_id: data.cus_id,
          cus_name: data.cus_name,
          dateFilter: dateFilter,
          dateType: dateType,
          emp_id: this.props.user.emp_id,
          from: fromDate,
          to: toDate,
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => this.openPDF(response, type));

    //activty log
    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "Payroll Report",
      sessionID: hash,
    };
    let msg = axios.post(APIURL + "/activity_log", log_data);
  }

  openPDF(response, reportAction = "open") {
    const file = new Blob([response.data], { type: "application/pdf" });
    console.log(response.data);
    const fileURL = URL.createObjectURL(file);
    this.setState({ loadingReport: false });

    if (reportAction === "download") {
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "Report-" + new Date() + ".pdf";
      link.click();
    } else {
      window.open(fileURL, "_blank");
    }
    // this.setState({ loadingReport: false, url: fileURL });
  }

  async getInvoiceReport(type) {
    this.setState({ loadingReport: true });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    axios
      .get(APIURL + "/jsreport/invoice", {
        params: {
          com_id: this.props.user.comp_id,
          emp_id: this.state.user.emp_id,
          start:
            this.state?.billingFilterData?.dateRange[0] &&
            moment(
              new Date(this.state?.billingFilterData?.dateRange[0])
            ).isValid()
              ? moment(
                  this.state?.billingFilterData?.dateRange[0]
                ).format("YYYY-MM-DD")
              : moment().startOf("month").format("YYYY-MM-DD"),
          end:
            this.state?.billingFilterData?.dateRange[1] &&
            moment(
              new Date(this.state?.billingFilterData?.dateRange[1])
            ).isValid()
              ? moment(
                  this.state?.billingFilterData?.dateRange[1]
                ).format("YYYY-MM-DD")
              : moment().endOf("month").format("YYYY-MM-DD"),
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => this.openPDF(response, type));

    //activty log
    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "Invoice Report",
      sessionID: hash,
    };
    let msg = await axios.post(APIURL + "/activity_log", log_data);
  }

  async getBillingTrans(type) {
    this.setState({ loadingReport: true });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    axios
      .get(APIURL + "/jsreport/billingTrans", {
        params: {
          com_id: this.props.user.comp_id,
          emp_id: this.props.user.emp_id,
          month: moment().month() + 1,
          cus_DDDIDs: this.state.cus_DDDIDs,
          start:
            this.state?.billingFilterData?.dateRange[0] &&
            moment(
              this.state?.billingFilterData?.dateRange[0]
            ).isValid()
              ? moment(
                  this.state?.billingFilterData?.dateRange[0]
                ).format("YYYY-MM-DD")
              : moment().startOf("month").format("YYYY-MM-DD"),
          end:
            this.state?.billingFilterData?.dateRange[1] &&
            moment(
              this.state?.billingFilterData?.dateRange[1]
            ).isValid()
              ? moment(
                  this.state?.billingFilterData?.dateRange[1]
                ).format("YYYY-MM-DD")
              : moment().endOf("month").format("YYYY-MM-DD"),
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => this.openPDF(response, type));

    //activty log
    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "Billing Transaction Report",
      sessionID: hash,
    };
    let msg = await axios.post(APIURL + "/activity_log", log_data);
  }

  async getClaimBalance(type) {
    this.setState({ loadingReport: true });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    axios
      .get(APIURL + "/jsreport/claimBalance", {
        params: {
          com_id: this.props.user.comp_id,
          emp_id: this.props.user.emp_id,
          cutoff: moment().startOf("month").format("YYYY-MM-DD"),
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => this.openPDF(response, type));

    //activty log
    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "Claim Balance Report",
      sessionID: hash,
    };
    let msg = await axios.post(APIURL + "/activity_log", log_data);
  }

  async getOutstandingBalance(type) {
    this.setState({ loadingReport: true });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    axios
      .get(APIURL + "/jsreport/outstandingBalance", {
        params: {
          com_id: this.props.user.comp_id,
          emp_id: this.props.user.emp_id,
          start:
            this.state?.billingFilterData?.dateRange[0] &&
            moment(
              this.state?.billingFilterData?.dateRange[0]
            ).isValid()
              ? moment(
                  this.state?.billingFilterData?.dateRange[0]
                ).format("YYYY-MM-DD")
              : moment().startOf("month").format("YYYY-MM-DD"),
          end:
            this.state?.billingFilterData?.dateRange[1] &&
            moment(
              this.state?.billingFilterData?.dateRange[1]
            ).isValid()
              ? moment(
                  this.state?.billingFilterData?.dateRange[1]
                ).format("YYYY-MM-DD")
              : moment().endOf("month").format("YYYY-MM-DD"),
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => this.openPDF(response, type));

    //activty log
    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "Billing Outstanding Balance Report",
      sessionID: hash,
    };
    let msg = await axios.post(APIURL + "/activity_log", log_data);
  }

  async getClaimCollect(type) {
    this.setState({ loadingReport: true });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    axios
      .get(APIURL + "/jsreport/claimCollect", {
        params: {
          com_id: this.props.user.comp_id,
          emp_id: this.props.user.emp_id,

          start:
            this.state?.billingFilterData?.dateRange[0] &&
            moment(
              this.state?.billingFilterData?.dateRange[0]
            ).isValid()
              ? moment(
                  this.state?.billingFilterData?.dateRange[0]
                ).format("YYYY-MM-DD")
              : moment().startOf("month").format("YYYY-MM-DD"),
          end:
            this.state?.billingFilterData?.dateRange[1] &&
            moment(
              this.state?.billingFilterData?.dateRange[1]
            ).isValid()
              ? moment(
                  this.state?.billingFilterData?.dateRange[1]
                ).format("YYYY-MM-DD")
              : moment().endOf("month").format("YYYY-MM-DD"),
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => this.openPDF(response, type));

    //activty log
    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "Claim Collection Report",
      sessionID: hash,
    };
    let msg = await axios.post(APIURL + "/activity_log", log_data);
  }

  async getPA_Utilization(type) {
    this.setState({ loadingReport: true });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    axios
      .get(APIURL + "/jsreport/PA_Utilization", {
        params: {
          com_id: this.props.user.comp_id,
          emp_id: this.props.user.emp_id,

          start:
            this.state?.billingFilterData?.dateRange[0] &&
            moment(
              this.state?.billingFilterData?.dateRange[0]
            ).isValid()
              ? moment(
                  this.state?.billingFilterData?.dateRange[0]
                ).utc().format("YYYY-MM-DD")
              : moment().startOf("month").format("YYYY-MM-DD"),
          end:
            this.state?.billingFilterData?.dateRange[1] &&
            moment(
              this.state?.billingFilterData?.dateRange[1]
            ).isValid()
              ? moment(
                  this.state?.billingFilterData?.dateRange[1]
                ).utc().format("YYYY-MM-DD")
              : moment().endOf("month").format("YYYY-MM-DD"),
        },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => this.openPDF(response, type));

    //activty log
    let sessionID = window.localStorage.getItem("Fw4_access_Token");
    var hash = sha1(sessionID);
    var log_data = {
      email: this.props.user.email,
      actionType: "Report Generated",
      actionSubType: "PA Utilization Report",
      sessionID: hash,
    };
    let msg = await axios.post(APIURL + "/activity_log", log_data);
  }

  async getFavorites() {
    this.setState({ loadingReport: true });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    axios.get(APIURL + "/jsreport/getFavorites", {
      params: { empID: this.props.user.emp_id },
    }).then((response) => {
      // console.log(" MMMMMM ", response?.data);
      this.setState({
        ...this.state,
        loadingReport: false,
        favoritesData: response?.data?.favorites || [],
      });
    });
  }

  async getMTCompletionsDetailsReports() {
    this.logToSegment(" : Administration : MT Completion Detail Report");
    this.setState({ loadingReport: true });
    axios
      .get(APIURL + "/jsreport/getMTCompletionsDetailsReports", {
        params: { company_id: this.props.user.comp_id, emp_id: this.state.user.emp_id, type: this.state.user.type },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          MTReportsData: response?.data?.managersData,
          tabValue: "3",
        });
        // console.log(">>>>>>>>>>>>>>>>DDDDDDDDDDDDDDDDDDD ", response?.data);
        this.setState({ loadingReport: false });
      })
      .catch((err) => {
        this.setState({ loadingReport: false });
        console.log(err);
      });
  }
  async getManagementReviewReports() {
    this.logToSegment(" : Administration : Management Review Report")
    this.setState({ loadingReport: true });
    axios
      .get(APIURL + "/jsreport/getMTManagmentReviewReports", {
        params: { company_id: this.props.user.comp_id, emp_id: this.state.user.emp_id },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          MRevReportsData: response?.data?.managementReviewReportResults
            ? response?.data?.managementReviewReportResults
            : [],
          tabValue: "4",
        });
        // console.log(">>>>>>>>>>>>>>>>DDDDDDDDDDDDDDDDDDD ", response?.data);
        this.setState({ loadingReport: false });
      })
      .catch((err) => {
        this.setState({ loadingReport: false });
        console.log(err);
      });
  }

  async getActiveCustomerReport() {
    this.logToSegment(" : Administration : Active Customer Report");
    this.setState({ loadingReport: true });
    axios
      .get(APIURL + "/reports/getActiveCustomers", {
        params: { com_id: this.props.user.comp_id, emp_id: this.state.user.emp_id },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          ActiveCustomerData: response?.data.length !== 0 ? response?.data : [],
          tabValue: "5",
        });
        this.setState({ loadingReport: false });
      })
      .catch((err) => {
        this.setState({ loadingReport: false });
        console.log(err);
      });
  }

  async getTasksReport() {
    this.logToSegment(" : Administration : Tasks Report")
    this.setState({ loadingReport: true });
    axios
      .get(APIURL + "/reports/getTasks", {
        params: { 
          com_id: this.state.comp_id,
          emp_id: this.state.user.emp_id,
          type: this.state.user.type,
          start:
            this.state?.companyFilterData?.dateRange[0] &&
            moment(
              this.state?.companyFilterData?.dateRange[0]
            ).isValid()
              ? moment(
                  this.state?.companyFilterData?.dateRange[0]
                ).startOf("day").format("YYYY-MM-DDTHH:mm:ss")
              : moment().startOf("month").startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
          end:
            this.state?.companyFilterData?.dateRange[1] &&
            moment(
              this.state?.companyFilterData?.dateRange[1]
            ).isValid()
              ? moment(
                  this.state?.companyFilterData?.dateRange[1]
                ).endOf("day").format("YYYY-MM-DDTHH:mm:ss")
              : moment().endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"), 
        },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          TasksData: response?.data,
          tabValue: "6",
        });
        this.setState({ loadingReport: false });
      })
      .catch((err) => {
        this.setState({ loadingReport: false });
        console.log(err);
      });
  }

  async getLateMTReport() {
    this.logToSegment(" : Administration : Late MT Report");
    this.setState({ loadingReport: true });
    axios
      .get(APIURL + "/reports/getLateMT", {
        params: { com_id: this.props.user.comp_id, period: moment(this.state.period).format('MMM YYYY') },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          LateMTData: response?.data.length !== 0 ? response?.data : [],
          tabValue: "7",
        });
        this.setState({ loadingReport: false });
      })
      .catch((err) => {
        this.setState({ loadingReport: false });
        console.log(err);
      });
  }

  async getMTSummaryReport() {
    this.logToSegment(" : Administration : MT Summary Report");
    this.setState({ loadingReport: true });
    axios
      .get(APIURL + "/reports/getMTSummary", {
        params: { com_id: this.props.user.comp_id },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          MTSummaryData: response?.data.length !== 0 ? response?.data : [],
          tabValue: "8",
        });
        this.setState({ loadingReport: false });
      })
      .catch((err) => {
        this.setState({ loadingReport: false });
        console.log(err);
      });
  }

  async getSessionReport() {
    this.logToSegment(" : Administration : Session Report");
    this.setState({ loadingReport: true });
    axios
      .get(APIURL + "/reports/getCustomerSessions", {
        params: { 
          cus_id: this.state?.selectedCustomer,
          start:
          this.state?.companyFilterData?.dateRange[0] &&
          moment(
            this.state?.companyFilterData?.dateRange[0]
          ).isValid()
            ? moment(
                this.state?.companyFilterData?.dateRange[0]
              ).startOf("day").format("YYYY-MM-DDTHH:mm:ss")
            : moment().startOf("month").startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
        end:
          this.state?.companyFilterData?.dateRange[1] &&
          moment(
            this.state?.companyFilterData?.dateRange[1]
          ).isValid()
            ? moment(
                this.state?.companyFilterData?.dateRange[1]
              ).endOf("day").format("YYYY-MM-DDTHH:mm:ss")
            : moment().endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"),  
        },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          SessionData: response?.data.length !== 0 ? response?.data : [],
          tabValue: "9",
        });
        this.setState({ loadingReport: false });
      })
      .catch((err) => {
        this.setState({ loadingReport: false });
        console.log(err);
      });
  }

  async getSessionReport2() {
    this.logToSegment(" : Administration : Session Report 2");
    this.setState({ loadingReport: true });
    axios
      .get(APIURL + "/reports/getSessionsByDate", {
        params: { 
          compID: this.props.user.comp_id,
          sessionDate: this.state.sessionDate,  
        },
      })
      .then((response) => {
        this.setState({
          ...this.state,
          Session2Data: response?.data.length !== 0 ? response?.data : [],
          tabValue: "10",
        });
        this.setState({ loadingReport: false });
      })
      .catch((err) => {
        this.setState({ loadingReport: false });
        console.log(err);
      });
  }
  
  async getAllReports() {
    this.setState({ loadingReport: true });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    axios.get(APIURL + "/jsreport/getAllReports", {
      params: { empID: this.props.user.emp_id },
    }).then((response) => {
      this.setState({
        ...this.state,
        loadingReport: false,
        allReportsData: response?.data?.reports || [],
        companyReports:
          response?.data?.reports.filter(function (ele) {
            return ele.report_group == "ADMINISTRATION" && ele.report_type === 1;
          }) || [],
        billingReports:
          response?.data?.reports.filter(function (ele) {
            return ele.report_group == "BILLING";
          }) || [],
      });
    });
  }

  async getReport(data, type) {
    // console.log("data ::: ", data);
    // console.log("type ::: ", type);
    // console.log("user ::: ", this.props.user);
    // console.log("state ::: ", this.state);

    // this.setState({ ...this.state, planRenewalData: null });
    this.setState({ ...this.state, tabValue: "1" });

    const report_name = data?.report_details?.report_name || data?.report_name;
    switch (report_name) {
      case "Invoice Report":
        this.getInvoiceReport(type);
        break;
      case "Billing Transaction Report":
        this.getBillingTrans(type);
        break;
      case "Claim Balance Report":
        this.getClaimBalance(type);
        break;
      case "Billing Outstanding Balance Report":
        this.getOutstandingBalance(type);
        break;
      case "Claim Collection Report":
        this.getClaimCollect(type);
        break;
      case "Prior Auth Utilization Report":
        this.getPA_Utilization(type)
        break;
      case "Payroll Report":
        this.getPayrollReport(type);
        break;
      case "Debarment Report":
        this.getDebarReport(type);
        break;
      case "NJ Consolidated Debarment Report":
        this.getConsolidatedDebarReport(type);
        break;
      case "companyTab":
        this.logToSegment(" : Administration : Company");
        break;
      default:
        break;
    }
  }

  async markFavourite(data, type) {
    // console.log("F data ::: ", data);
    // console.log("F type ::: ", type);
    // console.log("F user ::: ", this.props.user);
    // console.log("F state ::: ", this.state);

    this.setState({ loadingReport: true });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    const payload = {
      report_id: data?.report_id,
      status: type,
      empID: this.props.user.emp_id
    };
    axios.put(APIURL + "/jsreport/markFavorite", payload).then((response) => {
      // console.log(">>>>>>>>>>>> ", response);
      if (response?.status === 200 && response?.data?.code === 200) {
        this.setState({
          successText:
            response?.data?.status_message || "Favorite Modifed Succesfully",
        });
      } else {
        this.setState({
          loadingReport: false,
          errorText: "Something went wrong",
        });
      }
      this.getAllReports().then(() => {
        this.getFavorites();
      });
    });
  }

  onSideBarChange = (key) => {
    localStorage.removeItem("evvFilterData");
    localStorage.removeItem("timesheetFilterData");
    this.setState({ key });
  };

  render() {
    // console.log(this.props)
    if(this.props.source === "fromBilling") {
      return (
        <div className="m-1 w-100">
        <Tab.Container
          id="tab"
          defaultActiveKey="favoriteReports"
          activeKey={
            this.state.key === "0" ? "favoriteReports" : this.state.key
          }
        >
          <Grid container>
            <Col sm={10}>
              {/* {!this.state.loadingReport ? ( */}
                <Tab.Content>
                  <Tab.Pane eventKey="billing">
                    {this.state.key == "billing" && (
                      <Billings
                        user={this.props.user}
                        comp_id={this.props.comp_id}
                        openReport={(type, data) => this.getReport(type, data)}
                        data={this.state.billingReports}
                        billingFilterData={this.state.billingFilterData}
                        markFavourite={(type, data) =>
                          this.markFavourite(type, data)
                        }
                        setState={(s) => this.setState(s)}
                        state={this.state}
                      />
                    )}
                  </Tab.Pane>
                </Tab.Content>
            </Col>
          </Grid>
        </Tab.Container>
        <Snackbar
          open={this.state.successText !== ""}
          autoHideDuration={30000}
          onClose={() => {
            this.setState({ successText: "" });
          }}
        >
          <MuiAlert
            sx={{ width: "100%" }}
            severity={"success"}
            elevation={6}
            variant="filled"
          >
            {this.state.successText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => {
                this.setState({ successText: "" });
              }}
            >
              <CloseIcon />
            </IconButton>
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.errorText !== ""}
          autoHideDuration={30000}
          onClose={() => {
            this.setState({ errorText: "" });
          }}
        >
          <MuiAlert
            sx={{ width: "100%" }}
            severity={"error"}
            elevation={6}
            variant="filled"
          >
            {this.state.errorText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => this.setState({ errorText: "" })}
            >
              <CloseIcon />
            </IconButton>
          </MuiAlert>
        </Snackbar>
      </div>)
    }
    else if(this.props.source === "fromEVV" || this.props.source === "fromEVVAZ") {
      return (<div className="m-1 w-100">
      <Tab.Container
        id="tab"
        defaultActiveKey="favoriteReports"
        activeKey={
          this.state.key === "0" ? "favoriteReports" : this.state.key
        }
      >
        <Grid container>
          <Col sm={10}>
            {/* {!this.state.loadingReport ? ( */}
              <Tab.Content>
                <Tab.Pane eventKey="evvTest">
                  <EVVContainer
                    user={this.props.user}
                    comp_id={this.props.comp_id}
                    openReport={(type, data) => console.log(type)}
                    data={[
                      {
                        report_type: 1,
                        report_id: 1,
                        report_name: "Reports",
                      },
                      {
                        report_type: 2,
                        report_id: 2,
                        report_name: "Invoice",
                      },
                    ]}
                    markFavourite={(type, data) => console.log(type, data)}
                  />
                </Tab.Pane>
              </Tab.Content>
            {/* ) : (
              <div className="d-flex flex-row w-100 justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )} */}
          </Col>
        </Grid>
      </Tab.Container>

      <Snackbar
        open={this.state.successText !== ""}
        autoHideDuration={30000}
        onClose={() => {
          this.setState({ successText: "" });
        }}
      >
        <MuiAlert
          sx={{ width: "100%" }}
          severity={"success"}
          elevation={6}
          variant="filled"
        >
          {this.state.successText}
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.25 }}
            onClick={() => {
              this.setState({ successText: "" });
            }}
          >
            <CloseIcon />
          </IconButton>
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={this.state.errorText !== ""}
        autoHideDuration={30000}
        onClose={() => {
          this.setState({ errorText: "" });
        }}
      >
        <MuiAlert
          sx={{ width: "100%" }}
          severity={"error"}
          elevation={6}
          variant="filled"
        >
          {this.state.errorText}
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.25 }}
            onClick={() => this.setState({ errorText: "" })}
          >
            <CloseIcon />
          </IconButton>
        </MuiAlert>
      </Snackbar>
    </div>)
    }
    return (
      <div className="m-1 w-100">
        <Tab.Container
          id="tab"
          defaultActiveKey="favoriteReports"
          activeKey={
            this.state.key === "0" ? "favoriteReports" : this.state.key
          }
        >
          <Grid container>
            <Grid item xs={2}>
              <Nav className="flex-column">
                <Nav.Item>
                  <Nav.Link
                    eventKey="favoriteReports"
                    className="list-group-item list-group-item-action"
                    style={{ outline: "none", fontFamily: "Lato" }}
                    active={this.state.key === "favoriteReports" ? true : false}
                    onSelect={() => {
                      this.onSideBarChange("favoriteReports");
                    }}
                  >
                    <i
                      style={{
                        fontSize: "1.5rem",
                        paddingRight: "23px",
                        paddingLeft: "0px",
                        color:
                          this.state.key === "favoriteReports" && "#3fa7d6",
                      }}
                      className="fas fa-star"
                    ></i>
                    Favorites
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="billing"
                    className="list-group-item list-group-item-action"
                    style={{ outline: "none", fontFamily: "Lato" }}
                    active={this.state.key === "billing" ? true : false}
                    onSelect={() => {
                      this.onSideBarChange("billing");
                    }}
                  >
                    <i
                      style={{
                        fontSize: "1.5rem",
                        paddingRight: "23px",
                        paddingLeft: "0px",
                        color: this.state.key === "billing" && "#3fa7d6",
                      }}
                      className="fas fa-file-invoice-dollar mx-1"
                    ></i>
                    Billing
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="company"
                    className="list-group-item list-group-item-action"
                    style={{ outline: "none", fontFamily: "Lato" }}
                    active={this.state.key === "company" ? true : false}
                    onSelect={() => {
                      this.onSideBarChange("company");
                    }}
                  >
                    <i
                      style={{
                        fontSize: "1.5rem",
                        paddingRight: "21px",
                        paddingLeft: "0px",
                        color: this.state.key === "company" && "#3fa7d6",
                      }}
                      className="fas fa-building mx-1"
                    ></i>
                    Administration
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey="evvTest"
                    className="list-group-item list-group-item-action"
                    style={{ outline: "none", fontFamily: "Lato" }}
                    active={this.state.key === "evvTest" ? true : false}
                    onSelect={() => {
                      this.onSideBarChange("evvTest");
                    }}
                  >
                    <i
                      style={{
                        fontSize: "1.5rem",
                        paddingRight: "23px",
                        paddingLeft: "0px",
                        color: this.state.key === "evvTest" && "#3fa7d6",
                      }}
                      className="fas fa-file-invoice mx-1"
                    ></i>
                    EVV
                  </Nav.Link>
                </Nav.Item>

                {/* <Nav.Item>
                  <Nav.Link
                    eventKey="evvReports"
                    className="list-group-item list-group-item-action"
                    style={{ outline: "none", fontFamily: "Lato" }}
                    active={this.state.key === "evvReports" ? true : false}
                    onSelect={() => {
                      this.onSideBarChange("evvReports");
                    }}
                  >
                    <i
                      style={{
                        fontSize: "1.5rem",
                        paddingRight: "23px",
                        paddingLeft: "0px",
                        color: this.state.key === "evvReports" && "#3fa7d6",
                      }}
                      className="fas fa-clipboard-list mx-1"
                    ></i>
                    EVV Reports
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="evvInvoice"
                    className="list-group-item list-group-item-action"
                    style={{ outline: "none", fontFamily: "Lato" }}
                    active={this.state.key === "evvInvoice" ? true : false}
                    onSelect={() => {
                      this.onSideBarChange("evvInvoice");
                    }}
                  >
                    <i
                      style={{
                        fontSize: "1.5rem",
                        paddingRight: "23px",
                        paddingLeft: "0px",
                        color: this.state.key === "evvInvoice" && "#3fa7d6",
                      }}
                      className="fas fa-file-invoice mx-1"
                    ></i>
                    EVV Invoice
                  </Nav.Link>
                </Nav.Item> */}

                {/* <Nav.Item>
                  <Nav.Link
                    eventKey="evvReports"
                    className="list-group-item list-group-item-action"
                    style={{ outline: "none", fontFamily: "Lato" }}
                    active={
                      this.state.key === "evvReports" ||
                      this.state.key === "evvInvoice"
                        ? true
                        : false
                    }
                    onSelect={() => {
                      this.setState({
                        openEVV: !this.state.openEVV,
                      });
                    }}
                  >
                    <i
                      style={{
                        fontSize: "1.5rem",
                        paddingRight: "23px",
                        paddingLeft: "0px",
                        color:
                          (this.state.key === "evvReports" ||
                            this.state.key === "evvInvoice") &&
                          "#3fa7d6",
                      }}
                      className="fas fa-list-alt mx-1"
                    ></i>
                    EVV
                    {this.state.openEVV ? (
                      <i
                        class="fa fa-chevron-right"
                        aria-hidden="true"
                        style={{
                          fontSize: "15px",
                          float: "right",
                          lineHeight: "30px",
                          color:
                            (this.state.key === "evvReports" ||
                              this.state.key === "evvInvoice") &&
                            "#3fa7d6",
                        }}
                      ></i>
                    ) : (
                      <i
                        class="fa fa-chevron-down"
                        aria-hidden="true"
                        style={{
                          fontSize: "15px",
                          float: "right",
                          lineHeight: "30px",
                          color:
                            (this.state.key === "evvReports" ||
                              this.state.key === "evvInvoice") &&
                            "#3fa7d6",
                        }}
                      ></i>
                    )}
                  </Nav.Link>
                </Nav.Item> */}

                {this.state.openEVV && (
                  <React.Fragment>
                    <div style={{ paddingLeft: "22px", background: "white" }}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="evvReports"
                          className="list-group-item list-group-item-action"
                          style={{ outline: "none", fontFamily: "Lato" }}
                          active={
                            this.state.key === "evvReports" ? true : false
                          }
                          onSelect={() => {
                            this.onSideBarChange("evvReports");
                          }}
                        >
                          <i
                            style={{
                              fontSize: "1.5rem",
                              paddingRight: "23px",
                              paddingLeft: "0px",
                              color:
                                this.state.key === "evvReports" && "#3fa7d6",
                            }}
                            className="fas fa-clipboard-list mx-1"
                          ></i>
                          Reports
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="evvInvoice"
                          className="list-group-item list-group-item-action"
                          style={{ outline: "none", fontFamily: "Lato" }}
                          active={
                            this.state.key === "evvInvoice" ? true : false
                          }
                          onSelect={() => {
                            this.onSideBarChange("evvInvoice");
                          }}
                        >
                          <i
                            style={{
                              fontSize: "1.5rem",
                              paddingRight: "23px",
                              paddingLeft: "0px",
                              color:
                                this.state.key === "evvInvoice" && "#3fa7d6",
                            }}
                            className="fas fa-file-invoice mx-1"
                          ></i>
                          Invoice
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  </React.Fragment>
                )}

                <Nav.Item>
                  <Nav.Link
                    eventKey="timeSheetReports"
                    className="list-group-item list-group-item-action"
                    style={{ outline: "none", fontFamily: "Lato" }}
                    active={
                      this.state.key === "timeSheetReports" ? true : false
                    }
                    onSelect={() => {
                      this.onSideBarChange("timeSheetReports");
                    }}
                  >
                    <i
                      style={{
                        fontSize: "1.5rem",
                        paddingRight: "23px",
                        paddingLeft: "0px",
                        color:
                          this.state.key === "timeSheetReports" && "#3fa7d6",
                      }}
                      className="fas fa-clock"
                    ></i>
                    Timesheets
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey="caseManager"
                    className="list-group-item list-group-item-action"
                    style={{ outline: "none", fontFamily: "Lato" }}
                    active={this.state.key === "caseManager" ? true : false}
                    onSelect={() => {
                      this.onSideBarChange("caseManager");
                    }}
                  >
                    <i
                      style={{
                        fontSize: "1.5rem",
                        paddingRight: "22px",
                        paddingLeft: "0px",
                        color: this.state.key === "caseManager" && "#3fa7d6",
                      }}
                      className="fas fa-id-badge mx-1"
                    ></i>
                    Case Manager
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Grid>
            <Col sm={10}>
              {/* {!this.state.loadingReport ? ( */}
                <Tab.Content>
                  <Tab.Pane eventKey="favoriteReports">
                    {this.state.key == "favoriteReports" && (
                      <Favorites
                        openReport={(type, data) => this.getReport(type, data)}
                        data={this.state.favoritesData}
                        markFavourite={(type, data) =>
                          this.markFavourite(type, data)
                        }
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="evvReports">
                    {this.state.key == "evvReports" && (
                      <EvvReport
                        user={this.props.user}
                        comp_id={this.props.comp_id}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="evvInvoice">
                    {this.state.key == "evvInvoice" && (
                      <EvvInvoice
                        user={this.props.user}
                        comp_id={this.props.comp_id}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="timeSheetReports">
                    {this.state.key == "timeSheetReports" && (
                      <React.Fragment>
                        {this.props.page &&
                          this.props.user.type === "Admin" && (
                            <TimesheetReport
                              user={this.props.user}
                              comp_id={this.props.comp_id}
                              pagesize={this.state.timesheetPageSize}
                              pageNo={this.state.timesheetPageNo}
                              onPageSizeChange={this.onPageSizeChange}
                              onPageChange={this.onPageChange}
                            />
                          )}
                        {this.props.page &&
                          this.props.user.type !== "Admin" && (
                            <TimesheetReport
                              user={this.props.user}
                              comp_id={this.props.comp_id}
                              pagesize={this.state.timesheetPageSize}
                              pageNo={this.state.timesheetPageNo}
                              onPageChange={this.onPageChange}
                              onPageSizeChange={this.onPageSizeChange}
                            />
                          )}
                      </React.Fragment>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="billing">
                    {this.state.key == "billing" && (
                      <Billings
                        user={this.props.user}
                        comp_id={this.props.comp_id}
                        openReport={(type, data) => this.getReport(type, data)}
                        data={this.state.billingReports}
                        billingFilterData={this.state.billingFilterData}
                        markFavourite={(type, data) =>
                          this.markFavourite(type, data)
                        }
                        setState={(s) => this.setState(s)}
                        state={this.state}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="company">
                    {this.state.key == "company" && (
                      <Company
                        getPlanRenewalReports={() =>
                          this.getPlanRenewalReports()
                        }
                        openReport={(type, data) => this.getReport(type, data)}
                        getMTCompletionsDetailsReports={() =>
                          this.getMTCompletionsDetailsReports()
                        }
                        getManagementReviewReports={() =>
                          this.getManagementReviewReports()
                        }
                        getActiveCustomerReport={() =>
                          this.getActiveCustomerReport()
                        }
                        getTasksReport={() =>
                          this.getTasksReport()
                        }
                        getLateMTReport={() =>
                          this.getLateMTReport()
                        }
                        getMTSummaryReport={() =>
                          this.getMTSummaryReport()
                        }
                        getSessionReport={() =>
                          this.getSessionReport()
                        }
                        getSessionReport2={() =>
                          this.getSessionReport2()
                        }
                        markFavourite={(type, data) =>
                          this.markFavourite(type, data)
                        }
                        data={[
                          ...this.state.companyReports,
                          // {
                          //   report_id: 1,
                          //   report_name: "Plan Renewal Report",
                          //   status: 0,
                          // },
                          // {
                          //   report_id: 2,
                          //   report_name: "MT Completion Detail Report",
                          //   status: 0,
                          // },
                        ]}
                        user={this.props.user}
                        comp_id={this.props.comp_id}
                        renewalData={this.state.planRenewalData}
                        MTReportsData={this.state.MTReportsData}
                        MRevReportsData={this.state.MRevReportsData}
                        ActiveCustomerData={this.state.ActiveCustomerData}
                        TasksData={this.state.TasksData}
                        LateMTData={this.state.LateMTData}
                        onMonthChange={(month) => this.setState({ period: moment(month).format('MMM YYYY') })}
                        MTSummaryData={this.state.MTSummaryData}
                        SessionData={this.state.SessionData}
                        Session2Data={this.state.Session2Data}
                        customersList={this.state.customerList}
                        companyFilterData={this.state.companyFilterData}
                        onSearch={() => this.state.tabValue === '6' ? this.getTasksReport() : this.state.tabValue === '9' ? this.getSessionReport() : this.getSessionReport2()}
                        setState={(s) => this.setState(s)}
                        state={this.state}
                        tabValue={this.state.tabValue}
                        // MTReportsData={[]}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="caseManager">
                    {this.state.key == "caseManager" && <CaseManager
                      user={this.props.user}
                      comp_id={this.props.comp_id}
                      openReport={(type, data) =>
                        this.getCaseManagementReport(type, data)
                      }
                      markFavourite={(type, data) =>
                        this.markFavourite(type, data)
                      }
                      data={this.state.caseManagerReports}
                      totalCount={this.state.caseManagerReportsLength}
                      page={this.state.page}
                      page_size={this.state.page_size}
                      caseManagerFilterData={this.state.caseManagerFilterData}
                      onChangePagination={(paginationData) =>
                        this.getCaseManagementAllReports(paginationData)
                      }
                      customersList={this.state.customerList}
                      setState={(s) => this.setState(s)}
                      state={this.state}
                    />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="evvTest">
                    <EVVContainer
                      user={this.props.user}
                      comp_id={this.props.comp_id}
                      openReport={(type, data) => console.log(type)}
                      data={[
                        {
                          report_type: 1,
                          report_id: 1,
                          report_name: "Reports",
                        },
                        {
                          report_type: 2,
                          report_id: 2,
                          report_name: "Invoice",
                        },
                      ]}
                      markFavourite={(type, data) => console.log(type, data)}
                    />
                  </Tab.Pane>
                </Tab.Content>
              {/* ) : (
                <div className="d-flex flex-row w-100 justify-content-center mt-5">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )} */}
            </Col>
          </Grid>
        </Tab.Container>

        {/* <Modal
          show={this.state.showModal}
          onHide={() => { this.setState({ showModal: false }) }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <h3>We are loading your report in a new tab. This might take a moment...</h3>
          </Modal.Header>
          <Modal.Body
            style={{ display: 'flex', justifyContent: 'center', minHeight: '300px' }}
          >
            {this.state.loadingReport ?
              <div style={{ color: '#3FA7D6', height: '10rem', width: '10rem', marginTop: 'auto', marginBottom: 'auto' }} className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div> :
              <MessagePage title="Your Report Has Loaded." icon="fas fa-check"></MessagePage>}
          </Modal.Body>
          <Modal.Footer
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button onClick={() => this.setState({ showModal: false })}>Okay</Button>
          </Modal.Footer>
        </Modal> */}

        <Snackbar
          open={this.state.successText !== ""}
          autoHideDuration={30000}
          onClose={() => {
            this.setState({ successText: "" });
          }}
        >
          <MuiAlert
            sx={{ width: "100%" }}
            severity={"success"}
            elevation={6}
            variant="filled"
          >
            {this.state.successText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => {
                this.setState({ successText: "" });
              }}
            >
              <CloseIcon />
            </IconButton>
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.errorText !== ""}
          autoHideDuration={30000}
          onClose={() => {
            this.setState({ errorText: "" });
          }}
        >
          <MuiAlert
            sx={{ width: "100%" }}
            severity={"error"}
            elevation={6}
            variant="filled"
          >
            {this.state.errorText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => this.setState({ errorText: "" })}
            >
              <CloseIcon />
            </IconButton>
          </MuiAlert>
        </Snackbar>
      </div>
    );
  }
}
export default Reports;