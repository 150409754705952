import React, { Component } from 'react';
import './Overview.css'
import moment from 'moment'
import axios from 'axios'
import { APIURL } from '../../../config'
import { Card, CardActions, CardContent, Icon, IconButton, Dialog, DialogActions, DialogContent, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoteFormv2 from '../../../components/forms/NoteForm/NoteFormv2'
import MessagePage from '../../../components/MessagePages/MessagePage'
import CustomerForm from '../../../components/forms/CustomerForm/CustomerForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIRichTextEditor from 'mui-rte';
import Tooltip from '@mui/material/Tooltip';
var Buffer = require('buffer/').Buffer;
// import dd from '../../../manifestdd.json'



const myTheme = createTheme({
    overrides: {
        MUIRichTextEditor: {
            anchorLink: {
                color: "lightblue",
                textDecoration: "underline",
            },
        },
    },
});

class Overview extends Component {
    state = {
        contextMenu: 0,
        showMore: false,
        displayNotes: [],
        editNote: null,
        editCust: null,
        content: [],
        loading: true,
        currentCustomer: null,
        completedTaskData: [],
        customerType: null,
        customerStatus: null,
        loc_list: null,
        empList: null,
        pManagerId: null,
        pId: null,
        editLoading: true,
        customers: { list: [{}], customerType: [], customerStatus: [] },
        locations: { list: [{}], states: [], locType: [], locStatus: [] },
        mapRender: "cust",
        newCustLoading: true,
        taskTypes: [],
        customer_tasks: [],
        image: null
    }

    async fetchData() {
        let custTasks = await axios.get(APIURL + "/customers/CustomerTasks", { params: { comp_id: this.props.comp_id, customer_id: this.props.cust_details.cus_id } });

        if (custTasks.data.length !== 0) {
            let types = []
            custTasks.data.forEach((task) => {
                types.push(task.EntityValue)
            })
            this.setState({ taskTypes: types })
        }
        else {
            let taskTypeList = await axios.get(APIURL + "/dropdowns/dropdownType/taskType", {
                params: { comp_id: this.props.comp_id },
            });
            this.setState({ taskTypes: taskTypeList.data })
        }

        const response = await axios.get(APIURL + `/comments?customer_id=${this.props.cus_id}`)
        let notes = response.data
        notes = await Promise.all(notes.map(async (note) => {
            const noteDataResponse = await axios.get(APIURL + `/comments/details?comment_id=${note.comment_id}`)
            const noteData = noteDataResponse.data[0]
            return noteData
        }))

        notes = notes.filter((note) => note !== undefined);

        const audioResp = await axios.get(APIURL + `/audio_note`, {
            params: { customer_id: this.props.cus_id },
        });

        let audioNotes = audioResp.data.map((audioNote) => ({
            ...audioNote,
            created_at: audioNote.submitted,
        }));

        notes.push(...audioNotes)

        notes.sort((a, b) => {
            return moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1;
        })
        notes = notes.slice(0, 5);

        this.setState({ displayNotes: notes, currentCustomer: this.props.cus_id })
        this.generateNoteCards(this.state.displayNotes)
        this.setState({ loading: false })
    }

    async fetchProjData() {
        this.setState({ completedTaskData: [], taskCount: '...' })
        const response = await axios.get(APIURL + `/project`, { params: { cus_id: this.props.cus_id } })
        let projects = response.data

        projects.length !== 0 && this.setState({ pManagerId: projects[0].pro_manager_id, pId: projects[0].pro_id })

        let taskTimeSpent = []
        for (let i = 0; i < projects.length; i++) {
            let completedTaskData = await axios.get(APIURL + '/locate/TimeSpent', { params: { projId: projects[i].pro_id, comp_id: this.props.comp_id } })
            taskTimeSpent.push(...completedTaskData.data)
        }
        this.setState({ completedTaskData: taskTimeSpent, taskCount: taskTimeSpent.length })
    }

    async fetchCustomerImage() {
        const response = await axios.get(APIURL + `/customers/image`, { params: { customer_id: this.props.cust_details.cus_id } });
        const imageBase64 = response.data.image
        this.setState({ image: imageBase64 })
    }

    async fetchCusEditData() {
        let location_list = await axios.get(APIURL + '/dropdowns/dropdownType/locationslist', { params: { comp_id: this.props.comp_id } })
        let cusType = await axios.get(APIURL + '/dropdowns/dropdownType/customerType', { params: { comp_id: this.props.comp_id } })
        let cusStatus = await axios.get(APIURL + '/dropdowns/dropdownType/customerStatus', { params: { comp_id: this.props.comp_id } })
        let employee_list = await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: this.props.comp_id } })

        this.setState({ editLoading: false, customerType: cusType.data, customerStatus: cusStatus.data, loc_list: location_list.data, empList: employee_list.data })
    }

    async fetchNewCustData() {
        let data = {
            employees: {
                list: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/employeeslist", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
            },
            customers: {
                list: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/customerslist", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
                customerType: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/customerType", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
                customerStatus: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/customerStatus", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
            },
        };

        this.setState({ ...data });
        this.setState({ newCustLoading: false });
    }

    calCumulativeTimeSpent = () => {
        var TotalTime = moment()
        this.state.completedTaskData.forEach((taskData) => {
            var CheckInTime = moment(taskData.CheckInTime)
            var CheckoutTime = moment(taskData.CheckoutTime)
            var taskTime = moment.duration(CheckoutTime.diff(CheckInTime))
            TotalTime.add(taskTime)
        })
        return moment.duration(TotalTime.diff(moment())).humanize()
    }

    componentDidMount() {
        var dots = document.getElementById("dots");
        var moreText = document.getElementById("more");

        dots.style.display = "inline";
        moreText.style.display = "none";

        this.fetchData()
        this.fetchProjData()
        this.fetchCustomerImage()
    }

    async componentDidUpdate(prevProps, prevState) {
        if ((prevState.editNote !== this.state.editNote) || (prevState.contextMenu !== this.state.contextMenu) || (prevProps.cus_id !== this.props.cus_id)) {
            this.fetchData()
            this.fetchProjData()
        }

        if (this.state.contextMenu === 1 && !!this.state.editLoading) {
            await this.fetchCusEditData()
        }

        if (prevProps.new_customer !== this.props.new_customer) {
            this.setState({ contextMenu: 2 })
        }

        if ((this.state.contextMenu === 2 && !!this.state.newCustLoading)) {
            await this.fetchNewCustData()
        }
        if (this.props.cust_details.cus_id !== prevProps.cust_details.cus_id) {
            this.setState({ image: null })
            this.fetchCustomerImage();
        }
    }

    showMore = () => {
        var dots = document.getElementById("dots");
        var moreText = document.getElementById("more");
        // var btnText = document.getElementById("myBtn");

        if (dots.style.display === "none") {
            dots.style.display = "inline";
            this.setState({ showMore: false });
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            this.setState({ showMore: true })
            moreText.style.display = "inline";
        }
    }

    isJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    generateNoteCard = (note) => {
        let audioSrc;

        if (note.audio_note) {
            const decodedData = Buffer.from(note.audioNote[0].audio_note, 'base64');

            const blob = new Blob([decodedData], { type: 'audio/wav' });

            audioSrc = URL.createObjectURL(blob);
        }

        return (
            (<Card sx={{ marginBottom: '20px' }}>
                <CardActions sx={{ paddingLeft: '16px' }}>
                    <div style={{ width: '80%' }}>
                        {note.note_title && <h6 style={{ marginBottom: '0px' }}>{note.note_title}</h6>}
                        {!note.note_title ?
                            <h6 style={{ marginBottom: '0px' }}>{note.tas_title ? note.tas_title : note.pro_title ? note.pro_title : note.cus_name}</h6> :
                            <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{note.tas_title ? note.tas_title : note.pro_title ? note.pro_title : note.cus_name}</p>
                        }
                        <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{note.tas_type}</p>
                        <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{moment(note.created_at).format('MM/DD/YYYY hh:mm A')} created by {note.emp_first_name + ' ' + note.emp_last_name} ({note.emp_type})</p>
                    </div>
                    {!note.audioNote &&
                        <div style={note.tas_id != null ? { width: '20%', paddingBottom: '40px' } : { width: '20%', paddingBottom: '0px' }}>
                            <IconButton
                                style={{ float: 'right' }}
                                onClick={() => {
                                    // console.log(note);
                                    this.setState({ editNote: note })
                                }}
                            >
                                <Icon style={{ fontSize: '16px' }} baseClassName="fas" className="fa-edit" />
                            </IconButton>
                        </div>
                    }
                </CardActions>
                <CardContent sx={{ paddingBottom: '0px', paddingTop: '0px' }}>
                    <hr style={{ marginBottom: '10px', marginTop: '0px', backgroundColor: '#e2ecfa' }}></hr>
                    {!note.audioNote &&
                        <ThemeProvider theme={myTheme}>
                            <MUIRichTextEditor
                                defaultValue={this.isJSON(note.comment_text) ? note.comment_text : `{"blocks":[{"key":"block1","text":"${note.comment_text.replace(/\n/g, ' ').replace(/\t/g, ' ').replace(/"/g, '\\"')}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`}
                                readOnly={true}
                                toolbar={false}
                            />
                        </ThemeProvider>}
                    {/* <p style={{ marginBottom: '0px', fontSize: '1rem' }}>{note.comment_text}</p> */}
                    {note.audioNote && <audio controls src={audioSrc} />}
                </CardContent>
            </Card>)
        );
    }

    generateNoteCards = (noteList) => {
        let cards = []
        cards = noteList.map((note) => {
            return this.generateNoteCard(note)
        })
        this.setState({ content: cards })
    }

    navigationHandler = (contextMenu) => {
        this.setState({ contextMenu })
    }

    render() {
        let phoneNumbers = []
        let addresses = []
        try {
            phoneNumbers = this.props.cust_details.cus_phone.split(',')
            addresses = this.props.cust_details.cus_address.split(':')
        } catch (err) {
            console.log(err)
        }
        let timeSpent = this.calCumulativeTimeSpent()

        let content = null;
        if (this.state.contextMenu === 2) {
            content =
                <Dialog fullWidth={true} maxWidth={'sm'} open={this.state.contextMenu === 2} onClose={() => { this.navigationHandler(0); this.props.resetNew() }}>
                    <DialogActions>
                        <h4 style={{ marginLeft: '20px', marginTop: '10px' }}>New Customer</h4>
                        <hr style={{ marginBottom: '10px', marginTop: '10px', backgroundColor: '#e2ecfa' }}></hr>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => this.navigationHandler(0)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        {!!this.state.newCustLoading &&
                            <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!this.state.newCustLoading && <CustomerForm
                            {...this.props}
                            isFormValidated={this.props.isNewFormValidated}
                            states={this.state.locations.states}
                            customerType={this.state.customers.customerType}
                            customerStatus={this.state.customers.customerStatus}
                            empList={this.state.employees.list}
                            customerList={this.state.customers.list}
                            comp_id={this.props.comp_id}
                            customerPage={true}
                            user={this.props.user}
                        />}
                    </DialogContent>
                </Dialog>
        }
        else if (this.state.contextMenu === 1) {
            content =
                <Dialog fullWidth={true} maxWidth={'sm'} open={this.state.contextMenu === 1} onClose={() => this.navigationHandler(0)}>
                    <DialogActions>
                        <h4 style={{ marginLeft: '20px', marginTop: '10px' }}>Edit Customer Details</h4>
                        <hr style={{ marginBottom: '10px', marginTop: '10px', backgroundColor: '#e2ecfa' }}></hr>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => this.navigationHandler(0)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        {!!this.state.editLoading &&
                            <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!this.state.editLoading && <CustomerForm
                            history={this.props.history}
                            isFormValidated={this.props.isFormValidated}
                            customerStatus={this.state.customerStatus}
                            customerType={this.state.customerType}
                            states={this.props.states}
                            empList={this.state.empList}
                            tasklist={this.props.project_tasks}
                            loc_list={this.state.loc_list}
                            edit={true}
                            comp_id={this.props.comp_id}
                            // navigationHandler={this.navigationHandler}
                            cusDets={this.props.cust_details}
                            pId={this.state.pId}
                            pManagerId={this.state.pManagerId}
                            user={this.props.user}
                        />}
                    </DialogContent>
                </Dialog>

        }
        else if (this.state.contextMenu === 0) {
            content = (
                <div>
                    <div className="overview-top">
                        <div className='home_info'>{this.props.cust_details.cus_name}
                            <Tooltip title="Download Customer Badge PDF">
                                <IconButton
                                    style={{ float: 'right' }}
                                    onClick={async () => {
                                        const response = await axios.get(APIURL + `/customers/detailsPDF`, {
                                            params: { customer_id: this.props.cust_details.cus_id },
                                            responseType: 'blob'
                                        });
                                        const url = window.URL.createObjectURL(new Blob([response.data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', 'customer.pdf');
                                        document.body.appendChild(link);
                                        link.click();
                                    }}
                                >
                                    <Icon style={{ fontSize: '16px' }} baseClassName="fas" className="fa-qrcode" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Upload Customer Image">
                                <IconButton style={{ float: 'right', fontsize: '10px' }} onClick={(event) => {
                                    event.preventDefault();
                                    document.getElementById("fileInput").click();
                                }}>
                                    <Icon style={{ fontSize: '18px' }} baseClassName="fas" className="fa-image" />
                                </IconButton>
                            </Tooltip>
                            <input
                                id="fileInput"
                                type="file"
                                accept=".jpeg"
                                style={{ display: 'none' }}
                                onChange={async (event) => {
                                    let inputFile = event.target.files[0];
                                    let formData = new FormData();
                                    formData.append("file", inputFile);
                                    formData.append("customer_id", this.props.cust_details.cus_id);
                                    const response = await axios.post(APIURL + `/customers/image`, formData, {});
                                    // Reset the value of the file input field
                                    event.target.value = null;
                                    this.fetchCustomerImage();
                                }}
                            />

                            <Tooltip title="Edit Customer">
                                <IconButton
                                    style={{ float: 'right' }}
                                    onClick={() => this.navigationHandler(1)}
                                >
                                    <Icon style={{ fontSize: '16px' }} baseClassName="fas" className="fa-edit" />
                                </IconButton>
                            </Tooltip>


                            <div className="customer-info-block row col-sm-4 pt-3">
                                {this.state.image ?
                                    <img src={`data:image/jpeg;base64,${this.state.image}`} style={{ borderRadius: '5px', maxWidth: '128px' }} />
                                    : <></>}
                            </div>
                            <div className="customer-info-block row">
                                <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">CUSTOMER ID</div>
                                <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.cust_details.cus_id}</div>
                                {/* <span className="customerpageDetailsEditbtn col-sm-1 pt-3"> <i className="fas fa-edit fa-2px" style={{ cursor: "pointer" }} onClick={''}></i></span> */}
                            </div>
                            <div className="customer-info-block row">
                                <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">CUSTOMER DDD ID</div>
                                <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.cust_details.DDDID}</div>
                            </div>
                            <div className="customer-info-block row">
                                <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">LOCAL CUSTOMER ID</div>
                                <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.cust_details.cus_local_id}</div>
                            </div>
                            <div className="customer-info-block row">
                                <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">TYPE</div>
                                <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.cust_details.cus_type}</div>
                            </div>
                            <div className="customer-info-block row">
                                <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">STATUS</div>
                                <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.cust_details.cus_status}</div>
                            </div>
                            <div className="customer-info-block row">
                                <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">EMAIL</div>
                                <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.cust_details.cus_email}</div>
                            </div>
                            <div className="customer-info-block row">
                                <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">PHONE</div>
                                <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{phoneNumbers[0]}</div>
                            </div>
                            <div className="customer-info-block row">
                                <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">ADDRESS</div>
                                <div style={{ fontSize: '1rem' }} className="col-sm-7 pl-0 pt-3">{addresses[0]}</div>
                            </div>
                            <span id="dots"></span><span id="more">
                                <div className="customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">DATE OF BIRTH</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{moment(this.props.cust_details.dob.replace(/Z/g, "")).format('MMMM DD, YYYY')}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">ICD-10 DIAGNOSIS</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.cust_details.diagnose_code}</div>
                                </div>
                                <div className="supportcordinator customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">SUPPORT CORDINATOR</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{'none'}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">GENDER</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.cust_details.gender}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">MEDICAID ELIGIBILITY</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.cust_details.medicaid_eligible}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">PLAN APPROVAL DATE</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.project_details === undefined || this.props.project_details === null || !this.props.cust_details.last_plan_approval_date ? '' : moment(this.props.cust_details.last_plan_approval_date).format('MM/DD/YYYY')}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">PLAN START DATE</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.project_details === undefined || this.props.project_details === null ? '' : moment().format('MM/DD/YYYY')}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">PLAN END DATE</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.project_details === undefined || this.props.project_details === null || !this.props.cust_details.sign_page_date ? '' : moment(this.props.cust_details.sign_page_date).format('MM/DD/YYYY')}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">ACQUIRED DATE</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{moment(this.props.cust_details.cus_acquired_date).format('MM/DD/YYYY')}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">TRANSFER OUT REASON</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{this.props.cust_details.TransferOutReason ?? ''}</div>
                                </div>
                                <div className="customer-info-block row">
                                    <div style={{ fontSize: '1rem' }} className="col-sm-4 pt-3 text-muted">TRANSFER OUT DATE</div>
                                    <div style={{ fontSize: '1rem' }} className="col-sm-13 pt-3">{!!this.props.cust_details.TransferOutDate ? moment(this.props.cust_details.TransferOutDate).utc().format('MM/DD/YYYY') : ''}</div>
                                </div>
                            </span>
                            <div className="customer-info-block row">
                                <button style={{ fontSize: '1rem', background: '#F9F9F9' }} className="col-sm-4 pt-3 text-muted" onClick={this.showMore} id="myBtn">{this.state.showMore ? "Show less" : "Show more"} {this.state.showMore ? <i className="fas fa-chevron-up" ></i> : <i className="fas fa-chevron-down" ></i>}</button>
                            </div>
                        </div>
                        <div className='projects-overview'>Projects Overview
                            <div>
                                <Accordion style={{ marginTop: "10px" }}>
                                    <AccordionSummary
                                        // expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div id='contact_call_icon'>
                                            <i className="far fa-clock fa-sm"></i>
                                        </div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <Typography style={{ fontSize: '1rem' }} className="text-muted">TIME SPENT ON TASKS</Typography>
                                            <Typography style={{ fontSize: '1rem' }} >{timeSpent}</Typography>
                                            <Typography style={{ fontSize: '1rem' }} >spent on {this.state.taskCount} tasks</Typography>
                                        </div>
                                    </AccordionSummary>
                                </Accordion>
                                <Accordion style={{ marginTop: "10px" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div id='contact_call_icon'>
                                            <i className="fas fa-phone-alt fa-sm fa-rotate-270"></i>
                                        </div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <Typography style={{ fontSize: '1rem' }} className="text-muted">PHONE NUMBERS</Typography>
                                            {/* <Typography style={{ fontSize: '1.15rem' }} className="font-weight-bold col-sm-7">{phoneNumbers.length > 1 ? phoneNumbers.length + " phone numbers" : phoneNumbers.length + " phone number"}</Typography> */}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="d-flex flex-column">
                                            {
                                                phoneNumbers.map((number, i) => {
                                                    return <Typography key={i}><i style={{ paddingRight: "15px", paddingLeft: "15px" }} className="fas fa-phone pb-3"></i><span>{number}</span></Typography>
                                                })
                                            }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginTop: "10px" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div id='contact_call_icon'>
                                            <i className="fas fa-home fa-sm"></i>
                                        </div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <Typography style={{ fontSize: '1rem' }} className="text-muted">LOCATIONS</Typography>
                                            {/* <Typography style={{ fontSize: '1.15rem' }} className="font-weight-bold col-sm-7">{addresses.length > 1 ? addresses.length + " locations" : addresses.length + " location"}</Typography> */}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="d-flex flex-column">
                                            {
                                                addresses.map((address, i) => {
                                                    return <Typography key={i}><i style={{ paddingRight: "10px", paddingLeft: "15px" }} className="fas fa-map-marker-alt pb-3"></i><span>{address}</span></Typography>
                                                })
                                            }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginTop: "10px" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div id='contact_call_icon'>
                                            <i className="fas fa-tasks fa-sm"></i>
                                        </div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <Typography style={{ fontSize: '1rem' }} className="text-muted">TASK TYPES</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="d-flex flex-column">
                                            {
                                                this.state.taskTypes.map((type, i) => {
                                                    return <Typography key={i}><span style={{ marginLeft: "20px" }}>{'- ' + type}</span></Typography>
                                                })
                                            }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className="overview-bottom">
                        <div className='recent-notes'>
                            <div className='notes-header d-flex flex-column'>
                                <div style={{ fontSize: '25px' }}>Recent Notes</div>
                                <div style={{ color: '#737373', opacity: '87%', fontSize: '11px', fontStyle: 'italic' }}>Your recent 5 notes</div>
                            </div>

                            {!!this.state.loading &&
                                <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!this.state.loading && this.state.displayNotes.length === 0 &&
                                <div style={{ margin: 30, height: '100%' }} className='d-flex flex-column justify-content-center align-items-center'>
                                    {<MessagePage title="No Recent Notes Found" icon="far fa-sticky-note"></MessagePage>}
                                </div>
                            }
                            <div className='notes-content'>{this.state.content}</div>
                            <Dialog fullWidth={true} maxWidth={'sm'} open={this.state.editNote != null} >
                                <DialogActions>
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        sx={{ p: 0.25 }}
                                        onClick={() => this.setState({ editNote: null })}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogActions>
                                <DialogContent sx={{ paddingTop: '0px' }}>
                                    <NoteFormv2 mode='edit' checkTokenExpiry={this.props.checkTokenExpiry} project_id={this.state.editNote?.proj_id} task_id={this.state.editNote?.tas_id} note_id={this.state.editNote?.comment_id} user={this.props.user} customer_id={this.props.cus_id} company_id={this.props.comp_id} onSubmit={() => this.setState({ editNote: null })}></NoteFormv2>
                                </DialogContent>
                            </Dialog>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                {content}
            </div>
        )
    }
}

export default Overview;