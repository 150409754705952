import React, { useState, useEffect } from "react"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

const columns = [
    
    { field: 'id', headerName: 'id', width: 170, hide:true },
    { 
        field: 'description', 
        headerName: 'DESCRIPTION', 
        flex:1,
        renderCell : (params) =>{
         return(
            <div>
                <p className="text-wrap" style={{lineHeight:"1"}}>{params.value} </p>
            </div>
        )}
    },
    { 
        field: 'unit', 
        headerName: 'UNIT', 
        width:130,
    },
    {
        field: 'code',
        headerName: 'CODE',
        width:115,
        align:"center"
    },
    {
        field: 'modifierCode',
        headerName: 'MODIFIER',
        width:150,
    },
    {
        field: 'taskType',
        headerName: 'TYPE',
        width:135,
    }
    
]

// Hardcoded Data only for front end ----
const rows = [
    { id: 1, description: 'Services performed by a qualified physical therapist in the home health or hospice setting', unit: 'Each 15 Minutes', code: 'G0151', modifierCode:'', taskType:''},
    { id: 2, description: 'Services performed by a qualified occupational therapist in the home health or hospice setting', unit: 'Each 15 Minutes', code: 'G0152', modifierCode:'', taskType:''},
    { id: 3, description: 'Services performed by a qualified speech-language pathologist in the home health or hospice setting', unit: 'Each 15 Minutes', code: 'G0153', modifierCode:'', taskType:''},
    { id: 4, description: 'Direct skilled nursing services of a registered nurse (rn) in the home health or hospice setting', unit: 'Each 15 Minutes', code: 'G0299', modifierCode:'', taskType:''},
    { id: 5, description: 'Direct skilled nursing services of a registered nurse (rn) in the home health or hospice setting (Two patients served)', unit: 'Each 15 Minutes', code: 'G0299', modifierCode:':UN', taskType:''},
    { id: 6, description: 'Direct skilled nursing services of a registered nurse (rn) in the home health or hospice setting (Three patients served)', unit: 'Each 15 Minutes', code: 'G0299', modifierCode:':UP', taskType:''},
    { id: 7, description: 'Direct skilled nursing services of a licensed practical nurse (lpn) in the home health or hospice setting', unit: 'Each 15 Minutes', code: 'G0300', modifierCode:'', taskType:''},
    { id: 8, description: 'Direct skilled nursing services of a licensed practical nurse (lpn) in the home health or hospice setting (Two patients served)', unit: 'Each 15 Minutes', code: 'G0300', modifierCode:':UN', taskType:''},
    { id: 9, description: 'Direct skilled nursing services of a licensed practical nurse (lpn) in the home health or hospice setting (Three patients served)', unit: 'Each 15 Minutes', code: 'G0300', modifierCode:':UP', taskType:''},
    { id: 10, description: 'Attendant care services (Agency with Choice; Family member or non-spouse not residing at home)', unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':U7:U4', taskType:''},
    { id: 11, description: "Attendant care services (Agency with Choice; Family member or non-spouse residing in member's home)", unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':U7:U5', taskType:''},
    { id: 12, description: 'Attendant care services (Agency with Choice; Spouse - limit to 160 units per week)', unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':U7:U3', taskType:''},
    { id: 13, description: 'Attendant care services', unit: 'Per 15 Minutes', code: 'S5125', modifierCode:'', taskType:''},
    { id: 14, description: 'Attendant care services (Two patients served)', unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':UN', taskType:''},
    { id: 15, description: 'Attendant care services (Three patients served)', unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':UP', taskType:''},
    { id: 16, description: 'Attendant care services (Unskilled Self-directed care)', unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':U2', taskType:''},
    { id: 17, description: 'Attendant care services (Spouse - limit to 160 units per week)', unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':U3', taskType:''},
    { id: 18, description: 'Attendant care services (Family member or non-spouse not residing at home)', unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':U4', taskType:''},
    { id: 19, description: "Attendant care services (Family member or non-spouse residing in member's home)", unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':U5', taskType:''},
    { id: 20, description: 'Attendant care services (Skilled Self-directed care)', unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':U6', taskType:''},
    { id: 21, description: 'Attendant care services (Agency with Choice)', unit: 'Per 15 Minutes', code: 'S5125', modifierCode:':U7', taskType:''},
    { id: 22, description: 'Homemaker service, nos', unit: 'Per 15 Minutes', code: 'S5130', modifierCode:'', taskType:''},
    { id: 23, description: 'Homemaker service, nos (Agency with Choice)', unit: 'Per 15 Minutes', code: 'S5130', modifierCode:':U7', taskType:''},
    { id: 24, description: 'Companion care, adult (e.g. iadl/adl)', unit: 'Per 15 Minutes', code: 'S5135', modifierCode:'', taskType:''},
    { id: 25, description: 'Companion care, adult', unit: 'Per Diem', code: 'S5136', modifierCode:'', taskType:''},
    { id: 26, description: 'Unskilled respite care_15M', unit: 'Per 15 Minutes', code: 'S5150', modifierCode:'', taskType:''},
    { id: 27, description: 'Unskilled respite care_15M (Group setting)', unit: 'Per 15 Minutes', code: 'S5150', modifierCode:':HQ', taskType:''},
    { id: 28, description: 'Unskilled respite care_15M (Two patients served)', unit: 'Per 15 Minutes', code: 'S5150', modifierCode:':UN', taskType:''},
    { id: 29, description: 'Unskilled respite care_15M (Three patients served)', unit: 'Per 15 Minutes', code: 'S5150', modifierCode:':UP', taskType:''},
    { id: 30, description: 'Unskilled respite care_15M (Four patients served)', unit: 'Per 15 Minutes', code: 'S5150', modifierCode:':UQ', taskType:''},
    { id: 31, description: 'Unskilled respite care_15M (Six or more patients served)', unit: 'Per 15 Minutes', code: 'S5150', modifierCode:':US', taskType:''},
    { id: 32, description: 'Unskilled respite care_15M (Spouse - limit to 160 units per week)', unit: 'Per 15 Minutes', code: 'S5150', modifierCode:':U3', taskType:''},
    { id: 33, description: 'Unskilled respite care_15M (Family member or nonspouse not residing at home)', unit: 'Per 15 Minutes', code: 'S5150', modifierCode:':U4', taskType:''},
    { id: 34, description: "Unskilled respite care_15M (Family member or nonspouse residing in member's home)", unit: 'Per 15 Minutes', code: 'S5150', modifierCode:':U5', taskType:''},
    { id: 35, description: 'Unskilled respite care_15M (Agency with Choice)', unit: 'Per 15 Minutes', code: 'S5150', modifierCode:':U7', taskType:''},
    { id: 36, description: 'Unskilled respite care_PD (Agency with Choice)', unit: 'Per Diem', code: 'S5151', modifierCode:':U7', taskType:''},
    { id: 37, description: 'Unskilled respite care_PD', unit: 'Per Diem', code: 'S5151', modifierCode:'', taskType:''},
    { id: 38, description: 'Unskilled respite care_PD (Two patients served)', unit: 'Per Diem', code: 'S5151', modifierCode:':UN', taskType:''},
    { id: 39, description: 'Unskilled respite care_PD (Three patients served)', unit: 'Per Diem', code: 'S5151', modifierCode:':UP', taskType:''},
    { id: 40, description: 'Unskilled respite care_PD (Four patients served)', unit: 'Per Diem', code: 'S5151', modifierCode:':UQ', taskType:''},
    { id: 41, description: 'Unskilled respite care_PD (Five patients served)', unit: 'Per Diem', code: 'S5151', modifierCode:':UR', taskType:''},
    { id: 42, description: 'Unskilled respite care_PD (Six or more patients served)', unit: 'Per Diem', code: 'S5151', modifierCode:':US', taskType:''},
    { id: 43, description: 'Unskilled respite care_PD (Spouse - limit to 160 units per week)', unit: 'Per Diem', code: 'S5151', modifierCode:':U3', taskType:''},
    { id: 44, description: 'Unskilled respite care_PD (Family member or non-spouse not residing at home)', unit: 'Per Diem', code: 'S5151', modifierCode:':U4', taskType:''},
    { id: 45, description: "Unskilled respite care_PD (Family member or nonspouse residing in member's home)", unit: 'Per Diem', code: 'S5151', modifierCode:':U5', taskType:''},
    { id: 46, description: 'Home health respiratory, nos', unit: 'Per Diem', code: 'S5181', modifierCode:'', taskType:''},
    { id: 47, description: 'Nursing care, in the home; by registered nurse', unit: 'Per Hour', code: 'S9123', modifierCode:'', taskType:''},
    { id: 48, description: 'Nursing care, in the home; by registered nurse (Two patients served)', unit: 'Per Hour', code: 'S9123', modifierCode:':UN', taskType:''},
    { id: 49, description: 'Nursing care, in the home; by registered nurse (Three patients served)', unit: 'Per Hour', code: 'S9123', modifierCode:':UP', taskType:''},
    { id: 50, description: 'Nursing care, in the home; by registered nurse (Services provided, Morning)', unit: 'Per Hour', code: 'S9123', modifierCode:':UF', taskType:''},
    { id: 51, description: 'Nursing care, in the home; by registered nurse (Services provided, Afternoon)', unit: 'Per Hour', code: 'S9123', modifierCode:':UG', taskType:''},
    { id: 52, description: 'Nursing care, in the home; by licensed practical nurse', unit: 'Per Hour', code: 'S9124', modifierCode:'', taskType:''},
    { id: 53, description: 'Nursing care, in the home; by licensed practical nurse (Two patients served)', unit: 'Per Hour', code: 'S9124', modifierCode:':UN', taskType:''},
    { id: 54, description: 'Nursing care, in the home; by licensed practical nurse (Three patients served)', unit: 'Per Hour', code: 'S9124', modifierCode:':UP', taskType:''},
    { id: 55, description: 'Speech therapy, in the home', unit: 'Per Diem', code: 'S9128', modifierCode:'', taskType:''},
    { id: 56, description: 'Occupational therapy, in the home', unit: 'Per Diem', code: 'S9129', modifierCode:'', taskType:''},
    { id: 57, description: 'Physical therapy; in the home', unit: 'Per Diem', code: 'S9131', modifierCode:'', taskType:''},
    { id: 58, description: 'Personal care services (Agency with Choice)', unit: 'Per 15 Minutes', code: 'T1019', modifierCode:':U7', taskType:''},
    { id: 59, description: 'Personal care services', unit: 'Per 15 Minutes', code: 'T1019', modifierCode:'', taskType:''},
    { id: 60, description: 'Home Health Aide (Family Member not residing at home)', unit: '', code: 'T1021', modifierCode:':U4', taskType:''},
    { id: 61, description: 'Home Health Aide (Family Member residing in member’s home)', unit: '', code: 'T1021', modifierCode:':U5', taskType:''},
    { id: 62, description: 'Services performed by a home health aide or certified nurse assistant', unit: 'Per Visit', code: 'T1021', modifierCode:'', taskType:''},
    { id: 63, description: 'Habilitation (Agency with Choice)', unit: '15 Minutes', code: 'T2017', modifierCode:':U7', taskType:''},
    { id: 64, description: 'Habilitation', unit: '15 Minutes', code: 'T2017', modifierCode:'', taskType:''},
    { id: 65, description: 'Habilitation (Three patients served)', unit: '15 Minutes', code: 'T2017', modifierCode:':UP', taskType:''},
    { id: 66, description: 'Habilitation (Two patients served)', unit: '15 Minutes', code: 'T2017', modifierCode:':UN', taskType:''},
]

export const Procedure = (props) => {
    
    const [loggedToSegment, setLoggedToSegment] = useState(false);

    const logToSegment = () => {
        if(loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        console.log(page,window.location.href)
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page)
        analytics.page(page.toUpperCase() + " : Procedure",{
            title:page.toUpperCase() + " : Procedure",
            path:page.toUpperCase() + " : Procedure",
        }, {
          userId:String(props.user.emp_id),
        })
        console.log("logged to segment successfully")
        setLoggedToSegment(true);
    }

    useEffect(() => {
        logToSegment();
    }, []);
    
    return (
        (<div style={{margin:"auto", width:'100%', display: 'flex'}}>
            <DataGrid 
                rows={rows} 
                columns={columns} 
                autoHeight={true}
                showToolbar slots={{toolbar: GridToolbar, }} 
                checkboxSelection={true} 
                pageSize={16} />
        </div>)
    );
}
