import React, { useEffect, useState } from 'react';
import { Form, FormBuilder } from '@formio/react';
import { Button as MUIButton, Typography, TextField, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button } from "react-bootstrap";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import withStyles from "@mui/styles/withStyles";
import axios from 'axios'
import { APIURL } from '../../../config';

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#3FA7D6",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#3FA7D6",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "lightblue",
            },
            "&:hover fieldset": {
                borderColor: "#3FA7D6",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3FA7D6",
            },
        },
    },
})(TextField);

export default function FormLibrary(props) {
    const [formSchema, setFormSchema] = useState('');
    const [formList, setFormList] = useState([]);
    const [formNamesList, setFormNamesList] = useState([]);
    const [subscribedForms, setSubscribedForms] = useState([]);
    const [publicForms, setPublicForms] = useState([]);
    const [formID, setFormID] = useState('');
    const [formName, setFormName] = useState('');
    const [formDesc, setFormDesc] = useState('');
    const [formType, setFormType] = useState('');
    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [errorAlert, setErrorAlert] = useState('');
    const [successAlert, setSuccessAlert] = useState('');
    const [open, setOpen] = useState(false);
    const [maxFormID, setMaxFormID] = useState(0);
    const [formCopyTitle, setFormCopyTitle] = useState('');
    const [formCopyDesc, setFormCopyDesc] = useState('');
    const [formCopyType, setFormCopyType] = useState('');

    useEffect(async () => {
        const maxFormID = await axios.get(`${APIURL}/task_form/formBuilder`);
        const forms = await axios.get(`${APIURL}/task_form/forms`);
        const subscribedForms = await axios.get(`${APIURL}/task_form/subscribed?company_id=${props.comp_id}`)

        let formio_forms = forms.data.filter((form) => form.form_company === props.comp_id && !form.public_form);
        let subscribed_forms = subscribedForms.data;
        formio_forms = formio_forms.filter(form => !subscribed_forms.some(subForm => subForm.form_id === form.form_id));
        let public_forms = forms.data.filter((form) => form.public_form);

        setMaxFormID(maxFormID.data[0][""]);
        setFormNamesList(forms.data.map((form) => form.form_name));
        setFormList(formio_forms);
        setSubscribedForms(subscribed_forms);
        setPublicForms(public_forms);
        setLoading(false);
    }, [open]);

    const handleFormSelect = (form) => {
        setFormSchema(form.json_schema);
        setIsEdit(false);
        setFormID(form.form_id);
        setFormName(form.form_name);
        setFormDesc(form.form_description);
        setFormType(form.form_type);
    }

    const handleFormSchemaChange = (schema) => {
        schema.display = 'form';
        setFormSchema(JSON.stringify(schema));
    }

    const editForm = async () => {
        await axios.put(APIURL + `/task_form/formBuilder`, {
            form_id: formID,
            form_name: formName,
            json_schema: JSON.parse(formSchema),
        })
            .then(function (response) {
                setSuccessAlert('Form successfully edited. Please reload!')
            })
            .catch(function (error) {
                console.log(error);
                setErrorAlert('An error occured while trying to edit the form')
            });

        setIsEdit(false);
    };

    const addFormCopy = async () => {
        if (formNamesList.includes(formCopyTitle)) {
            alert(`Form Title "${formCopyTitle}" already exists. Please choose a different title.`);
            return;
        }

        if (formCopyTitle.replace(/\s/g, '').length !== 0 && formCopyType.replace(/\s/g, '').length !== 0) {
            await axios.post(APIURL + `/task_form/formBuilder`, {
                form_id: maxFormID + 1,
                form_name: formCopyTitle,
                form_description: formCopyDesc,
                form_added_by: props.user.emp_id,
                form_type: formCopyType,
                json_schema: JSON.parse(formSchema),
                form_company: props.comp_id,
                public_form: 0,
            })
                .then(function (response) {
                    setSuccessAlert('Form successfully added')
                    setOpen(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorAlert('An error occured while trying to add the form')
                    setOpen(false);
                });
        } else {
            if (!formCopyTitle) {
                alert(`Form Title is required to add the form`)
            }
            else if (!formCopyType) {
                alert(`Form Type is required to add the form`)
            } else {
                alert(`Please enter the required fields to add the form`)
            }
        }
    };

    const handleCopyButtonClick = async () => {
        setOpen(true);
        setFormCopyTitle(`Copy 0 of the ${formName}`);
        setFormCopyDesc(formDesc);
        setFormCopyType(formType);
    };

    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorAlert('');
    }

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessAlert('');
    }

    return (
        <div style={{ marginRight: '40px' }}>
            <Typography
                style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "Lato",
                    marginBottom: 10,
                }}
            >
                Form Library
            </Typography>
            <div style={{ maxHeight: '33vh' }}>
                {!!loading && (
                    <div className="d-flex flex-row w-100 justify-content-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {!loading &&
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div style={{ width: '33%' }}>
                            <h5 style={{ marginBottom: 10 }}>Your Forms</h5>
                            <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                {formList.map((form) => (
                                    <div key={form.form_id}>
                                        <MUIButton onClick={() => { handleFormSelect(form); setIsEditable(true); }}>
                                            {form.form_name}
                                        </MUIButton>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ width: '33%', borderLeft: '1px solid #ccc', paddingLeft: '10px' }}>
                            <h5 style={{ marginBottom: 10 }}>Community Forms</h5>
                            <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                {publicForms.map((form) => (
                                    <div key={form.form_id}>
                                        <MUIButton onClick={() => { handleFormSelect(form); setIsEditable(false); }}>
                                            {form.form_name}
                                        </MUIButton>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ width: '33%', borderLeft: '1px solid #ccc', paddingLeft: '10px' }}>
                            <h5 style={{ marginBottom: 10 }}>Subscribed Forms</h5>
                            <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                {subscribedForms.map((form) => (
                                    <div key={form.form_id}>
                                        <MUIButton onClick={() => { handleFormSelect(form); setIsEditable(false); }}>
                                            {form.form_name}
                                        </MUIButton>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <hr style={{ borderTop: '1px solid #ccc', margin: '20px 0px' }} />
            {!isEdit && <h4 style={{ textDecoration: 'underline' }}>{formName}</h4>}
            {formSchema && !isEdit &&
                <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -45 }}>
                        {isEditable &&
                            <Button
                                style={{
                                    width: '10%',
                                }}
                                onClick={() => setIsEdit(true)}
                                disabled={isEditable ? false : true}
                            >
                                Edit Form
                            </Button>
                        }

                        <Button
                            style={{
                                width: '10%',
                                marginLeft: 10
                            }}
                            onClick={handleCopyButtonClick}
                        >
                            Copy Form
                        </Button>
                    </div>
                    <div style={{ border: '1px solid #ccc', padding: 10, marginTop: isEditable ? 10 : 20 }}>
                        <Form form={JSON.parse(formSchema)} options={{ readOnly: true }} onSubmit={() => setErrorAlert('This is only a test. For submitting an actual form, please follow the appropriate steps.')} />
                    </div>
                </div>
            }
            {isEdit &&
                <div style={{ marginBottom: 50 }}>
                    <div>
                        <CssTextField
                            value={formName}
                            onChange={(event) => {
                                setFormName(event.target.value)
                            }}
                            style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                            inputProps={{ maxLength: 50 }}
                            label="Form Title *"
                            id="fTitle"
                            error={formName ? false : true}
                        />
                    </div>
                    <div style={{ border: '1px solid #ccc', padding: 10, marginTop: 20 }}>
                        <FormBuilder form={JSON.parse(formSchema)} options={{ builder: { premium: false } }} onChange={(schema) => handleFormSchemaChange(schema)} />
                    </div>
                    <hr style={{ borderTop: '1px solid #ccc', margin: '20px 0' }} />
                    <Button
                        style={{
                            width: '10%',
                            marginTop: '30px',
                        }}
                        onClick={() => editForm()}
                    >
                        Save Form
                    </Button>
                    <Button
                        style={{
                            width: '10%',
                            marginTop: '30px',
                            marginLeft: '20px'
                        }}
                        onClick={() => setIsEdit(false)}
                    >
                        Cancel
                    </Button>
                </div>
            }
            <Snackbar open={successAlert != ''} autoHideDuration={5000} onClose={handleSuccessAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"success"} elevation={6} variant="filled" {...props}>
                    {successAlert}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={handleSuccessAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
            <Snackbar open={errorAlert != ''} autoHideDuration={5000} onClose={handleErrorAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"error"} elevation={6} variant="filled" {...props}>
                    {errorAlert}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={handleErrorAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>

            <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={() => setOpen(false)}>
                <DialogActions>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{ paddingTop: '0px' }}>
                    <h4>Copy Form</h4>
                    <CssTextField
                        value={formCopyTitle}
                        onChange={(event) => {
                            setFormCopyTitle(event.target.value)
                        }}
                        style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                        inputProps={{ maxLength: 50 }}
                        label="Form Title *"
                        id="fTitle"
                        error={formNamesList.includes(formCopyTitle) || !formCopyTitle}
                        helperText={formNamesList.includes(formCopyTitle) ? "Form Title already exists" : ""}
                    />
                    <CssTextField
                        value={formCopyDesc}
                        onChange={(event) => {
                            setFormCopyDesc(event.target.value)
                        }}
                        style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                        inputProps={{ maxLength: 50 }}
                        label="Form Description"
                        id="fDesc"
                    />
                    <CssTextField
                        value={formCopyType}
                        onChange={(event) => {
                            setFormCopyType(event.target.value)
                        }}
                        style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                        inputProps={{ maxLength: 50 }}
                        label="Form Type *"
                        id="fType"
                        error={formCopyType ? false : true}
                    />
                    <Button
                        style={{
                            width: '100%',
                            padding: '5px',
                            margin: '5px',
                        }}
                        onClick={() => addFormCopy()}
                    >
                        Copy Form
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )
}