import React from 'react';
import { Form, Button, ListGroup } from 'react-bootstrap';
import { ReactBingmaps } from 'react-bingmaps';
// import { Emoji } from 'emoji-mart'
import {BINGAPIKEY} from '../../../../config'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@mui/styles/withStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Grid from '@mui/material/Grid';
import moment from 'moment';
import ccs from 'countrycitystatejson';
import './DateLocation.css'
import dayjs from 'dayjs';

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
  })(TextField);

const DateLocation = (props) => {
    const { locList } = props;
    // let curr = currency.map((cval,indx)=> <option key={indx} value={cval}><div><Emoji emoji=':santa::skin-tone-3:' size={16} /> {cval}</div></option>)
    // let pLoc = locList.map((loc, indx) => (<option key={indx} value={loc.id}>{loc.location_addr1 + " " + loc.location_addr2 + ", " + loc.location_city + ", " + loc.location_state + ", " + loc.location_zip}</option>))
    let pLoc = locList.map((loc, indx) => {
        return{value:loc.id, title:loc.location_addr1 + " " + loc.location_addr2 + ", " + loc.location_city + ", " + loc.location_state + ", " + loc.location_zip}})
   
    // let pLoc = locList.map((loc,indx)=> {
    //     if(cusLoc.some((custlocation)=>{return custlocation.ploc_loc_id === loc.id})){
    //         return <option key={indx} value={loc.id}>{loc.location_addr1 +" "+ loc.location_addr2 +", "+ loc.location_city +", "+ loc.location_state +", "+ loc.location_zip}</option>
    //     }
    //     return null  <Emoji emoji=':santa::skin-tone-3:' size={16} /> <span dangerouslySetInnerHTML={{__html: Emoji({html: true,set: 'apple',emoji: '+1',size: 24})}}></span>
    // })
    // !props.edit?"":""
    const showDeleteButton = (loc) => {
        return !props.taskList?false:props.taskList.some(task => ((task.tas_is_active === 0 || task.tas_is_active === 1) && task.tas_loc_id === loc.locId))
    }
    let selectedPloc;
    if(props.edit){
        selectedPloc = props.pLocations.map((loc, indx) => <ListGroup.Item style={{backgroundColor: '#EBF8FF', border:'1px solid #3FA7D6', marginBottom: 3}} key={indx} variant="info">{showDeleteButton(loc) ? null : <span style={{ marginRight: "10px", cursor: "pointer" }} onClick={props.onLocDelete.bind(this, loc.locId)}><i className="fas fa-trash text-danger"></i></span>}{loc.location_addr1 + " " + loc.location_addr2 + ", " + loc.location_city + ", " + loc.location_state + ", " + loc.location_zip}</ListGroup.Item>)
    }else{
        selectedPloc = props.pLocations.map((loc, indx) => <ListGroup.Item style={{backgroundColor: '#EBF8FF', border:'1px solid #3FA7D6', marginBottom: 3}} key={indx} variant="info"><span style={{ marginRight: "10px", cursor: "pointer" }} onClick={props.onLocDelete.bind(this, loc.locId)}><i className="fas fa-trash text-danger"></i></span>{loc.location_addr1 + " " + loc.location_addr2 + ", " + loc.location_city + ", " + loc.location_state + ", " + loc.location_zip}</ListGroup.Item>)
    }
    let code = ccs.getCountryByShort('US')
    
    return (
        <div>
            {/* <Form.Group controlId="pstrtDate">
                <Form.Label>START DATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.pstrtDate} type="date" onChange={props.handledateLocationChange} disabled={props.edit} />
            </Form.Group> */}
            {/* <div className='d-flex flex-row'> */}
            <Form.Row>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {/* <Grid container justify="flex-start"> */}
                        <DatePicker
                        // style={{width:'35%'}}
                        className={!!props.edit?'mx-1 w-100':'mx-1 my-3'}
                        id="pstrtDate"
                        label="START DATE"
                        disabled={props.edit}
                        value={new Date(moment(props.formVals.pstrtDate))}
                        onChange={(d) => {props.handledateLocationChange({target: {value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss'),id : 'pstrtDate'}})}}
                        slotProps={{textField: {variant: 'outlined'}}}
                        />
                    {/* </Grid> */}
                </LocalizationProvider>

            {/* <Form.Group controlId="pEndDate">
                <Form.Label>END DATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.pEndDate} type="date" onChange={props.handledateLocationChange} />
            </Form.Group> */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/* <Grid container justify="flex-start"> */}
                    <DatePicker
                    // style={{width:'35%'}}
                    className={!!props.edit?'mx-1 w-100':'mx-1 my-3'}
                    id="pEndDate"
                    label="END DATE"
                    // disabled={props.edit}
                    value={new Date(moment(props.formVals.pEndDate))}
                    onChange={(d) => {props.handledateLocationChange({target: {value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss'),id : 'pEndDate'}})}}
                    slotProps={{textField: {variant: 'outlined'}}}
                    />
                {/* </Grid> */}
            </LocalizationProvider>
            {/* </div> */}
            </Form.Row>


            {/* <Form.Group controlId="pLoc">
                <Form.Label>LOCATIONS</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control as="select" onChange={props.handleMultipleLoc}>
                    <option>Select Location</option>
                    {pLoc}
                </Form.Control>
            </Form.Group> */}

            <Autocomplete
                id="pLoc"
                value={null}
                className={!!props.edit?'mx-1 my-3 w-100':'mx-1 my-3 w-75'}
                onChange={(e, newValue)=> {
                    let value = pLoc.find((loc)=> loc.title === newValue.title)
                    console.log(value)
                    if(!!value){
                        props.handleMultipleLoc({target: {value: value.value, id: 'pLoc'}})
                    }
                    // return
                    
                }}
                options={pLoc}
                getOptionLabel={(option) => option.title}
                // style={{ width: 450 }}
                renderInput={(params) => <CssTextField {...params} label="LOCATION *" variant="outlined" />}
            />

            {(props.mapRender === "proj" || props.edit) && <div className={!!props.edit?'mb-2 w-100':'mb-2 w-75'} style={{ height: "284px" }}>
                <ReactBingmaps
                    id={props.mapRender}
                    bingmapKey={BINGAPIKEY}
                    center={[40.735657, -74.172363]}
                    infoboxesWithPushPins={props.mapPushPins}
                    zoom={8}
                >
                </ReactBingmaps>
            </div>}
            <Form.Label>SELECTED LOCATIONS</Form.Label>
            <ListGroup className={!!props.edit?'w-100':'w-75'}>
                {(!props.pLocations.length) ? <ListGroup.Item style={{backgroundColor: '#EBF8FF', border:'1px solid #3FA7D6'}} >No Location Selected</ListGroup.Item> : selectedPloc}
            </ListGroup>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="pPrice">
                    <Form.Label>PRICE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={props.formVals.pPrice} placeholder="Amount" onChange={props.handledateLocationChange} />
                </Form.Group> */}
                <CssTextField
                    style={{width:!!props.edit?'40%':'30%'}}
                    className={`mx-2 my-3`}
                    label="PRICE"
                    value={props.formVals.pPrice}
                    onChange={props.handledateLocationChange} 
                    variant="outlined"
                    id="pPrice"
                    required
                    type="number"
                />

                {/* <Form.Group as={Col} controlId="pCurrency">
                    <Form.Label>CURRENCY</Form.Label>
                    <Form.Control value={props.formVals.pCurrency} as="select" onChange={props.handledateLocationChange}>
                        <option value="">Select Currency</option>
                        {curr}
                    </Form.Control>
                </Form.Group> */}
                {/* <Form.Label>CURRENCY</Form.Label> */}

                {/* <Form.Group as={Col} controlId="pCurrency">
                    <Form.Label>CURRENCY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <div className="d-flex flex-row justify-content-around" style={{ width: '14.5rem', height: '2.4rem', border: '1px solid #D6D6D6', borderRadius: '5px', textAlign: 'center' }}>
                        <div style={{ marginLeft: 0, marginTop: 5 }}><Emoji emoji=':flag-us:' size={26} /></div> <div style={{ marginRight: 5, marginTop: 5 }}>USD</div>
                    </div>
                </Form.Group> */}
                <CssTextField
                  style={{width: !!props.edit?'50%':'40%'}}
                    className={`mx-1 my-3`}
                    label="CURRENCY"
                    value={`${code.emoji} ${code.name}(${code.currency})`}
                    // onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="pCurrency"
                    inputProps={{ maxLength: 50 }}
                />
            </Form.Row>

            <Button className={!!props.edit?'w-100 ml-2':'w-75 ml-2'} variant="primary" block type="button"
                onClick={(e) => {
                    if (props.edit) {
                        if (props.onContinue('dateLocation')){props.addProjectToDB(e)}
                         else { e.preventDefault()}
                        // props.addProjectToDB(e)
                    } else {
                        props.onContinue("finalDetails")

                    }
                }}
                disabled={!props.error ? false : true}>
                {props.edit ? "Submit" : "Continue"}
            </Button>
        </div>
    )
};

export default DateLocation;