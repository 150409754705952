import React, { useState, useEffect, } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import moment from "moment-timezone";
import {
    TextField,
    Typography,
    Button,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tooltip from "@mui/material/Tooltip";
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../../config';
import "./Remittances.css";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from "../../DataGrid/ControlledDataGrid";
import {
    DataGridPro,
    GridToolbar
} from '@mui/x-data-grid-pro';

const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY });
const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

export default function Remittances(props) {
    const [gridId, setGridId] = useState(63);
    const apiRef = useGridApiRef();

    const [remList, setRemList] = useState([]);
    const [displayRemList, display] = useState([]);
    const [loggedToSegment, setLoggedToSegment] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
    const [currentMonthEnd, setCurrMonthEnd] = useState(moment().endOf("month"));
    const [totRec, setTotRec] = useState(0);

    const logToSegment = () => {
        if (loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        console.log(page, window.location.href)
        if (page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page)
        analytics.page(page + " : Remittances", {
            title: page + " : Remittances",
            path: page + " : Remittances",
        }, {
            userId: String(props.user.emp_id),
        })
        console.log("logged to segment successfully")
        setLoggedToSegment(true);
    }
    useEffect(() => {
        async function fetchData() {
            axios.defaults.headers = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
                "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
                Authorization: "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
            };
            console.log(axios.defaults.headers)
            let remGet = await axios.get(APIURL + "/claims", { params: { companyID: props.comp_id, rem: true } });
            let allRems = remGet.data.map((rem, index) => {
                rem.id = index;
                return rem;
            });
            setRemList(allRems);
            setLoading(false);
        }
        logToSegment();
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            let billableData = await axios.get(APIURL + "/accountsReceivable", {
                params: { companyID: props.comp_id, month: moment(currentMonth).format('YYYYMM') },
            });

            let totRec = 0;
            billableData.data.forEach((rec) => {
                totRec += rec.total;
            });

            setTotRec(totRec);
        }

        fetchData();
    }, [currentMonth]);

    function onDateChange(newValue) {
        console.log("start DATE CHANGE");
        setCurrMonth(moment(newValue).startOf("month"));
        setCurrMonthEnd(moment(newValue).endOf("month"));
        console.log("end DATE CHANGE");
    }

    const getRemList = () => {
        let displayRemList = remList;
        displayRemList = displayRemList.filter((rem) => moment(rem.uploadDate).month() === currentMonth.month() && moment(rem.uploadDate).year() === currentMonth.year())

        let rows = displayRemList.map((rem) => {
            let remDets = {
                ...rem,
                startDate: moment(rem.startDate).isValid() ? moment(rem.startDate).utc().format("L") : "",
                endDate: moment(rem.endDate).isValid() ? moment(rem.endDate).utc().format("L") : "",
                uploadDate: moment(rem.uploadDate).isValid ? moment(rem.uploadDate).utc().format("L") : "",
                remDate: moment(rem.remDate).isValid() ? moment(rem.remDate).utc().format("L") : "",
                claimMatchStatus: rem.matchedClaimId ? "Claim matched" : "Claim not matched"
            }
            return remDets;
        });

        console.log(rows);

        let cols = [
            {
                field: "id",
                headerName: "ID",
                width: 50,
                hide: true,
            },
            {
                field: "remId",
                headerName: "Rem. ID",
                hide: true,
            },
            {
                field: "medicaidId",
                headerName: "Medicaid",
                width: 120,
                hide: false,
            },
            {
                field: "firstName",
                headerName: "First Name",
                width: 150,
                hide: false,
                renderHeader: (params) => {
                    return (<div style={{ maxHeight: "inherit", width: "100%", whiteSpace: "initial", lineHeight: "16px" }}>First Name</div>);
                },
                renderCell: (params) => {
                    return (
                        <Tooltip title={params.value}>
                            <div class="crop">{params.value}</div>
                        </Tooltip>);
                },
            },
            {
                field: "lastName",
                headerName: "Last Name",
                width: 150,
                hide: false,
                renderHeader: (params) => {
                    return (<div style={{ maxHeight: "inherit", width: "100%", whiteSpace: "initial", lineHeight: "16px" }}>Last Name</div>);
                },
                renderCell: (params) => {
                    return (
                        <Tooltip title={params.value}>
                            <div class="crop">{params.value}</div>
                        </Tooltip>);
                },
            },
            {
                field: "priorAuth",
                lastName: "Prior Auth",
                width: 120,
                hide: false,
                renderHeader: (params) => {
                    return (<div style={{ maxHeight: "inherit", width: "100%", whiteSpace: "initial", lineHeight: "16px", verticalAlign: "bottom" }}>Prior Authorization</div>);
                },
            },
            {
                field: "startDate",
                headerName: "Start Date",
                width: 100,
                hide: false,
                sortComparator: dateComparator,
            },
            {
                field: "endDate",
                headerName: "End Date",
                width: 100,
                hide: false,
                sortComparator: dateComparator,
            },
            {
                field: "approvedUnits",
                headerName: "Approved Units",
                width: 80,
                hide: false,
                renderHeader: (params) => {
                    return (<div style={{ maxHeight: "inherit", width: "100%", whiteSpace: "initial", lineHeight: "16px", verticalAlign: "bottom" }}>Approved units</div>);
                },
            },
            {
                field: "procCode",
                headerName: "Procedure Code",
                width: 100,
                hide: false,
                renderHeader: (params) => {
                    return (<div style={{ maxHeight: "inherit", width: "100%", whiteSpace: "initial", lineHeight: "16px", verticalAlign: "bottom" }}>Procedure code</div>);
                },
            },
            {
                field: "chargeAmount",
                headerName: "Charge Amount",
                width: 80,
                hide: false,
                renderHeader: (params) => {
                    return (<div style={{ maxHeight: "inherit", width: "100%", whiteSpace: "initial", lineHeight: "16px", verticalAlign: "bottom" }}>Auth Amount</div>);
                },
            },
            {
                field: "approvedAmount",
                headerName: "Approved Amount",
                width: 80,
                hide: false,
                renderHeader: (params) => {
                    return (<div style={{ maxHeight: "inherit", width: "100%", whiteSpace: "initial", lineHeight: "16px", verticalAlign: "bottom" }}>Approved Amount</div>);
                },
            },
            {
                field: "claimMatchStatus",
                width: 150,
                hide: false,
                headerName: "Claim Match Status",
                renderCell: (params) => {
                    return (
                        <Tooltip title={params.value}>
                            <div class="crop">{params.value}</div>
                        </Tooltip>
                    );
                },
            },
            {
                field: "claimStatus",
                headerName: "Claim Status",
                width: 200,
                hide: false,
                renderCell: (params) => {
                    return (
                        <Tooltip title={params.value}>
                            <div class="crop">{params.value}</div>
                        </Tooltip>
                    );
                },
            },
            {
                field: "remDate",
                headerName: "Payment Date",
                width: 120,
                hide: false,
                sortComparator: dateComparator,
            },
            {
                field: "errCodes",
                headerName: "Error Codes",
                width: 120,
                hide: false,
                renderCell: (params) => {
                    return (
                        <Tooltip title={params.value}>
                            <span style={{ overflow: "hidden" }}>{params.value}</span>
                        </Tooltip>
                    );
                },
            },
            {
                field: "errDesc",
                headerName: "Error Desc",
                width: 140,
                hide: false,
                renderCell: (params) => {
                    return (
                        <Tooltip title={params.value}>
                            <span style={{ overflow: "hidden" }}>{params.value}</span>
                        </Tooltip>
                    );
                },
            },
            {
                field: "uploadDate",
                headerName: "Upload Date",
                width: 120,
                hide: false,
                sortComparator: dateComparator,
            }
        ];

        let remListFin = (
            <div style={{ height:'calc(100vh - 200px)', width: '100%' }}>
                <ControlledDataGrid
                    isRowSelectable={(params) => (params.row.id < 0)}
                    gridId={gridId}
                    user={props.user}
                    apiRef={apiRef}
                    initialState={{
                        sorting: { sortModel: [{ field: "uploadDate", sort: "desc" }] },
                    }}
                    columnHeaderHeight={100}
                    width={"100%"}
                    // density={"compact"}
                    justifyContent="center"
                    style={{
                        maxHeight: "88%",
                        maxWidth: "100%",
                        display: "flex",
                    }}
                    rows={rows}
                    columns={cols}
                    columnVisibilityModel={{
                        id: false,
                        remId: false,
                    }}
                    // showToolbar
                    // slots={{
                    //     Toolbar: GridToolbar,
                    // }}
                    autoHeight={false}
                    pageSize={!props.pagesize ? 20 : props.pagesize}
                />
            </div>
        )
        return remListFin;
    }

    return (<div>
        <Typography style={{ fontWeight: "bold", fontSize: "1.5rem", fontFamily: "Lato", marginBottom: 10 }}>
            Remittances
        </Typography>
        <div id="claims-main" style={{ height: "100%", width: "calc(100vw - 500px)", backgroundColor: "#fafafa" }} className="d-flex row justify-content-start">
            <div style={{ height: '100%', width: "calc(100vw - 500px)", marginTop: 30 }} className="col-lg-12 col-sm-7 d-flex flex-column justify-content-start">
                {!!isLoading && (
                    <div className="d-flex flex-row w-100 justify-content-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {!isLoading && (
                    <div className="flex-container" style={{ display: "flex" }}>
                        <div className="flex-item" style={{ flexDirection: "row-reverse", height: "40px", marginTop: "5px", marginBottom: "20px", marginLeft: "20px", }}>
                            <Button
                                size="large"
                                className="DayButton"
                                onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                            >
                                <i className="fas fa-arrow-left"></i>
                            </Button>
                            <LocalizationProvider
                                class="DatePickerParent"
                                dateAdapter={AdapterDateFns}
                            >
                                <DatePicker
                                    label="Select Date"
                                    views={["year", "month"]}
                                    value={new Date(currentMonth)}
                                    sx={{marginTop: '10px'}}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(newValue) => {
                                        onDateChange(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                            <Button
                                size="large"
                                className="DayButton"
                                onClick={() => onDateChange(currentMonth.add(1, "month"))}
                            >
                                <i className="fas fa-arrow-right"></i>
                            </Button>
                        </div>
                        <div className="right">
                            <div className="flex-item-u">
                                <h5><span style={{ textDecoration: 'underline' }}>Total Receivable:</span></h5>
                                <h5>$ {totRec.toFixed(2)}</h5>
                            </div>
                        </div>
                    </div>)}
                {!isLoading && remList.length !== 0 && getRemList()}
                {!isLoading && remList.length === 0 && (
                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "500px", marginTop: -30 }}>
                        <h5 style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }} className="text-center">
                            This week's remittance file hasn't been uploaded yet, please contact the support team.
                        </h5>
                    </div>
                )}
            </div>
        </div>
    </div>);
}