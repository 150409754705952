import react, { useState, useEffect } from 'react';
import { Box, Typography, Chip, Checkbox, Stack, FormControlLabel, FormGroup, TextField, Button, IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';
import { isEmpty } from 'lodash';

const Type = (props) => {
    const [typeList, setTypeList] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [originalTypes, setOriginalTypes] = useState([]);
    const [newType, setNewType] = useState("");

    useEffect(() => {
        setTypeList(props.typeList);
    }, [props.typeList]);

    useEffect(() => {
        setSelectedTypes(props.selectedTypes);
        setOriginalTypes(props.selectedTypes);
    }, [props.selectedTypes]);

    const handleCheckBoxChange = (event) => {
        if(event.target.checked) {
            let type = typeList.find((type) => type.title === event.target.name);
            setSelectedTypes([...selectedTypes, { title: event.target.name, id: type.id, deletable: type.deletable }]);
        } else {
            setSelectedTypes(selectedTypes.filter((type) => type.title !== event.target.name));
        }
    }

    const handleChipDelete = (type) => {
        setSelectedTypes(selectedTypes.filter((t) => t.title !== type.title));
    }

    const handleNewTypeChange = (event) => {
        setNewType(event.target.value);
    }

    const handleAddNewType = () => {
        if(isEmpty(newType)) return;
        let typeObj = { title: newType, id: null, deletable: true, is_Active: 4 };
        props.setTypeList([...typeList, typeObj]);
        setSelectedTypes([...selectedTypes, typeObj]);
        setNewType("");
    }

    const handleDeleteType = (type) => {
        setTypeList(typeList.filter((t) => t.title !== type.title));
        setSelectedTypes(selectedTypes.filter((t) => t.title !== type.title));
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            {props.title}
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                           {props.description}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h6">
                            {props.subTitle}
                        </Typography>
                        <Box sx={{ minHeight: 32 }}>
                            <Box sx={{ minHeight: 40 }}>
                                {selectedTypes.length === 0 && <Typography variant="body1" sx={{ color: 'gray' }}>No {props.subTitle} selected</Typography>}
                                {selectedTypes.map((type, index) => {
                                    return (
                                        <Chip key={index} label={type.title} onDelete={() => handleChipDelete(type)} sx={{ mr: 1, mb: 1 }} color="primary" />
                                    );
                                })}
                            </Box>
                            <Typography variant="h6">
                                Select {props.subTitle}
                            </Typography>
                        </Box>
                        <FormGroup>
                            <Grid container spacing={0}>
                                {typeList.map((type, index) => (
                                    <Grid item xs={6} key={index}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <FormControlLabel
                                                sx={{ height: 25 }}
                                                control={<Checkbox checked={selectedTypes.find((t) => t.title === type.title) ? true : false} onChange={handleCheckBoxChange} name={type.title} />}
                                                label={type.title}
                                            />
                                            {(type.is_Active === 3 || type.is_Active === 4) &&
                                                <IconButton onClick={() => handleDeleteType(type)} sx={{ ml: 1, bottom: 8 }}>
                                                    <Cancel />
                                                </IconButton>
                                            }
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </FormGroup>
                        {!props.disableAddNew &&
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <TextField
                                    variant="outlined"
                                    label={`Add New ${props.subTitle}`}
                                    size='small'
                                    value={newType}
                                    onChange={handleNewTypeChange}
                                    fullWidth
                                />
                                <Button variant="contained" sx={{ ml: 2, width: 100 }} color="primary" onClick={handleAddNewType}>Add</Button>
                            </Box>
                        }
                        <SaveButton onClick={() => props.handleSave(selectedTypes, typeList)} disabled={JSON.stringify(selectedTypes) === JSON.stringify(originalTypes)} />
                    </Stack>
                </Grid>
            </Grid>             
        </Box>
    )
}

export default Type;