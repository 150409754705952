import React, { useState, useEffect } from "react"
import { Modal, Button, Dropdown, DropdownButton } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { APIURL } from '../../config'
import axios from 'axios'
import moment from "moment"
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import { Array.isArray } from "@material-ui/data-grid"
import './TimeSummary.css'
import WeeklyTimesheetAlt2 from '../../components/TimeManagement/WeeklyTimesheetAlt2'
import TimesheetsSummaryTable from '../../components/TimeManagement/TimesheetsSummaryTable'
import EmployeeHeader from "../../components/TimeManagement/EmployeeHeader"
import PreAuthCsv from "../../components/PreAuthCSV/PreAuthCsv"
// import { DataGrid } from '@mui/x-data-grid';
import './TimeSummaryAlt.css'
import MessagePage from "../../components/MessagePages/MessagePage"
import { Link } from "react-router-dom";
import DurationPicker from "../../components/TimeManagement/DurationPicker"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import NativeSelect from '@mui/material/NativeSelect';
import TimesheetForm from "../../components/forms/TimesheetForm/TimesheetForm.js"


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

const filterEmployeeListOptions = createFilterOptions({
    ignoreCase: true,
    matchFrom: 'any',
    stringify: option => option.employee_name,
    trim: true,
})

export default function TimeSummary(props){
    const [isLoading, setIsLoading] = useState(true);
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [currentEmployeeRefresh, setCurrentEmployeeRefresh] = useState(false);
    const [currentEmployeeTimeSheets, setCurrentEmployeeTimeSheets] = useState([]);
    const [companyEmployeeList, setCompanyEmployeeList] = useState([]);
    const [companyInfo, setCompanyInfo] = useState({});
    const [payPeriods, setPayPeriods] = useState([]);
    const [currentPayPeriod, setCurrentPayPeriod] = useState(null);
    const [showTimesheetModal, setShowTimesheetModal] = useState(false);

    useEffect(() => {
        const fetchCompanyData = async () => {
            //fetch company info
            await axios.get(`${APIURL}/company`, {
                params: {
                    comp_id: props.comp_id
                }
            })
            .then(response => {
                setCompanyInfo(response.data[0]);
            })
            .catch(error => {
                console.log('Error on timesheets page:')
                console.error(error);
            });

            if(props.user.type.toLowerCase().includes('manager')){
                await axios.get(`${APIURL}/employees/manager`, {
                    params: {
                        emp_id: props.user.emp_id,
                    }
                })
                .then(response => {
                    //filter employees by emp_is_active=true
                    let employees = response.data.filter(employee => employee.emp_is_active === true);
                    
                    setCompanyEmployeeList(employees.sort((a, b) => a.emp_first_name.localeCompare(b.emp_first_name)));
                })
                .catch(error => {
                    console.log('Error getting employees on timesheets page:')
                    console.error(error);
                });
            } else {
                await axios.get(`${APIURL}/employees`, {
                    params: {
                        com_id: props.company_id,
                    }
                })
                .then(response => {
                    //filter employees by emp_is_active=true
                    let employees = response.data.filter(employee => employee.emp_is_active === true);
                    
                    setCompanyEmployeeList(employees.sort((a, b) => a.emp_first_name.localeCompare(b.emp_first_name)));
                })
                .catch(error => {
                    console.log('Error getting employees on timesheets page:')
                    console.error(error);
                });
            }
            
        }

        setIsLoading(true);
        fetchCompanyData();

        setTimeout(() => {
            setIsLoading(false);
        }, 250);
    }, []);

    useEffect(() => {
        const fetchPayPeriods = async () => {
          if (Object.keys(companyInfo).length !== 0) {
            let type;
            switch (companyInfo.payCycle) {
              case "Bi-Weekly":
                type = 'BIWEEK';
                break;
              case "Monthly":
                type = 'MONTH';
                break;
              case "Semi-Monthly":
                type = 'SEMIMONTH';
                break;
              default:
                type = 'WEEK'
            }
            let day = companyInfo.startoftheWeek === 0 ? 'Sun'
              : companyInfo.startoftheWeek === 1 ? 'Mon'
                : companyInfo.startoftheWeek === 2 ? 'Tue'
                  : companyInfo.startoftheWeek === 3 ? 'Wed'
                    : companyInfo.startoftheWeek === 4 ? 'Thu'
                      : companyInfo.startoftheWeek === 5 ? 'Fri'
                        : 'Sat'
            let pay_periods_response = await axios.get(APIURL + '/pay_period', { params: { payroll_type: type, payroll_day: day } })
            setPayPeriods(pay_periods_response.data.sort((a, b) => moment(a.start_date).diff(moment(b.start_date))));
            
            let today = moment();
            let currentPayPeriod = pay_periods_response.data.find(pay_period => {
              return today.isBetween(moment(pay_period.start_date.replace(/Z/g, "")), moment(pay_period.end_date.replace(/Z/g, "")).endOf('day'));
            })
            setCurrentPayPeriod(currentPayPeriod);
          }
        };
        fetchPayPeriods();
    }, [companyInfo]);

    useEffect(async() => {
        await checkAutomaticPayroll();
    }, [payPeriods]);

    useEffect(() => {
        const fetchCurrentEmployeeTimesheets = async () => {
            if(currentEmployee?.emp_id){
                await axios.get(`${APIURL}/time_sheet`, {
                    params: {
                        emp_id: currentEmployee.emp_id,
                    }
                }).then(response => {
                    setCurrentEmployeeTimeSheets(response.data.sort((a, b) => moment(b.start_date).diff(moment(a.start_date))));
                });
            };
        };
        setIsLoading(true);
        fetchCurrentEmployeeTimesheets();
        setIsLoading(false);
    }, [currentEmployee]);

    useEffect(() => {
        const fetchCurrentEmployeeTimesheets = async () => {
            if(currentEmployee?.emp_id){
                await axios.get(`${APIURL}/time_sheet`, {
                    params: {
                        emp_id: currentEmployee.emp_id,
                    }
                }).then(response => {
                    setCurrentEmployeeTimeSheets(response.data.sort((a, b) => moment(b.start_date).diff(moment(a.start_date))));
                });
            };
        };
        if(currentEmployeeRefresh){
            setIsLoading(true);
            fetchCurrentEmployeeTimesheets();
            setIsLoading(false);
            setCurrentEmployeeRefresh(false);
        }
    }, [currentEmployeeRefresh]);

    useEffect(() => {
        let employee_type = props.user.type.toLowerCase();
        if(employee_type.indexOf('manager') === -1 && employee_type !== 'admin'){
            setCurrentEmployeeById(props.user.emp_id);
        }
    }, [companyEmployeeList]);

    const setCurrentEmployeeById = (emp_id) => {
        setCurrentEmployee(companyEmployeeList.find(employee => employee.emp_id === emp_id));
    }

    const handlePreviousPayPeriod = () => {
        let index = payPeriods.findIndex(pay_period => pay_period.pay_period_id === currentPayPeriod.pay_period_id);
        if (index > 0) {
            setCurrentPayPeriod(payPeriods[index - 1]);
        }
    }

    const handleNextPayPeriod = () => {
        let index = payPeriods.findIndex(pay_period => pay_period.pay_period_id === currentPayPeriod.pay_period_id);
        if (index < payPeriods.length - 1) {
            setCurrentPayPeriod(payPeriods[index + 1]);
        }
    }

    const checkAutomaticPayroll = async() => {
        let id = null;
        const currentDate = moment();
        for (const entry of payPeriods) {
            const startDate = moment(entry.start_date.replace(/.Z/g, ""));
            const endDate = moment(entry.end_date.replace(/.Z/g, ""));

            if (currentDate.isBetween(startDate, endDate, undefined, '[]')) {
                id = entry.pay_period_id;
            }
        }

        if(id){ //TODO 7/31/24 check if payroll period is already active before adding a new one. No harm but can save a request
            let data = {
                "company_id": props.comp_id,
                "pay_period_id": id,
                "pay_period_status": "ACTIVE",
                "closed_on": null,
                "closed_by": null
            }

            console.log('Will attempt to add company payroll period (may already exist) using pay period ID:', id)
            axios.post(APIURL + '/payroll', { ...data })
                .then(activateResult => {
                    
                })
                .catch(error => {
                    console.log('An error occured during the automatic payroll request:')
                    console.error(error);
                });
        }
    }

    const showCurrentEmployeeTimesheets = () => {
        return currentEmployeeTimeSheets.map((timesheet, index) => {
            let type;
            switch (companyInfo.payCycle) {
                case "Bi-Weekly":
                    type = 'BIWEEK';
                    break;
                case "Monthly":
                    type = 'MONTH';
                    break;
                case "Semi-Monthly":
                    type = 'SEMIMONTH';
                    break;
                default:
                    type = 'WEEK'
            }
            if (type === timesheet.payroll_type) {
                return <WeeklyTimesheetAlt2 user={props.user} timeSheetData={timesheet} openByDefault={index == 0} currentEmployee={currentEmployee} setCurrentEmployeeRefresh={setCurrentEmployeeRefresh} setCurrentEmployee={setCurrentEmployee}></WeeklyTimesheetAlt2>
            }
            return null;
        });
    }

    return (
        <div className="timesheet-summary-main" style={{ display: 'flex', padding: '10px', paddingTop: '0px' }}>

            {/* Sidebar */}
            <div style={{ flex: '0 0 15%', display: 'flex', flexDirection: 'column', gap: '10px', padding: '5px', paddingTop: '0px'}}>
                <Button style={{ font: '15px Lato', minWidth: '180px' }} className='FW-Button' variant="contained" onClick={() => setCurrentEmployee(null)}>Timesheets Summary</Button>
                {props.user.type === 'Admin' &&
                    <Link to={'/payroll'}><Button style={{ font: '15px Lato', width: '100%' }} className='FW-Button' variant="contained">Payroll Settings</Button></Link>
                }
                <Button style={{ font: '15px Lato', minWidth: '180px' }} className='FW-Button' variant="contained" onClick={() => setShowTimesheetModal(true)}>New Time Entry</Button>
                
                {props.user.type.toLowerCase().includes('manager') || props.user.type.toLowerCase().includes('admin') ?
                    <Autocomplete
                        value={currentEmployee ? currentEmployee : null}
                        options={companyEmployeeList}
                        getOptionLabel={(option) => `${option.emp_first_name} ${option.emp_last_name} (${option.emp_id})`}
                        onChange={(event, newValue) => {
                            setCurrentEmployee(null)
                            setCurrentEmployee(newValue)
                        }}
                        renderInput={(params) => <TextField {...params} label="Select an employee" />}
                    />
                : null}
            </div>
            
            {currentEmployee?.emp_id ? (
                <div className="employee-weekly-timesheets timesheet-summary-child">
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', marginTop: '50px' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <EmployeeHeader comp_id={props.comp_id} emp_id={currentEmployee.emp_id} employee={currentEmployee} />
                            {showCurrentEmployeeTimesheets()}
                        </>
                    )
                    }
                    
                </div>
            ) : (
                <div style={{ flex: '1', padding: '5px', paddingTop: '0px' }}>
                    <TimesheetsSummaryTable
                        isLoading={isLoading}
                        company_id={props.comp_id}
                        currentPayPeriod={currentPayPeriod}
                        setCurrentEmployeeById={setCurrentEmployeeById}
                        handlePreviousPayPeriod={handlePreviousPayPeriod}
                        handleNextPayPeriod={handleNextPayPeriod}
                        user={props.user}
                    />
                </div>
            )}
            <Modal
                show={showTimesheetModal}
                onHide={() => setShowTimesheetModal(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <TimesheetForm user={props.user} onSubmit={() => {
                        setShowTimesheetModal(false);
                        let tempCurrentEmployee = currentEmployee;
                        setCurrentEmployee(null);
                        setCurrentEmployee(tempCurrentEmployee);
                    } }></TimesheetForm>
                </Modal.Body>
            </Modal>
            
        </div>
    );
}