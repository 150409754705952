import React from 'react';
import { Form, Button } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@mui/styles/withStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import './OtherDetails.css'

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
  })(TextField);


const OtherDetails = (props) => {
    // const designation = props.designation.map((elem, index) => {
    //     return (<option key={elem + index}>{elem}</option>)
    // })

    // const contactType = props.contactType.map((elem, index) => {
    //     return (<option key={elem + index}>{elem}</option>)
    // })

    // const contactStatus = props.contactStatus.map((elem, index) => {
    //     return (<option key={elem + index}>{elem}</option>)
    // })

    // const customerList = props.customerList.map((elem, index) => {
    //     return (<option key={elem + index} value={elem.id}>{elem["customer_name"]}</option>)
    // })

    let designation = props.designation.map((elem, index) => {
        return ({value : elem, title: elem})
    })

    let contactType = props.contactType.map((elem, index) => {
        return ({value : elem, title: elem})
    })

    let contactStatus = props.contactStatus.map((elem, index) => {
        return ({value : elem, title: elem})
    })

    let customerList = props.customerList.map((elem, index) => {
        return ({value : elem.id, title: elem["customer_name"]})
    })
    customerList = [{value:'NULL',title: 'Not Related to Any Customer'},...customerList]

    return (
        <div>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="designation">
                    <Form.Label>DESIGNATION</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control as="select" value={props.formVals.designation} onChange={(event) => props.formChangeHandler(event)} required>
                        <option value="">Select designation</option>
                        {designation}
                    </Form.Control>
                </Form.Group> */}
                <Autocomplete
                    id="designation"
                    value={designation.find((type)=> type.title === props.formVals.designation) || null}
                    className={`mx-1 my-3 w-75`}
                    onChange={(e, newValue)=> {
                        props.formChangeHandler({target: {value: newValue.title, id : 'designation'}})
                    }}
                    options={designation}
                    getOptionLabel={(option) => option.title}
                    // style={{ width: 450 }}
                    renderInput={(params) => <CssTextField {...params} label="DESIGNATION"  variant="outlined" />}
                />
            </Form.Row>
            {/* <Form.Group controlId="customer">
                <Form.Label>Customer</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control as="select" value={props.formVals.customer} onChange={(event) => props.formChangeHandler(event)} disabled={props.edit} required>
                    <option value="">Select Customer</option>
                    <option value="NULL">Not Related to Any Customer</option>
                    {customerList}
                </Form.Control>
            </Form.Group> */}
            <Autocomplete
                id="customer"
                value={customerList.find((type)=> type.value === props.formVals.customer) || null}
                className={`mx-1 my-3 w-75`}
                onChange={(e,newValue)=> {
                    let val = customerList.find((man)=> man.title === newValue.title)
                    props.formChangeHandler({target: {id: 'customer', value: !!val?val.value:''}})
                }}
                disabled={props.edit}
                options={customerList}
                getOptionLabel={(option) => option.title}
                // style={{ width: 450 }}
                renderInput={(params) => <CssTextField {...params} label="RELATED CUSTOMER *"  variant="outlined" />}
            />
            <Form.Row>
                {/* <Form.Group as={Col} controlId="status">
                    <Form.Label>STATUS</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control as="select" value={props.formVals.status} onChange={(event) => props.formChangeHandler(event)} required>
                        <option value="">Select Contact Status</option>
                        {contactStatus}
                    </Form.Control>
                </Form.Group> */}
                <Autocomplete
                    id="status"
                    value={contactStatus.find((type)=> type.title === props.formVals.status) || null}
                    className={`mx-1 my-3 w-75`}
                    onChange={(e,newValue)=> {
                        props.formChangeHandler({target: {value: newValue.title, id : 'status'}})
                    }}
                    options={contactStatus}
                    getOptionLabel={(option) => option.title}
                    // style={{ width: 450 }}
                    renderInput={(params) => <CssTextField {...params} label="CONTACT STATUS *"  variant="outlined" />}
                />
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="contact_type">
                    <Form.Label>CONTACT TYPE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control as="select" value={props.formVals.contact_type} onChange={(event) => props.formChangeHandler(event)} required>
                        <option value="">Select Contact Type</option>
                        {contactType}
                    </Form.Control>
                </Form.Group> */}
                <Autocomplete
                    id="contact_type"
                    value={contactType.find((type)=> type.title === props.formVals.contact_type) || null}
                    className={`mx-1 my-3 w-75`}
                    onChange={(e,newValue)=> {
                        props.formChangeHandler({target: {value: newValue.title, id : 'contact_type'}})
                    }}
                    options={contactType}
                    getOptionLabel={(option) => option.title}
                    // style={{ width: 450 }}
                    renderInput={(params) => <CssTextField {...params} label="CONTACT TYPE *"  variant="outlined" />}
                />
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="first_contact_date">
                    <Form.Label>FIRST CONTACT DATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control type="date" value={props.formVals.first_contact_date} onChange={(event) => props.formChangeHandler(event)}required />
                </Form.Group> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container justifyContent="flex-start">
                        <DatePicker
                            className={`mx-1 my-3 w-75`}
                            id="first_contact_date"
                            label="FIRST CONTACT DATE"
                            value={new Date(moment(props.formVals.first_contact_date).format('YYYY-MM-DDTHH:mm:ss'))}
                            onChange={(d) => {props.formChangeHandler({target: {value: moment(d).format('YYYY-MM-DDTHH:mm:ss'),id : 'first_contact_date'}})}}
                            slotProps={{textField: {variant: 'outlined'}}}
                        />
                    </Grid>
                </LocalizationProvider>
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="last_contact_date">
                    <Form.Label>LAST CONTACT DATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control type="date" value={props.formVals.last_contact_date} onChange={(event) => props.formChangeHandler(event)} required/>
                </Form.Group> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container justifyContent="flex-start">
                        <DatePicker
                        className={`mx-1 my-3 w-75`}
                        id="last_contact_date"
                        label="LAST CONTACT DATE"
                        value={new Date(moment(props.formVals.last_contact_date).format('YYYY-MM-DDTHH:mm:ss'))}
                        onChange={(d) => {props.formChangeHandler({target: {value: moment(d).format('YYYY-MM-DDTHH:mm:ss'),id : 'last_contact_date'}})}}
                        slotProps={{textField: {variant: 'outlined'}}}
                        />
                    </Grid>
                </LocalizationProvider>
            </Form.Row>
            <Button className='w-75 ml-2 mb-2' variant="primary" block type="submit">
                {!props.edit?'Create Contact':'Save Changes'}
            </Button>
        </div>
    );
};

export default OtherDetails;