import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Form, FormControl, Button } from "react-bootstrap";
import { StyledEngineProvider } from "@mui/material/styles";
import { Tab, Tabs, Autocomplete } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import moment from "moment";
import ReportCard from "./ReportCard";
import axios from "axios";
import { APIURL } from "../../config";
import { Typography } from "@mui/material";
import TreeViewDataReports from "./TreeViewDataReports";
import TreeViewMTDetailsReports from "./TreeViewMTDetailsReports";
import ManagementReviewReports from "./ManagementReviewReports";
import ActiveCustomerReport from "./ActiveCustomerReport";
import TasksReport from "./TasksReport";
import LateMTReport from "./LateMTReport";
import MTSummaryReport from "./MTSummaryReport";
import SessionReport from "./SessionReport";
import SessionReport2 from "./SessionReport2";
import dayjs from "dayjs";
// import "./reports.css";

const useStyles = makeStyles((theme) => ({
  tabss: {
    "& .MuiTab-root": { textTransform: "capitalize" },
    "& .Mui-selected": {
      backgroundColor: "#edebeb7a",
      fontWeight: "bold",
      fontFamily: "Lato",
    },
  },
}));

const Billings = (props) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  // const [tabValue, setTabValue] = useState("1");

  useEffect(() => {
    // handleChange('', tabValue);
  }, []);

  const onSubmitSearch = (e) => {
    console.log("Search Text: ", props);
    e.preventDefault();
  };
  // console.log("Company.js>>", props);
  const handleChange = (event, newValue) => {
    // console.log("event, newValue", event, newValue, props);
    // setTabValue(newValue);
    switch (newValue) {
      case "1":
        props.openReport({
          report_name: "companyTab",
        }, "open");
        break;
      case "2":
        props?.getPlanRenewalReports();
        break;
      case "3":
        props?.getMTCompletionsDetailsReports();
        break;
      case "4":
        props?.getManagementReviewReports();
        break;
      case "5":
        props?.getActiveCustomerReport();
        break;
      case "6":
        props?.getTasksReport();
        break;
      case "7":
        props?.getLateMTReport();
        break;
      case "8":
        props?.getMTSummaryReport();
        break;
      case "9":
        props?.getSessionReport();
        break;
      case "10":
        props?.getSessionReport2();
        break;

      default:
        break;
    }
  };
  return (
    <Grid>
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              fontFamily: "Lato",
              marginBottom: 5,
            }}
          >
            Administration Reports
          </Typography>
        </Grid>

        {/* <Grid item xs={5}>
          <Form inline onSubmit={onSubmitSearch} className="mb-4">
            <FormControl
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              type="text"
              value={searchText}
              placeholder="Search"
              className="form-control w-50 mr-1"
            />
            <Button
              type="submit"
              variant="outline-primary"
              style={{
                border: "solid 1px #95D0EB",
                marginLeft: "-5px",
                borderRadius: "0 5px 5px 0",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <i className="fas fa-search"></i>
            </Button>
          </Form>
        </Grid> */}

        <Grid item xs={9}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
            onChange={handleChange}
            value={props?.tabValue}
            className={classes.tabss}
            variant="scrollable"
          >
            <Tab
              index={1}
              label="Company"
              value="1"
            />
            <Tab
              label="Plan Renewal Report"
              value="2"
            />
            <Tab
              index={3}
              label="MT Completion Detail Report"
              value="3"
            />
            <Tab
              index={4}
              label="Management Review Report"
              value="4"
            />
            <Tab
              index={5}
              label="Active Customer Report"
              value="5"
            />
            <Tab
              index={6}
              label="Tasks Report"
              value="6"
            />
            <Tab
              index={7}
              label="Late MT Report"
              value="7"
            />
            <Tab
              index={8}
              label="MT Summary Report"
              value="8"
            />
            <Tab
              index={9}
              label="Session Report"
              value="9"
            />
            <Tab
              index={10}
              label="Session Report 2"
              value="10"
            />
          </Tabs>
        </Grid>

        {(props?.tabValue == "6" || props?.tabValue == "9" || props?.tabValue == "10") && (
          <Grid style={{ display: 'flex', marginBottom: '20px', marginTop: '20px' }}>
            {props?.tabValue == "9" &&
              <Grid item xs={6} className="mr-2" style={{ marginTop: -15 }}>
                <Autocomplete
                  id="client-select"
                  options={props?.customersList}
                  getOptionLabel={(option) => option.cus_name}
                  onChange={(event, value) => props?.setState({
                    ...props?.state,
                    selectedCustomer: value.cus_id,
                  })}
                  renderInput={(params) => <TextField {...params} label="Select Client" margin="normal" />}
                  fullWidth
                  size="small"
                />
              </Grid>
            }
            {props?.tabValue == "10" &&
              <Grid item xs={6} className="mr-2" style={{ marginTop: -15, marginLeft: 60 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      id="date"
                      label="SESSION DATE"
                      value={new Date(moment(props.state.sessionDate).format('YYYY-MM-DDTHH:mm:ss'))}
                      onChange={(d) => {
                        props?.setState({
                          ...props?.state,
                          sessionDate: moment(d).format('YYYY-MM-DD'),
                        })
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="SESSION DATE"
                          error={''}
                        />
                      )}
                    />
                </LocalizationProvider>
              </Grid>
            }
            {(props?.tabValue == "6" || props?.tabValue == "9") &&
              <Grid item xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={{ start: "From Date", end: "To Date" }}
                >
                  <DateRangePicker
                    value={dayjs(props?.companyFilterData?.dateRange)}
                    onChange={(newValue) => {
                      props?.setState({
                        ...props?.state,
                        companyFilterData: {
                          dateRange: [new Date(newValue[0]), new Date(newValue[1])],
                        },
                      });
                      console.log('billingFilterData props', props.companyFilterData)
                      console.log('billingFilterData >>>', moment(
                        new Date(newValue[0])
                      ).isValid()
                        ? moment(
                          new Date(newValue[0])
                        ).format("YYYY-MM-DD")
                        : null)
                    }}
                    slotProps={{ fieldSeparator: { children: 'to' } }}
                  />
                </LocalizationProvider>
              </Grid>
            }
            <Grid style={{ marginLeft: '10px' }} item xs={1}>
              <Button
                onClick={(e) => {
                  console.log("Search...", props?.state);
                  props?.onSearch(e)
                }}
                variant="primary"
                size="lg"
                className="font-weight-bold"
                style={{ fontSize: 14, fontFamily: "Lato" }}
              >
                SEARCH
              </Button>
            </Grid>
          </Grid>
        )}

        {/* <Grid
          item
          xs={3}
          container
          direction="row"
          justifyContent="flex-end"
          className="mb-2"
          alignItems="center"
        >
          <button
            style={{ outline: "none" }}
            type="button"
            className="btn btn-sm"
            data-toggle="modal"
            data-target="#staticBackdropA"
          >
            <i className="fas fa-filter"></i> Filters
          </button>
          <div className="dropdown">
            <button
              style={{ outline: "none" }}
              className="btn dropdown-toggle mb-1"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>
                <i className="fas fa-sort-amount-up"></i>
              </span>{" "}
              SORT BY
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
              <button
                onClick={() => console.log("title")}
                className="dropdown-item"
                type="button"
              >
                Title
              </button>
              <button
                onClick={() => console.log("date")}
                className="dropdown-item"
                type="button"
              >
                Date
              </button>
            </div>
          </div>
        </Grid> */}
      </Grid>
      {props?.tabValue == "1" && (
        <Grid
          container
          style={{ bottom: "0px", top: "5px", position: "relative" }}
        >
          <Grid item xs={9}>
            {props.data.map((e) => (
              <ReportCard
                data={e}
                openReport={props?.openReport}
                markFavourite={props?.markFavourite}
                getPlanRenewalReports={props?.getPlanRenewalReports}
              />
            ))}
          </Grid>
        </Grid>
      )}

      {props?.tabValue == 2 && props?.renewalData ? (
        <Grid xs={12}>
          <TreeViewDataReports data={props?.renewalData} user={props.user} />
        </Grid>
      ) : null}

      {props?.tabValue == 3 && props?.MTReportsData ? (
        <Grid xs={12}>
          <TreeViewMTDetailsReports data={props?.MTReportsData} user={props.user} />
        </Grid>
      ) : (
        ""
      )}
      {props?.tabValue == 4 && props?.MRevReportsData ? (
        <Grid xs={12}>
          <ManagementReviewReports data={props?.MRevReportsData} user={props.user} />
        </Grid>
      ) : (
        ""
      )}
      {props?.tabValue == 5 && props?.ActiveCustomerData ? (
        <Grid xs={12}>
          <ActiveCustomerReport data={props?.ActiveCustomerData} user={props.user} />
        </Grid>
      ) : (
        ""
      )}
      {props?.tabValue == 6 && props?.TasksData ? (
        <Grid xs={12}>
          <TasksReport data={props?.TasksData} user={props.user} />
        </Grid>
      ) : (
        ""
      )}
      {props?.tabValue == 7 && props?.LateMTData ? (
        <Grid xs={12}>
          <LateMTReport data={props?.LateMTData} user={props.user} onMonthChange={props.onMonthChange} />
        </Grid>
      ) : (
        ""
      )}
      {props?.tabValue == 8 && props?.MTSummaryData ? (
        <Grid xs={12}>
          <MTSummaryReport data={props?.MTSummaryData} user={props.user} />
        </Grid>
      ) : (
        ""
      )}
      {props?.tabValue == 9 && props?.SessionData ? (
        <Grid xs={12}>
          <SessionReport data={props?.SessionData} user={props.user} />
        </Grid>
      ) : (
        ""
      )}
      {props?.tabValue == 10 && props?.Session2Data ? (
        <Grid xs={12}>
          <SessionReport2 data={props?.Session2Data} user={props.user} />
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default Billings;
